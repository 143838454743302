import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { onApiError } from "../helpers/onApiError";

import axios from "shared/api/setup";

export const useCreateDepartmentMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createOrganizationDepartment", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useUpdateDepartmentMutation = (
  departmentId,
  organizationId,
  options
) => {
  const toast = useToast();
  return useMutation(
    async (data) =>
      axios.put(
        `/updateOrganizationDepartment?id=${departmentId}&organizationId=${organizationId}`,
        data
      ),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useDeleteDepartmentMutation = (
  departmentId,
  organizationId,
  options
) => {
  const toast = useToast();
  return useMutation(
    async () =>
      axios.put("/deleteOrganizationDepartment", {
        departmentId,
        organizationId,
      }),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
