import React from "react";
import ReactRouterPrompt from "react-router-prompt";

import { ConfirmationAlert } from "../confirmation-alert";

export const PromptIfDirty = ({ showPrompt }) => (
  <ReactRouterPrompt when={showPrompt}>
    {({ isActive, onConfirm, onCancel }) => (
      <ConfirmationAlert
        isOpen={isActive}
        onClose={onCancel}
        onConfirm={onConfirm}
        confirmVariant="warning"
        description="All unsaved changes will be lost, are you sure you want to leave?"
        isAlert={false}
        cancelLabel="Cancel"
        confirmLabel="Discard and Leave"
      />
    )}
  </ReactRouterPrompt>
);
