import React, { useRef } from "react";
// import { isEmpty } from "lodash"; //For next sprint
import { Box, Button, Flex, FormLabel, Text } from "@chakra-ui/react";
import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import {
  FormikInputControl,
  PromptIfDirty,
  RadioBlock,
  RoundedBox,
  MultipleProfileSelection,
  CreateOneInputSetArray,
} from "libs/ui-components/src";
import { useUsers } from "shared/queries/users";
import {
  useDepartmentDispatch,
  useDepartmentState,
} from "../../DepartmentContext";
import difference from "lodash.difference";

export const DetailsStep = ({
  onNext,
  onOpenDeleteWarning,
  organisation,
  isEdit = false,
}) => {
  const { details: detailsValues, dirty: formDirty } = useDepartmentState();
  const formRef = useRef(null);
  const { data: users, isLoading: isUserLoading } = useUsers();
  // const { data: invitedUsers, isLoading: isInvitedUserLoading } =
  //   useInvitedUsers(); //For next sprint

  if (isUserLoading) {
    return null;
  }
  let availableUsers = users;

  // if (!isEmpty(invitedUsers)) { //For next sprint
  //   availableUsers = users.concat(invitedUsers);
  // }

  const { createdBy, userIds = [] } = { ...organisation };
  const representatives = availableUsers?.filter(
    (user) => userIds.includes(user.id) || createdBy === user.id
  );
  const { onUpdateDetails, onUpdateDirty } = useDepartmentDispatch();
  const handleNext = (values) => {
    onUpdateDetails(values);
    onUpdateDirty(true);
    onNext(values);
  };
  return (
    <Box>
      <Text variant="h2" color="white" mb="25px">
        Enter team details
      </Text>
      <Box mb="25px">
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={detailsValues}
          onSubmit={handleNext}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            description: Yup.string().required("Description is required"),
            documentationUrl: Yup.string().url(),
            isHidden: Yup.boolean(),
            stakeHolderUserIds: Yup.array().of(Yup.string()),
            goals: Yup.array().of(Yup.string().required("Goal is required")),
            hazards: Yup.array().of(
              Yup.string().required("Hazard is required")
            ),
          })}
        >
          {({ setFieldValue, values, errors, dirty }) => (
            <Form id="detailsForm" noValidate>
              <PromptIfDirty showPrompt={!formDirty && dirty} />
              <RoundedBox mb="25px">
                <FormikInputControl
                  label="Name"
                  name="name"
                  placeholder="Give a name to the team"
                  required
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Description"
                  name="description"
                  placeholder="Describe the team"
                  required
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Working files"
                  name="documentationUrl"
                  placeholder="https://deepwork.studio"
                  styleProps={{ mb: "21px" }}
                />
                <MultipleProfileSelection
                  label="Project Manager(s)"
                  possibleProfiles={representatives ?? []}
                  selectedUserIds={values.projectManagersUserIds}
                  error={errors.projectManagersUserIds}
                  showError={!!errors.projectManagersUserIds}
                  onChange={(value) =>
                    setFieldValue(
                      "projectManagersUserIds",
                      value?.map((item) => item.value) || []
                    )
                  }
                  disabled={false}
                  styleProps={{ mb: "21px" }}
                />
                <MultipleProfileSelection
                  label="Assign a Lead(s)"
                  possibleProfiles={representatives ?? []}
                  selectedUserIds={values.stakeHolderUserIds}
                  error={errors.stakeHolderUserIds}
                  showError={!!errors.stakeHolderUserIds}
                  onChange={(value) => {
                    const updatedStakeHolderUserIds =
                      value?.map((item) => item.value) || [];

                    const currentStakeHoldersUserIds =
                      values.stakeHolderUserIds || [];
                    const currentProjectManagersUserIds =
                      values.projectManagersUserIds || [];

                    const onlyCustomProjectManagersUserIds = difference(
                      currentProjectManagersUserIds,
                      currentStakeHoldersUserIds
                    );
                    const updatedProjectManagersUserIds = [
                      ...updatedStakeHolderUserIds,
                      ...onlyCustomProjectManagersUserIds,
                    ];

                    setFieldValue(
                      "stakeHolderUserIds",
                      updatedStakeHolderUserIds
                    );

                    setFieldValue(
                      "projectManagersUserIds",
                      updatedProjectManagersUserIds
                    );
                  }}
                  disabled={false}
                  styleProps={{ mb: "21px" }}
                />
              </RoundedBox>
              <RoundedBox mb="25px">
                <FieldArray
                  name="goals"
                  render={(arrayHelpers) => (
                    <CreateOneInputSetArray
                      fieldName="goals"
                      title="Goal"
                      fieldValues={values.goals}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                      bg="gray.24"
                    />
                  )}
                />
              </RoundedBox>
              <RoundedBox mb="25px">
                <FieldArray
                  name="hazards"
                  render={(arrayHelpers) => (
                    <CreateOneInputSetArray
                      fieldName="hazards"
                      title="Hazard"
                      fieldValues={values.hazards}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                      bg="gray.24"
                    />
                  )}
                />
              </RoundedBox>
              <RoundedBox mb="25px">
                <FormLabel>Team permissions</FormLabel>
                <Flex
                  flexDirection={{ base: "column", md: "row" }}
                  gridColumnGap="55px"
                  gridRowGap="25px"
                >
                  <RoundedBox bg="gray.24" w="270px">
                    <RadioBlock
                      radioProps={{
                        isChecked: !values.isHidden,
                        onChange: () => setFieldValue("isHidden", false),
                      }}
                      label="Public"
                      description="Everyone can access the team"
                    />
                  </RoundedBox>
                  <RoundedBox bg="gray.24" w="270px">
                    <RadioBlock
                      radioProps={{
                        isChecked: values.isHidden,
                        onChange: () => setFieldValue("isHidden", true),
                      }}
                      label="Private"
                      description="Only the team lead and specific accounts can access the
                        team"
                    />
                  </RoundedBox>
                </Flex>
              </RoundedBox>
              <Box
                display="flex"
                mt="32px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button type="submit">{isEdit ? "Update" : "Continue"}</Button>
                {isEdit && (
                  <Button
                    variant="link"
                    color="warning"
                    mt={{ base: "32px", md: 0 }}
                    onClick={onOpenDeleteWarning}
                  >
                    Delete team
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
