import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { isEmpty, isUndefined } from "lodash";

import { AppContainer } from "components/app-container";
import { PromptIfDirty } from "libs/ui-components/src";
import {
  useOrganisationDetails,
  useOrganisationTeamFunctions,
} from "shared/queries/organisation";
import { useUserSkillsByOrganization } from "shared/queries/users";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { APP_PATHS } from "paths";
import {
  DepartmentProvider,
  useDepartmentDispatch,
  useDepartmentState,
} from "../DepartmentContext";
import { FunctionsStep } from "../steps/FunctionsStep";

import groupBy from "lodash.groupby";

const EditDepartmentFunctionsComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUserContextState();
  const { organizationId, departmentId } = params;
  const { data: organisation, isLoading: isOrganizationLoading } =
    useOrganisationDetails(organizationId);
  const { data: organizationFunctions = [], isLoading: isFunctionsLoading } =
    useOrganisationTeamFunctions([organizationId], departmentId);
  const { data: userSkills, isLoading: isUserSkillsLoading } =
    useUserSkillsByOrganization(organizationId);
  const { dirty } = useDepartmentState();
  const { onUpdateDetails, onUpdateFunctions, onUpdateDirty } =
    useDepartmentDispatch();
  const [functionList, setFunctionList] = useState([]);
  const { createdBy, departments = [] } = { ...organisation };
  const department = departments?.find(
    (department) => department._id === departmentId
  );
  const { name, stakeHolderUserIds } = { ...department };
  const isAdmin = createdBy === user?.id;
  const isTeamLeader = stakeHolderUserIds?.includes(user?.id);
  useEffect(() => {
    if (department) {
      const departmentDefaultData = { ...department };
      onUpdateDetails(departmentDefaultData);
      onUpdateFunctions({
        functions: functionList,
      });
    }
  }, [department, functionList]);

  useEffect(() => {
    if (!isUndefined(userSkills)) {
      const userSkillsMap = groupBy(userSkills, "organizationRoleId");
      setFunctionList(
        organizationFunctions.map((item) => ({
          ...item,
          owners: userSkillsMap[item.id]?.map((skill) => skill.userId) || [],
          userSkills: userSkillsMap[item.id] || [],
        }))
      );
    }
  }, [organizationFunctions, userSkills]);
  const existingFunctionIds = organizationFunctions.map((item) => item.id);

  const handleFinish = async () => {
    onUpdateDirty(false);
    navigate(
      generatePath(APP_PATHS.departmentDetails, {
        departmentId,
        organizationId,
      })
    );
  };

  if (isOrganizationLoading || isFunctionsLoading || isUserSkillsLoading) {
    return null;
  }
  const steps = [];

  if (isAdmin) {
    steps.push({ page: 1, name: "functions", label: "Roles" });
  } else if (isTeamLeader) {
    steps.push({ page: 1, name: "functions", label: "Roles" });
  }
  if (isEmpty(steps)) {
    return (
      <>
        <AppContainer.Header>
          <Button
            mt={4}
            as="a"
            href={generatePath(APP_PATHS.departmentDetails, {
              organizationId,
              departmentId,
            })}
            variant="link"
            leftIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bg="primary"
              >
                <ArrowBackIcon color="#2D3A4A" />
              </Box>
            }
          >
            Back to team
          </Button>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            alignItems={{ base: "flex-start", md: "center" }}
            mt="18px"
          >
            <Box display="flex" flex="1 0 auto">
              <Text variant="h1" color="white">
                Edit “{name}” roles
              </Text>
            </Box>
          </Box>
        </AppContainer.Header>
        <AppContainer.Content isDetails pt="13px">
          <Text>You do not have permissions to edit team</Text>
        </AppContainer.Content>
      </>
    );
  }

  return (
    <>
      <AppContainer.Header>
        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.departmentDetails, {
            organizationId,
            departmentId,
          })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Back to team
        </Button>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          mt="18px"
        >
          <Box display="flex" flex="1 0 auto">
            <Text variant="h1" color="white">
              Edit “{name}” roles
            </Text>
          </Box>
        </Box>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt="13px">
        <PromptIfDirty showPrompt={dirty} />
        <FunctionsStep
          isAdmin={isAdmin || isTeamLeader}
          organizationId={organizationId}
          departmentId={departmentId}
          onNext={handleFinish}
          isEdit={true}
          existingFunctionIds={existingFunctionIds}
        />
      </AppContainer.Content>
    </>
  );
};

export const EditDepartmentFunctions = () => (
  <DepartmentProvider>
    <EditDepartmentFunctionsComponent />
  </DepartmentProvider>
);
