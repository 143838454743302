import React, { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from "@chakra-ui/react";

import { APP_PATHS } from "paths";
import { JoinCard } from "../../layout/join-card/join-card";
import {
  useConfirmJoinOrganizationMutation,
  useDeclineJoinOrganizationMutation,
} from "shared/mutations/organisation";

export const PopupJoinRequestList = ({
  users,
  joinRequests,
  onCloseJoinListWindow,
  isOpenJoinListWindow,
  updateJoin,
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const { mutate: onConfirmRequest } = useConfirmJoinOrganizationMutation();
  const { mutate: onDeclineRequest } = useDeclineJoinOrganizationMutation();

  const getContributor = (joinRequest) => {
    return users?.find((user) => user.id === joinRequest.createdBy);
  };

  const handleApplyClick = (joinRequest) => {
    const joinRequestId = joinRequest.id;

    onConfirmRequest(joinRequestId, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Join request confirm.",
        });
        updateJoin(joinRequestId);
      },
    });
  };

  const handleDeclineClick = (joinRequest) => {
    const joinRequestId = joinRequest.id;

    onDeclineRequest(joinRequestId, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "info",
          title: "Join request decline.",
        });
        updateJoin(joinRequestId);
      },
    });
  };

  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };

  useEffect(() => {
    if (joinRequests?.length === 0) {
      setTimeout(onCloseJoinListWindow, 1000);
    }
  }, [joinRequests, onCloseJoinListWindow]);

  return (
    <Modal
      onClose={onCloseJoinListWindow}
      isOpen={isOpenJoinListWindow}
      isCentered
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent minHeight="20%" maxHeight="70%" mx={5}>
        <ModalHeader
          fontSize="1.75rem"
          pl={10}
          pt={6}
          pb={3.5}
          pr={14}
          color="gray.79"
        >
          Join organization request list
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={3}>
          {joinRequests?.length ? (
            <>
              {joinRequests.map((joinRequest) => (
                <Box mb="26px" key={joinRequest.id}>
                  <JoinCard
                    joinRequest={joinRequest}
                    contributor={getContributor(joinRequest)}
                    onApplyClick={() => handleApplyClick(joinRequest)}
                    onDeclineClick={() => handleDeclineClick(joinRequest)}
                    onUserProfileClick={handleOpenUserProfile}
                  />
                </Box>
              ))}
            </>
          ) : (
            <Text color="white" align="center">
              You will see applications over here once someone applies
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
