import React, { useState } from "react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { groupBy, isEmpty, isEqual, startCase } from "lodash";
import { CSVLink } from "react-csv";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import {
  Budget,
  DataBox,
  FormikInputControl,
  ProfileBox,
} from "libs/ui-components/src";
import { formatDateL } from "libs/utils/src";
import { ETHEREUM, SUPPORTED_CHAINS } from "services/helpers/chains";
import { sendTransaction } from "services/metamask/provider";
import { WalletConnectService } from "services/walletConnect";
import { getWeb3Modal } from "services/walletmodal";

import {
  useCompletePaymentMutation,
  useUnsafeCompletePaymentMutation,
} from "shared/mutations/payments";
import { QueriesKeysEnum } from "shared/queries/queries-keys-enum";

// NOTE: good idea swith to addresses instead
const STABLE_COINS = ["USDT", "USDC", "DAI"];

export const PaymentTable = ({
  payments,
  project,
  smartContracts,
  users,
  creator,
  unsafeMod,
  handleOpenUserProfile,
  paymentRefetch,
  setProcessPayments,
}) => {
  const { mutate: onCompletePayment } = useCompletePaymentMutation({
    onSuccess: () => {
      toast({
        position: "top-right",
        status: "success",
        title: "Payment complete!",
      });
      queryCache.invalidateQueries([QueriesKeysEnum.payments]);
      paymentRefetch();
    },
    onSettled: () => {
      setProcessPayments(false);
    },
  });
  const { mutate: onUnsafeCompletePayment } = useUnsafeCompletePaymentMutation({
    onSuccess: () => {
      toast({
        position: "top-right",
        status: "success",
        title: "Payment complete!",
      });
      queryCache.invalidateQueries([QueriesKeysEnum.payments]);
      paymentRefetch();
    },
    onSettled: () => {
      setProcessPayments(false);
    },
  });

  const toast = useToast();
  const queryCache = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAddress, setAddress] = useState(null);
  const [selectedPayments, setPaymentSelected] = useState({});
  const groupedPayments = groupBy(payments, ({ tokenContractAddress }) =>
    tokenContractAddress.toLowerCase()
  );
  const paymentIds = Object.keys(groupedPayments).reduce(
    (payments, address) => ({
      ...payments,
      [address]: groupedPayments[address]
        .filter((item) => item.state !== "completed")
        .map((item) => item.id),
    }),
    {}
  );

  const handleMarkPaymentClick = (address) => {
    setAddress(address);
    onOpen();
  };

  const selectAllPayments = (address) => {
    if (
      isEqual(selectedPayments[address], paymentIds[address]) &&
      !isEmpty(selectedPayments[address])
    ) {
      setPaymentSelected({
        ...selectedPayments,
        [address]: [],
      });
    } else {
      setPaymentSelected({
        ...selectedPayments,
        [address]: paymentIds[address],
      });
    }
  };

  const selectPayment = (address, id) => {
    const currentSelectedPayments = selectedPayments[address] || [];
    if (currentSelectedPayments.includes(id)) {
      setPaymentSelected({
        ...selectedPayments,
        [address]: currentSelectedPayments.filter((item) => item !== id),
      });
    } else {
      setPaymentSelected({
        ...selectedPayments,
        [address]: [...currentSelectedPayments, id],
      });
    }
  };

  const executePayment = async (address) => {
    const smartContract = smartContracts?.find(
      (contract) => contract.address.toLowerCase() === address
    );
    const chain =
      SUPPORTED_CHAINS.find((chain) => chain.network === project?.chainId) ||
      ETHEREUM;
    const web3Modal = getWeb3Modal(chain.chain_id);
    const provider = await web3Modal.connect();

    selectedPayments[address]?.reduce(async (memo, paymentId) => {
      // Magic. It waits until previous transaction is completed
      const temp = [...(await memo)];
      await setProcessPayments(true);
      const payment = payments?.find((payment) => payment.id === paymentId);
      const { id, amount, walletAddress, tokenContractAddress, status } =
        payment;
      if (status === "completed") {
        return;
      }
      try {
        if (provider instanceof WalletConnectService) {
          await provider.connect();
          const result = await provider.sendTransaction(
            tokenContractAddress,
            walletAddress,
            amount,
            smartContract?.decimals || 18
          );
          if (result?.success) {
            temp.push({ id, transactionHash: result.txHash });
            await onCompletePayment({ id, transactionHash: result.txHash });
          } else if (result?.success === false) {
            toast({
              position: "top-right",
              status: "error",
              title: result?.error?.message || "Unexpected error occurred",
            });
            setProcessPayments(false);
          } else {
            toast({
              position: "top-right",
              status: "error",
              title: "Unexpected error occurred",
            });
            setProcessPayments(false);
          }
        } else {
          const transaction = await sendTransaction(
            provider,
            chain.chain_id,
            tokenContractAddress,
            walletAddress,
            amount,
            smartContract?.decimals || 18
          );
          if (transaction) {
            await transaction.wait();
            temp.push({ id, transactionHash: transaction.txHash });
            await onCompletePayment({ id, transactionHash: transaction.hash });
          } else {
            setProcessPayments(false);
          }
        }
      } catch (error) {
        toast({
          position: "top-right",
          status: "error",
          title: error.message || "Unexpected error occurred",
        });
        setProcessPayments(false);
      }
      await setPaymentSelected({
        ...selectedPayments,
        [address]: selectedPayments[address].filter((item) => item !== id),
      });
      return temp;
    }, []);
  };

  const markPayments = async (address, data) => {
    data.hashes.map(async (hash) => {
      const { id } = hash;
      try {
        await onCompletePayment(hash);
      } catch (error) {
        toast({ position: "top-right", status: "error", title: error.message });
      }
      await setPaymentSelected({
        ...selectedPayments,
        [address]: selectedPayments[address].filter((item) => item !== id),
      });
      onClose();
    });
  };

  const markPaymentsUnsafe = async (value) => {
    try {
      await onUnsafeCompletePayment(value);
    } catch (error) {
      toast({ position: "top-right", status: "error", title: error.message });
    }
    onClose();
  };

  const transactionValidation = unsafeMod
    ? Yup.string().url().required("URL is required")
    : Yup.string().required("hash is required");

  return (
    <>
      {Object.keys(groupedPayments)
        .sort((address) =>
          address === project.currencyTokenContract?.toLowerCase() ? -1 : 1
        )
        .map((address) => {
          const smartContract = smartContracts?.find(
            (contract) => contract.address.toLowerCase() === address
          );
          const contractType =
            smartContract?.symbol ||
            (address === project.currencyTokenContract?.toLowerCase()
              ? "Primary"
              : "Secondary");

          const payments = groupedPayments[address].map((item) => ({
            ...item,
            tokenType: contractType,
          }));
          const csvXeroHeaders = [
            { label: "Date", key: "date" },
            { label: "Amount", key: "amount" },
            { label: "Payee", key: "payee" },
            { label: "Description", key: "description" },
            { label: "Reference", key: "placeholder" },
            { label: "Cheque Number", key: "placeholder" },
            { label: "Transaction Type", key: "placeholder" },
            { label: "Analysis code", key: "placeholder" },
          ];
          const csvXeroData = payments
            .filter((item) => item.state === "completed")
            .map((item) => {
              const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              };
              const date = new Date(item.updatedAt)?.toLocaleDateString(
                "en-US",
                options
              );
              let name;
              let description;
              if (item.type === "costs") {
                const cost = project?.costs?.find(
                  (cost) => cost._id === item.costsId
                );
                name = cost.description;
                description = `${name} (${project.title})`;
              } else if (item.type === "role") {
                const user = users?.find((user) => user.id === item.userId);
                name = `${user?.firstName} ${user?.lastName}`;
                description = `${item.roleId} (${project.title})`;
              } else {
                return;
              }
              return {
                date: date,
                amount: item.amount,
                payee: `${name} ${item.walletAddress}`,
                description,
                placeholder: "",
              };
            });
          const csvXeroFileName = project.title
            ? `XERO - ${project.title} ${contractType}`
            : `XERO - Project ${contractType}`;
          const csvUtopiaHeaders = [
            { label: "Name (optional)", key: "name" },
            { label: "Wallet", key: "wallet" },
            { label: "Amount", key: "amount" },
            {
              label:
                "Amount denominated in (optional) We currently support 5 currencies: USD, CAD, EUR, GBP, AUD." +
                " Leave blank to directly pay amount as the token",
              key: "currency",
            },
            { label: "Pay-out Token", key: "token" },
            { label: "", key: "description" },
          ];
          const csvUtopiaData = payments
            .filter((item) => item.state === "pending")
            .map((item) => {
              let name;
              let currency = "";
              if (STABLE_COINS.includes(contractType)) {
                currency = "USD";
              }
              let description;
              if (item.type === "costs") {
                const cost = project?.costs?.find(
                  (cost) => cost._id === item.costsId
                );
                name = cost.description;
                description = `Send ${item.amount} ${contractType} to ${name} for ${project.title}`;
              } else if (item.type === "role") {
                const user = users?.find((user) => user.id === item.userId);
                name = `${user?.firstName} ${user?.lastName}`;
                description = `Send ${item.amount} ${contractType} to ${name}, ${item.roleId} for ${project.title}`;
              } else {
                return;
              }
              return {
                name,
                wallet: item.walletAddress,
                amount: item.amount,
                currency,
                token: contractType,
                description,
              };
            });
          const csvUtopiaFileName = project.title
            ? `Utopia - ${project.title} ${contractType}`
            : `Utopia - Project ${contractType}`;
          const csvGnosisHeaders = [
            { label: "token_type", key: "tokenType" },
            { label: "token_address", key: "tokenAddress" },
            { label: "receiver", key: "receiver" },
            { label: "amount", key: "amount" },
            { label: "id", key: "id" },
          ];
          const csvGnosisData = payments
            .filter((item) => item.state === "pending")
            .map((item) => {
              return {
                tokenType: "erc-20",
                tokenAddress: item.tokenContractAddress,
                receiver: item.walletAddress,
                amount: item.amount,
                id: "",
              };
            });
          const csvGnosisFileName = project.title
            ? `Gnosis - ${project.title} ${contractType}`
            : `Gnosis - Project ${contractType}`;
          const csvTreasuryHeaders = [
            { label: "Name", key: "name" },
            { label: "Address", key: "address" },
            { label: "Amount", key: "amount" },
          ];

          const paymentsMap = {};

          for (const payment of payments) {
            if (payment.state !== "pending") continue;

            const { walletAddress } = payment;

            paymentsMap[walletAddress] = paymentsMap[walletAddress] || {
              name: "",
              address: walletAddress,
              amount: 0,
            };

            if (payment.type === "costs") {
              const cost = project?.costs?.find(
                (cost) => cost._id === payment.costsId
              );
              paymentsMap[walletAddress].name =
                cost?.description || paymentsMap[walletAddress].name;
            } else if (payment.type === "role") {
              const user = users?.find((user) => user.id === payment.userId);
              paymentsMap[walletAddress].name = user
                ? `${user.firstName} ${user.lastName}`
                : paymentsMap[walletAddress].name;
            }

            const adjustedAmount = Number(payment.amount) * Math.pow(10, 18);
            paymentsMap[walletAddress].amount += adjustedAmount;
          }

          const csvTreasuryData = Object.values(paymentsMap);

          for (const payment of csvTreasuryData) {
            payment.amount = payment.amount / Math.pow(10, 18);
          }

          const csvTreasuryFileName = project.title
            ? `Treasury - ${project.title} ${contractType}`
            : `Treasury - Project ${contractType}`;
          return (
            <Box key={address} mt={{ base: "25px", md: "10px" }}>
              <Box
                display="flex"
                mb={{ base: "15px", md: "25px" }}
                width={{ base: "100%", md: "auto" }}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Text variant="h1" color="white">
                    {`${contractType} requests`}
                  </Text>
                  <Text color="gray.50">
                    Select the relevant requests from the following to continue
                    with payments
                  </Text>
                  <Box
                    display="flex"
                    gridGap="10px"
                    alignItems="center"
                    mb={{ base: "15px", md: "25px" }}
                    mt={{ base: "15px", md: "25px" }}
                  >
                    <DataBox gap="6px" label="Project owner">
                      {creator && (
                        <ProfileBox
                          size="xs"
                          userId={creator.id}
                          onClick={handleOpenUserProfile}
                          name={`${creator.firstName} ${creator.lastName}`}
                          avatarUrl={creator.avatarUrl}
                        />
                      )}
                    </DataBox>
                    <DataBox gap="6px" label="Blockchain">
                      <Text textTransform="capitalize">{project.chainId}</Text>
                    </DataBox>
                  </Box>
                </Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="link"
                    rightIcon={<ChevronDownIcon />}
                  >
                    Export as CSV
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <CSVLink
                        data={csvTreasuryData}
                        headers={csvTreasuryHeaders}
                        filename={`${csvTreasuryFileName}.csv`}
                      >
                        <Button
                          as="div"
                          variant="link"
                          rightIcon={<ExternalLinkIcon />}
                        >
                          Safe Treasury
                        </Button>
                      </CSVLink>
                    </MenuItem>
                    <MenuItem>
                      <CSVLink
                        data={csvGnosisData}
                        headers={csvGnosisHeaders}
                        filename={`${csvGnosisFileName}.csv`}
                      >
                        <Button
                          as="div"
                          variant="link"
                          rightIcon={<ExternalLinkIcon />}
                        >
                          Gnosis SAFE
                        </Button>
                      </CSVLink>
                    </MenuItem>
                    <MenuItem>
                      <CSVLink
                        data={csvXeroData}
                        headers={csvXeroHeaders}
                        filename={`${csvXeroFileName}.csv`}
                      >
                        <Button
                          as="div"
                          variant="link"
                          rightIcon={<ExternalLinkIcon />}
                        >
                          Xero
                        </Button>
                      </CSVLink>
                    </MenuItem>
                    <MenuItem>
                      <CSVLink
                        data={csvUtopiaData}
                        headers={csvUtopiaHeaders}
                        filename={`${csvUtopiaFileName}.csv`}
                      >
                        <Button
                          as="div"
                          variant="link"
                          rightIcon={<ExternalLinkIcon />}
                        >
                          Utopia
                        </Button>
                      </CSVLink>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Box bg="gray.22" borderRadius="12px">
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>
                          <Checkbox
                            isChecked={isEqual(
                              selectedPayments[address],
                              paymentIds[address]
                            )}
                            isDisabled={
                              isEmpty(paymentIds[address]) || undefined
                            }
                            onChange={() => selectAllPayments(address)}
                          />
                        </Th>
                        <Th>Name</Th>
                        <Th>
                          Amount
                          {smartContract ? ` (${smartContract.symbol})` : ""}
                        </Th>
                        <Th>Role</Th>
                        <Th>Date</Th>
                        <Th>Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {payments?.map((payment) => {
                        let name;
                        if (payment.type === "costs") {
                          const cost = project?.costs?.find(
                            (cost) => cost._id === payment.costsId
                          );
                          name = cost.description;
                        } else if (payment.type === "role") {
                          const user = users?.find(
                            (user) => user.id === payment.userId
                          );
                          name = `${user?.firstName} ${user?.lastName}`;
                        } else {
                          return;
                        }
                        return (
                          <Tr key={payment.id}>
                            <Td>
                              <Checkbox
                                isChecked={
                                  selectedPayments[address]?.includes(
                                    payment.id
                                  ) || payment.state === "completed"
                                }
                                isDisabled={
                                  payment.state !== "pending" || undefined
                                }
                                onChange={() =>
                                  selectPayment(address, payment.id)
                                }
                              />
                            </Td>
                            <Td>{name}</Td>
                            <Td>
                              <Budget
                                budget={payment.amount}
                                symbol={""}
                                currency={""}
                              />
                            </Td>
                            <Td>{payment.roleTitle}</Td>
                            <Td>{formatDateL(payment.updatedAt)}</Td>
                            <Td>
                              {payment.transactionUrl ? (
                                <Button
                                  as="a"
                                  href={payment.transactionUrl}
                                  target="_blank"
                                  variant="outline"
                                  rightIcon={<ExternalLinkIcon />}
                                >
                                  View Transaction
                                </Button>
                              ) : (
                                startCase(payment.state)
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                display="flex"
                mt={{ base: "5px", md: "25px" }}
                mb={{ base: "5px", md: "25px" }}
                width={{ base: "100%", md: "auto" }}
                flexWrap="wrap"
                alignItems="center"
              >
                <Button
                  mt={{ base: "32px", md: 0 }}
                  disabled={isEmpty(selectedPayments[address])}
                  onClick={() => executePayment(address)}
                >
                  Execute payments
                </Button>
                <Button
                  ml={{ base: "5px", md: "25px" }}
                  mt={{ base: "32px", md: 0 }}
                  variant="outline"
                  disabled={isEmpty(selectedPayments[address])}
                  onClick={() => handleMarkPaymentClick(address)}
                >
                  Mark as Paid
                </Button>
              </Box>
            </Box>
          );
        })}
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Mark payments complete{" "}
            {unsafeMod ? (
              <Text as="span" color={"warning"}>
                Unsafe
              </Text>
            ) : null}
          </ModalHeader>
          <ModalCloseButton />
          {!unsafeMod ? (
            <ModalBody>
              <Formik
                enableReinitialize
                noValidate
                initialValues={{
                  hashes: selectedPayments[selectedAddress]?.map(
                    (paymentId) => ({
                      id: paymentId,
                      transactionHash: "",
                    })
                  ),
                }}
                onSubmit={(values) => markPayments(selectedAddress, values)}
                validationSchema={Yup.object().shape({
                  hashes: Yup.array().of(
                    Yup.object().shape({
                      id: Yup.string().required("Id is required"),
                      transactionHash: transactionValidation,
                    })
                  ),
                })}
              >
                {() => (
                  <Form noValidate>
                    <FieldArray name="hashes">
                      {() =>
                        selectedPayments[selectedAddress]?.map(
                          (paymentId, index) => {
                            const parentNode = `hashes.${index}`;
                            return (
                              <div
                                key={index}
                                className="list-group list-group-flush"
                              >
                                <div className="list-group-item">
                                  <div className="form-row">
                                    <Field name={`${parentNode}.id`}>
                                      {({ field }) => {
                                        const payment = payments?.find(
                                          (payment) =>
                                            payment.id === field.value
                                        );
                                        return (
                                          <Text
                                            variant="h6"
                                            color="white"
                                            mr="8px"
                                          >
                                            {payment.roleTitle}
                                          </Text>
                                        );
                                      }}
                                    </Field>
                                    <FormikInputControl
                                      label="Please enter the transaction 'hash' here"
                                      type="text"
                                      parentName={parentNode}
                                      name={`${parentNode}.transactionHash`}
                                      placeholder="0x..."
                                      styleProps={{ mb: "15px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      }
                    </FieldArray>
                    <Box display="flex" mb="28px">
                      <Button mr="28px" type="submit">
                        Complete payments
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          ) : (
            <ModalBody>
              <Formik
                enableReinitialize
                noValidate
                initialValues={{
                  ids: selectedPayments[selectedAddress],
                  transactionUrl: "",
                }}
                onSubmit={(values) => markPaymentsUnsafe(values)}
                validationSchema={Yup.object().shape({
                  ids: Yup.array().required("Id is required"),
                  transactionUrl: transactionValidation,
                })}
              >
                {() => (
                  <Form noValidate>
                    <FormikInputControl
                      label="Please enter the transaction 'URL' here"
                      type="text"
                      name="transactionUrl"
                      placeholder="https://..."
                      styleProps={{ mb: "15px" }}
                    />
                    <Box display="flex" mb="28px">
                      <Button mr="28px" type="submit">
                        Complete payments
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
