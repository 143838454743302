import React from "react";
import { Image, Box, Text, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

const TEXT_SIZES = {
  xs: "h6",
  sm: "h5",
  md: "h4",
  lg: "h3",
  xl: "h3",
};

export const ImageBox = ({
  title,
  imageUrl,
  tooltipText,
  description,
  variant = "wide",
  direction = "row",
  color = "primary",
  size = "sm",
  textMaxW = "180px",
  borderRadius = "full",
  fitContent = true,
  onClick,
  boxProps,
}) => {
  const iconProps = {
    ...(!!imageUrl && {
      bgColor: "transparent",
      objectFit: fitContent ? "contain" : "cover",
    }),
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={direction === "column" ? "column" : "row"}
      cursor={onClick ? "pointer" : "default"}
      pr={direction === "column" ? 0 : "15px"}
      {...boxProps}
    >
      <Image
        boxSize={size}
        borderRadius={borderRadius}
        src={imageUrl}
        mr={direction === "column" ? 0 : "16px"}
        mb={direction === "column" ? "16px" : 0}
        {...iconProps}
      />
      <Box
        display="flex"
        flexDirection={variant === "compact" ? "column" : "row"}
        alignItems={variant === "compact" ? "flex-start" : "center"}
      >
        <Box display="flex">
          <Text
            as="span"
            color={color}
            variant={TEXT_SIZES[size] || "h6"}
            aria-label={title}
            isTruncated
            maxW={textMaxW}
          >
            {title}
          </Text>
          {!!tooltipText && (
            <Tooltip label={tooltipText} fontSize="sm">
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
        {!!description && (
          <Text
            as="span"
            variant="h4"
            color="gray.79"
            fontWeight={400}
            ml={variant === "compact" ? 0 : "8px"}
            mt={variant === "compact" ? "7px" : 0}
            aria-label={description}
            textTransform="capitalize"
            isTruncated
            maxW={{ base: "90%", md: "200px" }}
          >
            {description}
          </Text>
        )}
      </Box>
    </Box>
  );
};
