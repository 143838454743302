import React, { useRef, useState } from "react";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  ConfirmationAlert,
  CreateOrganizationFunctionSet,
} from "libs/ui-components/src";
import { useUsers } from "shared/queries/users";
import {
  useDepartmentDispatch,
  useDepartmentState,
} from "../../DepartmentContext";

export const FunctionsStep = ({
  organizationId,
  departmentId,
  isAdmin,
  onBack,
  onNext,
  existingFunctionIds = [],
  isEdit = false,
}) => {
  const { details, functions } = useDepartmentState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openWarning, setOpenWarning] = useState(false);
  let [counter, setCounter] = useState({ true: 0, false: 0 });
  const [functionsToSave, setFunctionsToSave] = useState({});
  const { onUpdateFunctions } = useDepartmentDispatch();

  const { data: users } = useUsers();
  const formRef = useRef(null);
  const handleNext = (values) => {
    if (openWarning) {
      setFunctionsToSave(values);
      onOpen();
    } else {
      confirmSubmit(values);
    }
  };
  const confirmSubmit = (values) => {
    onUpdateFunctions(values);
    onNext(details, values);
  };
  const confirmLeave = () => {
    confirmSubmit(functionsToSave);
  };

  const handleUnsavedrole = (isOpenRole) => {
    let openRoleForms = counter;

    if (isOpenRole) {
      openRoleForms.true++;
    } else {
      openRoleForms.false++;
    }

    setCounter(openRoleForms);

    if (counter.false === counter.true) {
      setOpenWarning(false);
    } else {
      setOpenWarning(true);
    }
  };

  return (
    <Box>
      <ConfirmationAlert
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={confirmLeave}
        description="All unsaved changes will be lost, are you sure you want to leave?"
        isAlert={false}
        cancelLabel="Cancel"
        confirmLabel="Discard and Leave"
      />
      <Text variant="h2" color="white" mb="25px">
        Add or update roles
      </Text>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={functions}
        onSubmit={handleNext}
        validationSchema={Yup.object({
          functions: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required("Title is required"),
              description: Yup.string(),
              governanceTokenMultiplier: Yup.number(),
              changesSummary: Yup.string(),
              owners: Yup.array().of(Yup.string()),
            })
          ),
        })}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form id="pplAndSkillsForm" noValidate>
              <Box mb="25px">
                <CreateOrganizationFunctionSet
                  isEdit={isEdit}
                  functions={values.functions}
                  organizationId={organizationId}
                  departmentId={departmentId}
                  onChange={(e) => setFieldValue("functions", e)}
                  existingFunctionIds={existingFunctionIds}
                  users={users}
                  setOpenWarning={handleUnsavedrole}
                />
              </Box>
              <Box
                display="flex"
                mt="32px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {onBack && isAdmin && (
                    <Button
                      variant="link"
                      mr="40px"
                      onClick={onBack}
                      data-testid={"back-btn"}
                    >
                      Back
                    </Button>
                  )}
                  <Button type="submit" data-testid="next-btn">
                    {isEdit ? "Finish" : "Finish"}
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
