import React from "react";
import { Flex } from "@chakra-ui/react";

import { FormikInputControl } from "../../form/formik-wrappers";

export const InvoiceDataForm = ({ index }) => {
  const parentNode = `items.${index}`;
  return (
    <>
      <Flex>
        <FormikInputControl
          label="Description"
          type="text"
          parentName={parentNode}
          name={`${parentNode}.description`}
          placeholder="Description"
          styleProps={{
            mr: { base: "15px", md: "15px" },
            mb: { base: "32px", md: 0 },
          }}
        />
        <FormikInputControl
          label="Qty"
          type="number"
          parentName={parentNode}
          name={`${parentNode}.quantity`}
          placeholder="1"
          styleProps={{
            mr: { base: "15px", md: "15px" },
            mb: { base: "32px", md: 0 },
          }}
        />
        <FormikInputControl
          label="Unit Price"
          parentName={parentNode}
          name={`${parentNode}.item`}
          type="number"
          placeholder="0"
          styleProps={{ mr: "15px" }}
        />
      </Flex>
    </>
  );
};
