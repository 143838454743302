export const Table = {
  variants: {
    simple: {
      th: {
        color: "gray.400",
        borderBottom: "1px",
        borderColor: "gray.600",
        paddingStart: 4,
        paddingEnd: 4,
      },
      tr: {
        "&:last-of-type": {
          td: {
            borderBottomWidth: 0,
          },
        },
        "&.borderLess": {
          td: {
            borderBottomWidth: 0,
          },
        },
      },
      td: {
        borderBottom: "1px",
        borderColor: "gray.24",
        "&.noPadding": {
          paddingTop: 0,
          paddingBottom: 0,
        },
        paddingStart: 4,
        paddingEnd: 4,
      },
    },
    unstyled: {
      table: {
        background: "gray.18",
        borderRadius: "6px",
      },
      th: {
        fontSize: "0.7rem",
        paddingTop: 3,
        paddingBottom: 1,
        paddingStart: 4,
        paddingEnd: 4,
        textTransform: "capitalize",
        letterSpacing: "normal",
        fontWeight: "medium",
        color: "gray.58",
      },
      td: {
        fontSize: "0.8rem",
        paddingTop: 1,
        paddingBottom: 3,
        paddingStart: 4,
        paddingEnd: 4,
        fontWeight: "medium",
      },
    },
  },
};
