import React, { useState } from "react";
import { AdminCreateButton, RoundedBox } from "libs/ui-components/src";
import { Text } from "@chakra-ui/react";
import { pluralise } from "../../../../../utils/src";
import { FunctionForm } from "./function-form";
import { FunctionListItem } from "./function-list-item";

export const FunctionsList = ({
  isEdit,
  isUpdateOnly,
  onAddFunction,
  onDeleteFunction,
  functions,
  existingFunctionIds,
  users,
  setOpenWarning,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const handleCreateFunctionTest = () => {
    setOpenForm(true);
    setOpenWarning(true);
  };
  const handleSubmitFunction = (values) => {
    onAddFunction({ ...values });
    setOpenForm(false);
    setOpenWarning(false);
  };
  const handleCancel = () => {
    setOpenForm(false);
    setOpenWarning(false);
  };
  const handleDeleteFunction = (id) => {
    onDeleteFunction(id);
  };
  return (
    <>
      {functions.length > 0 && (
        <RoundedBox m="17px 0 25px" bgColor="gray.22">
          {functions.map((item, index) => (
            <FunctionListItem
              key={index}
              item={item}
              isEdit={isEdit}
              isUpdateOnly={isUpdateOnly}
              existingFunctionIds={existingFunctionIds}
              onDelete={handleDeleteFunction}
              onSubmit={handleSubmitFunction}
              users={users}
              setOpenWarning={setOpenWarning}
              boxProps={{
                _notLast: {
                  mb: "13px",
                },
              }}
            />
          ))}
          <Text mt="17px" variant="small" color="gray.58">
            {functions.length} {pluralise("role", functions.length)}
          </Text>
        </RoundedBox>
      )}
      {openForm && (
        <RoundedBox bg="gray.22" mb="10px">
          <FunctionForm
            onSubmit={handleSubmitFunction}
            onCancel={handleCancel}
            users={users}
          />
        </RoundedBox>
      )}
      {!openForm && !isUpdateOnly && (
        <AdminCreateButton bg="gray.22" onClick={handleCreateFunctionTest}>
          Add a role
        </AdminCreateButton>
      )}
    </>
  );
};
