import { Icon } from "@chakra-ui/react";
import React from "react";

export const EllipsisIcon = (props) => (
  <Icon viewBox="0 0 72 72" {...props}>
    <circle cx="20.7008" cy="35" r="5.4" fill="currentColor" />
    <circle cx="35.9996" cy="35" r="5.4" fill="currentColor" />
    <circle cx="51.3004" cy="35" r="5.4" fill="currentColor" />
  </Icon>
);
