import { ApolloClient, InMemoryCache } from "@apollo/client";
import { environment } from "environments";

const graphqlUrl = environment.NEAR_IS_MAIN_NETWORK
  ? "https://interop-mainnet.hasura.app/v1/graphql"
  : "https://interop-testnet.hasura.app/v1/graphql";

export default new ApolloClient({
  uri: graphqlUrl,
  cache: new InMemoryCache(),
});
