import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { identity, pickBy } from "lodash";

import { AppContainer } from "components/app-container";
import { PromptIfDirty, Stepper } from "libs/ui-components/src";
import { useOrganisationDetails } from "shared/queries/organisation";
import { useCreateDepartmentMutation } from "shared/mutations/department";
import { APP_PATHS } from "paths";
import {
  DepartmentProvider,
  useDepartmentDispatch,
  useDepartmentState,
} from "../DepartmentContext";
import { DetailsStep } from "../steps/DetailsStep";
import { FunctionsStep } from "../steps/FunctionsStep";

const NewDepartmentComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { organizationId } = params;
  const { dirty } = useDepartmentState();
  const { onUpdateDirty } = useDepartmentDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const [departmentId, setDepartmentId] = useState();
  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };
  const handleFinish = () => {
    navigate(
      generatePath(APP_PATHS.departmentDetails, {
        departmentId,
        organizationId,
      })
    );
  };
  const handlePrevStep = () => {
    // setActiveStep((prevState) => prevState - 1);
  };
  const { data: organisation, isLoading: isOrganizationLoading } =
    useOrganisationDetails(organizationId);
  const { mutate: onCreateDepartment } = useCreateDepartmentMutation();
  const handleSubmitDepartment = async (details) => {
    const department = {
      ...pickBy(details, identity),
      organizationId,
    };
    onUpdateDirty(false);
    onCreateDepartment(department, {
      onSuccess: async (response) => {
        if (response?.id) {
          setDepartmentId(response?.id);
          handleNextStep();
        } else {
          generatePath(APP_PATHS.organisationDetails, {
            organizationId,
          });
        }
      },
    });
  };
  if (isOrganizationLoading) {
    return null;
  }
  const steps = [
    { page: 1, name: "details", label: "Details" },
    { page: 2, name: "functions", label: "Roles" },
  ];
  const { name } = { ...organisation };
  return (
    <>
      <AppContainer.Header>
        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.organisationDetails, { organizationId })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          {name}
        </Button>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          mt="18px"
        >
          <Box display="flex" flex="1 0 auto">
            <Text variant="h1" color="white">
              Create new Team
            </Text>
          </Box>
        </Box>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      <AppContainer.Content isDetails pt="13px">
        <PromptIfDirty showPrompt={dirty} />
        {activeStep === 1 && (
          <DetailsStep
            organisation={organisation}
            onNext={handleSubmitDepartment}
          />
        )}
        {activeStep === 2 && (
          <FunctionsStep
            organizationId={organizationId}
            departmentId={departmentId}
            onNext={handleFinish}
            onBack={handlePrevStep}
          />
        )}
      </AppContainer.Content>
    </>
  );
};

export const NewDepartment = () => (
  <DepartmentProvider>
    <NewDepartmentComponent />
  </DepartmentProvider>
);
