import React from "react";
import { Badge, Box, Button, Flex, Text, Spacer } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { sumBy } from "lodash";

import { AvatarList } from "../../data-display/avatar-list/avatar-list";
import { DataBox } from "../../data-display/data-box/data-box";
import { Budget } from "../../data-display/data-box/budget/budget";

export const ProjectHidableCard = ({
  onCardClick,
  handleUpdateProjectVisibility,
  projectId,
  projectName,
  projectDescription,
  status,
  dateRange,
  roles,
  costs,
  users,
  currency,
  isVisible = true,
  boxProps,
}) => {
  const updateProjectVisibility = (e, visibility) => {
    e.stopPropagation();
    handleUpdateProjectVisibility(projectId, visibility);
  };

  return (
    <Box
      bg="gray.22"
      borderRadius="12px"
      w={{ base: "100%", sm: "48.2%", md: "290px" }}
      h="280px"
      display="flex"
      flexDirection="column"
      onClick={onCardClick}
      cursor={"pointer"}
      {...boxProps}
    >
      <Flex m="13px 25px 11px" justifyContent="space-between">
        <Box noOfLines={1}>
          <Text color={isVisible ? "white" : "gray.50"} variant="h3">
            {projectName}
          </Text>
        </Box>
        <Button
          variant="link"
          p={0}
          pl={2}
          rightIcon={
            <Box
              height="16px"
              width="16px"
              justifyContent="center"
              alignItems="center"
            >
              {isVisible ? (
                <ViewIcon
                  color="white"
                  onClick={(e) => updateProjectVisibility(e, false)}
                />
              ) : (
                <ViewOffIcon
                  color="white"
                  onClick={(e) => updateProjectVisibility(e, true)}
                />
              )}
            </Box>
          }
        />
      </Flex>
      <Box
        p="14px 25px 18px"
        display="flex"
        borderTop="1px solid"
        borderColor="gray.18"
        flexDirection="column"
        justifyContent="space-between"
        h="100%"
      >
        <Box mb="24px">
          {!!projectDescription && (
            <Box mb="10px">
              <Text color={isVisible ? "white" : "gray.50"} variant="paragraph">
                {projectDescription}
              </Text>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={{ base: "row", md: "column" }}
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "space-between", md: "center" }}
          >
            {!!dateRange && (
              <Box
                mb={{ base: "unset", md: "10px" }}
                order={{ base: 2, md: 1 }}
              >
                <Text color={isVisible ? "gray.79" : "gray.50"} variant="h6">
                  {dateRange}
                </Text>
              </Box>
            )}
          </Box>
          {!isVisible && (
            <Badge bg="gray.18" variant="custom" color="white">
              Project hidden
            </Badge>
          )}
        </Box>
        <Spacer />
        <Box
          display="flex"
          flexDirection={{ base: "row", md: "column" }}
          alignItems={{ base: "center", md: "flex-start" }}
          justifyContent={{ base: "space-between", md: "center" }}
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          {!!status && (
            <Box
              mb={{ base: "24px", md: "5px" }}
              width={{ base: "100%", md: "auto" }}
            >
              <Text color={isVisible ? "fluorescent" : "gray.36"} variant="h8">
                {status}
              </Text>
            </Box>
          )}
          <Box mb={{ base: "unset", md: "24px" }}>
            <DataBox label="Total Budget" disabled={!isVisible}>
              <Budget
                disabled={!isVisible}
                budget={
                  sumBy(roles, "currencyTokenCompensation") +
                  sumBy(costs, "currencyTokenCompensation")
                }
                symbol={""}
                currency={currency}
              />
            </DataBox>
          </Box>
          {!!users?.length && (
            <AvatarList disabled={!isVisible} size="sm" users={users} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
