import React from "react";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";
import { BellIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";

export const Notification = ({ buttonLabel, onReviewClick, children }) => (
  <RoundedBox
    bg="gray.24"
    display="flex"
    alignItems="center"
    p={{ base: "14px 20px", md: "16px 24px" }}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#fff"
      borderRadius="50%"
      minW={{ base: "32px", md: "46px" }}
      minH={{ base: "32px", md: "46px" }}
      fontSize={{ base: "18px", md: "28px" }}
    >
      <BellIcon color="primary" />
    </Box>
    <Box flex={{ base: "unset", sm: "1 0 auto" }}>
      <Text variant="paragraph.l" ml="16px" mr="10px" color="white">
        {children}
      </Text>
    </Box>
    <Button
      minW={{ base: "100px", sm: "190px" }}
      onClick={onReviewClick}
      textTransform="capitalize"
    >
      {buttonLabel}
    </Button>
  </RoundedBox>
);
