import React from "react";
import classNames from "classnames";
import { startCase, isUndefined } from "lodash";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon, CopyIcon } from "@chakra-ui/icons";
import { Budget } from "libs/ui-components/src";
import { formatDateL } from "libs/utils/src";

export const PaymentsTable = ({
  payments,
  projects,
  smartContracts,
  users,
}) => (
  <TableContainer>
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Project name</Th>
          <Th>Amount</Th>
          <Th>Role</Th>
          <Th>More info</Th>
        </Tr>
      </Thead>
      <Tbody>
        {payments?.map((payment) => {
          const smartContract = smartContracts?.find(
            (contract) =>
              contract.address.toLowerCase() ===
              payment.tokenContractAddress.toLowerCase()
          );
          const project = projects?.find(
            (project) => project.id === payment.projectId
          );
          if (isUndefined(project)) {
            return null;
          }
          const symbol =
            smartContract?.symbol ||
            (payment.tokenContractAddress === project.currencyTokenContract
              ? "Primary"
              : "Secondary");
          return (
            <CollapsablePaymentTableRow
              key={payment.id}
              title={project.title}
              symbol={symbol}
              payment={payment}
              users={users}
            />
          );
        })}
      </Tbody>
    </Table>
  </TableContainer>
);

const CollapsablePaymentTableRow = ({ title, symbol, payment, users }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(payment.transactionHash);
  const user = users?.find((user) => user.id === payment?.payerUserId);
  return (
    <>
      <Tr className="borderLess">
        <Td>{title}</Td>
        <Td>
          <Budget budget={payment.amount} symbol={""} currency={symbol} />
        </Td>
        <Td>{payment.roleTitle}</Td>
        <Td>
          <Button
            onClick={onToggle}
            variant="link"
            rightIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bg="primary"
              >
                {isOpen ? (
                  <ArrowUpIcon color="#2D3A4A" />
                ) : (
                  <ArrowDownIcon color="#2D3A4A" />
                )}
              </Box>
            }
          >
            Details
          </Button>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4} className={classNames({ noPadding: !isOpen })}>
          <Collapse in={isOpen} animateOpacity>
            <TableContainer>
              <Table variant="unstyled">
                <Thead>
                  <Tr>
                    <Th>Status</Th>
                    <Th>Date</Th>
                    <Th>Project Owner</Th>
                    <Th>Blockchain</Th>
                    <Th>Transaction hash</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{startCase(payment.state)}</Td>
                    <Td>{formatDateL(payment.updatedAt)}</Td>
                    <Td>{`${user?.firstName} ${user?.lastName}`}</Td>
                    <Td>{startCase(payment.chainId)}</Td>
                    <Td>
                      {payment.transactionHash && (
                        <>
                          {`${payment.transactionHash
                            .replace(" ", "")
                            .slice(0, 8)}...`}
                          <Tooltip
                            label="Copied"
                            closeDelay={500}
                            isOpen={hasCopied}
                          >
                            <IconButton
                              aria-label="copy hash"
                              variant="activeLink"
                              size="sm"
                              onClick={onCopy}
                              icon={<CopyIcon />}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};
