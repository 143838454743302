import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { pluralise } from "libs/utils/src";

import Markdown from "components/Markdown";

import { APP_PATHS } from "paths";
import { generatePath, useNavigate } from "react-router-dom";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";

export const PopupMdDescription = ({
  title,
  description = null,
  organizationId = null,
  departmentId = null,
  organizationRoleId = null,
  canEditRole = false,
  closeDescription,
  isDescriptionOpen,
  goals = [],
  hazards = [],
  isArray = false,
  modalWindowSize = "6xl",
}) => {
  const navigate = useNavigate();

  const handleEditFunctions = () => {
    navigate(
      generatePath(APP_PATHS.editDepartmentFunctions, {
        organizationId,
        departmentId,
      })
    );
  };

  const toast = useToast();

  const hideCopyLinkButton =
    organizationId !== null &&
    departmentId !== null &&
    organizationRoleId !== null;

  const handleCopyLink = () => {
    const siteUrl = window.location.origin;
    const generatedUrl = generatePath(APP_PATHS.organizationRoleDescription, {
      organizationId,
      departmentId,
      organizationRoleId,
    });

    const URL = siteUrl + generatedUrl;

    navigator.clipboard
      .writeText(URL)
      .then(() => {
        toast({
          position: "top-right",
          status: "success",
          title: "URL copied to clipboard.",
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          status: "error",
          title: error.message,
        });
      });
  };

  return (
    <Modal
      onClose={closeDescription}
      isOpen={isDescriptionOpen}
      isCentered
      scrollBehavior="inside"
      size={modalWindowSize}
    >
      <ModalOverlay />
      <ModalContent minHeight="70%" mx={5}>
        <ModalHeader
          fontSize="1.75rem"
          pl={16}
          pt={6}
          pb={3.5}
          pr={14}
          color="gray.79"
        >
          {title}
          {canEditRole && (
            <Button variant="link" onClick={handleEditFunctions} ml={2} mt={5}>
              Edit
            </Button>
          )}
          {hideCopyLinkButton && (
            <Button variant="link" onClick={handleCopyLink} ml={2} mt={5}>
              Copy link
            </Button>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={3}>
          {!isEmpty(goals) && (
            <>
              <Text variant="h1" mx={14} mb="12px">
                {pluralise("Goal", goals.length)}
              </Text>

              <UnorderedList key="goals" styleType="none">
                <Box whiteSpace="pre-wrap" mx={14} fontWeight="700" mb="12px">
                  {goals.map((goal, index) => (
                    <ListItem key={index} mb="12px" ml="-16px">
                      <RoundedBox
                        bg="gray.18"
                        p={{ base: "8px 16px", md: "8px 16px" }}
                        borderRadius="4px"
                      >
                        <Markdown
                          linkTarget="_blank"
                          text={isArray ? goal : goal.description}
                        />
                      </RoundedBox>
                    </ListItem>
                  ))}
                </Box>
              </UnorderedList>
            </>
          )}
          {!isEmpty(hazards) && (
            <>
              <Text variant="h1" mx={14} mb="12px">
                {pluralise("Hazard", hazards.length)}
              </Text>

              <UnorderedList key="hazards" styleType="none">
                <Box whiteSpace="pre-wrap" mx={14} fontWeight="700" mb="12px">
                  {hazards.map((hazard, index) => (
                    <ListItem key={index} mb="12px" ml="-16px">
                      <RoundedBox
                        bg="gray.18"
                        p={{ base: "8px 16px", md: "8px 16px" }}
                        borderRadius="4px"
                      >
                        <Markdown
                          linkTarget="_blank"
                          text={isArray ? hazard : hazard.description}
                        />
                      </RoundedBox>
                    </ListItem>
                  ))}
                </Box>
              </UnorderedList>
            </>
          )}
          {!isEmpty(description) && (
            <>
              <Text variant="h1" mx={14} mb="12px">
                Description
              </Text>
              <RoundedBox
                bg="gray.18"
                display="flex"
                flexDirection="row"
                p={{ base: "8px 16px", md: "8px 16px" }}
                borderRadius="4px"
                mx={14}
                mb="12px"
              >
                <Text whiteSpace="pre-wrap" fontWeight="700">
                  <Markdown linkTarget="_blank" text={description} />
                </Text>
              </RoundedBox>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
