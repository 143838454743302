import Linkify from "linkify-react";
import React from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import classNames from "classnames";
import { formatDateL } from "libs/utils/src";
import { RoundedBox } from "libs/ui-components/src";
import Markdown from "components/Markdown";

const renderLink = ({ attributes, content }) => {
  const { href } = attributes;
  return (
    <Button
      as="a"
      href={href}
      target="_blank"
      variant="link"
      whiteSpace="pre-wrap"
      display="block"
      rightIcon={<ExternalLinkIcon />}
    >
      {content}
    </Button>
  );
};

export const FunctionVersionsTable = ({ versions, createdBy, users }) => (
  <TableContainer>
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th
            color="gray.58"
            textTransform="none"
            fontSize="md"
            fontWeight="450"
            letterSpacing="0"
            borderColor="gray.28"
          >
            Date
          </Th>
          <Th
            color="gray.58"
            textTransform="none"
            fontSize="md"
            fontWeight="450"
            letterSpacing="0"
            borderColor="gray.28"
          >
            Updated by
          </Th>
          <Th borderColor="gray.28" />
        </Tr>
      </Thead>
      <Tbody>
        {versions?.map((version) => (
          <CollapsableFunctionsTableRow
            key={version.versionNumber}
            date={version.date}
            title={version.title}
            description={version.description}
            summary={version.summary}
            createdBy={createdBy}
            updatedBy={version.updatedBy}
            users={users}
          />
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);

const CollapsableFunctionsTableRow = ({
  date,
  title,
  description,
  summary,
  createdBy,
  updatedBy,
  users,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const userId = updatedBy || createdBy;
  const user = users?.find((user) => user.id === userId);
  return (
    <>
      <Tr className="borderLess">
        <Td>{formatDateL(date)}</Td>
        <Td>{`${user?.firstName} ${user?.lastName}`}</Td>
        <Td>
          <Button
            onClick={onToggle}
            variant="link"
            rightIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {isOpen ? (
                  <ChevronUpIcon color="primary" />
                ) : (
                  <ChevronDownIcon color="primary" />
                )}
              </Box>
            }
          >
            Details
          </Button>
        </Td>
      </Tr>
      <Tr>
        <Td
          colSpan={4}
          className={classNames({ noPadding: !isOpen })}
          p="5px 15px 15px"
        >
          <Collapse in={isOpen} animateOpacity>
            {!title && !description && !summary && (
              <Text variant="h3" mb="8px" ml="4px">
                Additional information is not available
              </Text>
            )}
            {title && (
              <>
                <Text variant="h3" mb="8px" ml="4px">
                  Title
                </Text>
                <RoundedBox
                  bg="gray.18"
                  p={{ base: "5px 20px", md: "5px 20px" }}
                  borderRadius="4px"
                  mb="8px"
                >
                  <Box whiteSpace="pre-wrap">
                    <Text>{title}</Text>
                  </Box>
                </RoundedBox>
              </>
            )}
            {description && (
              <>
                <Text variant="h3" mb="8px" ml="4px">
                  Description
                </Text>
                <RoundedBox
                  bg="gray.18"
                  p={{ base: "5px 20px", md: "5px 20px" }}
                  borderRadius="8px"
                  mb="8px"
                  overflowY="auto"
                  maxHeight="400px"
                >
                  <Box whiteSpace="pre-wrap">
                    <Markdown linkTarget="_blank" text={description} />
                  </Box>
                </RoundedBox>
              </>
            )}
            {summary && (
              <>
                <Text variant="h3" mb="8px" ml="4px">
                  Summary
                </Text>
                <RoundedBox
                  bg="gray.18"
                  p={{ base: "5px 20px", md: "5px 20px" }}
                  borderRadius="4px"
                  overflowY="auto"
                  maxHeight="400px"
                >
                  <Box whiteSpace="pre-wrap">
                    <Linkify options={{ render: renderLink }}>
                      {summary}
                    </Linkify>
                  </Box>
                </RoundedBox>
              </>
            )}
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};
