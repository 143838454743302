import React from "react";
import { Box } from "@chakra-ui/react";
import { ImageBox } from "../image-box/image-box";

export const NftCard = ({ image, name, boxProps }) => (
  <Box
    bg="gray.22"
    borderRadius="12px"
    h="312px"
    w={{ base: "100%", sm: "48.2%", md: "290px" }}
    display="flex"
    flexDirection="column"
    {...boxProps}
  >
    <Box
      h="100%"
      borderBottom="1px solid"
      borderColor="gray.18"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p="0 15px"
    >
      <ImageBox
        color="gray.96"
        direction="column"
        variant="compact"
        title={name}
        imageUrl={image ?? ""}
        borderRadius="none"
        textMaxW="250px"
        fitContent
        size="2xs"
      />
    </Box>
  </Box>
);
