import React from "react";
import { Box } from "@chakra-ui/react";
import { FunctionsList } from "./functions-list";

export const CreateFunctionSet = ({
  isUpdateOnly = false,
  isEdit,
  onChange,
  users,
  setOpenWarning,
  functions = [],
  existingFunctionIds = [],
}) => {
  const handleAddFunction = (values) => {
    const foundFunction = functions.find((t) => t.id === values.id);
    if (foundFunction) {
      const updatedFunctions = functions.map((item) =>
        item.id === values.id ? { ...values } : item
      );
      onChange(updatedFunctions);
      return;
    }
    const updatedFunctions = [...functions, values];
    onChange(updatedFunctions);
  };
  const handleDeleteFunction = (id) => {
    const updatedFunctions = functions.filter((item) => item.id !== id);
    onChange(updatedFunctions);
  };
  return (
    <Box>
      <FunctionsList
        isEdit={isEdit}
        isUpdateOnly={isUpdateOnly}
        onAddFunction={handleAddFunction}
        onDeleteFunction={handleDeleteFunction}
        functions={functions}
        existingFunctionIds={existingFunctionIds}
        users={users}
        setOpenWarning={setOpenWarning}
      />
    </Box>
  );
};
