import React from "react";
import { Box, Container } from "@chakra-ui/react";

export const AppHeader = ({ isDetails, children }) => (
  <Box bg={isDetails ? "gray.18" : "inherit"}>
    <Container maxW="container.lg" pt="39px" pb="45px" px="1rem">
      {children}
    </Container>
  </Box>
);
