import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";

import axios from "shared/api/setup";

export const useCreateAppFeedbackMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/createAppFeedback", data), {
    onError: (error) => {
      toast({ position: "top-right", status: "error", title: error.message });
    },
    ...options,
  });
};
