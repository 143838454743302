import React, { useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  Skeleton,
  Text,
  useBreakpoint,
  useDisclosure,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { isEmpty, size, sumBy } from "lodash";

import { AppContainer } from "components/app-container";
import { ProjectRolesList } from "components/project-roles-list";
import {
  AvatarList,
  Budget,
  CollaboratorApplication,
  ConfirmationAlert,
  DataBox,
  InvoiceModal,
  Notification,
  NotificationPopup,
  OverviewSidebar,
  ProfileBox,
} from "libs/ui-components/src";
import { formatDateRange, pluralise } from "libs/utils/src";
import { STATUSES } from "shared/constants/statuses";
import { useUserContextState } from "shared/contexts/user-context-provider";
import {
  useArchiveProjectMutation,
  useCompleteProjectMutation,
  useProjectConfirmAssigneeMutation,
  useProjectDeclineAssigneeMutation,
  useRequestCompleteProjectMutation,
  useRequestUnCompleteProjectMutation,
} from "shared/mutations/project";
import { useProjectDetails, useProjectAssignee } from "shared/queries/project";
import { useUsers } from "shared/queries/users";
import { useSmartContractList } from "shared/queries/payments";
import { useOrganisationDetails } from "shared/queries/organisation";
import { QueriesKeysEnum } from "shared/queries/queries-keys-enum";
import { APP_PATHS } from "paths";

export const ProjectDetails = () => {
  const applicationsRef = useRef(null);
  const [selectedApplicationDecline, setSelectedApplicationDecline] =
    useState();
  const [isDisabledBTN, setIsDisabledBTN] = useState(false);

  const { data: users, isLoading: isUserLoading } = useUsers();
  const { user } = useUserContextState();
  const params = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { projectId } = params;

  // Get project details data
  const {
    data: project,
    isLoading: isProjectLoading,
    refetch: projectRefetch,
  } = useProjectDetails(projectId);
  const {
    title,
    state,
    startsAt,
    endsAt,
    roles = [],
    costs = [],
    goals = [],
    hazards = [],
    departmentId,
    organizationId,
    description,
    documentationUrl,
    createdBy,
    currencyTokenContract,
    governanceTokenContract,
    stakeHolderUserIds = [],
  } = { ...project };
  const showOrganization = false;
  const { data: organisation, isLoading: isOrganisationLoading } =
    useOrganisationDetails(organizationId);
  const {
    data: assignee,
    isLoading: isAssigneeLoading,
    refetch: assigneeRefetch,
  } = useProjectAssignee(projectId);
  const { mutate: onArchiveProject } = useArchiveProjectMutation();
  const { mutate: onCompleteProject } = useCompleteProjectMutation();
  const { mutate: onRequestCompleteProject } =
    useRequestCompleteProjectMutation();
  const { mutate: onRequestUnCompleteProject } =
    useRequestUnCompleteProjectMutation();
  const { mutate: onRemoveApplication } = useProjectDeclineAssigneeMutation();
  const addresses =
    isProjectLoading || !project
      ? []
      : [currencyTokenContract, governanceTokenContract];
  const { data: smartContracts, isLoading: isContractsLoading } =
    useSmartContractList(addresses);

  const isLoading =
    isUserLoading ||
    isProjectLoading ||
    isAssigneeLoading ||
    isContractsLoading ||
    isOrganisationLoading ||
    !project;

  // Chakra UI Disclosures
  const {
    isOpen: isOpenDeleteWarning,
    onClose: onCloseDeleteWarning,
    onOpen: onOpenDeleteWarning,
  } = useDisclosure();
  const {
    isOpen: isOpenDeclineApplication,
    onClose: onCloseDeclineApplication,
    onOpen: onOpenDeclineApplication,
  } = useDisclosure();
  const {
    isOpen: isOpenRequestCompleteWarning,
    onClose: onCloseRequestCompleteWarning,
    onOpen: onOpenRequestCompleteWarning,
  } = useDisclosure();
  const {
    isOpen: isOpenRequestUnCompleteWarning,
    onClose: onCloseRequestUnCompleteWarning,
    onOpen: onOpenRequestUnCompleteWarning,
  } = useDisclosure();
  const {
    isOpen: isOpenCannotCompleteWarning,
    onClose: onCloseCannotCompleteWarning,
    onOpen: onOpenCannotCompleteWarning,
  } = useDisclosure();
  const {
    isOpen: isOpenCompleteWarning,
    onClose: onCloseCompleteWarning,
    onOpen: onOpenCompleteWarning,
  } = useDisclosure({
    defaultIsOpen: query.get("complete") === "true",
  });
  const {
    isOpen: isInvoiceModalOpen,
    onOpen: onOpenInvoice,
    onClose: onCloseInvoice,
  } = useDisclosure();
  const {
    isOpen: isOpenRequestCompleteVerify,
    onClose: onCloseRequestCompleteVerify,
    onOpen: onOpenRequestCompleteVerify,
  } = useDisclosure();
  const {
    isOpen: isOpenCompleteVerify,
    onClose: onCloseCompleteVerify,
    onOpen: onOpenCompleteVerify,
  } = useDisclosure();

  const baseSmartContract = smartContracts?.find(
    (contract) =>
      contract.address.toLowerCase() === currencyTokenContract?.toLowerCase()
  );
  const baseCurrency = baseSmartContract?.symbol || "Primary";
  const governanceSmartContract = smartContracts?.find(
    (contract) =>
      contract.address.toLowerCase() === governanceTokenContract?.toLowerCase()
  );
  const governanceCurrency = governanceSmartContract?.symbol || "Secondary";

  const creator = users?.find((user) => user.id === createdBy);
  const stakeholders = users?.filter((user) =>
    stakeHolderUserIds?.includes(user.id)
  );

  const rolesUserIds = [];

  for (let index in roles) {
    rolesUserIds.push(roles[index]?.assigneeUserId);
  }

  const userCanConfirmApplicants =
    createdBy === user.id && state === "applicationsOpen";
  const unconfirmedAssignee = useMemo(() => {
    return assignee?.filter((user) => user.state !== "confirmed");
  }, [assignee]);

  const completeConfirmationMessage =
    size(stakeholders) > 0
      ? `Stakeholder(s) receives email: ”Project “${title}” has been completed, please confirm the completion to unlock the team payments.”`
      : `Project “${title}” will be completed automatically. Please check your email.`;

  const handleApplyClick = (role) => {
    if (project && role._id) {
      navigate(
        generatePath(APP_PATHS.applyToProject, {
          projectId: project.id,
          roleId: role._id,
        })
      );
    }
  };
  const handleEditProject = () => {
    if (project) {
      navigate(generatePath(APP_PATHS.editProject, { projectId: project.id }));
    }
  };
  const handleDuplicateProject = () => {
    if (project) {
      navigate(
        generatePath(APP_PATHS.duplicateProject, { projectId: project.id })
      );
    }
  };
  const handleIncomes = () => {
    if (project) {
      navigate(generatePath(APP_PATHS.incomes, { projectId: project.id }));
    }
  };
  const previousPageUrl =
    organizationId && departmentId
      ? generatePath(APP_PATHS.departmentDetails, {
          organizationId,
          departmentId,
        })
      : APP_PATHS.projects;
  const isItTeamPage = organizationId && departmentId;

  const backTitle = isItTeamPage ? "To the team" : "All projects";
  const handleDeleteProject = () => {
    onArchiveProject(project.id, {
      onSuccess: () => {
        navigate(previousPageUrl);
      },
    });
  };
  const handleRequestCompleteProject = () => {
    setIsDisabledBTN(true);
    onRequestCompleteProject(
      { id: project.id },
      {
        onSuccess: () => {
          onCloseRequestCompleteWarning();
          onOpenRequestCompleteVerify();
          projectRefetch();
          assigneeRefetch();
          setIsDisabledBTN(false);
        },
      }
    );
  };
  const handleRequestUnCompleteProject = () => {
    onRequestUnCompleteProject(
      { id: project.id },
      {
        onSuccess: () => {
          onCloseRequestUnCompleteWarning();
          // onOpenRequestCompleteVerify();
          projectRefetch();
          // assigneeRefetch();
        },
      }
    );
  };
  const handleCompleteProject = () => {
    onCompleteProject(
      { id: project.id },
      {
        onSuccess: () => {
          onCloseCompleteWarning();
          onOpenCompleteVerify();
          projectRefetch();
          assigneeRefetch();
        },
      }
    );
  };
  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };
  const handleOpenOrganisation = (organizationId) => {
    navigate(generatePath(APP_PATHS.organisationDetails, { organizationId }));
  };
  const handleDeclineApplication = (applicationId) => {
    onOpenDeclineApplication();
    setSelectedApplicationDecline(applicationId);
  };

  const handleConfirmDeclineApplication = () => {
    onRemoveApplication(
      {
        id: selectedApplicationDecline ?? "",
      },
      {
        onSuccess: () => {
          onCloseDeclineApplication();
          projectRefetch();
          assigneeRefetch();
        },
      }
    );
  };

  const handleScrollToApplications = () => {
    applicationsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const stateId = STATUSES.indexOf(state);

  let costsName = "Additional costs";
  if (costs?.length === 1) {
    costsName = costs[0]?.description;
  }
  if (costs[0]?.description.length > 23) {
    costsName = costs[0]?.description?.slice(0, 23) + "...";
  }

  const rolesSum = sumBy(roles, "currencyTokenCompensation");
  const costsSum = sumBy(costs, "currencyTokenCompensation");
  const totalBudget = rolesSum + costsSum;

  return (
    <>
      <AppContainer.Content>
        <Box display="flex" flexDirection={{ base: "column", md: "row" }}>
          <OverviewSidebar
            loading={isLoading}
            title={title || ""}
            badge={state}
            description={description}
            goals={goals}
            hazards={hazards}
            showAvatar={false}
            showBackButton={true}
            backButtonProps={{
              path: generatePath(previousPageUrl),
              title: backTitle,
            }}
            projectId={projectId}
          >
            {documentationUrl && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mb="18px"
              >
                <Button
                  as="a"
                  href={documentationUrl}
                  variant="link"
                  target="_blank"
                  rightIcon={<ExternalLinkIcon />}
                >
                  Project details
                </Button>
              </Box>
            )}
            <DataBox
              loading={isLoading}
              label="Dates"
              boxProps={{
                mb: "18px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {formatDateRange(startsAt, endsAt)}
            </DataBox>

            {rolesSum > 0 && (
              <DataBox
                loading={isLoading}
                label="Team Budget"
                boxProps={{
                  mb: "18px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Budget budget={rolesSum} symbol={""} currency={baseCurrency} />
              </DataBox>
            )}

            {costsSum > 0 && (
              <DataBox
                loading={isLoading}
                label={costsName}
                boxProps={{
                  mb: "18px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Budget budget={costsSum} symbol={""} currency={baseCurrency} />
              </DataBox>
            )}

            {totalBudget > 0 && costsSum > 0 && (
              <DataBox
                loading={isLoading}
                label="Total Budget"
                boxProps={{
                  mb: "18px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Budget
                  budget={totalBudget}
                  symbol={""}
                  currency={baseCurrency}
                />
              </DataBox>
            )}

            {showOrganization && organisation && (
              <DataBox
                loading={isLoading}
                label="Organization"
                boxProps={{
                  mb: "18px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {creator && (
                  <ProfileBox
                    size="xs"
                    userId={organisation.id}
                    name={organisation.name}
                    avatarUrl={organisation.imageUrl}
                    onClick={handleOpenOrganisation}
                  />
                )}
              </DataBox>
            )}
            <DataBox
              loading={isLoading}
              label="Owner"
              boxProps={{
                mb: "18px",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {creator && (
                <ProfileBox
                  size="xs"
                  userId={creator.id}
                  onClick={handleOpenUserProfile}
                  variant="compact"
                  name={`${creator.firstName} ${creator.lastName}`}
                  avatarUrl={creator.avatarUrl}
                />
              )}
            </DataBox>
            {!isEmpty(stakeholders) && (
              <DataBox
                loading={isLoading}
                label={pluralise("Stakeholder", size(stakeholders))}
                boxProps={{
                  mb: "18px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {size(stakeholders) === 1 ? (
                  <ProfileBox
                    size="xs"
                    userId={stakeholders[0].id}
                    variant="compact"
                    onClick={handleOpenUserProfile}
                    name={`${stakeholders[0].firstName} ${stakeholders[0].lastName}`}
                    avatarUrl={stakeholders[0].avatarUrl}
                  />
                ) : (
                  <AvatarList size="xs" users={stakeholders} popoverAvailable />
                )}
              </DataBox>
            )}
            <Flex
              justifyContent={{ base: "space-between", md: "center" }}
              flexDirection="column"
            >
              {createdBy === user?.id && (
                <>
                  <Divider borderColor="gray.28" mt="15px" mb="15px" />
                  {stateId < STATUSES.indexOf("completionRequested") && (
                    <Button
                      variant="link"
                      justifyContent="flex-start"
                      mt="18px"
                      onClick={handleEditProject}
                    >
                      Edit project
                    </Button>
                  )}
                  <Button
                    variant="link"
                    justifyContent="flex-start"
                    mt="18px"
                    onClick={handleDuplicateProject}
                  >
                    Duplicate project
                  </Button>
                  {stateId < STATUSES.indexOf("completionRequested") && (
                    <Button
                      variant="link"
                      justifyContent="flex-start"
                      mt="18px"
                      onClick={
                        state === "applicationsClosed" || state === "inProgress"
                          ? onOpenRequestCompleteWarning
                          : onOpenCannotCompleteWarning
                      }
                    >
                      Complete project & pay team
                    </Button>
                  )}
                  <Button
                    variant="link"
                    justifyContent="flex-start"
                    mt="18px"
                    onClick={handleIncomes}
                  >
                    Add and review incomes
                  </Button>
                </>
              )}
              {(stakeHolderUserIds.includes(user?.id) ||
                createdBy === user?.id) &&
                state === "completed" && (
                  <Button
                    as="a"
                    variant="link"
                    justifyContent="flex-start"
                    mt="18px"
                    href={generatePath(APP_PATHS.payments, {
                      projectId: project.id,
                    })}
                  >
                    Manage Payments
                  </Button>
                )}
              {stakeHolderUserIds.includes(user?.id) &&
                state === "completionRequested" && (
                  <Button
                    variant="link"
                    justifyContent="flex-start"
                    mt="18px"
                    onClick={onOpenCompleteWarning}
                  >
                    Confirm completion
                  </Button>
                )}
              {createdBy === user?.id && state === "completionRequested" && (
                <Button
                  variant="link"
                  justifyContent="flex-start"
                  mt="18px"
                  onClick={onOpenRequestUnCompleteWarning}
                >
                  Return to in progress state
                </Button>
              )}
              {createdBy === user?.id &&
                stateId <= STATUSES.indexOf("completionRequested") && (
                  <Button
                    variant="link"
                    justifyContent="flex-start"
                    mt="18px"
                    color="warning"
                    onClick={onOpenDeleteWarning}
                  >
                    Delete project
                  </Button>
                )}
              {createdBy === user?.id && (
                <Button
                  variant="link"
                  justifyContent="flex-start"
                  mt="18px"
                  onClick={onOpenInvoice}
                >
                  Generate client invoice
                </Button>
              )}
              {rolesUserIds.includes(user?.id) &&
                state === ("completed" || "completionRequested") && (
                  <>
                    <Divider borderColor="gray.28" mt="15px" mb="15px" />
                    <Button
                      variant="link"
                      justifyContent="flex-start"
                      onClick={() =>
                        navigate(
                          generatePath(APP_PATHS.feedback, {
                            projectId: project.id,
                          })
                        )
                      }
                    >
                      Leave feedback
                    </Button>
                  </>
                )}
            </Flex>
          </OverviewSidebar>
          <Box
            width="100%"
            ml={{ base: "0", md: "30px" }}
            mt={{ base: "30px", md: "0" }}
          >
            {userCanConfirmApplicants && !!unconfirmedAssignee?.length && (
              <Box mb="25px">
                <Notification
                  buttonLabel={
                    breakpoint === "base" ? "Review" : "Review Applications"
                  }
                  onReviewClick={handleScrollToApplications}
                >
                  You have {unconfirmedAssignee?.length} new{" "}
                  {pluralise("application", unconfirmedAssignee.length)}!
                </Notification>
              </Box>
            )}
            {isLoading ? (
              <Skeleton isLoaded={!isLoading} h="32px" w="300px" />
            ) : (
              <>
                <ProjectRolesList
                  assignee={assignee ?? []}
                  roles={roles}
                  users={users ?? []}
                  baseCurrency={baseCurrency}
                  governanceCurrency={governanceCurrency}
                  onApplyClick={handleApplyClick}
                  onDeclineClick={handleDeclineApplication}
                  onUserProfileClick={handleOpenUserProfile}
                  project={project}
                  organisation={organisation}
                  projectState={state}
                />
                {userCanConfirmApplicants && (
                  <Box mt="67px" ref={applicationsRef}>
                    <ApplicationsList
                      projectId={projectId}
                      users={users}
                      assignee={assignee ?? []}
                      onUserProfileClick={handleOpenUserProfile}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </AppContainer.Content>
      {project && (
        <InvoiceModal
          isOpen={isInvoiceModalOpen}
          onClose={onCloseInvoice}
          project={project}
          organisation={organisation}
        />
      )}
      <ConfirmationAlert
        isOpen={isOpenDeleteWarning}
        onClose={onCloseDeleteWarning}
        onConfirm={handleDeleteProject}
        title={`Are you sure you want to delete ${title}?`}
      />
      <ConfirmationAlert
        isOpen={isOpenRequestCompleteWarning}
        onClose={onCloseRequestCompleteWarning}
        onConfirm={handleRequestCompleteProject}
        title={`Completing the project will notify ${pluralise(
          "stakeholder",
          size(stakeholders)
        )} to confirm the completion and unlock the team payments.\n\nWould you like to proceed?`}
        isDisabled={isDisabledBTN}
      />
      <ConfirmationAlert
        isOpen={isOpenRequestUnCompleteWarning}
        onClose={onCloseRequestUnCompleteWarning}
        onConfirm={handleRequestUnCompleteProject}
        title={`Uncompleting the project will notify ${pluralise(
          "stakeholder",
          size(stakeholders)
        )} that project rollback to in progress state.\n\nWould you like to proceed?`}
      />
      <ConfirmationAlert
        isOpen={isOpenCannotCompleteWarning}
        onConfirm={onCloseCannotCompleteWarning}
        isAlert
        confirmLabel="Close"
        title="Please make sure all roles are assigned to users before completing the project."
      />
      <ConfirmationAlert
        isOpen={
          isOpenCompleteWarning &&
          stakeHolderUserIds.includes(user?.id) &&
          state === "completionRequested"
        }
        onClose={onCloseCompleteWarning}
        onConfirm={handleCompleteProject}
        title={`Are you sure you want to confirm the completion ${title}?`}
      />
      <ConfirmationAlert
        isOpen={isOpenDeclineApplication}
        onClose={onCloseDeclineApplication}
        onConfirm={handleConfirmDeclineApplication}
        title={`Are you sure you want to Decline your application?`}
      />
      <NotificationPopup
        title="Project complete requested"
        description={completeConfirmationMessage}
        isOpen={isOpenRequestCompleteVerify}
        onClose={onCloseRequestCompleteVerify}
      />
      {!isEmpty(stakeholders) && (
        <NotificationPopup
          title="Project completing confirmed"
          description={`When all stakeholders confirm project completion project owner will receive email:
           ”All stakeholders had confirmed the completion of the project. You can now pay the team.”`}
          isOpen={isOpenCompleteVerify}
          onClose={onCloseCompleteVerify}
        />
      )}
    </>
  );
};

const ApplicationsList = ({
  projectId,
  users,
  assignee,
  onUserProfileClick,
}) => {
  const queryCache = useQueryClient();

  const [selectedAssigneeRequest, setSelectedAssigneeRequest] = useState(null);
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();
  const { mutate: onConfirmAssigneeRequest } =
    useProjectConfirmAssigneeMutation({
      onSuccess: () => {
        onCloseConfirm();
      },
      onError: (err) => {
        console.error(err.message);
      },
      onSettled: () => {
        queryCache.invalidateQueries([
          QueriesKeysEnum.projectDetails,
          projectId,
        ]);
        queryCache.invalidateQueries([
          QueriesKeysEnum.projectAssignee,
          projectId,
        ]);
      },
    });
  const handleConfirmContributor = (assigneeRequest) => () => {
    onOpenConfirm();
    setSelectedAssigneeRequest(assigneeRequest);
  };

  const handleConfirmApplication = () => {
    if (selectedAssigneeRequest) {
      onConfirmAssigneeRequest({ id: selectedAssigneeRequest.id });
    }
  };

  return (
    <>
      <Text variant="h2" mb="26px" textTransform="capitalize">
        Contributor applications
      </Text>
      {assignee?.length ? (
        <>
          {assignee.map((assigneeRequest) => (
            <Box mb="26px" key={assigneeRequest.id}>
              <CollaboratorApplication
                user={users?.find(
                  (user) => user.id === assigneeRequest.createdBy
                )}
                jobTitle={assigneeRequest.roleTitle}
                canApprove={assigneeRequest.state === "pending"}
                onConfirm={handleConfirmContributor(assigneeRequest)}
                onUserProfileClick={onUserProfileClick}
              >
                {assigneeRequest.description}
              </CollaboratorApplication>
            </Box>
          ))}
          {isOpenConfirm && selectedAssigneeRequest && (
            <ConfirmationAlert
              isOpen={isOpenConfirm}
              onClose={onCloseConfirm}
              title={`Are you sure you want to confirm this application?`}
              onConfirm={handleConfirmApplication}
            />
          )}
        </>
      ) : (
        <Text color="white">
          You will see applications over here once someone applies
        </Text>
      )}
    </>
  );
};
