import { useToast } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { onApiError } from "../helpers/onApiError";

import axios from "../api/setup";

export const useVerifyEmailMutation = (options) => {
  const toast = useToast();
  return useMutation(
    (verificationToken) =>
      axios.get("/verifyEmail", { params: { token: verificationToken } }),
    {
      onError: (response) => {
        const { error } = response;
        if (error?.code !== "COM-2") {
          toast({
            position: "top-right",
            status: "error",
            title: error.message,
          });
        }
      },
      ...options,
    }
  );
};

export const useResendVerifyLinkMutation = (options) => {
  const toast = useToast();
  return useMutation(
    (email) => axios.get("/resendVerificationEmail", { params: { email } }),
    {
      onError: (response) => {
        const { error } = response;
        if (error?.code === "COM-2") {
          toast({
            position: "top-right",
            status: "error",
            title: "User with this email is nor registered",
          });
        } else {
          toast({
            position: "top-right",
            status: "error",
            title: error.message,
          });
        }
      },
      ...options,
    }
  );
};

export const useLoginMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createSessionByEmail", data),
    {
      onError: (error) => {
        toast({
          title: "Failed to log in.",
          description: error.message
            ? error.message
            : "Some error occurred, please try again later!",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      ...options,
    }
  );
};
export const useWalletLoginMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createSessionByWallet", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
export const useRegistrationMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/registerUser", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useInviteUserMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/inviteUser", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useResetPasswordMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/requestResetPassword", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
export const useChangeResetPasswordMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.put("/resetPassword", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useUserProfileMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => await axios.put("/updateMyUser", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useUserConfigMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => await axios.put("/updateMyConfig", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useUserSkillMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => await axios.post("/createUserSkill", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useRemoveUserSkillMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (userSkillId) => axios.delete(`/deleteUserSkill?id=${userSkillId}`),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useUpdateMyUserSkillMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => await axios.put("/updateMyUserSkill", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
