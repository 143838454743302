import React from "react";
import { Box, Container, Skeleton } from "@chakra-ui/react";

export const CardListSkeleton = ({ isLoading }) => {
  return isLoading ? (
    <Container maxW="container.lg">
      <Box
        display="flex"
        flexWrap="wrap"
        __css={{ "> div:nth-of-type(3n + 3)": { marginRight: "0" } }}
      >
        {[...Array(15)].map((_, i) => (
          <Skeleton
            key={i}
            isLoaded={!isLoading}
            borderRadius="24px"
            width={"290px"}
            height={"300px"}
            mb="40px"
            mr="30px"
          />
        ))}
      </Box>
    </Container>
  ) : null;
};
