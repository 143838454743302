import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
export const ConfirmationAlert = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmVariant = "primary",
  isAlert = false,
  cancelLabel = "Cancel",
  confirmLabel = "Yes",
  isDisabled = false,
}) => {
  const cancelRef = React.useRef(null);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size={"xs"}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          {title ? (
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="pre-wrap"
            >
              {title}
            </AlertDialogHeader>
          ) : (
            <AlertDialogHeader pt={0} />
          )}

          {description && (
            <AlertDialogBody whiteSpace="pre-line">
              {description}
            </AlertDialogBody>
          )}

          <AlertDialogFooter justifyContent="space-between">
            {isAlert ? (
              <Button onClick={onConfirm} ml={3} w="100%">
                {confirmLabel}
              </Button>
            ) : (
              <>
                <Button variant="link" ref={cancelRef} onClick={onClose}>
                  {cancelLabel}
                </Button>
                <Button
                  variant={confirmVariant}
                  onClick={onConfirm}
                  ml={3}
                  isDisabled={isDisabled}
                >
                  {confirmLabel}
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
