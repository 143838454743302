import React from "react";
import { Box, CloseButton, Text } from "@chakra-ui/react";

import { filter, keyBy } from "lodash";

import { RoundedBox } from "libs/ui-components/src";
import { RoleForm } from "./role-form";

export const RolesList = ({
  isReadOnly,
  onDeleteRole,
  roles,
  setFieldValue,
  functions,
  userSkills,
  availableAssignee,
  cardName,
  simpleRole = false,
  symbols,
  initialRolesDataMap,
}) => {
  const cardLabel = cardName || "Role";

  const functionsIsNotArchived = filter(functions, { isArchived: false });
  const currentFunctionsList = keyBy(functionsIsNotArchived, "id");

  return roles.map((role, index) => {
    const initialRoleData = initialRolesDataMap[role._id];

    const selectedRoleId = role.organizationRoleId;
    const currentFunctionInfo = currentFunctionsList[selectedRoleId];

    return (
      <RoundedBox key={index} bg="gray.24" mb="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="15px"
        >
          <Text color="white" variant="h4">
            {cardLabel} #{(index || 0) + 1}
          </Text>
          <CloseButton
            isDisabled={isReadOnly}
            onClick={() => onDeleteRole(index)}
          />
        </Box>
        <RoleForm
          key={`${role?.id}-${index}`}
          index={index}
          disabled={isReadOnly}
          role={role}
          functions={functionsIsNotArchived}
          userSkills={userSkills}
          availableAssignee={availableAssignee}
          setFieldValue={setFieldValue}
          simpleRole={simpleRole}
          symbols={symbols}
          initialRoleData={initialRoleData}
          currentFunctionInfo={currentFunctionInfo}
        />
      </RoundedBox>
    );
  });
};
