import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContainer } from "components/app-container";
import { useUserContextStateDispatch } from "shared/contexts/user-context-provider";

export const ResetPassword = () => {
  const params = useParams();
  const { onOpenChoosePasswordModal } = useUserContextStateDispatch();
  const { token } = params;
  useEffect(() => {
    if (token) {
      onOpenChoosePasswordModal(token);
    }
  }, [params]);

  return (
    <>
      <AppContainer>
        <AppContainer.Header />
      </AppContainer>
    </>
  );
};
