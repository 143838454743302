import React from "react";
import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
  @font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Book.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Book.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BookItalic.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BookItalic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Medium.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-MediumItalic.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-MediumItalic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Bold.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BoldItalic.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BoldItalic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Black.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-Black.woff2') format('woff2');
  }
  @font-face {
    font-family: 'CircularStd';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BlackItalic.woff') format('woff');
    src: url('./assets/fonts/CircularXXSub/CircularXXSub-BlackItalic.woff2') format('woff2');
  }
`}
  />
);
