import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { isEthereumAddress } from "services/helpers/validators";
import { environment } from "../../../../../../environments";
import { FormikInputControl } from "../../form/formik-wrappers";
import { TERMS_URL } from "libs/ui-components/src/links";

export const RegisterModal = ({
  isOpen,
  onClose,
  onSignUpClicked,
  invitedEmail,
}) => {
  const toast = useToast();
  const handleSubmitForm = (values) => {
    onSignUpClicked(values);
  };
  const network = environment.NEAR_IS_MAIN_NETWORK ? "near" : "testnet";
  const onChangePublicEmailFiled = (setFieldValue) => (event) => {
    setFieldValue("isEmailPublic", event.target.checked);
    if (!event.target.checked) {
      toast({
        position: "top-right",
        status: "error",
        title:
          "If you switch email visibility off, others won't be able reach out to you for work",
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New to Deep Teams?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text variant="h6" color="white" mb="28px">
            Create an account for free, start working with various DAOs on
            amazing projects, and get paid and attested for the work you do!
          </Text>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: "",
              lastName: "",
              bio: "",
              discordId: "",
              workLink: "",
              email: invitedEmail || "",
              password: "",
              confirmPassword: "",
              avatarUrl: "",
              nearAccountId: "",
              walletAddress: "",
              agreed: false,
            }}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First name is required"),
              lastName: Yup.string().required("Last name is required"),
              email: Yup.string()
                .required("Please Enter your email")
                .email("Invalid email"),
              discordId: Yup.string().nullable(),
              bio: Yup.string().max(500, "Bio must be under 500 characters"),
              workLink: Yup.string().url("Must be a valid URL").nullable(),
              password: Yup.string()
                .required("Please Enter your password")
                .matches(
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])([a-zA-Z0-9`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+)(?=.{8,})/,
                  "Must Contain 9 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
              confirmPassword: Yup.string()
                .required("Please re-type your password")
                .oneOf([Yup.ref("password")], "Passwords does not match"),
              nearAccountId: Yup.string()
                .min(2)
                .max(64)
                .matches(
                  /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/,
                  "Account ID: consists of Account ID parts separated by . and part consists of lowercase" +
                    " alphanumeric symbols separated by either _ or -."
                ),
              walletAddress: Yup.string()
                .test(
                  "is-valid",
                  "Put valid ERC20 address in format '0x...'",
                  isEthereumAddress
                )
                .required("Wallet is required"),
            })}
            onSubmit={handleSubmitForm}
          >
            {({ values, setFieldValue }) => (
              <Form noValidate>
                <FormikInputControl
                  label="First Name"
                  name="firstName"
                  placeholder="John"
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Last Name"
                  name="lastName"
                  placeholder="Doe"
                  styleProps={{ mb: "21px" }}
                />

                <FormikInputControl
                  label="Bio"
                  name="bio"
                  type="text"
                  isTextArea={true}
                  placeholder="A short bio"
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Discord ID"
                  name="discordId"
                  placeholder="Your Discord ID"
                  styleProps={{ mb: "21px" }}
                />

                <FormikInputControl
                  label="Work Link"
                  name="workLink"
                  type="text"
                  placeholder="http://yourwork.link"
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="johndoe@example.com"
                  styleProps={{ mb: "21px" }}
                  disabled={invitedEmail}
                />
                <Switch
                  label="Is email public"
                  name="isEmailPublic"
                  onChange={onChangePublicEmailFiled(setFieldValue)}
                  colorScheme="green"
                  defaultIsChecked="true"
                  styleProps={{ mb: "21px" }}
                >
                  <Text variant="h6" color="white" mt="10px">
                    Make email public
                  </Text>
                </Switch>
                <FormikInputControl
                  label="Password"
                  name="password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type="password"
                  styleProps={{ mb: "21px", mt: "21px" }}
                />
                <FormikInputControl
                  label="Confirm Password"
                  name="confirmPassword"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type="password"
                  styleProps={{ mb: "21px", mt: "21px" }}
                />
                <FormikInputControl
                  label="Ethereum Address"
                  placeholder="0x..."
                  name="walletAddress"
                  styleProps={{ mb: "21px" }}
                />
                {environment.NEAR_AVAILABLE && (
                  <FormikInputControl
                    label="Near Named Account Id"
                    placeholder={`name.${network}`}
                    name="nearAccountId"
                    styleProps={{ mb: "21px" }}
                  />
                )}
                <FormControl mb="21px">
                  <Text variant="h6" mb="12px">
                    Terms and conditions
                  </Text>
                  <Checkbox
                    name="agreed"
                    onChange={(event) =>
                      setFieldValue("agreed", event.target.checked)
                    }
                  >
                    <Text data-testid="agreed-label" variant="h6" as="span">
                      I agree to the Deep Work Payment{" "}
                      <Button
                        variant="link"
                        as="a"
                        href={TERMS_URL}
                        target="_blank"
                      >
                        Terms and Conditions
                      </Button>
                    </Text>
                  </Checkbox>
                </FormControl>
                <Button disabled={!values.agreed} type="submit">
                  Sign up
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
