import React from "react";
import { useParams } from "react-router-dom";
import { generatePath, useNavigate } from "react-router-dom";
import { keyBy } from "lodash";
import {
  Box,
  Button,
  Text,
  useDisclosure,
  useToast,
  Link,
} from "@chakra-ui/react";

import Markdown from "components/Markdown";

import { AppContainer } from "components/app-container";
import { ConfirmationAlert, DataBox, RoundedBox } from "libs/ui-components/src";
import { formatDateL } from "libs/utils/src";

import { APP_PATHS } from "paths";

import {
  useConfirmOrganizationRoleUpdateRequestMutation,
  useDeclineOrganizationRoleUpdateRequestMutation,
} from "shared/mutations/project";
import { useOrganizationRoleUpdateRequest } from "shared/queries/project";
import {
  useOrganisationDetails,
  useOrganisationTeamFunctions,
} from "shared/queries/organisation";
import { useUsers } from "shared/queries/users";

export const ReviewFunctionUpdateRequest = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const { orgRoleUpdateRequestId } = params;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: roleUpdateRequest, isLoading: isRoleUpdateRequestLoading } =
    useOrganizationRoleUpdateRequest(orgRoleUpdateRequestId);
  const { data: organizationDetails, isLoading: isOrganizationDetailsLoading } =
    useOrganisationDetails(roleUpdateRequest?.organizationId);
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationTeamFunctions(
      [roleUpdateRequest?.organizationId],
      roleUpdateRequest?.organizationDepartmentId
    );
  const { data: users, isLoading: isUserLoading } = useUsers();

  const { mutate: onConfirmRequest } =
    useConfirmOrganizationRoleUpdateRequestMutation();
  const { mutate: onDeclineRequest } =
    useDeclineOrganizationRoleUpdateRequestMutation();

  if (
    isRoleUpdateRequestLoading ||
    isFunctionsLoading ||
    isUserLoading ||
    isOrganizationDetailsLoading
  ) {
    return null;
  }

  const handleSubmit = () => {
    onConfirmRequest(orgRoleUpdateRequestId, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Role update",
        });
        setTimeout(() => navigate(generatePath(APP_PATHS.projects)), 1500);
      },
    });
  };

  const handleDecline = () => {
    onDeclineRequest(orgRoleUpdateRequestId, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Role update decline",
        });
        onClose();
        setTimeout(() => navigate(generatePath(APP_PATHS.projects)), 1500);
      },
    });
  };

  const rolesIndex = keyBy(functions, "id");
  const requestRoleInfo = rolesIndex[roleUpdateRequest?.organizationRoleId];

  const usersIndex = keyBy(users, "id");
  const userRequestFrom = usersIndex[roleUpdateRequest?.createdBy];

  const departmentIndex = keyBy(organizationDetails?.departments, "_id");
  const currentDepartment =
    departmentIndex[roleUpdateRequest?.organizationDepartmentId];

  const departmentLink = `/organisations/${roleUpdateRequest?.organizationId}/department/${roleUpdateRequest?.organizationDepartmentId}`;
  const editRolesLink = `/organisations/${roleUpdateRequest?.organizationId}/department/${roleUpdateRequest?.organizationDepartmentId}/editFunctions`;

  return (
    <AppContainer.Content>
      <Box>
        <ConfirmationAlert
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleDecline}
          description="Do you want to reject the proposed changes to the role?"
          isAlert={false}
          cancelLabel="Cancel"
          confirmLabel="Confirm"
        />
        <Text variant="h2" color="white" mb="25px">
          Pending role update requests
        </Text>
        <RoundedBox m="17px 0 25px" bgColor="gray.22">
          <Box>
            {roleUpdateRequest?.state === "pending" ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  w="100%"
                  alignItems="flex-start"
                  gridColumnGap="20px"
                  flexWrap="wrap"
                >
                  <DataBox
                    label="Department"
                    boxProps={{
                      mb: "16px",
                      gridRowGap: "4px",
                      flex: { base: "100%", sm: "40%", md: "20%" },
                    }}
                  >
                    <Link
                      variant="h3"
                      fontWeight="700"
                      href={departmentLink}
                      isExternal
                      color="primary"
                    >
                      {currentDepartment.name}
                    </Link>
                  </DataBox>
                  <DataBox
                    label="Role"
                    boxProps={{
                      mb: "16px",
                      gridRowGap: "4px",
                      flex: { base: "100%", sm: "40%", md: "20%" },
                    }}
                  >
                    <Link
                      variant="h3"
                      fontWeight="700"
                      href={editRolesLink}
                      isExternal
                      color="primary"
                    >
                      {requestRoleInfo.title}
                    </Link>
                  </DataBox>
                  <DataBox
                    label="Date"
                    boxProps={{
                      mb: "16px",
                      gridRowGap: "4px",
                      flex: { base: "100%", sm: "40%", md: "20%" },
                    }}
                  >
                    <Text
                      variant="h4"
                      lineHeight="24px"
                      fontWeight="700"
                      whiteSpace="nowrap"
                    >
                      {formatDateL(roleUpdateRequest.createdAt)}
                    </Text>
                  </DataBox>
                  <DataBox
                    label="Request from"
                    boxProps={{
                      mb: "16px",
                      gridRowGap: "4px",
                      flex: { base: "100%", sm: "40%", md: "20%" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Link
                      href={`/profile/${userRequestFrom.id}`}
                      isExternal
                      color="primary"
                      fontSize="16px"
                      fontWeight="700"
                    >
                      {userRequestFrom.firstName} {userRequestFrom.lastName}
                    </Link>
                  </DataBox>
                </Box>
                <Box w="100%">
                  <Text variant="h3" mb="8px">
                    Description
                  </Text>
                  <RoundedBox
                    bg="gray.18"
                    p="12px 20px"
                    borderRadius="4px"
                    mb="16px"
                  >
                    <Box whiteSpace="pre-wrap">
                      <Markdown
                        linkTarget="_blank"
                        text={roleUpdateRequest.description}
                      />
                    </Box>
                  </RoundedBox>

                  <Text variant="h3" mb="8px">
                    Changes Summary
                  </Text>
                  <RoundedBox bg="gray.18" p="12px 20px" borderRadius="4px">
                    <Box whiteSpace="pre-wrap">
                      {roleUpdateRequest.changesSummary}
                    </Box>
                  </RoundedBox>

                  <Box
                    display="flex"
                    mt="30px"
                    justifyContent="space-between"
                    gridColumnGap="16px"
                  >
                    <Button
                      variant="link"
                      fontWeight="500"
                      onClick={() => handleSubmit()}
                    >
                      Confirm request
                    </Button>

                    <Button
                      variant="link"
                      color="warning"
                      fontWeight="500"
                      onClick={onOpen}
                    >
                      Reject request
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Text variant="h6" textAlign="center">
                No active requests available
              </Text>
            )}
          </Box>
        </RoundedBox>
      </Box>
    </AppContainer.Content>
  );
};
