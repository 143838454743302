import React, { useEffect, useState } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { groupBy, keyBy } from "lodash";

import classNames from "classnames";
import Linkify from "linkify-react";
import { formatDateL } from "libs/utils/src";
import { RoundedBox } from "libs/ui-components/src";
import Markdown from "components/Markdown";
import { RoleUpdateRequestModal } from "libs/ui-components/src";
import { useUserRoleUpdateRequests } from "shared/queries/project";
import { useUserContextState } from "shared/contexts/user-context-provider";

export const SkillsTable = ({
  organizations = [],
  functions = [],
  userSkills = [],
}) => {
  const { user } = useUserContextState();
  const [functionsById, setFunctionsById] = useState({});
  const [orgsById, setOrgsById] = useState({});
  const [skillsByOrganization, setSkillsByOrganization] = useState({});
  const { data: userRoleRequests = [], isLoading: isRequestsLoading } =
    useUserRoleUpdateRequests(user.id);

  const userRoleUpdateRequestsMap = keyBy(
    userRoleRequests,
    "organizationRoleId"
  );

  useEffect(() => setOrgsById(keyBy(organizations, "id")), [organizations]);
  useEffect(() => setFunctionsById(keyBy(functions, "id")), [functions]);
  useEffect(
    () => setSkillsByOrganization(groupBy(userSkills, "organizationId")),
    [userSkills]
  );

  if (isRequestsLoading) {
    return <Text>Data loading</Text>;
  }
  return Object.entries(skillsByOrganization).map(
    ([organizationId, skills]) => {
      const organization = orgsById[organizationId];

      return (
        <>
          <Text>{organization?.name || "OOPS"}</Text>
          <TableContainer
            key={organizationId}
            bgColor="gray.22"
            borderRadius="12px"
            p="8px 12px"
          >
            <Table variant="simple">
              <Tbody>
                {skills?.map((skill) => {
                  const organizationFunction =
                    functionsById[skill.organizationRoleId];
                  const { title, description } = { ...organizationFunction };
                  return (
                    <CollapsableSkillTableRow
                      key={skill.id}
                      isOutdated={skill.isOutdated}
                      title={title}
                      date={skill.updatedAt}
                      description={description}
                      organizationRoleId={skill?.organizationRoleId}
                      havePendingRequest={
                        userRoleUpdateRequestsMap[skill?.organizationRoleId]
                      }
                    />
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      );
    }
  );
};

const CollapsableSkillTableRow = ({
  title,
  date,
  description,
  isOutdated,
  organizationRoleId,
  havePendingRequest,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isRoleUpdateRequestModalOpen,
    onOpen: onOpenRoleUpdateRequest,
    onClose: onCloseRoleUpdateRequestModal,
  } = useDisclosure();
  const renderLink = ({ attributes, content }) => {
    const { href } = attributes;
    return (
      <Button
        as="a"
        href={href}
        target="_blank"
        variant="link"
        whiteSpace="pre-wrap"
        display="block"
        rightIcon={<ExternalLinkIcon />}
      >
        {content}
      </Button>
    );
  };
  return (
    <>
      <Tr className="borderLess" color={isOutdated ? "warning" : "gray.79"}>
        <Td w="40%">{title}</Td>
        <Td w="40%">
          {(isOutdated ? "Out-of-date since: " : "Last Updated: ") +
            formatDateL(date)}
        </Td>
        <Td w="20%">
          <Button
            onClick={onToggle}
            variant="link"
            rightIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {isOpen ? (
                  <ChevronUpIcon color="primary" />
                ) : (
                  <ChevronDownIcon color="primary" />
                )}
              </Box>
            }
          >
            Details
          </Button>
        </Td>
      </Tr>
      <Tr>
        <Td
          colSpan={4}
          className={classNames({ noPadding: !isOpen })}
          p="5px 15px 15px"
        >
          <Collapse in={isOpen} animateOpacity>
            <RoundedBox
              bg="gray.18"
              display="flex"
              flexDirection="column"
              p={{ base: "5px 20px", md: "5px 20px" }}
              borderRadius="4px"
            >
              <Text fontSize="14px" color="gray.58">
                Description
              </Text>
              <Box whiteSpace="pre-wrap">
                <Linkify options={{ render: renderLink }}>
                  <Markdown linkTarget="_blank" text={description} />
                </Linkify>
              </Box>
            </RoundedBox>
            <Button
              disabled={havePendingRequest}
              onClick={onOpenRoleUpdateRequest}
              mt={"12px"}
              size="sm"
            >
              Edit
            </Button>
          </Collapse>
        </Td>
      </Tr>
      <RoleUpdateRequestModal
        isOpen={isRoleUpdateRequestModalOpen}
        onClose={onCloseRoleUpdateRequestModal}
        description={description}
        roleTitle={title}
        organizationRoleId={organizationRoleId}
      />
    </>
  );
};
