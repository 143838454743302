import React from "react";

import {
  Button,
  // FormErrorMessage,
  Box,
  Modal,
  ModalBody,
  // ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikTextareaControl } from "libs/ui-components/src";
import { useCreateOrganizationRoleUpdateRequestMutation } from "shared/mutations/project";

export const RoleUpdateRequestModal = ({
  isOpen,
  onClose,
  description,
  roleTitle,
  organizationRoleId,
}) => {
  const toast = useToast();

  const { mutate: createOrganizationRoleUpdateRequest } =
    useCreateOrganizationRoleUpdateRequestMutation();
  const handleRoleUpdateSend = (values) => {
    const updateRequestData = {
      changesSummary: values.summary,
      description: values.descr,
      organizationRoleId: organizationRoleId,
    };

    createOrganizationRoleUpdateRequest(updateRequestData, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Update request sent.",
        });
        onClose();
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change your {roleTitle} role info</ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Formik
            // enableReinitialize
            initialValues={{
              descr: description,
              summary: "",
            }}
            onSubmit={handleRoleUpdateSend}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              descr: Yup.string().required("Description is required"),
              summary: Yup.string().required("Summary is required"),
            })}
          >
            {() => (
              <Form id="roleUpdateForm" noValidate>
                <FormikTextareaControl
                  label="Description"
                  name="descr"
                  required={true}
                  placeholder="..."
                  styleProps={{ mb: "21px" }}
                />
                <FormikTextareaControl
                  label="Change's summary"
                  name="summary"
                  required={true}
                  placeholder="..."
                  styleProps={{ mb: "21px" }}
                />
                <Box display="flex" justifyContent="space-between">
                  <Button type="submit">Update Role</Button>
                  <Button
                    variant="link"
                    size="sm"
                    color="warning"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
