import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInputControl } from "libs/ui-components/src";

const INITIAL_VALUES = {
  email: "",
};

export const EmailVerificationExpired = ({ isOpen, onClose, onResendLink }) => {
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>You email verification link expired!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="25px" color="white" variant="h6">
            Looks like your verification token is invalid or expired.
          </Text>
          <Text mb="25px" color="white" variant="h6">
            Please check you email and try again or resend verification token.
          </Text>
          <Formik
            enableReinitialize
            noValidate
            initialValues={INITIAL_VALUES}
            onSubmit={onResendLink}
            validationSchema={Yup.object({
              email: Yup.string().email().required("Email is required"),
            })}
          >
            {() => (
              <Form noValidate>
                <FormikInputControl
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="john@example.com"
                  styleProps={{ mb: "21px" }}
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Button mt="16px" type="submit">
                    Resend verification link
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
