export const getPercentage = (value = 0, percentage) => {
  return value * (percentage / 100);
};

export const formatCurrency = (rate) => {
  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return `${format(Math.floor(+rate))}`;
};
