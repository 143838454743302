import React from "react";
import { Divider } from "@chakra-ui/react";

import { useOrganisationFunctions } from "shared/queries/organisation";
import { useUsers, useUserSkillsByOrganization } from "shared/queries/users";
import { useAllSmartContractList } from "shared/queries/payments";

import { RolesList } from "./roles-list";
import { AdminCreateButton } from "../admin-create-button/admin-create-button";

export const CreateRoleSet = ({
  isReadOnly,
  roles = [],
  arrayHelpers,
  setFieldValue,
  organizationId,
  organizationUserIds = [],
  addButtonText,
  cardName,
  simpleRole = false,
  formValues,
  initialRolesDataMap = {},
}) => {
  const initialValues = {
    id: "",
    organizationRoleId: "",
    tasksDescription: "",
    shouldRecieveSkill: true,
    ...(!simpleRole && {
      currencyTokenCompensation: undefined,
      governanceTokenCompensation: undefined,
    }),
  };
  const { data: availableAssignee } = useUsers(organizationUserIds);
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationFunctions([organizationId]);
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkillsByOrganization(organizationId);
  const { data: smartContracts = [], isLoading: isContractsLoading } =
    useAllSmartContractList();

  if (isFunctionsLoading || isUserSkillsLoading || isContractsLoading) {
    return null;
  }

  const contractsMap = {};

  for (const smartContract of smartContracts) {
    const { symbol, chainId, address } = smartContract;
    contractsMap[chainId] = contractsMap[chainId] || {};
    contractsMap[chainId][address] = symbol;
  }

  const findContract = (tokenContract) => {
    const tokenFromForm = formValues[tokenContract];
    if (!tokenFromForm) {
      return "Token not set";
    }

    const trimAddress = (address, startLength = 4, endLength = 2) => {
      return `${address?.substring(0, startLength)}...${address?.substring(
        address?.length - endLength
      )}`;
    };

    const tokenData =
      contractsMap[formValues?.chainId]?.[tokenFromForm?.toLowerCase()];

    if (tokenData) {
      return tokenData;
    }
    return trimAddress(tokenFromForm);
  };

  const symbols = {
    currencyTokenContract: findContract("currencyTokenContract"),
    governanceTokenContract: findContract("governanceTokenContract"),
  };

  const labelAddButton = addButtonText || "Add a role";

  return (
    <>
      {roles.length > 0 && (
        <RolesList
          isReadOnly={isReadOnly}
          onDeleteRole={(id) => arrayHelpers.remove(id)}
          roles={roles}
          functions={functions}
          userSkills={userSkills}
          availableAssignee={availableAssignee}
          setFieldValue={setFieldValue}
          cardName={cardName}
          simpleRole={simpleRole}
          symbols={symbols}
          initialRolesDataMap={initialRolesDataMap}
        />
      )}
      {!isReadOnly && (
        <>
          {roles.length > 0 && <Divider mt="20px" mb="20px" />}
          <AdminCreateButton
            bg="gray.22"
            p="11.5px 15px"
            onClick={() => arrayHelpers.insert(roles.length, initialValues)}
          >
            {labelAddButton}
          </AdminCreateButton>
        </>
      )}
    </>
  );
};
