import React from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { Box, Button, Skeleton, Text } from "@chakra-ui/react";
import { groupBy, orderBy, startCase } from "lodash";
import { DateTime } from "luxon";

import {
  ProjectCard,
  ProjectHidableCard,
  RoundedBox,
} from "libs/ui-components/src";
import { formatDateRange } from "libs/utils/src";
import { APP_PATHS } from "paths";

const userProjects = (
  isCurrentUser,
  projects,
  availableUsers,
  handleNavigateToProjectDetails,
  projectDetailsPath,
  noProjectsLabel,
  openProjectButtonName,
  handleOpenProject,
  user,
  allowHide = false,
  handleUpdateProjectVisibility = () => {}
) => {
  const sortedProjects = orderBy(projects, "startsAt", "desc");
  const projectsByMonth = groupBy(sortedProjects, ({ startsAt }) => {
    const date = DateTime.fromISO(startsAt);
    return `${date.year}-${("0" + date.month).slice(-2)}`;
  });
  return projects?.length ? (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {Object.entries(projectsByMonth).map(([key, monthProjects]) => (
        <>
          <Text variant="h3" alignSelf="flex-start" textTransform="uppercase">
            {DateTime.fromFormat(key, "yyyy-LL").toFormat("yyyy LLLL")}
          </Text>
          <Box display="flex" flexWrap="wrap" maxWidth={"100%"} mt={"15px"}>
            {monthProjects.map((project) => {
              const { id, title, roles, state, startsAt, endsAt, costs } =
                project;
              const userIds = roles.map((role) => role.assigneeUserId);
              const users = availableUsers?.filter((user) =>
                userIds.includes(user.id)
              );
              const isVisible = roles.find(
                (role) => role.assigneeUserId === user?.id
              )?.isAssigneeVisible;
              return isCurrentUser && allowHide ? (
                <ProjectHidableCard
                  key={id}
                  projectId={id}
                  projectName={title}
                  roles={roles}
                  costs={costs}
                  users={users}
                  onCardClick={handleNavigateToProjectDetails(id)}
                  handleUpdateProjectVisibility={handleUpdateProjectVisibility}
                  dateRange={formatDateRange(startsAt, endsAt)}
                  status={startCase(state)}
                  isVisible={isVisible}
                  boxProps={{
                    mb: "40px",
                    mr: { base: "0", sm: "10px" },
                  }}
                />
              ) : (
                <ProjectCard
                  key={id}
                  projectId={id}
                  projectName={title}
                  roles={roles}
                  costs={costs}
                  users={users}
                  onCardClick={handleNavigateToProjectDetails(id)}
                  navigationPath={projectDetailsPath(id)}
                  dateRange={formatDateRange(startsAt, endsAt)}
                  status={startCase(state)}
                  boxProps={{
                    mb: "40px",
                    mr: { base: "0", sm: "10px" },
                  }}
                />
              );
            })}
          </Box>
        </>
      ))}
    </Box>
  ) : (
    <RoundedBox
      h={"125px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <>
          <Text variant="h6">{noProjectsLabel}</Text>
          {isCurrentUser && (
            <Button mt="16px" onClick={handleOpenProject}>
              {openProjectButtonName}
            </Button>
          )}
        </>
      </Box>
    </RoundedBox>
  );
};

export const UserProjectsTab = ({
  isLoading,
  //ownProjects,
  user,
  handleUpdateProjectVisibility,
  projects,
  availableUsers,
  isCurrentUser = false,
}) => {
  const navigate = useNavigate();

  const projectDetailsPath = (projectId) => {
    return generatePath(APP_PATHS.projectDetails, { projectId });
  };
  const handleNavigateToProjectDetails = (id) => () => {
    navigate(projectDetailsPath(id));
  };
  /*
  const handleCreateNewProject = () => {
    navigate(APP_PATHS.newProject);
  };
   */
  const handleOpenProject = () => {
    navigate(APP_PATHS.projects);
  };

  if (isLoading) {
    return (
      <Skeleton isLoaded={!isLoading} w="100%" h="325px" borderRadius="24px" />
    );
  }

  return (
    <>
      {userProjects(
        isCurrentUser,
        projects,
        availableUsers,
        handleNavigateToProjectDetails,
        projectDetailsPath,
        `${
          isCurrentUser ? "You do" : "User does"
        } not seem to applied to any projects yet`,
        "Find a project",
        handleOpenProject,
        user,
        isCurrentUser,
        handleUpdateProjectVisibility
      )}
    </>
  );
};
