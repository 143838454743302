import React from "react";
import { Button, Text, useToast } from "@chakra-ui/react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import { FormikInputControl, RoundedBox } from "libs/ui-components/src";
import { useInviteUserMutation } from "shared/mutations/user";

export const InviteUser = () => {
  const toast = useToast();

  const { mutate: inviteUserMutation } = useInviteUserMutation();

  const handleSubmit = (values) => {
    const data = {
      email: values.email,
    };
    inviteUserMutation(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Invite sent.",
        });
        values.email = "";
      },
    });
  };

  return (
    <AppContainer>
      <AppContainer.Content>
        <Text as="h1" variant="h1" mb="8px">
          Invite person to become a member
        </Text>
        <RoundedBox>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required("Please Enter email")
                .email("Invalid email"),
            })}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <FormikInputControl
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="johndoe@example.com"
                  styleProps={{ mb: "8px" }}
                />

                <Button mt={2} type="submit">
                  Send invite
                </Button>
              </Form>
            )}
          </Formik>
        </RoundedBox>
      </AppContainer.Content>
    </AppContainer>
  );
};
