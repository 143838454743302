import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { AppContainer } from "components/app-container";
import { RoundedBox } from "libs/ui-components/src";

export const DontHavePermissions = () => {
  return (
    <AppContainer>
      <AppContainer.Header />
      <AppContainer.Content>
        <Box>
          <Text variant="h1" color="white">
            403 Forbidden.
          </Text>
          <RoundedBox mt="21px" mb="21px" p="10px 25px">
            <Text color="gray.79">
              You do not have permission to view this page.
            </Text>
          </RoundedBox>
        </Box>
      </AppContainer.Content>
    </AppContainer>
  );
};
