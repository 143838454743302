import React from "react";
import { FormControl, FormLabel, Radio, Text } from "@chakra-ui/react";
export const RadioBlock = ({ label, description, radioProps }) => {
  return (
    <FormControl
      bg="gray.24"
      width="240px"
      borderRadius="12px"
      display="flex"
      flexDirection="column"
    >
      <Radio mb="16px" {...radioProps} />
      <FormLabel>
        <Text variant="h5" mb="14px" textTransform="capitalize">
          {label}
        </Text>
        <Text variant="small">{description}</Text>
      </FormLabel>
    </FormControl>
  );
};
