import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InfoIcon } from "@chakra-ui/icons";
import { Textarea } from "@chakra-ui/react";
export const FormikInputControl = ({
  styleProps,
  InputLeftAddon,
  InputRightAddon,
  InputLeftElement,
  InputRightElement,
  inputProps,
  ...props
}) => {
  const {
    name,
    parentName,
    label,
    tooltipLabel,
    type,
    placeholder,
    required,
    disabled,
    infoLabel,
    isTextArea,
  } = props;
  const [field, meta] = useField(name);
  const [_, parentMeta] = useField(parentName || name);  // eslint-disable-line
  const touched = parentMeta?.touched || meta.touched;
  const isGroup =
    InputLeftAddon || InputRightAddon || InputLeftElement || InputRightElement;
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <FormControl
      id={name}
      isRequired={required}
      data-testid={`${field.name}-input`}
      isDisabled={disabled}
      isInvalid={!!meta.error && touched}
      {...styleProps}
    >
      <Box display="flex" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {!!tooltipLabel && (
          <Box mb={4} ml={2} lineHeight={0}>
            <Tooltip label={tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      {isGroup ? (
        <InputGroup alignItems="center">
          {InputLeftAddon && InputLeftAddon}
          {InputLeftElement && InputLeftElement}
          {isTextArea ? (
            <Textarea
              required={required}
              placeholder={placeholder}
              onWheel={numberInputOnWheelPreventChange}
              {...field}
              {...inputProps}
            />
          ) : (
            <Input
              type={type}
              required={required}
              placeholder={placeholder}
              onWheel={numberInputOnWheelPreventChange}
              {...field}
              {...inputProps}
            />
          )}
          {InputRightElement && InputRightElement}
          {InputRightAddon && InputRightAddon}
        </InputGroup>
      ) : isTextArea ? (
        <Textarea
          required={required}
          placeholder={placeholder}
          onWheel={numberInputOnWheelPreventChange}
          {...field}
          {...inputProps}
        />
      ) : (
        <Input
          type={type}
          required={required}
          placeholder={placeholder}
          onWheel={numberInputOnWheelPreventChange}
          {...field}
          {...inputProps}
        />
      )}
      {touched && meta.error && (
        <FormErrorMessage>{meta.error ?? " "}</FormErrorMessage>
      )}
      {!!infoLabel && <Text color="primary">{infoLabel}</Text>}
    </FormControl>
  );
};
