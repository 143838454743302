import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { theme } from "libs/ui-components/src";
import App from "App";
import { unregister } from "./serviceWorkerRegistration";
import gqlClient from "shared/gql/setup";
import { UserContextProvider } from "shared/contexts/user-context-provider";
import { ReactQueryProvider } from "shared/contexts/react-query-provider";
import { Fonts } from "components/fonts";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactQueryProvider>
        <ChakraProvider theme={theme}>
          <Fonts />
          <UserContextProvider>
            <ApolloProvider client={gqlClient}>
              <App />
            </ApolloProvider>
          </UserContextProvider>
        </ChakraProvider>
      </ReactQueryProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

unregister();
