import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { RoundedBox, FunctionsTable } from "libs/ui-components/src";

export const OrganisationRolesTab = ({
  isLoading,
  functions,
  userSkills,
  users,
  emptyText,
  departments,
  canEditRole,
}) => {
  const [functionList, setFunctionList] = useState([]);
  useEffect(() => {
    if (!isLoading) {
      setFunctionList(
        functions.map((item) => ({
          ...item,
          owners: userSkills.filter(
            (skill) => skill.organizationRoleId === item.id
          ),
        }))
      );
    }
  }, [userSkills, functions, isLoading]);
  const notArchivedFunctionsList = functionList.filter((fL) => {
    return fL.isArchived === false;
  });
  return (
    <Box>
      {notArchivedFunctionsList?.length ? (
        <Box>
          <FunctionsTable
            functions={notArchivedFunctionsList}
            departments={departments}
            users={users}
            canEditRole={canEditRole}
          />
        </Box>
      ) : (
        <RoundedBox
          h={"325px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="h6">{emptyText}</Text>
        </RoundedBox>
      )}
    </Box>
  );
};
