import React, { useMemo } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  FormLabel,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

import { FieldArray, Form, Formik } from "formik";
import { DateTime } from "luxon";
import {
  identity,
  isEmpty,
  isNil,
  isNumber,
  omit,
  pickBy,
  keyBy,
  concat,
  uniq,
} from "lodash";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import { APP_PATHS } from "paths";
import { useCreateProjectMutation } from "shared/mutations/project";
import { useInvitedUsers, useUsers } from "shared/queries/users";
import {
  CreateRoleSet,
  FormikAutocompleteControl,
  FormikInputControl,
  RoundedBox,
  FormikSelectControl,
  MultipleProfileSelection,
  PromptIfDirty,
  CreateCostSet,
  RadioBlock,
  ConfirmationAlert,
  FormikTextareaControl,
  CreateOneInputSetObject,
} from "libs/ui-components/src";

import { isEthereumAddress } from "services/helpers/validators";
import { SUPPORTED_CHAINS } from "services/helpers/chains";
import { useMyOrganisationList } from "shared/queries/organisation";
import { useUserContextState } from "shared/contexts/user-context-provider";

export const NewProject = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const organizationId = query.get("organizationId");
  const departmentId = query.get("departmentId");
  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: invitedUsers, isLoading: isInvitedUserLoading } =
    useInvitedUsers();
  const { data: organisations, isLoading: isOrganisationsLoading } =
    useMyOrganisationList();

  const currentOrganisationData = organisations?.find(
    (organisation) => organisation.id === organizationId
  );

  const chainId = currentOrganisationData?.chainId || "";
  const currencyTokenContract =
    currentOrganisationData?.currencyTokenContract || "";
  const governanceTokenContract =
    currentOrganisationData?.governanceTokenContract || "";

  const invoiceDefaultData = {
    title: "",
    description: "",
    documentationUrl: "",
    chainId,
    currencyTokenContract,
    governanceTokenContract,
    walletAddress: "",
    stakeHolderUserIds: [],
    organizationId,
    departmentId,
    roles: [],
    costs: [],
    goals: [],
    hazards: [],
    startsAt: undefined,
    endsAt: undefined,
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: onCreateProject } = useCreateProjectMutation({
    onSuccess: (response) => {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: response?.id })
      );
    },
  });
  const handleSubmitProject = (data) => {
    let details = pickBy(data, identity);
    details = omit(details, "roles");
    details = omit(details, "costs");
    if (isEmpty(details.stakeHolderUserIds)) {
      details = omit(details, "stakeHolderUserIds");
    }
    const roles = data.roles?.map((role) =>
      pickBy(role, (item) => !isNil(item) && item !== "")
    );
    const costs = data.costs?.map((cost) =>
      pickBy(cost, (item) => !isNil(item))
    );
    const project = {
      ...details,
      roles,
      costs,
      ...(details.organizationId && {
        organizationId: details.organizationId,
      }),
      ...(details.departmentId && {
        departmentId: details.departmentId,
      }),
      startsAt: DateTime.fromISO(details.startsAt),
      endsAt: DateTime.fromISO(details.endsAt),
    };
    onCreateProject(project);
  };
  if (isUserLoading || isOrganisationsLoading || isInvitedUserLoading) {
    return null;
  }
  let availableAssignee = users;

  if (!isEmpty(invitedUsers)) {
    availableAssignee = users.concat(invitedUsers);
  }

  const mappedNetworks = SUPPORTED_CHAINS.map((chain) => ({
    label: chain.name,
    value: chain.network,
  }));
  const organisationsMap = keyBy(organisations, "id");
  const mappedOrganisations =
    organisations?.map((organisation) => ({
      label: organisation.name,
      value: organisation.id,
    })) || [];
  const { user } = useUserContextState();
  const mapDepartments = (id) => {
    const organisation = organisations?.find(
      (organisation) => organisation.id === id
    ) || { departments: [] };
    const usersDepartments =
      organisation.departments.filter((d) => {
        return d.projectManagersUserIds.includes(user?.id);
      }) || [];
    return (
      usersDepartments.map((department) => ({
        label: department.name,
        value: department._id,
      })) || []
    );
  };

  const mapTokenData = (id, currentData) => {
    const findOrganisation = (orgId) =>
      organisations?.find((organisation) => organisation.id === orgId) || {};

    const organisationNew = findOrganisation(id);
    const organisationOld = findOrganisation(currentData.organizationId);

    const createFieldData = (org) => ({
      chainId: org.chainId || "",
      currencyTokenContract: org.currencyTokenContract || "",
      governanceTokenContract: org.governanceTokenContract || "",
    });

    const newFieldData = createFieldData(organisationNew);
    const oldOrgFieldData = createFieldData(organisationOld);
    const currentFieldData = createFieldData(currentData);

    const compareOldCurrentFieldData =
      oldOrgFieldData.chainId === currentFieldData.chainId &&
      oldOrgFieldData.currencyTokenContract ===
        currentFieldData.currencyTokenContract &&
      oldOrgFieldData.governanceTokenContract ===
        currentFieldData.governanceTokenContract;

    if (compareOldCurrentFieldData) {
      return newFieldData;
    } else {
      return currentFieldData;
    }
  };

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Create a new project
        </Text>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt="13px">
        <Box>
          <Text variant="h2" color="white" mb="25px">
            Enter project details
          </Text>
          <Formik
            enableReinitialize
            initialValues={invoiceDefaultData}
            onSubmit={handleSubmitProject}
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Name is required"),
              description: Yup.string()
                .max(512, "Description must be at most 512 characters")
                .required("Description is required"),
              documentationUrl: Yup.string().url("Invalid URL"),
              walletAddress: Yup.string().test(
                "is-valid",
                "Put valid ERC20 address in format '0x...'",
                isEthereumAddress
              ),
              organizationId: Yup.string().nullable(),
              departmentId: Yup.string().nullable(),
              stakeHolderUserIds: Yup.array().of(Yup.string()),
              chainId: Yup.string().oneOf(
                SUPPORTED_CHAINS.map((chain) => chain.network),
                "Please select valid network"
              ),
              currencyTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              governanceTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              roles: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string().required("Role name is required"),
                  tasksDescription: Yup.string(),
                  currencyTokenCompensation: Yup.number().nullable(),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              goals: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Goal is required"),
                })
              ),
              hazards: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Hazard is required"),
                })
              ),
              costs: Yup.array().of(
                Yup.object().shape({
                  description: Yup.string().required("Description is required"),
                  walletAddress: Yup.string().test(
                    "is-valid",
                    "Put valid ERC20 address in format '0x...'",
                    isEthereumAddress
                  ),
                  currencyTokenCompensation: Yup.number().test(
                    "is-valid",
                    "Token Amount is required",
                    isNumber
                  ),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              startsAt: Yup.date().required("Start date is required"),
              endsAt: Yup.date()
                .required("End date is required")
                .when("startsAt", (startsAt, schema) => {
                  return startsAt
                    ? schema.min(
                        startsAt,
                        "End date must be greater than Start date"
                      )
                    : schema;
                }),
            })}
          >
            {({ values, setFieldValue, errors, dirty, submitCount }) => (
              <Form id="detailsForm" noValidate>
                <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                <RoundedBox mb="25px">
                  <FormikInputControl
                    label="Name your project"
                    name="title"
                    placeholder="Title"
                    required={true}
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikTextareaControl
                    label="Describe your project"
                    name="description"
                    size="md"
                    placeholder="Description"
                    required={true}
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikInputControl
                    label="Add an external document with project details"
                    name="documentationUrl"
                    placeholder="Document URL"
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikInputControl
                    label="Project wallet address"
                    name="walletAddress"
                    placeholder="0x..."
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikAutocompleteControl
                    label="Organisation"
                    name="organizationId"
                    options={mappedOrganisations}
                    inputProps={{
                      placeholder: "Select organisation",
                    }}
                    onClick={(value) => {
                      setFieldValue("organizationId", value);
                      setFieldValue("departmentId", null);

                      let data = mapTokenData(value, values);

                      Object.entries(data).forEach(
                        ([fieldName, fieldValue]) => {
                          setFieldValue(fieldName, fieldValue);
                        }
                      );
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikAutocompleteControl
                    label="Team"
                    name="departmentId"
                    disabled={isEmpty(mapDepartments(values.organizationId))}
                    options={mapDepartments(values.organizationId)}
                    inputProps={{
                      placeholder: "Select team",
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <MultipleProfileSelection
                    label="Assign stakeholders"
                    possibleProfiles={availableAssignee ?? []}
                    selectedUserIds={values.stakeHolderUserIds}
                    error={errors.stakeHolderUserIds}
                    showError={!!errors.stakeHolderUserIds}
                    onChange={(value) =>
                      setFieldValue(
                        "stakeHolderUserIds",
                        value?.map((item) => item.value)
                      )
                    }
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikSelectControl
                    label="Blockchain"
                    name="chainId"
                    options={mappedNetworks}
                    required={true}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikInputControl
                    label="Primary Payment Token (ERC-20)"
                    required={false}
                    name="currencyTokenContract"
                    placeholder="0x..."
                    tooltipLabel="Stable only accepted"
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikInputControl
                    label="Secondary Token (ERC-20)"
                    required={false}
                    name="governanceTokenContract"
                    placeholder="0x..."
                    tooltipLabel="Stable only accepted"
                    styleProps={{ mb: "21px" }}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="roles"
                    render={(arrayHelpers) => (
                      <CreateRoleSet
                        isReadOnly={false}
                        roles={values.roles}
                        organizationId={values.organizationId}
                        organizationUserIds={uniq(
                          concat(
                            organisationsMap[values.organizationId]?.userIds ||
                              [],
                            organisationsMap[values.organizationId]
                              ?.createdBy || []
                          )
                        )}
                        departmentId={values.departmentId}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        addButtonText="Add a role"
                        cardName="Role"
                        formValues={values}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="goals"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="goals"
                        title="Goal"
                        fieldValues={values.goals}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="hazards"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="hazards"
                        title="Hazard"
                        fieldValues={values.hazards}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="costs"
                    render={(arrayHelpers) => (
                      <CreateCostSet
                        isReadOnly={false}
                        costs={values.costs}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikInputControl
                    label="Start Date"
                    type="date"
                    name="startsAt"
                    required={true}
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikInputControl
                    label="End date"
                    type="date"
                    name="endsAt"
                    required={true}
                    styleProps={{ mb: "21px" }}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FormLabel>Project permissions</FormLabel>
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    gridColumnGap="55px"
                    gridRowGap="25px"
                  >
                    <RoundedBox bg="gray.24" w="270px">
                      <RadioBlock
                        radioProps={{
                          isChecked: !values.isHidden,
                          onChange: () => setFieldValue("isHidden", false),
                          isDisabled: values.isHidden,
                        }}
                        label="Public"
                        description="Everyone can access the team"
                      />
                    </RoundedBox>
                    <RoundedBox bg="gray.24" w="270px">
                      <RadioBlock
                        radioProps={{
                          isChecked: values.isHidden,
                          onChange: () => {
                            if (!values.isHidden) {
                              onOpen();
                            }
                            setFieldValue("isHidden", true);
                          },
                          isDisabled: values.isHidden,
                        }}
                        label="Private"
                        description="Only the team lead and specific accounts can access the
                          team"
                      />
                    </RoundedBox>
                    <ConfirmationAlert
                      isOpen={isOpen}
                      onClose={() => {
                        onClose();
                        setFieldValue("isHidden", false);
                      }}
                      onConfirm={onClose}
                      description="This operation is not reversible cause of security reasons. 
                      Change back to the public will be blocked. Do you want to proceed?"
                      isAlert={false}
                    />
                  </Flex>
                </RoundedBox>
                <Button type="submit">Create Project</Button>
              </Form>
            )}
          </Formik>
        </Box>
      </AppContainer.Content>
    </>
  );
};
