import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { onApiError } from "../helpers/onApiError";

import axios from "shared/api/setup";

export const useCreateOrganisationMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/createOrganization", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useUpdateOrganisationMutation = (organizationId, options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put(`/updateOrganization?id=${organizationId}`, data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useInviteOrganisationMembersMutation = (
  organizationId,
  options
) => {
  const toast = useToast();
  return useMutation(
    async (userIds) =>
      axios.put(`/inviteOrganizationMembers`, {
        id: organizationId,
        userIds,
      }),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useRemoveOrganisationMembersMutation = (
  organizationId,
  options
) => {
  const toast = useToast();
  return useMutation(
    async (userIds) =>
      axios.put(`/removeOrganizationMembers`, {
        id: organizationId,
        userIds,
      }),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useCreateOrganisationRoleMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createOrganizationRole", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useUpdateOrganisationRoleMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async ({ roleId, data }) =>
      axios.put(`/updateOrganizationRole?id=${roleId}`, data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useRemoveOrganisationRoleMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (roleId) => axios.delete(`/archiveOrganizationRole?id=${roleId}`),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useCreateJoinOrganizationMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createJoinOrganizationRequest", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useConfirmJoinOrganizationMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (joinRequestId) =>
      axios.put(`/confirmJoinOrganizationRequest?id=${joinRequestId}`),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useDeclineJoinOrganizationMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (joinRequestId) =>
      axios.put(`/declineJoinOrganizationRequest?id=${joinRequestId}`),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};
