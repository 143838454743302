import React from "react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

export const DateFilter = ({ value, onChange }) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      isClearable
      customInput={
        <Button variant="link" rightIcon={<TriangleDownIcon />}>
          {value
            ? `Started ${DateTime.fromJSDate(value).toFormat("LLL dd, yyyy")}`
            : "Any time"}
        </Button>
      }
    />
  );
};
