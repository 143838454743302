import React, { useState } from "react";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Divider, useDisclosure } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
  ConfirmationAlert,
  CreateOneInputSetObject,
  FormikInputControl,
  FormikTextareaControl,
  MultipleProfileSelection,
} from "libs/ui-components/src";

const INITIAL_TASK_VALUES = {
  id: "",
  title: "",
  description: "",
};

export const FunctionForm = ({
  item,
  onSubmit,
  onCancel,
  isEdit = false,
  isUpdateOnly = false,
  users,
  existingFunctionIds = [],
}) => {
  const initialValues = item ? { ...item } : INITIAL_TASK_VALUES;
  const popupDescription = isUpdateOnly
    ? "Do you wish to send a request to the team lead for updating any of the roles?"
    : "This will send all owners of this role a message and mark their role as out-of-date until they" +
      " re-verified it on a project.\n\nWould you like to continue?";
  const isNewFunction = !existingFunctionIds.find((id) => id === item.id);
  const isEditExistingItem = !isNewFunction && isEdit;
  const handleCancel = () => {
    onCancel();
  };
  const [summaryRequired, setSummaryRequired] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = (values) => {
    const item = {
      ...values,
      id: values.id === "" ? uuidv4() : values.id,
    };
    onSubmit(item);
  };
  const isOnlyMetadataUpdated = (values) => {
    const hasNoChangeSummary = !values.changesSummary;
    const descriptionTheSame = values.description === initialValues.description;

    return hasNoChangeSummary && descriptionTheSame;
  };

  const saveMessage = (values) => {
    if (isUpdateOnly) {
      setSummaryRequired(false);
      return "Save changes";
    }
    const noBroadcasetUpdates = isOnlyMetadataUpdated(values);
    if (noBroadcasetUpdates) {
      setSummaryRequired(false);
      return "Save changes";
    }
    setSummaryRequired(true);
    return "Save & broadcast update";
  };

  const onSaveClick = (values) => {
    const newComponentCreation =
      initialValues?.id === "" && initialValues?.description === "";

    const noBroadcasetUpdates = isOnlyMetadataUpdated(values);
    if (newComponentCreation) {
      handleSubmit(values);
    } else if (noBroadcasetUpdates) {
      delete values.description;
      delete values.changesSummary;
      handleSubmit(values);
    } else {
      onOpen();
    }
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    governanceTokenMultiplier: Yup.number(),
    changesSummary: summaryRequired
      ? Yup.string().required(
          "Update summary is required when broadcasting changes."
        )
      : Yup.string(),
    owners: Yup.array().of(Yup.string()),
    unitOfPrice: Yup.string(),
    unitCurrencyTokenPrice: Yup.number().min(0, "Min value 0"),
    unitGovernanceTokenPrice: Yup.number().min(0, "Min value 0"),
    tasks: Yup.array().of(
      Yup.object({ description: Yup.string().required("Task is required") })
    ),
    goals: Yup.array().of(
      Yup.object({ description: Yup.string().required("Goal is required") })
    ),
    hazards: Yup.array().of(
      Yup.object({ description: Yup.string().required("Hazard is required") })
    ),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSaveClick}
      validationSchema={validationSchema}
    >
      {({ submitForm, values, errors, setFieldValue }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            mb="21px"
            gridColumnGap="21px"
          >
            <FormikInputControl
              label="Title"
              name="title"
              type="text"
              required={true}
              placeholder="Prepare Miro Board..."
              disabled={false}
            />
          </Box>
          <MultipleProfileSelection
            label="Owner(s)"
            possibleProfiles={users ?? []}
            selectedUserIds={values.owners}
            error={errors.owners}
            showError={!!errors.owners}
            onChange={(value) =>
              setFieldValue(
                "owners",
                value?.map((item) => item.value)
              )
            }
            disabled={false}
            styleProps={{ mb: "21px" }}
          />
          <FormikTextareaControl
            label="Description"
            name="description"
            size="md"
            required={true}
            placeholder="Enter task description or task document URL"
            styleProps={{ mb: "21px" }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            mb="21px"
            gridColumnGap="21px"
          >
            <FormikInputControl
              label="Unit dimension"
              name="unitOfPrice"
              type="text"
              placeholder="day..."
              disabled={false}
            />
            <FormikInputControl
              label="Currency Tokens per unit"
              name="unitCurrencyTokenPrice"
              type="number"
              placeholder="0"
              disabled={false}
            />
            <FormikInputControl
              label="Governance Tokens per unit"
              name="unitGovernanceTokenPrice"
              type="number"
              placeholder="0"
              disabled={false}
            />
          </Box>
          {isEditExistingItem && (
            <FormikTextareaControl
              label="Update summary and message to owners"
              name="changesSummary"
              size="sm"
              required={summaryRequired}
              placeholder="Creative Director"
              styleProps={{ mb: "21px" }}
            />
          )}
          <FieldArray
            name="tasks"
            render={(arrayHelpers) => (
              <CreateOneInputSetObject
                fieldName="tasks"
                title="Task"
                fieldValues={values.tasks}
                arrayHelpers={arrayHelpers}
                setFieldValue={setFieldValue}
                bg="transparent"
                p={{ base: "22px 20px", md: "8px 0px 8px 0px" }}
              />
            )}
          />
          <Divider mt="20px" mb="20px" />
          <FieldArray
            name="goals"
            render={(arrayHelpers) => (
              <CreateOneInputSetObject
                fieldName="goals"
                title="Goal"
                fieldValues={values.goals}
                arrayHelpers={arrayHelpers}
                setFieldValue={setFieldValue}
                bg="transparent"
                p={{ base: "22px 20px", md: "8px 0px 8px 0px" }}
              />
            )}
          />
          <Divider mt="20px" mb="20px" />
          <FieldArray
            name="hazards"
            render={(arrayHelpers) => (
              <CreateOneInputSetObject
                fieldName="hazards"
                title="Hazard"
                fieldValues={values.hazards}
                arrayHelpers={arrayHelpers}
                setFieldValue={setFieldValue}
                bg="transparent"
                p={{ base: "22px 20px", md: "8px 0px 8px 0px" }}
              />
            )}
          />
          <Box
            display="flex"
            mt="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            {isEdit ? (
              <>
                <Button onClick={submitForm} variant="link" size="sm">
                  {saveMessage(values)}
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  color="warning"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button onClick={submitForm} variant="link" size="sm">
                  Save
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  color="warning"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
            <ConfirmationAlert
              isOpen={isOpen}
              onClose={onClose}
              onConfirm={() => {
                onClose();
                handleSubmit(values);
              }}
              description={popupDescription}
              isAlert={false}
              cancelLabel="Cancel"
              confirmLabel={isUpdateOnly ? "Yes" : "Yes, send update"}
            />
          </Box>
        </>
      )}
    </Formik>
  );
};
