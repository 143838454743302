import React from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { sumBy } from "lodash";

import { AvatarList } from "../../data-display/avatar-list/avatar-list";
import { DataBox } from "../../data-display/data-box/data-box";
import { Budget } from "../../data-display/data-box/budget/budget";

export const ProjectCard = ({
  onCardClick,
  navigationPath,
  projectName,
  projectDescription,
  status,
  dateRange,
  roles,
  costs,
  users,
  currency,
  boxProps,
}) => {
  // Its allow to use open in new tab handler
  const onclick = (e) => {
    e.preventDefault();
    onCardClick();
  };

  const totalBudget =
    sumBy(roles, "currencyTokenCompensation") +
    sumBy(costs, "currencyTokenCompensation");

  return (
    <Box
      bg="gray.22"
      borderRadius="12px"
      w={{ base: "100%", sm: "48.2%", lg: "290px" }}
      onClick={onclick}
      cursor={"pointer"}
      {...boxProps}
    >
      <a href={navigationPath} rel="noreferrer">
        <Tooltip
          label={projectName}
          aria-label="project-name-hint"
          placement="top"
          variant="compact"
        >
          <Box m="13px 25px 11px" noOfLines={1}>
            <Text color="white" variant="h3">
              {projectName}
            </Text>
          </Box>
        </Tooltip>
        <Box
          p="14px 25px 18px"
          borderTop="1px solid"
          borderColor="gray.18"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box mb="24px">
            {!!projectDescription && (
              <Box mb="10px">
                <Text color="white" variant="paragraph">
                  {projectDescription}
                </Text>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={{ base: "row", md: "column" }}
              alignItems={{ base: "center", md: "flex-start" }}
              justifyContent={{ base: "space-between", md: "center" }}
            >
              {!!dateRange && (
                <Box
                  mb={{ base: "unset", md: "10px" }}
                  order={{ base: 2, md: 1 }}
                >
                  <Text color="gray.79" variant="h6">
                    {dateRange}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={{ base: "row", md: "column" }}
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "space-between", md: "center" }}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            {!!status && (
              <Box
                mb={{ base: "24px", md: "5px" }}
                width={{ base: "100%", md: "auto" }}
              >
                <Text color="fluorescent" variant="h8">
                  {status}
                </Text>
              </Box>
            )}
            <Box mb={{ base: "unset", md: "24px" }}>
              {totalBudget > 0 && !isNaN(totalBudget) && (
                <DataBox label="Total Budget">
                  <Budget
                    budget={totalBudget}
                    symbol={""}
                    currency={currency}
                  />
                </DataBox>
              )}
            </Box>
            {!!users?.length && <AvatarList size="sm" users={users} />}
          </Box>
        </Box>
      </a>
    </Box>
  );
};
