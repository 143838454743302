import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, generatePath } from "react-router-dom";
import {
  Box,
  Container,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

import { APP_PATHS } from "paths";
import {
  useUserContextState,
  useUserContextStateDispatch,
} from "shared/contexts/user-context-provider";
import { useOrganisationList } from "shared/queries/organisation";
import { useImportProjectMutation } from "shared/mutations/imports";
import { AppFeedback, Header, ImportModal } from "libs/ui-components/src";
import OrganisationsSidebar from "../organizations-sidebar";

export const Layout = () => {
  const navigate = useNavigate();
  const { user } = useUserContextState();
  const smVariant = {
    navigation: "drawer",
    navigationButton: true,
    defaultVisibility: false,
  };
  const lgVariant = {
    navigation: "sidebar",
    navigationButton: false,
    defaultVisibility: true,
  };
  const { data: organisations = [] } = useOrganisationList();
  const { favoriteOrganizationIds = [] } = { ...user?.config };
  const visibleOrganizations = organisations.filter(
    (org) =>
      favoriteOrganizationIds.includes(org.id) || org.createdBy === user?.id
  );
  const {
    isOpen: isImportModalOpen,
    onOpen: onOpenImport,
    onClose: onCloseImport,
  } = useDisclosure();
  const {
    isOpen: isOpenAppFeedbackWindow,
    onClose: onCloseAppFeedbackWindow,
    onOpen: onOpenAppFeedbackWindow,
  } = useDisclosure();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: lgVariant });
  const handleOpenOrgDetails = (id) => {
    navigate(
      generatePath(APP_PATHS.organisationDetails, { organizationId: id })
    );
  };
  const handleOpenOrganisations = () => {
    navigate(APP_PATHS.organisations);
  };
  const handleAddOrganisation = () => {
    navigate(APP_PATHS.newOrganisation);
  };
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  useEffect(() => {
    setSidebarOpen(
      !isEmpty(user) && (isSidebarOpen || variants.defaultVisibility)
    );
  }, [user, variants, isSidebarOpen]);
  const { mutate: onImportProject } = useImportProjectMutation({
    onSuccess: (response) => {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: response?.id })
      );
    },
  });

  const links = user.id
    ? [
        {
          label: "Organisations",
          route: APP_PATHS.organisations,
        },
        {
          label: "Projects",
          route: APP_PATHS.projects,
        },
        {
          label: "Import Project",
          onClick: onOpenImport,
        },
        {
          label: "Leave feedback",
          onClick: onOpenAppFeedbackWindow,
        },
      ]
    : [];
  const { onOpenLoginModal, onLogout } = useUserContextStateDispatch();
  const profileLinks = user.id
    ? [
        {
          label: "My profile",
          route: generatePath(APP_PATHS.userProfile, {
            userId: user.id,
          }),
        },
        {
          label: "Invite user",
          route: generatePath(APP_PATHS.inviteUser),
        },
        {
          label: "Logout",
          onClick: onLogout,
        },
      ]
    : [];
  return (
    <>
      <Box
        bg="gray.12"
        marginLeft={variants?.navigation == "sidebar" ? { xl: 0, md: 20 } : 0}
      >
        <Container maxW="container.lg" py="30px">
          <Header
            user={user.id ? user : undefined}
            onHomeClick={() => navigate(APP_PATHS.home)}
            links={links}
            profileLinks={profileLinks}
            onNavigationLinkClick={navigate}
            onLoginClick={onOpenLoginModal}
            showSidebarButton={variants?.navigationButton}
            onShowSidebar={toggleSidebar}
          />
        </Container>
        <Outlet />
        <ImportModal
          isOpen={isImportModalOpen}
          onClose={onCloseImport}
          onSave={onImportProject}
        />
      </Box>
      <OrganisationsSidebar
        variant={variants?.navigation}
        isOpen={isSidebarOpen}
        organisations={visibleOrganizations}
        onClose={toggleSidebar}
        onOrganisationClick={handleOpenOrgDetails}
        onLogoClick={handleOpenOrganisations}
        onAddClick={handleAddOrganisation}
      />
      <AppFeedback
        onCloseAppFeedbackWindow={onCloseAppFeedbackWindow}
        isOpenAppFeedbackWindow={isOpenAppFeedbackWindow}
      />
    </>
  );
};
