import React from "react";

import { OneInputListArray } from "./one-input-list-array";
import { AdminCreateButton } from "../admin-create-button/admin-create-button";

export const CreateOneInputSetArray = ({
  isReadOnly = false,
  fieldName,
  title,
  fieldValues = [],
  arrayHelpers,
  setFieldValue,
  ...props
}) => {
  const initialValues = "";
  return (
    <>
      {fieldValues.length > 0 && (
        <OneInputListArray
          isReadOnly={isReadOnly}
          fieldName={fieldName}
          title={title}
          onDeleteCost={(id) => arrayHelpers.remove(id)}
          fieldValues={fieldValues}
          setFieldValue={setFieldValue}
          {...props}
        />
      )}
      {!isReadOnly && (
        <>
          <AdminCreateButton
            bg="transparent"
            p="11.5px 15px"
            onClick={() =>
              arrayHelpers.insert(fieldValues.length, initialValues)
            }
          >
            {`Add ${title.toLowerCase()}`}
          </AdminCreateButton>
        </>
      )}
    </>
  );
};
