import React from "react";
import { generatePath, useParams } from "react-router-dom";
import { startCase, sumBy, isEmpty } from "lodash";
import {
  Box,
  Button,
  Text,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { APP_PATHS } from "paths";
import { Budget, FormikInputControl, RoundedBox } from "libs/ui-components/src";
import { AppContainer } from "components/app-container";
import { formatDateL } from "libs/utils/src";

import { useUserContextState } from "shared/contexts/user-context-provider";

import { useProjectDetails } from "shared/queries/project";
import { useIncomePaymentMutation } from "shared/mutations/payments";
import {
  usePaymentsIncomeList,
  useSmartContractList,
} from "shared/queries/payments";

export const Incomes = () => {
  const params = useParams();
  const toast = useToast();
  const { projectId } = params;
  const { user } = useUserContextState();

  const {
    data: projectIncomes,
    isLoading: isProjectIncomesLoading,
    refetch: refetchProjectIncomes,
  } = usePaymentsIncomeList(projectId);
  const { data: project, isLoading: isProjectLoading } =
    useProjectDetails(projectId);

  const addresses = isProjectLoading
    ? []
    : [project?.currencyTokenContract, project?.governanceTokenContract];
  const { data: smartContracts, isLoading: isContractsLoading } =
    useSmartContractList(addresses);

  const { mutate: sendTransactionHash } = useIncomePaymentMutation();

  const isLoading =
    isProjectLoading ||
    isProjectIncomesLoading ||
    isContractsLoading ||
    !project;
  if (isLoading) {
    return null;
  }

  const isOwner = user?.id === project.createdBy;

  const handleSendHash = (values, { resetForm }) => {
    const data = {
      projectId: projectId,
      transactionHash: values.transactionHash,
    };

    sendTransactionHash(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Transaction Hash sent.",
        });
        resetForm();
        refetchProjectIncomes();
      },
      onError: () => {
        resetForm();
      },
    });
  };

  const smartContract = (tokenContractAddress) => {
    return smartContracts?.find(
      (contract) =>
        contract.address.toLowerCase() === tokenContractAddress.toLowerCase()
    );
  };
  console.log(projectIncomes);

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Project incomes: {project?.title}
        </Text>

        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.projectDetails, { projectId })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Back to project
        </Button>
      </AppContainer.Header>
      {isOwner ? (
        <AppContainer.Content isDetails pt="13px">
          <Text variant="h2" color="white" mb="25px">
            Enter project incomes
          </Text>
          <Formik
            enableReinitialize
            initialValues={{ transactionHash: "" }}
            onSubmit={handleSendHash}
            validationSchema={Yup.object().shape({
              transactionHash: Yup.string().required(
                "Transaction hash is required"
              ),
            })}
          >
            {({ isSubmitting }) => (
              <Form id="detailsForm" noValidate>
                <RoundedBox mb="25px">
                  <FormikInputControl
                    label="Transaction Hash for incomes"
                    name="transactionHash"
                    placeholder="0x..."
                    styleProps={{ mb: "21px" }}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Send hash
                  </Button>
                </RoundedBox>
              </Form>
            )}
          </Formik>
          {!isEmpty(projectIncomes) && (
            <Text variant="h2" color="white" mb="25px">
              Project incomes
            </Text>
          )}

          <Box bg="gray.22" borderRadius="12px">
            {!isEmpty(projectIncomes) ? (
              <>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Transaction</Th>
                        <Th>Amount</Th>
                        <Th>Date</Th>
                        <Th>Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {projectIncomes.map((income) => (
                        <Tr key={income.id}>
                          <Td>
                            <VStack alignItems="left">
                              <Text>
                                From: {income.processedByAddress.toLowerCase()}
                              </Text>
                              <Text>
                                To: {income.walletAddress.toLowerCase()}
                              </Text>
                            </VStack>
                          </Td>
                          <Td>
                            <Budget
                              budget={income.amount}
                              symbol={""}
                              currency={
                                smartContract(income.tokenContractAddress)
                                  ? ` (${
                                      smartContract(income.tokenContractAddress)
                                        .symbol
                                    })`
                                  : ""
                              }
                            />
                          </Td>
                          <Td>{formatDateL(income.updatedAt)}</Td>
                          <Td>
                            {income.transactionUrl ? (
                              <Button
                                as="a"
                                href={income.transactionUrl}
                                target="_blank"
                                variant="outline"
                                rightIcon={<ExternalLinkIcon />}
                              >
                                View Transaction
                              </Button>
                            ) : (
                              startCase(income.state)
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Divider my="16px" />
                <Text variant="h3" color="white" mb="16px" ml="16px">
                  Total incomes:{" "}
                  {sumBy(projectIncomes, (income) => parseInt(income.amount))}
                </Text>
              </>
            ) : (
              <Text variant="h2" color="white" my="60px" align="center">
                Add transaction hash for incomes
              </Text>
            )}
          </Box>
        </AppContainer.Content>
      ) : (
        <AppContainer.Content isDetails pt="13px">
          <RoundedBox>
            <Text variant="h2" color="white" my="60px" align="center">
              Only project owner can add incomes
            </Text>
          </RoundedBox>
        </AppContainer.Content>
      )}
    </>
  );
};
