import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FunctionsList } from "./functions-list";

export const ReviewFunctionSet = ({
  onChange,
  users,
  setOpenWarning,
  functions = [],
}) => {
  const handleChangeFunction = (values) => {
    const foundFunction = functions.find((t) => t.id === values.id);
    if (foundFunction) {
      const updatedFunctions = functions.map((item) =>
        item.id === values.id ? { ...values } : item
      );
      onChange(updatedFunctions);
    }
  };
  return (
    <Box>
      {functions.length ? (
        <FunctionsList
          handleChangeFunction={handleChangeFunction}
          functions={functions}
          users={users}
          setOpenWarning={setOpenWarning}
        />
      ) : (
        <Text variant="h6" textAlign="center">
          No active requests available
        </Text>
      )}
    </Box>
  );
};
