import React from "react";
import { Box, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
export const DataBox = ({
  loading = false,
  disabled = false,
  label,
  tooltipLabel,
  gap,
  children,
  boxProps,
}) => (
  <Box display="flex" flexDirection="column" {...boxProps}>
    {loading ? (
      <Skeleton isLoaded={!loading} h="18px" w="100px" />
    ) : (
      <Box display="flex" alignItems="center">
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="17.71px"
          color={disabled ? "gray.50" : "gray.79"}
          mb={gap}
          mr={tooltipLabel ? "6px" : 0}
        >
          {label}
        </Text>
        {!!tooltipLabel && (
          <Tooltip label={tooltipLabel}>
            <InfoIcon color={disabled ? "gray.50" : "gray.79"} />
          </Tooltip>
        )}
      </Box>
    )}
    {loading ? (
      <Skeleton isLoaded={!loading} h="18px" w="100px" />
    ) : typeof children === "string" || typeof children === "number" ? (
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="17.71px"
        color={disabled ? "gray.50" : "white"}
      >
        {children}
      </Text>
    ) : (
      children
    )}
  </Box>
);
