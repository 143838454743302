import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { startsWith } from "lodash";

import { AppContainer } from "components/app-container";
import { CardListSkeleton } from "components/loading-skeletons";
import { OrganisationCard } from "libs/ui-components/src";
import { useOrganisationList } from "shared/queries/organisation";
import { useProjectsList } from "shared/queries/project";
import { APP_PATHS } from "paths";

export const OrganisationsList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { data: projects, isLoading: isProjectsLoading } = useProjectsList(
    true,
    { state: "applicationsOpen" }
  );
  const { data, isLoading: isOrganisationsLoading } = useOrganisationList();
  const organizationPath = (organizationId) => {
    return generatePath(APP_PATHS.organisationDetails, { organizationId });
  };
  const handleOpenOrgDetails = (id) => () => {
    navigate(organizationPath(id));
  };
  const handleSearchOrgs = (value) => {
    setSearch(value.target.value);
  };
  const handleClearSearch = () => {
    setSearch("");
  };
  if (isOrganisationsLoading || isProjectsLoading) {
    return (
      <CardListSkeleton loading={isOrganisationsLoading || isProjectsLoading} />
    );
  }
  const organisations = data?.filter((organisation) =>
    startsWith(organisation.name, search)
  );
  return (
    <AppContainer.Content>
      <Box mt="13px" mb="17px">
        <Text variant="h1" color="white">
          Find an organisation
        </Text>
      </Box>
      <Box mb="40px" display="flex" alignItems="center">
        <InputGroup>
          <Input
            placeholder="Search"
            onChange={handleSearchOrgs}
            value={search}
          />
          <InputRightElement>
            <CloseIcon
              color="gray.36"
              cursor="pointer"
              onClick={handleClearSearch}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={{ base: "center", md: "flex-start" }}
      >
        {organisations?.map((item) => {
          const { id, name, imageUrl } = item;
          const organizationProjects = projects?.filter(
            (project) => project.organizationId === id
          );
          return (
            <OrganisationCard
              key={id}
              onCardClick={handleOpenOrgDetails(id)}
              navigationPath={organizationPath(id)}
              name={name}
              image={imageUrl}
              projects={organizationProjects}
              boxProps={{
                mb: "40px",
                mr: { base: "0", sm: "10px" },
              }}
            />
          );
        })}
      </Box>
    </AppContainer.Content>
  );
};
