import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import React from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import { NftCard, RoundedBox } from "libs/ui-components/src";
import getNftQuery from "shared/queries/nft";

export const NftsTab = ({ nearAccountId }) => {
  const nftQuery = getNftQuery(nearAccountId);
  const { loading: isLoading, data: nfts } = useQuery(nftQuery);
  if (isLoading) {
    return (
      <Skeleton isLoaded={!isLoading} w="100%" h="325px" borderRadius="24px" />
    );
  }
  return !isEmpty(nfts?.mb_views_nft_tokens) ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexWrap="wrap" maxWidth={"100%"} mt={"15px"}>
        {nfts.mb_views_nft_tokens.map((item) => {
          const { token_id: tokenId, title, media } = item;
          return (
            <NftCard
              key={tokenId}
              name={title}
              image={media}
              boxProps={{
                mb: "40px",
                mr: { base: "0", sm: "10px" },
              }}
            />
          );
        })}
      </Box>
    </Box>
  ) : (
    <RoundedBox
      h={"325px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text variant="h6">No NFT Minted yet.</Text>
      </Box>
    </RoundedBox>
  );
};
