// CALCULATE GAS REFFERENCE
// https://github.com/near/near-api-js/blob/master/packages/cookbook/utils/calculate-gas.js

import { keyStores, connect, WalletConnection, utils } from "near-api-js";
import { transfer } from "near-api-js/lib/transaction";
import { environment } from "environments";

// App refference
// https://github.com/SenderWallet/sender-wallet-dapp-example/blob/master/src/App.js

const CONFIG = {
  testNet: {
    networkId: "testnet",
    chainId: "nearTestNet",
    nodeUrl: "https://rpc.testnet.near.org",
    walletUrl: "https://wallet.testnet.near.org",
    helperUrl: "https://helper.testnet.near.org",
    explorerUrl: "https://explorer.testnet.near.org",
  },
  mainNet: {
    networkId: "mainnet",
    chainId: "near",
    nodeUrl: "https://rpc.mainnet.near.org",
    walletUrl: "https://wallet.mainnet.near.org",
    helperUrl: "https://helper.mainnet.near.org",
    explorerUrl: "https://explorer.mainnet.near.org",
  },
};

export class NearService {
  constructor() {
    const network = environment.NEAR_IS_MAIN_NETWORK ? "mainNet" : "testNet";
    this._connectionConfig = CONFIG[network];
    this._connectionConfig.keyStore =
      new keyStores.BrowserLocalStorageKeyStore();
    this._nearConnection = null;
    this._walletConnection = null;
    this._walletAccountId = null;
    this._walletAccount = null;
  }

  async connect() {
    this._nearConnection = await connect(this._connectionConfig);
    this._walletConnection = new WalletConnection(
      this._nearConnection,
      `deepFinance${this._connectionConfig.networkId}`
    );

    this._walletAccountId = this._walletConnection.getAccountId();
    this._walletAccount = this._walletConnection.account();
  }

  walletSignIn() {
    if (this.isWalletSignedIn) {
      return;
    }
    const contract = environment.NEAR_IS_MAIN_NETWORK
      ? "deepfinancemain.mintbase1.near"
      : "firstdeepskillstest.mintspace2.testnet";
    this._walletConnection.requestSignIn({
      contractId: contract,
    });
  }

  async walletSignedOut() {
    await this._walletConnection.signOut();
  }

  get chainId() {
    return this._connectionConfig.chainId;
  }

  async completeSignIn() {
    return await this._walletConnection.isSignedInAsync();
  }

  get isWalletSignedIn() {
    return this._walletConnection.isSignedIn();
  }

  async sendNearTokens(to, amount, callBackUrl) {
    return await this._walletAccount.signAndSendTransaction({
      receiverId: to,
      actions: [transfer(utils.format.parseNearAmount(amount))],
      walletCallbackUrl: callBackUrl,
    });
  }

  async _pullKeyPair() {
    const { keyStore, networkId } = this._connectionConfig;
    return await keyStore.getKey(networkId, this._walletAccountId);
  }

  async signMessage(msgString) {
    const keyPair = this._pullKeyPair();
    const msg = Buffer.from(msgString);

    const { signature } = keyPair.sign(msg);

    return signature;
  }
}
