import React from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { omitBy, isUndefined } from "lodash";

import { AppContainer } from "components/app-container";
import { EditProfileModal, OverviewSidebar } from "libs/ui-components/src";
import { useNavigate } from "react-router-dom";
import {
  useUserContextState,
  useUserContextStateDispatch,
} from "shared/contexts/user-context-provider";
import { useUserProfileMutation } from "shared/mutations/user";
import { QueriesKeysEnum } from "shared/queries/queries-keys-enum";
import { useUser, useUsers, useUserSkills } from "shared/queries/users";
import { UserProjectsTab } from "components/user-projects-tab";
import { PaymentsTab } from "components/payments-tab";
import { NftsTab } from "components/nfts-tab";
import { SkillsTableTab } from "components/user-skills-tab";
import { makeFriendly } from "libs/utils/src";
import axios from "shared/api/setup";
import { useProjectsList } from "shared/queries/project";
import {
  useSmartContractList,
  useUserPaymentsList,
} from "shared/queries/payments";
import { useProjectUpdateVisibilityMutation } from "shared/mutations/project";
import { APP_PATHS } from "paths";
import {
  useOrganisationFunctions,
  useOrganisationList,
} from "shared/queries/organisation";
import { EMPTY_SKILLS_TAB_TEXT_OWNER } from "shared/constants/empty-text";

export const MyUserProfile = () => {
  const navigate = useNavigate();
  const { user } = useUserContextState();
  const { updateUserContext } = useUserContextStateDispatch();
  const queryCache = useQueryClient();
  const { data: currentUser, isLoading: isUserLoading, refetch } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: onEditUserProfile } = useUserProfileMutation();
  const { mutate: onUpdateProjectVisibility } =
    useProjectUpdateVisibilityMutation();
  const {
    data: userProjects,
    isLoading: isUserProjectLoading,
    refetch: refetchUserProject,
  } = useProjectsList(true, { assigneeUserId: user?.id });
  const { data: userOwnProjects, isLoading: isUserOwnProjectLoading } =
    useProjectsList(true, { createdBy: user?.id });
  const { data: payments, isLoading: isPaymentsLoading } = useUserPaymentsList(
    user?.id
  );
  const { data: organizations = [], isLoading: isOrganisationsLoading } =
    useOrganisationList();
  const { data: functions } = useOrganisationFunctions(
    organizations.map((organization) => organization.id)
  );
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkills([user?.id]);
  const { data: users, isLoading: isUsersLoading } = useUsers();
  const addresses = isPaymentsLoading
    ? []
    : payments?.map((payment) => payment.tokenContractAddress);

  const { data: smartContracts, isLoading: isContractsLoading } =
    useSmartContractList(addresses);
  const isProjectsLoading = isUserProjectLoading || isUserOwnProjectLoading;

  const handleEditProfile = async (values) => {
    const filteredValues = omitBy(
      values,
      (value, key) => !value || key === "avatar"
    );
    if (values?.avatar) {
      const formData = new FormData();
      formData.append("file", values?.avatar);
      const { url } = await axios.post("/media/avatars", formData);
      filteredValues.avatarUrl = url;
    }
    filteredValues.isEmailPublic = values.isEmailPublic;
    filteredValues.isNotificationsOn = values.isNotificationsOn;
    await onEditUserProfile(filteredValues, {
      onSuccess: () => {
        onClose();
        updateUserContext({
          type: "update",
          payload: { user: { ...user, ...filteredValues } },
        });
        queryCache.invalidateQueries([QueriesKeysEnum.user]);
        refetch();
      },
      onSettled: () => {
        onClose();
      },
    });
  };

  const handleUpdateProjectVisibility = (projectId, visibility) => {
    if (!projectId || isUndefined(visibility)) {
      return;
    }
    onUpdateProjectVisibility(
      { projectId, visibility },
      {
        onSuccess: () => {
          queryCache.invalidateQueries([QueriesKeysEnum.userProjects]);
          refetchUserProject();
        },
      }
    );
  };
  const handleCreateOrg = () => {
    navigate(APP_PATHS.newOrganisation);
  };
  const isLoading =
    isUserLoading ||
    isOrganisationsLoading ||
    isUserSkillsLoading ||
    !currentUser;

  return (
    <>
      <AppContainer>
        <AppContainer.Content>
          <Box display="flex" flexDirection={{ base: "column", md: "row" }}>
            <OverviewSidebar
              loading={isLoading}
              title={`${currentUser?.firstName} ${currentUser?.lastName}`}
              avatar={currentUser?.avatarUrl}
              extraChildren={true}
            >
              <Box mt={"18px"} mb={"24px"}>
                <Button variant="link" onClick={handleCreateOrg}>
                  Create a new organisation
                </Button>
              </Box>
              <Box mt={"18px"} mb={"18px"}>
                {currentUser?.email && (
                  <Button
                    as="a"
                    href={`mailto:${currentUser.email}`}
                    leftIcon={<EmailIcon />}
                    variant="link"
                    fontSize="14px"
                    lineHeight="17.71px"
                    fontWeight="450"
                  >
                    {currentUser?.email}
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  mb={"50px"}
                  size="sm"
                  onClick={onOpen}
                  data-testid={"edit-btn"}
                >
                  Edit
                </Button>
              </Box>
            </OverviewSidebar>
            <Box
              width="100%"
              ml={{ base: "0", md: "30px" }}
              mt={{ base: "30px", md: "0" }}
            >
              <Tabs>
                <TabList>
                  <Tab>
                    Projects{" "}
                    {makeFriendly(
                      userProjects?.length + userOwnProjects?.length
                    )}
                  </Tab>
                  <Tab>Roles {makeFriendly(userSkills?.length)}</Tab>
                  <Tab>Payments {makeFriendly(payments?.length)}</Tab>
                  {user?.nearAccountId && <Tab>NFTs</Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <UserProjectsTab
                      ownProjects={userOwnProjects}
                      projects={userProjects}
                      availableAssignee={users}
                      isLoading={isProjectsLoading || isUsersLoading}
                      user={user}
                      handleUpdateProjectVisibility={
                        handleUpdateProjectVisibility
                      }
                      isCurrentUser={true}
                    />
                  </TabPanel>
                  <TabPanel>
                    <SkillsTableTab
                      userSkills={userSkills}
                      functions={functions}
                      organizations={organizations}
                      emptyText={EMPTY_SKILLS_TAB_TEXT_OWNER}
                    />
                  </TabPanel>
                  <TabPanel>
                    <PaymentsTab
                      payments={payments}
                      projects={userProjects}
                      smartContracts={smartContracts}
                      users={users}
                      isLoading={
                        isPaymentsLoading ||
                        isProjectsLoading ||
                        isContractsLoading ||
                        isUsersLoading ||
                        !userProjects ||
                        !payments ||
                        !users
                      }
                    />
                  </TabPanel>
                  {user?.nearAccountId && (
                    <TabPanel>
                      <NftsTab nearAccountId={user.nearAccountId} />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </AppContainer.Content>
      </AppContainer>
      {currentUser && (
        <EditProfileModal
          isOpen={isOpen}
          onClose={onClose}
          profile={currentUser}
          onSave={handleEditProfile}
        />
      )}
    </>
  );
};
