import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { onApiError } from "../helpers/onApiError";

import axios from "shared/api/setup";

export const useCreateProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/createProject", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useEditProjectMutation = (projectId, options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put(`/updateProject?id=${projectId}`, data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      onSettled: (data) => {
        console.log("useEditProjectMutation: ", data);
      },
      ...options,
    }
  );
};

export const useArchiveProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (projectId) => axios.delete(`/archiveProject?id=${projectId}`),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useCompleteProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.put("/completeProject", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useRequestCompleteProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put("/requestCompleteProject", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useRequestUnCompleteProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put(`/rollbackToInProgressProject?id=${data.id}`, {}),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useProjectCreateAssigneeMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createProjectAssigneeRequest", data),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useProjectConfirmAssigneeMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put("/confirmProjectAssigneeRequest", data),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useProjectDeclineAssigneeMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put("/declineProjectAssigneeRequest", data),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useProjectUpdateVisibilityMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) =>
      axios.put(`/updateMyProjectVisibility?id=${data.projectId}`, {
        visibilityState: data.visibility ? "on" : "off",
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useCreateOrganizationRoleUpdateRequestMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/createOrganizationRoleUpdateRequest", data),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useDeclineOrganizationRoleUpdateRequestMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (organizationRoleId) =>
      axios.put("/declineOrganizationRoleUpdateRequest", {
        id: organizationRoleId,
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useConfirmOrganizationRoleUpdateRequestMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (organizationRoleId) =>
      axios.put("/confirmOrganizationRoleUpdateRequest", {
        id: organizationRoleId,
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useCreateProjectFeedbackMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/createFeadback", data), {
    onError: (error) => {
      toast({ position: "top-right", status: "error", title: error.message });
    },
    ...options,
  });
};

export const useCompleteMyProjectTaskMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) =>
      axios.put(`/completeMyProjectTask?id=${data.projectId}`, {
        roleId: data.roleId,
        taskId: data.taskId,
        isCompleted: data.isCompleted,
      }),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
