import React from "react";
import { RoundedBox, SkillsTable } from "libs/ui-components/src";
import { Box, Text } from "@chakra-ui/react";

export const SkillsTableTab = ({
  userSkills,
  functions,
  organizations,
  emptyText,
}) => {
  return (
    <Box>
      {userSkills?.length ? (
        <Box>
          <SkillsTable
            functions={functions}
            organizations={organizations}
            userSkills={userSkills}
          />
        </Box>
      ) : (
        <RoundedBox
          h={"325px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="h6">{emptyText}</Text>
        </RoundedBox>
      )}
    </Box>
  );
};
