import React from "react";
import { useField } from "formik";
import { isEmpty } from "lodash";
import { Box, Text } from "@chakra-ui/react";

import { FormikInputControl } from "../../form/formik-wrappers";

export const CostForm = ({ index, disabled }) => {
  const [governanceTokenContractField] = useField("governanceTokenContract");
  const parentNode = `costs.${index}`;

  const isGovernanceTokenContractExists = !isEmpty(
    governanceTokenContractField.value
  );

  return (
    <>
      <FormikInputControl
        label="Description"
        type="text"
        parentName={parentNode}
        name={`${parentNode}.description`}
        placeholder="Gross profit"
        disabled={disabled}
        required={!disabled}
        styleProps={{ mb: "15px" }}
      />
      <FormikInputControl
        label="Receiving Address"
        parentName={parentNode}
        name={`${parentNode}.walletAddress`}
        placeholder="0x..."
        required={!disabled}
        styleProps={{ mb: "21px", mr: "15px" }}
      />
      <Box mt="8px" display="flex" alignItems="center">
        <Text variant="h6" color="white" mr="8px">
          Amount
          <Text as="span" ml={1} color="red.500">
            *
          </Text>
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
      >
        <FormikInputControl
          parentName={parentNode}
          name={`${parentNode}.currencyTokenCompensation`}
          type="number"
          placeholder="0"
          inputProps={{ min: 0 }}
          styleProps={{ mr: "15px" }}
          disabled={disabled}
          InputRightElement={
            <Text variant="h6" color="white" ml="8px" mr="30px">
              Primary
            </Text>
          }
        />
        <FormikInputControl
          parentName={parentNode}
          name={`${parentNode}.governanceTokenCompensation`}
          type="number"
          placeholder="0"
          disabled={disabled || !isGovernanceTokenContractExists}
          inputProps={{
            min: 0,
          }}
          InputRightElement={
            <Text variant="h6" color="white" ml="8px" mr="30px">
              Secondary
            </Text>
          }
        />
      </Box>
    </>
  );
};
