import { useQuery } from "react-query";
import { useToast } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { QueriesKeysEnum } from "shared/queries/queries-keys-enum";
import axios from "shared/api/setup";

export const useUser = (options) => {
  const toast = useToast();
  return useQuery(QueriesKeysEnum.user, () => axios.get("/readMyUser"), {
    retry: false,
    onError: (error) => {
      toast({ position: "top-right", status: "error", title: error.message });
    },
    ...options,
  });
};

export const useUsers = (userIds, options) => {
  const toast = useToast();
  const params = {};
  if (userIds && userIds.length > 0) {
    params.ids = userIds.toString();
  }
  return useQuery(
    [QueriesKeysEnum.users, userIds],
    () => axios.get("/indexUsers", { params }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useUserById = (userId, options) => {
  const toast = useToast();
  return useQuery(
    QueriesKeysEnum.customUsers,
    () =>
      axios.get("/indexUsers", {
        params: {
          ids: [userId].toString(),
        },
      }),
    {
      enabled: !!userId,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useInvitedUsers = (userIds, options) => {
  const toast = useToast();
  const params = {};
  if (userIds && userIds.length > 0) {
    params.ids = userIds.toString();
  }
  return useQuery(
    [QueriesKeysEnum.invitedUsers, userIds],
    () => axios.get("/indexInvitedUsers", { params }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useInvitedUserById = (userId, options) => {
  const toast = useToast();
  return useQuery(
    QueriesKeysEnum.customInvitedUser,
    () =>
      axios.get("/indexInvitedUsers", {
        params: {
          ids: [userId].toString(),
        },
      }),
    {
      enabled: !!userId,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useUserSkills = (userIds, options) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userSkills, userIds],
    () =>
      axios.get("/indexUserSkills", {
        params: { userIds: userIds.toString(), extendByIsOutdated: true },
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useUserSkillsByOrganization = (organizationId, options) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userSkills, organizationId],
    () =>
      !isEmpty(organizationId)
        ? axios.get("/indexUserSkills", {
            params: { organizationId, extendByIsOutdated: true },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useUserSkillsByUsersAndOrganization = (
  userIds,
  organizationId,
  options
) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userSkills, userIds, organizationId],
    () =>
      !isEmpty(userIds) && !isEmpty(organizationId)
        ? axios.get("/indexUserSkills", {
            params: {
              userIds: userIds.toString(),
              organizationId,
              extendByIsOutdated: true,
            },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useUserSkillByUserProjectOrgRoleId = (
  userIds,
  organizationRoleId,
  organizationId,
  options
) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userSkills, userIds, organizationRoleId, organizationId],
    () =>
      !isEmpty(userIds) &&
      !isEmpty(organizationId) &&
      !isEmpty(organizationRoleId)
        ? axios.get("/indexUserSkills", {
            params: {
              userIds: userIds.toString(),
              organizationRoleId,
              organizationId,
              extendByIsOutdated: true,
            },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
      ...options,
    }
  );
};

export const useReadeMyUserSkills = (userSkillId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userSkills, userSkillId],
    () =>
      axios.get("/readUserSkill", {
        params: { id: userSkillId },
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};
