import React from "react";
import { isEmpty } from "lodash";
import {
  Badge,
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Markdown from "components/Markdown";

import { useOrganisationTeamFunctions } from "shared/queries/organisation";
import { AppContainer } from "components/app-container";
import { generatePath, useParams } from "react-router-dom";
import { RoundedBox } from "libs/ui-components/src";
import { NotFound } from "pages/not-found";
import { APP_PATHS } from "paths";
import { pluralise } from "libs/utils/src";

export const RoleDescription = () => {
  const params = useParams();
  const toast = useToast();
  const { organizationId, departmentId, organizationRoleId } = params;

  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationTeamFunctions([organizationId], departmentId);

  if (isFunctionsLoading) {
    return null;
  }

  const filteredFunctions = functions.filter(
    (role) => role.id === organizationRoleId
  );
  const selectedFunction = filteredFunctions[0];

  const { isArchived, title, description, goals, hazards } = selectedFunction;

  const handleCopyLink = () => {
    const currentUrl = window.location.href;

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        toast({
          position: "top-right",
          status: "success",
          title: "URL copied to clipboard.",
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          status: "error",
          title: error.message,
        });
      });
  };

  return (
    <AppContainer>
      <AppContainer.Content>
        <Button
          // mt={4}
          mb={4}
          as="a"
          href={generatePath(APP_PATHS.departmentDetails, {
            organizationId,
            departmentId,
          })}
          variant="link"
          justifyContent="flex-start"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Go to team
        </Button>
        {filteredFunctions.length !== 0 ? (
          <RoundedBox minHeight={`calc(80vh - 100px)`}>
            {/* 100px - height of header */}
            {isArchived && (
              <Badge colorScheme="red" mx={10} mb={5}>
                Archived
              </Badge>
            )}
            <Flex mx={10} mb={5}>
              <Text as="h1" variant="h1">
                {title}
              </Text>
              <Button variant="link" onClick={handleCopyLink} ml={2} mt={4}>
                Copy link
              </Button>
            </Flex>

            <Text variant="h1" mx={10} mb="12px">
              Description
            </Text>
            <RoundedBox
              bg="gray.18"
              display="flex"
              flexDirection="row"
              p={{ base: "8px 16px", md: "8px 16px" }}
              borderRadius="4px"
              mx={10}
              mb="12px"
            >
              <Text whiteSpace="pre-wrap" fontWeight="700">
                <Markdown linkTarget="_blank" text={description} />
              </Text>
            </RoundedBox>

            {!isEmpty(goals) && (
              <>
                <Text variant="h1" mx={10} mb="12px">
                  {pluralise("Goal", goals.length)}
                </Text>

                <UnorderedList>
                  <Box whiteSpace="pre-wrap" mx={10} fontWeight="700" mb="12px">
                    {goals.map((goal, index) => (
                      <ListItem key={index} mb="12px" ml="-16px">
                        <RoundedBox
                          bg="gray.18"
                          p={{ base: "8px 16px", md: "8px 16px" }}
                          borderRadius="4px"
                        >
                          <Markdown
                            linkTarget="_blank"
                            text={goal.description}
                          />
                        </RoundedBox>
                      </ListItem>
                    ))}
                  </Box>
                </UnorderedList>
              </>
            )}
            {!isEmpty(hazards) && (
              <>
                <Text variant="h1" mx={10} mb="12px">
                  {pluralise("Hazard", hazards.length)}
                </Text>

                <UnorderedList>
                  <Box whiteSpace="pre-wrap" mx={10} fontWeight="700" mb="12px">
                    {hazards.map((hazard, index) => (
                      <ListItem key={index} mb="12px" ml="-16px">
                        <RoundedBox
                          bg="gray.18"
                          p={{ base: "8px 16px", md: "8px 16px" }}
                          borderRadius="4px"
                        >
                          <Markdown
                            linkTarget="_blank"
                            text={hazard.description}
                          />
                        </RoundedBox>
                      </ListItem>
                    ))}
                  </Box>
                </UnorderedList>
              </>
            )}
          </RoundedBox>
        ) : (
          <NotFound />
        )}
      </AppContainer.Content>
    </AppContainer>
  );
};
