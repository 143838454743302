export const Slider = {
  baseStyle: {
    track: {
      bg: "primary",
    },
    filledTrack: {
      bg: "primary",
    },
    thumb: {
      border: "8px solid",
      borderColor: "gray.18",
      borderRadius: "100%",
      _focus: {
        boxShadow: "none",
      },
    },
  },
  sizes: {
    md: {
      thumb: { w: "36px", h: "36px" },
    },
  },
};
