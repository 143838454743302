import React from "react";
import { useParams } from "react-router-dom";
import { MyUserProfile } from "./MyUserProfile";
import { UserProfile } from "./UserProfile";
import { useUserContextState } from "shared/contexts/user-context-provider";

export const ProfilePage = () => {
  const params = useParams();
  const { userId } = params;
  const { user } = useUserContextState();
  if (user?.id === userId) {
    return <MyUserProfile />;
  }
  return <UserProfile />;
};
