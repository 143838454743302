import React from "react";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Text,
  Textarea,
  FormErrorMessage,
  HStack,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import { ArrowBackIcon, StarIcon } from "@chakra-ui/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { APP_PATHS } from "paths";

import { AppContainer } from "components/app-container";
import { RoundedBox } from "libs/ui-components/src";

import { useUserContextState } from "shared/contexts/user-context-provider";
import {
  useIndexProjectFeedbackRequests,
  useProjectDetails,
} from "shared/queries/project";
import { useCreateProjectFeedbackMutation } from "shared/mutations/project";

const FeedbackSchema = Yup.object().shape({
  description: Yup.string().required("Feedback is required"),
  score: Yup.number()
    .min(1, "Score must be 1-5")
    .max(5, "Score must be 1-5")
    .required("Score is required"),
});

const StarRating = ({ field, form }) => {
  const { value } = field;
  return (
    <HStack spacing={1}>
      {[1, 2, 3, 4, 5].map((rating) => (
        <StarIcon
          key={rating}
          boxSize={6}
          onClick={() => form.setFieldValue(field.name, rating)}
          cursor="pointer"
          color={rating <= value ? "primary" : "gray"}
        />
      ))}
    </HStack>
  );
};

export const ProjectFeedback = () => {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { projectId } = params;
  const { user } = useUserContextState();

  const { data: project, isLoading: isProjectLoading } =
    useProjectDetails(projectId);
  const { data: feedbacks = [], isLoading: isFeedbacksLoading } =
    useIndexProjectFeedbackRequests(projectId, user.id);

  const isLoading = isProjectLoading || isFeedbacksLoading || !project;

  const { title, state, roles = [] } = { ...project };

  const isProjectCompleted = state === ("completed" || "completionRequested");
  const isUserHaveNotFeedback = feedbacks.length === 0;

  const rolesUserIds = [];

  for (let index in roles) {
    rolesUserIds.push(roles[index]?.assigneeUserId);
  }

  const isUserMember = rolesUserIds?.includes(user?.id);

  const projectPageUrl = generatePath(APP_PATHS.projectDetails, { projectId });

  const { mutate: onCreateProjectFeedback } = useCreateProjectFeedbackMutation({
    onSuccess: () => {
      toast({
        position: "top-right",
        status: "success",
        title: "Feedback sent.",
      });
      setTimeout(() => navigate(projectPageUrl), 1000);
    },
  });

  const handleSubmit = (values) => {
    const feedback = {
      projectId: projectId,
      description: values.description,
      score: values.score,
    };
    onCreateProjectFeedback(feedback);
  };

  return (
    <AppContainer>
      <AppContainer.Content>
        <Button
          mb={4}
          as="a"
          href={projectPageUrl}
          variant="link"
          justifyContent="flex-start"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Go to project
        </Button>

        <RoundedBox>
          <Skeleton isLoaded={!isLoading} borderRadius={10}>
            {isUserMember ? (
              <Text as="h1" variant="h1" mb={4}>
                {title}
              </Text>
            ) : (
              <Text>You are not a contributor and cannot leave feedback.</Text>
            )}
            {isUserMember && !isProjectCompleted && (
              <Text>
                When the project is completed, you will be able to leave
                feedback.
              </Text>
            )}
            {isUserMember && !isUserHaveNotFeedback && (
              <Text>You have left feedback for this project.</Text>
            )}

            {isUserMember && isProjectCompleted && isUserHaveNotFeedback && (
              <Formik
                initialValues={{
                  description: "",
                  score: 0,
                }}
                validationSchema={FeedbackSchema}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form>
                    <Field name="description">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                        >
                          <FormLabel htmlFor="description">Feedback</FormLabel>
                          <Textarea
                            {...field}
                            id="description"
                            placeholder="Enter your feedback"
                          />
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="score">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.score && form.touched.score}
                          mt={4}
                        >
                          <FormLabel htmlFor="score">Score</FormLabel>
                          <StarRating field={field} form={form} />
                          <FormErrorMessage>
                            {form.errors.score}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button mt={6} type="submit">
                      Send Feedback
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </Skeleton>
        </RoundedBox>
      </AppContainer.Content>
    </AppContainer>
  );
};
