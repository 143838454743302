import React from "react";
import { Box } from "@chakra-ui/react";
import { isEmpty, isEqual } from "lodash";

import difference from "lodash.difference";
import keyBy from "lodash.keyby";

import { FunctionsList } from "./functions-list";
import {
  useCreateOrganisationRoleMutation,
  useRemoveOrganisationRoleMutation,
  useUpdateOrganisationRoleMutation,
} from "shared/mutations/organisation";

import {
  useUserSkillMutation,
  useRemoveUserSkillMutation,
} from "shared/mutations/user";

export const CreateOrganizationFunctionSet = ({
  isUpdateOnly = false,
  onChange,
  isEdit,
  organizationId,
  departmentId,
  users,
  functions = [],
  existingFunctionIds = [],
  setOpenWarning,
}) => {
  const { mutateAsync: onCreateOrganisationRole } =
    useCreateOrganisationRoleMutation();
  const { mutateAsync: onUpdateOrganisationRole } =
    useUpdateOrganisationRoleMutation();
  const { mutateAsync: onRemoveOrganisationRole } =
    useRemoveOrganisationRoleMutation();

  const { mutate: onCreateUserSkill } = useUserSkillMutation();
  const { mutate: onDeleteUserSkill } = useRemoveUserSkillMutation();

  const handleAddFunction = (values) => {
    const foundFunction = functions.find((t) => t.id === values.id);

    if (foundFunction) {
      const mutations = [];

      const descriptionChanged =
        values.description &&
        foundFunction &&
        values.description !== foundFunction.description;

      const hasValueChanged = (
        key,
        newValues = values,
        existFunction = foundFunction
      ) => {
        return existFunction && newValues[key] !== existFunction[key];
      };

      const unitOfPriceChanged = hasValueChanged("unitOfPrice");
      const unitCurrencyTokenPriceChanged = hasValueChanged(
        "unitCurrencyTokenPrice"
      );
      const unitGovernanceTokenPriceChanged = hasValueChanged(
        "unitGovernanceTokenPrice"
      );

      const functionUpdated =
        values.title !== foundFunction.title ||
        descriptionChanged ||
        unitOfPriceChanged ||
        unitCurrencyTokenPriceChanged ||
        unitGovernanceTokenPriceChanged ||
        values.governanceTokenMultiplier !==
          foundFunction.governanceTokenMultiplier ||
        !!values.changesSummary ||
        !isEqual(values.tasks, foundFunction.tasks) ||
        !isEqual(values.goals, foundFunction.goals) ||
        !isEqual(values.hazards, foundFunction.hazards);

      if (functionUpdated) {
        let data = {
          title: values.title,
          description: values.description,
          changesSummary: values.changesSummary,
          governanceTokenMultiplier: values.governanceTokenMultiplier,
          unitOfPrice: values.unitOfPrice ? values.unitOfPrice : undefined,
          unitCurrencyTokenPrice: values.unitCurrencyTokenPrice
            ? values.unitCurrencyTokenPrice
            : 0,
          unitGovernanceTokenPrice: values.unitGovernanceTokenPrice
            ? values.unitGovernanceTokenPrice
            : 0,
          tasks: values.tasks,
          goals: values.goals,
          hazards: values.hazards,
        };

        onUpdateOrganisationRole({ roleId: values.id, data });
      }

      const oldOwners = foundFunction.owners || [];
      const updatedOwners = values.owners || [];
      const ownersToAdd = difference(updatedOwners, oldOwners);
      const ownersToDelete = difference(oldOwners, updatedOwners);

      const userSkillsUserMap = keyBy(foundFunction.userSkills, "userId");

      ownersToAdd.forEach((ownerId) => {
        mutations.push(
          onCreateUserSkill({
            userId: ownerId,
            organizationRoleId: foundFunction.id,
          })
        );
      });

      ownersToDelete.forEach((ownerId) => {
        const userSkillId = userSkillsUserMap[ownerId]._id;
        mutations.push(onDeleteUserSkill(userSkillId));
      });

      Promise.all(mutations).then(() => {
        const updatedFunctions = functions.map((item) =>
          item.id === values.id ? { ...values } : item
        );
        onChange(updatedFunctions);
      });

      return;
    }

    const mutations = [];
    let organizationRoleId;
    const data = {
      organizationId,
      organizationDepartmentId: departmentId,
      title: values.title,
      governanceTokenMultiplier: values.governanceTokenMultiplier,
      description: values.description,
      unitOfPrice: values.unitOfPrice,
      unitCurrencyTokenPrice: values.unitCurrencyTokenPrice,
      unitGovernanceTokenPrice: values.unitGovernanceTokenPrice,
      tasks: values.tasks,
      goals: values.goals,
      hazards: values.hazards,
    };
    const result = onCreateOrganisationRole(data);
    result.then((orgFunction) => {
      const { id } = orgFunction;
      organizationRoleId = id;
      if (!isEmpty(values.owners)) {
        values.owners.forEach((ownerId) => {
          mutations.push(
            onCreateUserSkill({
              userId: ownerId,
              organizationRoleId: id,
            })
          );
        });
        Promise.all(mutations).then(() => {
          const updatedFunctions = [
            ...functions,
            { ...values, id: organizationRoleId },
          ];
          onChange(updatedFunctions);
        });
      }
    });
  };
  const handleDeleteFunction = (id) => {
    Promise.all([onRemoveOrganisationRole(id)]).then(() => {
      const updatedFunctions = functions.filter((item) => item.id !== id);
      onChange(updatedFunctions);
    });
  };
  return (
    <Box>
      <FunctionsList
        isEdit={isEdit}
        isUpdateOnly={isUpdateOnly}
        onAddFunction={handleAddFunction}
        onDeleteFunction={handleDeleteFunction}
        functions={functions}
        existingFunctionIds={existingFunctionIds}
        users={users}
        setOpenWarning={setOpenWarning}
      />
    </Box>
  );
};
