import { gql } from "@apollo/client";
import { environment } from "environments";

const contract = environment.NEAR_IS_MAIN_NETWORK
  ? "deepfinancemain.mintbase1.near"
  : "firstdeepskillstest.mintspace2.testnet";

const getNftQuery = (owner) => gql`
query get_nft {
  mb_views_nft_tokens(
    where: {nft_contract_id: {_eq: "${contract}"}, owner: {_eq: "${owner}"}}
    order_by: {minted_timestamp: asc}
  ) {
    burned_timestamp
    description
    expires_at
    issued_at
    last_transfer_receipt_id
    media
    metadata_id
    mint_memo
    token_id
    title
  }
}
`;
export default getNftQuery;
