export const Modal = {
  baseStyle: {
    header: {
      color: "white",
      bg: "gray.22",
      px: "25px",
      pt: "34px",
      pb: "15px",
      borderRadius: "12px 12px 0 0",
    },
    closeButton: {
      top: "34px",
      right: "25px",
    },
    body: {
      bg: "gray.22",
      px: "25px",
      pb: "32px",
      borderRadius: "0 0 12px 12px",
    },
    dialog: {
      bg: "gray.22",
      borderRadius: "12px",
    },
  },
};
