import React from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { isEthereumAddress } from "services/helpers/validators";
import {
  FormikInputControl,
  CreateInvoiceDataSet,
  FormikTextareaControl,
} from "libs/ui-components/src";
import { WorkLogo } from "libs/ui-components/src/icons/logo-work";
import Invoice from "./invoice";

export const InvoiceModal = ({ isOpen, onClose, project, organisation }) => {
  const items = [
    {
      description: project.title,
      quantity: 1,
      item: project.roles.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.currencyTokenCompensation,
        0
      ),
    },
  ];

  if (project.costs.length > 0) {
    items.push({
      description: "Project cost",
      quantity: 1,
      item: project.costs.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.currencyTokenCompensation,
        0
      ),
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Review and update invoice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <WorkLogo />
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              companyName: organisation?.invoiceName,
              companyAddress: organisation?.invoiceAddress,
              clientName: "",
              clientAddress: "",
              walletAddress: project.walletAddress,
              chainId: project.chainId,
              date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
              items,
            }}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              companyName: Yup.string().required("Company name is required"),
              companyAddress1: Yup.string().required(
                "First address is required"
              ),
              companyAddress2: Yup.string(),
              companyAddress3: Yup.string(),
              clientName: Yup.string().required("Client name is required"),
              clientAddress: Yup.string().required(
                "Client address is required"
              ),
              walletAddress: Yup.string().test(
                "is-valid",
                "Put valid ERC20 address in format '0x...'",
                isEthereumAddress
              ),
              date: Yup.date().required("Start date is required"),
              items: Yup.array().of(
                Yup.object().shape({
                  description: Yup.string().required("Description is required"),
                  quantity: Yup.string().required("Quantity is required"),
                  item: Yup.number().required("Unit price is required"),
                })
              ),
            })}
          >
            {({ values }) => (
              <Form noValidate>
                <Flex mb="21px">
                  <Box>
                    <FormikInputControl
                      name="companyName"
                      placeholder="Company Name"
                      styleProps={{
                        mb: "21px",
                      }}
                    />
                    <FormikTextareaControl
                      label="Address"
                      name="companyAddress"
                      placeholder="..."
                    />
                  </Box>
                  <Spacer />
                </Flex>
                <Box display="flex" justifyContent="center" m={4}>
                  <Text variant="h1" color="white">
                    Invoice
                  </Text>
                </Box>
                <Flex>
                  <Box mb="21px">
                    <FormikInputControl
                      label="Prepared for"
                      name="clientName"
                      placeholder="Client Name"
                    />
                    <FormikTextareaControl
                      name="clientAddress"
                      placeholder="Client Address"
                      styleProps={{
                        mb: "21px",
                      }}
                    />
                    <FormikInputControl
                      label="Payable to"
                      name="walletAddress"
                      placeholder="0x..."
                      styleProps={{
                        mb: "21px",
                      }}
                    />
                    <FormikInputControl
                      label="Payment network"
                      type="chainId"
                      name="chainId"
                    />
                  </Box>
                  <Spacer />
                  <Box>
                    <FormikInputControl
                      label="Submitted on:"
                      type="date"
                      name="date"
                    />
                  </Box>
                </Flex>
                <Box mb="21px">
                  <Text color="orange">
                    <WarningTwoIcon w={6} h={6} mr={2} />
                    In USDC - Please only send USDC to this address
                  </Text>
                </Box>
                <Box mb="21px">
                  <FieldArray
                    name="items"
                    render={(arrayHelpers) => (
                      <CreateInvoiceDataSet
                        isReadOnly={false}
                        items={values.items}
                        arrayHelpers={arrayHelpers}
                      />
                    )}
                  />
                </Box>
                <Button>
                  <PDFDownloadLink
                    fileName={`${project.title || "Project"} invoice.pdf`}
                    document={<Invoice values={values} />}
                  >
                    {({ loading }) =>
                      loading ? "Loading document..." : "Download now!"
                    }
                  </PDFDownloadLink>
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
