import React from "react";
import { Box, Button, Spacer, Text } from "@chakra-ui/react";
import { ProfileBox } from "../profile-box/profile-box";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";

export const JoinCard = ({
  joinRequest,
  contributor,
  onApplyClick,
  onDeclineClick,
  onUserProfileClick,
}) => {
  console.log(joinRequest);
  return (
    <RoundedBox
      bg="gray.24"
      display="flex"
      flexDirection="column"
      p={{ base: "22px 20px", md: "16px 16px 20px 16px" }}
    >
      <Box display="flex" alignItems="start" mb="8px">
        <ProfileBox
          size="lg"
          variant="compact"
          onClick={onUserProfileClick}
          userId={contributor.id}
          avatarUrl={contributor.avatarUrl}
          name={`${contributor.firstName} ${contributor.lastName}`}
        />
      </Box>
      {joinRequest.note && (
        <Box>
          <Text variant="h3" mb="8px">
            Description
          </Text>
          <Text bg="gray.28" p="8px" borderRadius="8px">
            {joinRequest.note}
          </Text>
        </Box>
      )}
      <Spacer />
      <Box display="flex" justifyContent="center" mx="16px" mt="8px">
        <Button onClick={onDeclineClick} variant="warning" mr={4}>
          Decline
        </Button>
        <Spacer />
        <Button onClick={onApplyClick}>Apply</Button>
      </Box>
    </RoundedBox>
  );
};
