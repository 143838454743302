import React from "react";
import { generatePath } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { APP_PATHS } from "paths";
import { ProfileBox } from "../profile-box/profile-box";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";
import { Budget } from "../../data-display/data-box/budget/budget";
import { DataBox } from "../../data-display/data-box/data-box";

import { useCompleteMyProjectTaskMutation } from "shared/mutations/project";
import { PopupChildren } from "../../modals/popup-cildren/popup-cildren";
import Markdown from "components/Markdown";

export const RoleCard = ({
  role,
  userSkillList = {},
  functionData = {},
  isAnonymous,
  contributor,
  user,
  application,
  baseCurrency,
  governanceCurrency,
  onShowTasksClick,
  onApplyClick,
  onUserProfileClick,
  onShowDescription,
  isDescriptionActiveBTN,
  projectId,
  projectCreatedBy,
  projectCreatedAt,
  projectState,
}) => {
  const STATUSES = ["applicationsOpen", "applicationsClosed", "inProgress"];
  const userSkill = userSkillList[contributor?.id] || {};

  const { mutate: completeMyProjectTask } = useCompleteMyProjectTaskMutation();

  const {
    isOpen: isOpenTaskList,
    onClose: onCloseTaskList,
    onOpen: onOpenTaskList,
  } = useDisclosure();

  const handleCheckTask = (roleId, taskId, isCompleted) => () => {
    const data = {
      projectId,
      roleId,
      taskId,
      isCompleted: !isCompleted,
    };

    completeMyProjectTask(data, {
      onSuccess: () => {
        role.tasks = role.tasks.map((t) =>
          t.taskId === taskId ? { ...t, isCompleted: !isCompleted } : t
        );
      },
    });
  };

  let isOutdated, isLastProjectVersionRead, isUserSkillOwner;

  if (!isEmpty(functionData)) {
    const projectCurrentRoleVersion = functionData.versions
      .slice()
      .reverse()
      .find((item) => new Date(item.date) <= new Date(projectCreatedAt));

    const { versionNumber } =
      projectCurrentRoleVersion || functionData.versions[0];

    isOutdated = userSkill?.organizationRoleVersion < versionNumber;

    isLastProjectVersionRead =
      versionNumber === userSkill?.organizationRoleReviledVersion;

    isUserSkillOwner = userSkill?.userId === user?.id;
  }
  const isProjectCompleted = projectState === "completed";

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" mb="14px">
          {(isDescriptionActiveBTN && (
            <>
              <Button
                variant="link"
                fontWeight="700"
                justifyContent="left"
                onClick={onShowDescription}
              >
                {role.title}
              </Button>
              {isOutdated &&
                !isLastProjectVersionRead &&
                isUserSkillOwner &&
                !isProjectCompleted && (
                  <Button
                    as="a"
                    href={generatePath(APP_PATHS.userSkillOutdated, {
                      projectId,
                      userSkillId: userSkill.id,
                    })}
                    variant="link"
                  >
                    <Text color="warning" ml="8px">
                      Outdated version
                    </Text>
                  </Button>
                )}
              {isOutdated &&
                !isLastProjectVersionRead &&
                (!isUserSkillOwner ||
                  (isUserSkillOwner && isProjectCompleted)) && (
                  <Text color="warning" ml="8px">
                    Outdated version
                  </Text>
                )}
              {isOutdated && isLastProjectVersionRead && (
                <Text color="primaryActive" ml="8px">
                  Read version for project
                </Text>
              )}
            </>
          )) || (
            <Text color="white" variant="h4">
              {role.title}
            </Text>
          )}
          <Spacer />
          <Button
            variant="link"
            isDisabled={role.tasks.length === 0 && "true"}
            onClick={() => onOpenTaskList()}
            mr="16px"
          >
            Tasks
          </Button>
          <Button
            variant="link"
            isDisabled={isEmpty(role.tasksDescription)}
            onClick={onShowTasksClick}
          >
            Details
          </Button>
        </Box>
        {application && (
          <RoundedBox p={{ base: "10px 25px" }} bg="gray.22" mb="7px">
            <Text color="gray.79">Your application is in review</Text>
          </RoundedBox>
        )}
        <Box borderRadius="12px" overflow="hidden">
          <Item
            role={role}
            isAnonymous={isAnonymous}
            contributor={contributor}
            baseCurrency={baseCurrency}
            governanceCurrency={governanceCurrency}
            alreadyApplied={application?.state === "pending"}
            onApplyClick={onApplyClick}
            onUserProfileClick={onUserProfileClick}
          />
        </Box>
      </Box>
      <PopupChildren
        title="Tasks status"
        closeDescription={onCloseTaskList}
        isDescriptionOpen={isOpenTaskList}
        size="4xl"
      >
        {role.tasks.map((task) => (
          <>
            <RoundedBox
              key={task.taskId}
              p={{ base: "10px 25px" }}
              bg="gray.18"
              mb="8px"
              display="flex"
              alignItems="center"
            >
              <Checkbox
                isChecked={task.isCompleted}
                isDisabled={
                  (role.assigneeUserId !== user.id &&
                    projectCreatedBy !== user.id) ||
                  STATUSES.includes(application?.state)
                }
                onChange={handleCheckTask(
                  role._id,
                  task.taskId,
                  task.isCompleted
                )}
                mr="16px"
                ml="-8px"
              />
              <Box>
                <Markdown linkTarget="_blank" text={task.description} />
              </Box>
            </RoundedBox>
          </>
        ))}
      </PopupChildren>
    </>
  );
};

const getProfileBox = (contributor, onUserProfileClick, isAnonymous) => {
  if (contributor) {
    return (
      <ProfileBox
        size="lg"
        variant="compact"
        onClick={onUserProfileClick}
        userId={contributor.id}
        avatarUrl={contributor.avatarUrl}
        name={`${contributor.firstName} ${contributor.lastName}`}
      />
    );
  } else if (isAnonymous) {
    return (
      <ProfileBox
        size="lg"
        variant="compact"
        name="Anonymous contributor"
        color="white"
      />
    );
  }
  return <Avatar size="lg" />;
};

const Item = ({
  contributor,
  isAnonymous,
  alreadyApplied,
  role,
  baseCurrency,
  governanceCurrency,
  onApplyClick,
  onUserProfileClick,
}) => {
  const isAmountDefined =
    role.currencyTokenCompensation > 0 || role.governanceTokenCompensation > 0;

  return (
    <Box
      bg="gray.24"
      px="20px"
      py="16px"
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Box
        display={{ base: "flex", md: "grid" }}
        flexDirection={{ base: "column" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyItems="flex-start"
        gridTemplateColumns={{
          md: "max-content 1fr auto",
        }}
        gridTemplateAreas={{
          md: '"avatar-content compensation apply-btn"',
        }}
      >
        <Box
          gridArea="avatar-content"
          display="flex"
          alignItems="center"
          width={{ base: "auto", md: "330px" }}
        >
          {getProfileBox(contributor, onUserProfileClick, isAnonymous)}
        </Box>
        {!isAnonymous && isAmountDefined && (
          <DataBox
            label="Token Amount"
            tooltipLabel={"Token Amount"}
            boxProps={{
              gridArea: "compensation",
              my: { base: "24px", sm: "0" },
            }}
          >
            <Box
              display="flex"
              justifyContent={{ base: "space-between", md: "center" }}
              minW="115px"
              width={{ base: "100%", md: "auto" }}
              flexWrap="wrap"
              alignItems="center"
              gridGap="10px"
            >
              {role.currencyTokenCompensation > 0 && (
                <Budget
                  budget={role.currencyTokenCompensation || 0}
                  symbol={""}
                  currency={baseCurrency}
                />
              )}

              {role.governanceTokenCompensation > 0 && (
                <Budget
                  budget={role.governanceTokenCompensation}
                  symbol={""}
                  currency={governanceCurrency}
                />
              )}
            </Box>
          </DataBox>
        )}
      </Box>
      <Spacer />
      <Box
        order={{ base: 100 }}
        gridArea="apply-btn"
        display="flex"
        alignItems="center"
      >
        {!contributor && !isAnonymous && (
          <Button onClick={onApplyClick} disabled={alreadyApplied}>
            Apply
          </Button>
        )}
      </Box>
    </Box>
  );
};
