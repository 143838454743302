import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { isEqual, isEmpty } from "lodash";
import { chakraComponents } from "chakra-react-select";
import { ProfileBox } from "../../layout/profile-box/profile-box";
import { Autocomplete } from "../autocomplete/autocomplete";

export const ProfileSelection = ({
  label,
  tooltipLabel,
  error,
  showError,
  placeholder,
  disabled,
  required,
  selectedUserId,
  onChange,
  possibleProfiles,
  profileDescriptions,
}) => {
  const selectedProfile = possibleProfiles.find(
    (profile) => profile.id === selectedUserId
  );
  const [selected, setSelected] = useState(selectedProfile);
  const [descriptions, setDescriptions] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [mappedProfiles, setMappedProfiles] = useState([]);
  useEffect(() => {
    if (
      !isEmpty(mappedProfiles) &&
      !!profileDescriptions &&
      !isEqual(descriptions, profileDescriptions)
    ) {
      const options = mappedProfiles.map((profile) => {
        const description = profileDescriptions[profile.value.toString()];
        return {
          ...profile,
          description: description ? (
            <Text color={description.color}>{description.text}</Text>
          ) : (
            ""
          ),
        };
      });
      setMappedProfiles(options);
      setDescriptions(profileDescriptions);
    }
  }, [profileDescriptions, mappedProfiles]);
  useEffect(() => {
    if (!!possibleProfiles && !isEqual(possibleProfiles, profiles)) {
      const options = possibleProfiles.map((profile) => {
        return {
          label: `${profile.firstName} ${profile.lastName}`,
          value: profile.id,
          icon: (
            <Avatar
              size="xs"
              borderRadius="full"
              src={profile.avatarUrl}
              mr="8px"
            />
          ),
        };
      });
      setMappedProfiles(options);
      setProfiles(possibleProfiles);
    }
  }, [possibleProfiles]);
  const handleSelect = (data) => {
    const selectedProfile = possibleProfiles.find(
      (profile) => profile.id === data.value
    );
    if (selectedProfile) {
      setSelected(selectedProfile);
      onChange(selectedProfile);
    }
  };
  const handleChange = () => {
    setSelected(null);
    onChange();
  };
  useEffect(() => {
    if (selectedProfile?.id && !selected) {
      setSelected(selectedProfile);
    }
  }, [selectedProfile, selected, possibleProfiles]);
  useEffect(() => {
    if (selectedProfile === undefined) {
      setSelected(null);
    }
  }, [selectedProfile]);
  const customComponents = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        {props.data.icon} {children} <Spacer /> {props.data.description}
      </chakraComponents.Option>
    ),
  };
  return (
    <FormControl
      isInvalid={showError}
      isDisabled={disabled}
      isRequired={required}
    >
      <Box display="flex" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {!!tooltipLabel && (
          <Box mb={4} ml={2}>
            <Tooltip label={tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      {selected ? (
        <Box
          display="flex"
          justifyContent="space-between"
          height="45px"
          borderColor="primary"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="8px"
          p="0.125rem 1rem"
        >
          <ProfileBox
            size="sm"
            avatarUrl={selected.avatarUrl}
            name={`${selected.firstName} ${selected.lastName}`}
          />
          {!disabled && (
            <Button variant="link" onClick={handleChange}>
              Change
            </Button>
          )}
        </Box>
      ) : (
        <Autocomplete
          data-testid="profile-selection"
          options={mappedProfiles}
          onChange={handleSelect}
          placeholder={placeholder ?? "Select user"}
          isDisabled={disabled}
          components={customComponents}
          chakraStyles={{
            option: (provided) => ({
              ...provided,
              display: "flex",
            }),
          }}
        />
      )}
      {showError && <FormErrorMessage>{error ?? " "}</FormErrorMessage>}
    </FormControl>
  );
};
