import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  Textarea,
  useToast,
  Flex,
  Divider,
  useBreakpointValue,
  CircularProgress,
} from "@chakra-ui/react";
import { sumBy } from "lodash";
import { APP_PATHS } from "paths";
import { AppContainer } from "components/app-container";
import Markdown from "components/Markdown";
import {
  Budget,
  CircleArrowRight,
  DataBox,
  RoundedBox,
} from "libs/ui-components/src";
import { formatDateRange } from "libs/utils/src";
import { TERMS_URL } from "libs/ui-components/src/links";
import { useUserSkillByUserProjectOrgRoleId } from "shared/queries/users";
import { useProjectDetails } from "shared/queries/project";
import { useProjectCreateAssigneeMutation } from "shared/mutations/project";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { useOrganizationRole } from "shared/queries/organisation";
import { useUpdateMyUserSkillMutation } from "shared/mutations/user";

export const ProjectApplication = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [description, setDescription] = useState("");
  const textSizeVersions = useBreakpointValue({ base: "h2", md: "h1" });
  const textSizeVersionItemRole = useBreakpointValue({ base: "h3", md: "h2" });
  const textSizeVersionItemDescriptionSummary = useBreakpointValue({
    base: "h4",
    md: "h3",
  });
  const textSizeChecked = useBreakpointValue({ base: "h5", md: "h4" });
  const { user } = useUserContextState();
  const userId = user?.id;
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { projectId, roleId } = params;
  const { data: project } = useProjectDetails(projectId);
  const roleDetails = (project?.roles || []).find(
    (role) => role._id === roleId
  );
  const organizationId = project?.organizationId;
  const organizationRoleId = roleDetails?.organizationRoleId;
  const { mutate: onSubmitRequest } = useProjectCreateAssigneeMutation();
  const {
    data: userSkill = [],
    isLoading: isLoadingUserSkill,
    refetch: refetchMyUserSkill,
  } = useUserSkillByUserProjectOrgRoleId(
    userId,
    organizationRoleId,
    organizationId
  );
  const { mutate: updateMyUserSkill } = useUpdateMyUserSkillMutation();

  const { data: organizationRole = [], isLoading: isOrganizationRoleLoading } =
    useOrganizationRole(organizationRoleId);

  if (isLoadingUserSkill || isOrganizationRoleLoading) {
    return (
      <>
        <AppContainer.Header>
          <Heading as="h1">Loading...</Heading>
        </AppContainer.Header>
        <AppContainer.Content>
          <CircularProgress isIndeterminate color="green.300" />
        </AppContainer.Content>
      </>
    );
  }
  const { isArchived, versions } = organizationRole;
  const handlerUpdateMyRoleVersion = () => {
    const data = { id: userSkill[0]?.id, projectId };
    updateMyUserSkill(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Role version checked as read.",
        });
        refetchMyUserSkill();
      },
    });
  };

  const firstMatchingVersion =
    versions &&
    versions
      .slice()
      .reverse()
      .find((item) => new Date(item.date) <= new Date(project.createdAt));
  const versionNumber =
    firstMatchingVersion?.versionNumber || versions?.[0].versionNumber;
  const isLastProjectVersionRead =
    versionNumber ===
    (userSkill[0]?.organizationRoleReviledVersion ||
      userSkill[0]?.organizationRoleVersion);
  const handleChangeTerms = (event) => setTermsAccepted(event.target.checked);
  const handleSubmitApplication = () => {
    const data = {
      projectId: project.id,
      roleId: roleDetails._id,
      description,
    };
    onSubmitRequest(data, {
      onSuccess: () => {
        navigate(
          generatePath(APP_PATHS.projectApplicationSent, {
            projectId,
            roleId,
          })
        );
      },
    });
  };
  const handleChangeDescription = (event) => {
    const maxLength = 512;
    const inputValue = event.target.value;

    if (inputValue.length <= maxLength) {
      setDescription(inputValue);
    }
  };
  const reviledVersion =
    userSkill[0]?.organizationRoleReviledVersion ||
    userSkill[0]?.organizationRoleVersion ||
    null;
  const isUserSkill = userSkill.length > 0;
  const startSliceIndex =
    reviledVersion !== null ? reviledVersion : versionNumber - 1;
  const arrayOfMissedVersions = versions?.slice(startSliceIndex, versionNumber);
  return (
    <>
      {roleDetails && (
        <>
          <AppContainer.Header>
            <Button
              onClick={() => navigate(-1)}
              variant="link"
              leftIcon={<CircleArrowRight transform={"rotate(180deg)"} />}
            >
              {project?.title}
            </Button>
            <Text mt="13px" variant="h1" color="white">
              Application: {roleDetails.title}
            </Text>
            <Box mt="21px" display="flex" alignItems="center">
              <DataBox label="Dates" boxProps={{ mr: "64px" }}>
                {project && formatDateRange(project.startsAt, project.endsAt)}
              </DataBox>
              <DataBox
                label="Estimated payment"
                tooltipLabel="The exact payment depends on if and how the work will be split. The lead will decide on the split before the project starts. By delegating 100% of the tasks, the lead receives 100% of the recruitment bonus."
              >
                <Budget
                  budget={sumBy(project?.roles, "currencyTokenCompensation")}
                />
              </DataBox>
            </Box>
          </AppContainer.Header>
          <AppContainer.Content isDetails>
            {organizationRole && versionNumber >= reviledVersion && (
              <>
                {reviledVersion && (
                  <>
                    <Text as="h1" variant={textSizeVersions}>
                      You are already familiar with the role version:{" "}
                      <Text
                        as="span"
                        variant={textSizeVersions}
                        color={isLastProjectVersionRead ? "primary" : "warning"}
                        ml="4px"
                      >
                        {reviledVersion}.
                      </Text>
                    </Text>
                    <Divider mb="10px" borderColor="transparent" />
                  </>
                )}
                <Text as="h1" variant={textSizeVersions}>
                  Actual role version in project:{" "}
                  <Text
                    as="span"
                    variant={textSizeVersions}
                    ml="4px"
                    color="primary"
                  >
                    {versionNumber}.
                  </Text>
                </Text>
                <Divider mb="10px" borderColor="transparent" />
                {!isLastProjectVersionRead && (
                  <RoundedBox>
                    <UnorderedList
                      listStyleType="none"
                      mr="0px"
                      ml="0px"
                      mt="0px"
                      mb="0px"
                    >
                      {arrayOfMissedVersions.length > 0 &&
                        arrayOfMissedVersions.map((version, index) => (
                          <ListItem key={version._id} mr="0px" ml="0px">
                            <RoundedBox
                              bg="gray.28"
                              mb={
                                index === arrayOfMissedVersions.length - 1
                                  ? "0px"
                                  : "20px"
                              }
                              mr="0"
                              ml="0"
                            >
                              <Flex display="flex" flexDirection="row">
                                <Text as="h2" variant={textSizeVersionItemRole}>
                                  Role version:
                                </Text>
                                <Text
                                  as="span"
                                  variant={textSizeVersionItemRole}
                                  ml="4px"
                                  color="primary"
                                >
                                  {version.versionNumber}
                                </Text>
                              </Flex>
                              <Box as="div">
                                <Text
                                  as="h3"
                                  variant={
                                    textSizeVersionItemDescriptionSummary
                                  }
                                >
                                  <Divider
                                    mb="10px"
                                    borderColor="transparent"
                                  />
                                  Summary:
                                  <Box mt="5px">
                                    <Markdown text={version.summary || "N/A"} />
                                  </Box>
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                  mt="10px"
                                  as="h3"
                                  variant={
                                    textSizeVersionItemDescriptionSummary
                                  }
                                >
                                  Description:
                                  <Box mt="5px">
                                    <Markdown
                                      text={version.description || "N/A"}
                                    />
                                  </Box>
                                </Text>
                              </Box>
                            </RoundedBox>
                          </ListItem>
                        ))}
                    </UnorderedList>
                  </RoundedBox>
                )}

                {isUserSkill && !isArchived && (
                  <Flex flexDirection="flex-row" alignItems="center">
                    <Button
                      mt="20px"
                      variant="warning"
                      paddingX={{ base: "30px", sm: "16.5px" }}
                      onClick={handlerUpdateMyRoleVersion}
                      disabled={isLastProjectVersionRead}
                      _hover={
                        !isLastProjectVersionRead
                          ? {
                              bg: "red",
                              transition: "all 0.4s ease-in-out",
                            }
                          : {}
                      }
                    >
                      Familiarized
                    </Button>
                    {isLastProjectVersionRead && (
                      <Text
                        variant={textSizeChecked}
                        mt="15px"
                        ml="16px"
                        color="primary"
                      >
                        You are already familiar with the new role version
                      </Text>
                    )}
                  </Flex>
                )}
              </>
            )}

            <Box>
              <Text variant="h2" color="white" mb="21px" mt="40px">
                Application
              </Text>
              <RoundedBox mb="21px">
                <FormControl>
                  <FormLabel>
                    <Text variant="h4" color="white" mb="8px">
                      Why should you be chosen ?
                    </Text>
                    <Text color="gray.79" mb="16px">
                      Please share anything specific that the project owner
                      should know about that they won’t see in your profile.
                    </Text>
                    <Textarea
                      placeholder="I would be great because..."
                      value={description}
                      onChange={handleChangeDescription}
                    />
                  </FormLabel>
                </FormControl>
              </RoundedBox>
              <RoundedBox pb="24px">
                <FormControl>
                  <FormLabel mb={0}>
                    <Text variant="h4" color="white" mb="28px">
                      Terms and conditions
                    </Text>
                    <Checkbox
                      checked={termsAccepted}
                      onChange={handleChangeTerms}
                    >
                      <Text variant="h6" color="white" ml="15px">
                        I agree to the project{" "}
                        <Button
                          variant="link"
                          as="a"
                          href={TERMS_URL}
                          target="_blank"
                        >
                          terms &amp conditions
                        </Button>
                        .
                      </Text>
                    </Checkbox>
                  </FormLabel>
                </FormControl>
              </RoundedBox>
              <Button
                disabled={!termsAccepted || !description}
                mt="67px"
                onClick={handleSubmitApplication}
              >
                Submit Application
              </Button>
            </Box>
          </AppContainer.Content>
        </>
      )}
    </>
  );
};
