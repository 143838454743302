import React, { useMemo, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { isUndefined } from "lodash";
import { formatDateL } from "libs/utils/src";
import { ExternalLinkIcon, DownloadIcon } from "@chakra-ui/icons";
import { AppContainer } from "components/app-container";
import { DepartmentsTab } from "components/departments-tab";
import { OrganisationRolesTab } from "components/organisation-roles-tab";
import {
  OverviewSidebar,
  PopupJoinRequestList,
  PopupJoinRequestNote,
  ProfileBox,
  SkillBox,
} from "libs/ui-components/src";
import { makeFriendly } from "libs/utils/src";
import {
  useIndexJoinOrganizationRequests,
  useOrganisationDetails,
  useOrganisationFunctions,
} from "shared/queries/organisation";
import { useProjectsList } from "shared/queries/project";
import {
  useUserContextState,
  useUserContextStateDispatch,
} from "shared/contexts/user-context-provider";
import { useUsers, useUserSkillsByOrganization } from "shared/queries/users";
import {
  useOrganizationPaymentsList,
  useSmartContractList,
} from "shared/queries/payments";
import { QueriesKeysEnum } from "shared/queries/queries-keys-enum";
import { useUserConfigMutation } from "shared/mutations/user";
import {
  EMPTY_SKILLS_TAB_TEXT,
  EMPTY_SKILLS_TAB_TEXT_ORGANIZATION,
  EMPTY_TEAMS_TAB_TEXT,
} from "shared/constants/empty-text";
import { APP_PATHS } from "paths";
import { PaymentsTab } from "../../../components/payments-tab";

export const OrganisationDetails = ({ rolesEnabled = false }) => {
  const params = useParams();
  const { organizationId } = params;
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { updateUserContext } = useUserContextStateDispatch();
  const queryCache = useQueryClient();
  const { user } = useUserContextState();
  const { favoriteOrganizationIds = [] } = { ...user?.config };
  const { data: users, isLoading: isUsersLoading } = useUsers();
  const { mutate: onUpdateConfig } = useUserConfigMutation({
    onSuccess: (response) => {
      updateUserContext({
        type: "update",
        payload: { user: { ...user, ...response } },
      });
      queryCache.invalidateQueries([QueriesKeysEnum.user]);
    },
  });
  const {
    isOpen: isOpenJoinListWindow,
    onClose: onCloseJoinListWindow,
    onOpen: onOpenJoinListWindow,
  } = useDisclosure({
    defaultIsOpen: query.get("joinRequests") === "true",
  });
  const {
    isOpen: isOpenJoinRequestWindow,
    onClose: onCloseJoinRequestWindow,
    onOpen: onOpenJoinRequestWindow,
  } = useDisclosure();

  const { data: organisation, isLoading } =
    useOrganisationDetails(organizationId);
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationFunctions([organizationId]);
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkillsByOrganization(organizationId);
  const { data: payments, isLoading: isPaymentsLoading } =
    useOrganizationPaymentsList(organizationId);
  const addresses = isPaymentsLoading
    ? []
    : payments?.map((payment) => payment.tokenContractAddress);

  const { data: smartContracts, isLoading: isContractsLoading } =
    useSmartContractList(addresses);
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isLoadingError,
  } = useProjectsList(true, {
    organizationIds: organizationId,
  });
  const navigate = useNavigate();
  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };
  const handleCreateNewDepartment = () => {
    navigate(
      generatePath(APP_PATHS.newDepartment, {
        organizationId,
      })
    );
  };
  const handleUpdateSidebar = (toggle) => {
    let newList;
    if (toggle) {
      newList = [...favoriteOrganizationIds, organizationId];
    } else {
      newList = favoriteOrganizationIds.filter(
        (orgId) => orgId !== organizationId
      );
    }
    onUpdateConfig({
      favoriteOrganizationIds: newList,
    });
  };
  const handleEditOrganisation = () => {
    if (organizationId) {
      navigate(
        generatePath(APP_PATHS.editOrganisation, {
          organizationId: organizationId,
        })
      );
    }
  };
  useEffect(() => {
    if (isLoadingError) {
      navigate(APP_PATHS.organisations);
    }
  }, [isLoadingError, history]);
  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (
      isLoading ||
      isPaymentsLoading ||
      isProjectsLoading ||
      isContractsLoading
    ) {
      return;
    }
    setCsvData(
      payments
        ?.filter((payment) => {
          const project = projects?.find(
            (project) => project.id === payment.projectId
          );
          return !isUndefined(project) && payment.organizationRoleId;
        })
        .map((payment) => {
          const smartContract = smartContracts?.find(
            (contract) =>
              contract.address.toLowerCase() ===
              payment.tokenContractAddress.toLowerCase()
          );
          const project = projects?.find(
            (project) => project.id === payment.projectId
          );
          const contractType =
            payment.tokenContractAddress === project.currencyTokenContract
              ? "Primary"
              : "Secondary";
          return {
            date: formatDateL(payment.updatedAt),
            function: payment.roleTitle,
            project: project.title,
            amount: payment.amount,
            symbol: smartContract?.symbol,
            contractType,
            hash: payment.transactionHash,
          };
        })
    );
  }, [payments, projects, smartContracts, isLoading]);
  const csvHeaders = [
    { label: "Date", key: "date" },
    { label: "Role", key: "function" },
    { label: "Projects name", key: "project" },
    { label: "Amount", key: "amount" },
    { label: "Token", key: "symbol" },
    { label: "Type", key: "contractType" },
    { label: "Hash ", key: "hash" },
  ];
  const {
    id,
    name,
    description,
    departments = [],
    userIds = [],
    imageUrl,
    websiteUrl,
    communityUrl,
    knowledgeBaseUrl,
    roles = [],
    createdBy,
  } = { ...organisation };
  const admin = users?.find((user) => user.id === createdBy);
  const isAdmin = createdBy === user?.id;
  const isBookmarked = favoriteOrganizationIds.includes(id);
  const isMember = userIds?.includes(user?.id) || isAdmin;
  const filteredFunctions = functions.filter((f) => {
    return f.isArchived === false;
  });

  const [joinRequestsUpdate, setJoinRequestsUpdate] = useState([]);

  const { data: joinRequests, isLoading: isJoinRequestsLoading } =
    useIndexJoinOrganizationRequests(organizationId, isAdmin, {
      state: "pending",
    });

  useEffect(() => {
    if (joinRequests) {
      setJoinRequestsUpdate(joinRequests);
    }
  }, [joinRequests, isJoinRequestsLoading]);

  const handleUpdateJoin = (joinId) => {
    setJoinRequestsUpdate(
      joinRequestsUpdate?.filter((item) => item.id !== joinId)
    );
  };
  const handleDeleteMembers = () => {
    navigate(
      generatePath(APP_PATHS.deleteOrgMembers, {
        organizationId: organizationId,
      })
    );
  };

  return (
    <AppContainer.Content>
      <Box
        pt="50px"
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
      >
        <OverviewSidebar
          loading={isLoading || isUsersLoading}
          title={name ?? ""}
          description={description}
          avatar={imageUrl}
          avatarProps={{
            name,
            bgColor: "gray.28",
            color: "primary",
          }}
        >
          {isMember && (
            <Box mb="20px">
              <Button onClick={handleCreateNewDepartment}>
                Create a new team
              </Button>
            </Box>
          )}
          {isAdmin && (
            <Box mb="20px">
              <Button
                onClick={onOpenJoinListWindow}
                isDisabled={joinRequestsUpdate?.length === 0}
                variant={"outline"}
              >
                Review join requests{" "}
                {joinRequestsUpdate?.length > 0 &&
                  `(${joinRequestsUpdate?.length})`}
              </Button>
            </Box>
          )}
          {!isAdmin && (
            <Box mb="35px">
              <Button
                onClick={onOpenJoinRequestWindow}
                isDisabled={isMember}
                variant={"outline"}
              >
                Join organization
              </Button>
            </Box>
          )}
          {csvData && isAdmin && (
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`${name || "Organization"} Payments.csv`}
            >
              <Button
                as="a"
                variant="link"
                rightIcon={<DownloadIcon />}
                mb={"30px"}
              >
                Export paid tokens
              </Button>
            </CSVLink>
          )}
          {!isAdmin && (
            <Box mb="35px">
              <Button
                onClick={() => handleUpdateSidebar(!isBookmarked)}
                variant={isBookmarked ? "link" : "primary"}
                textColor={isBookmarked ? "red" : "white"}
              >
                {isBookmarked ? "Remove from sidebar" : "Add to sidebar"}
              </Button>
            </Box>
          )}
          {admin && (
            <Flex mt="15px" mb="15px">
              <Text as="span" vacolor="white">
                Admin
              </Text>
              <Spacer />
              <ProfileBox
                size="xs"
                userId={admin.id}
                onClick={handleOpenUserProfile}
                name={`${admin.firstName} ${admin.lastName}`}
                avatarUrl={admin.avatarUrl}
              />
            </Flex>
          )}
          {(websiteUrl || knowledgeBaseUrl || communityUrl) && (
            <Divider borderColor="gray.28" mt="15px" mb="15px" />
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            {websiteUrl && (
              <Button
                as="a"
                href={websiteUrl}
                target="_blank"
                variant="link"
                rightIcon={<ExternalLinkIcon />}
              >
                Website
              </Button>
            )}
            {knowledgeBaseUrl && (
              <Button
                as="a"
                href={knowledgeBaseUrl}
                target="_blank"
                variant="link"
                rightIcon={<ExternalLinkIcon />}
                mt={"30px"}
              >
                Knowledge base
              </Button>
            )}{" "}
            {communityUrl && (
              <Button
                as="a"
                href={communityUrl}
                target="_blank"
                variant="link"
                rightIcon={<ExternalLinkIcon />}
                mt={"30px"}
              >
                Community
              </Button>
            )}
          </Box>
          {rolesEnabled && !!roles?.length && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Box mb={"50px"}>
                <Text variant={"h3"} color={"white"} mb={"18px"}>
                  Tasks
                </Text>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {roles?.slice(0, 5).map((item) => (
                    <SkillBox
                      isExpert
                      key={item.id}
                      icon={imageUrl}
                      label={item.id}
                      boxProps={{
                        __css: {
                          ":nth-of-type(2n + 1)": {
                            mr: { base: "15px", md: "15px" },
                          },
                          ":nth-of-type(2n + 2)": {
                            mr: { base: "15px", md: "0" },
                          },
                        },
                        mb: "18px",
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </>
          )}
          {isAdmin && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Button
                justifySelf="right"
                variant="link"
                onClick={handleEditOrganisation}
              >
                Edit organization
              </Button>
              {userIds.length > 0 && (
                <>
                  <Divider borderColor="gray.28" mt="15px" mb="15px" />

                  <Button
                    justifySelf="right"
                    variant="link"
                    color={"red"}
                    onClick={handleDeleteMembers}
                  >
                    Delete Team Members
                  </Button>
                </>
              )}
            </>
          )}
        </OverviewSidebar>
        <Box
          width="100%"
          ml={{ base: "0", md: "30px" }}
          mt={{ base: "30px", md: "0" }}
        >
          <Tabs>
            <TabList>
              <Tab>Teams {makeFriendly(departments?.length)}</Tab>
              <Tab>Roles {makeFriendly(filteredFunctions?.length)}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DepartmentsTab
                  organizationId={params.organizationId}
                  loading={isProjectsLoading}
                  departments={departments}
                  projects={projects}
                  logo={imageUrl}
                  availableUsers={users}
                  emptyText={EMPTY_TEAMS_TAB_TEXT.replace("{name}", name ?? "")}
                />
              </TabPanel>
              <TabPanel>
                <OrganisationRolesTab
                  isLoading={
                    isFunctionsLoading ||
                    isUserSkillsLoading ||
                    isPaymentsLoading ||
                    isProjectsLoading ||
                    isContractsLoading ||
                    isUsersLoading
                  }
                  emptyText={
                    isMember
                      ? EMPTY_SKILLS_TAB_TEXT_ORGANIZATION
                      : EMPTY_SKILLS_TAB_TEXT.replace("{owner}", name ?? "")
                  }
                  functions={functions}
                  userSkills={userSkills}
                  users={users}
                  organizationName={name}
                  departments={departments}
                  canEditRole={isAdmin}
                />
              </TabPanel>
              <TabPanel>
                <PaymentsTab
                  payments={payments}
                  projects={projects}
                  smartContracts={smartContracts}
                  users={users}
                  isLoading={
                    isPaymentsLoading ||
                    isProjectsLoading ||
                    isContractsLoading ||
                    isUsersLoading ||
                    !projects ||
                    !payments ||
                    !users
                  }
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <PopupJoinRequestList
        users={users}
        joinRequests={joinRequestsUpdate}
        onCloseJoinListWindow={onCloseJoinListWindow}
        isOpenJoinListWindow={isOpenJoinListWindow}
        updateJoin={handleUpdateJoin}
      />
      <PopupJoinRequestNote
        organizationId={organizationId}
        onCloseJoinRequestWindow={onCloseJoinRequestWindow}
        isOpenJoinRequestWindow={isOpenJoinRequestWindow}
      />
    </AppContainer.Content>
  );
};
