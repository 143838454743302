import React from "react";
import { Box, CloseButton, Text } from "@chakra-ui/react";

import { RoundedBox } from "libs/ui-components/src";
import { CostForm } from "./cost-form";

export const CostsList = ({
  isReadOnly,
  onDeleteCost,
  costs,
  setFieldValue,
}) => {
  return costs.map((cost, index) => {
    return (
      <RoundedBox key={index} bg="gray.24" mb="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="15px"
        >
          <Text color="white" variant="h4">
            Additional Cost #{(index || 0) + 1}
          </Text>
          <CloseButton
            isDisabled={isReadOnly}
            onClick={() => onDeleteCost(index)}
          />
        </Box>
        <CostForm
          index={index}
          disabled={isReadOnly}
          cost={cost}
          setFieldValue={setFieldValue}
        />
      </RoundedBox>
    );
  });
};
