import React from "react";
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

import { AppContainer } from "components/app-container";
import { UserProjectsTab } from "components/user-projects-tab";
import { SkillsTableTab } from "components/user-skills-tab";
import { useProjectsList } from "shared/queries/project";
import { makeFriendly } from "libs/utils/src";
import { OverviewSidebar } from "libs/ui-components/src";
import { useUserById, useUsers, useUserSkills } from "shared/queries/users";
import {
  useOrganisationFunctions,
  useOrganisationList,
} from "shared/queries/organisation";
import { EMPTY_SKILLS_TAB_TEXT } from "shared/constants/empty-text";

export const UserProfile = () => {
  const params = useParams();
  const { userId } = params;
  const { data: users, isLoading: isUserLoading } = useUserById(userId);
  const user = users?.[0];
  const { data: userProjects, isLoading: isUserProjectLoading } =
    useProjectsList(true, { assigneeUserId: userId });
  const { data: userOwnProjects, isLoading: isUserOwnProjectLoading } =
    useProjectsList(true, { createdBy: userId });
  const { data: availableAssignee, isLoading: isUsersLoading } = useUsers();
  const { data: organizations = [], isLoading: isOrganisationsLoading } =
    useOrganisationList();
  const { data: functions } = useOrganisationFunctions(
    organizations.map((organization) => organization.id)
  );
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkills([userId]);
  const isProjectLoading = isUserProjectLoading || isUserOwnProjectLoading;
  const isLoading =
    isUserLoading || isUserSkillsLoading || isOrganisationsLoading || !user;
  const name = `${user?.firstName} ${user?.lastName}`;
  return (
    <>
      <AppContainer>
        <AppContainer.Content>
          <Box display="flex" flexDirection={{ base: "column", md: "row" }}>
            <OverviewSidebar
              loading={isLoading}
              title={name}
              avatar={user?.avatarUrl}
              extraChildren={true}
            >
              <Box mt={"18px"} mb={"18px"}>
                {user?.email && (
                  <Button
                    as="a"
                    href={`mailto:${user.email}`}
                    leftIcon={<EmailIcon />}
                    variant={"link"}
                    fontSize="14px"
                    lineHeight="17.71px"
                    fontWeight="450"
                  >
                    {user?.email}
                  </Button>
                )}
              </Box>
            </OverviewSidebar>
            <Box
              width="100%"
              ml={{ base: "0", md: "30px" }}
              mt={{ base: "30px", md: "0" }}
            >
              <Tabs>
                <TabList>
                  <Tab>
                    Projects{" "}
                    {makeFriendly(
                      userOwnProjects?.length + userProjects?.length
                    )}
                  </Tab>
                  <Tab>Roles {makeFriendly(userSkills?.length)}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <UserProjectsTab
                      ownProjects={userOwnProjects}
                      projects={userProjects}
                      availableAssignee={availableAssignee}
                      isLoading={isProjectLoading || isUsersLoading}
                    />
                  </TabPanel>
                  <TabPanel>
                    <SkillsTableTab
                      userSkills={userSkills}
                      functions={functions}
                      organizations={organizations}
                      emptyText={EMPTY_SKILLS_TAB_TEXT.replace(
                        "{owner}",
                        name ?? ""
                      )}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </AppContainer.Content>
      </AppContainer>
    </>
  );
};
