import React from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import { flatten } from "lodash";

import { DepartmentCard, RoundedBox } from "libs/ui-components/src";
import { APP_PATHS } from "paths";

export const DepartmentsTab = ({
  organizationId,
  isLoading,
  availableUsers,
  departments,
  projects,
  logo,
  emptyText,
}) => {
  const navigate = useNavigate();
  const departmentDetailsPath = (departmentId) => {
    return generatePath(APP_PATHS.departmentDetails, {
      departmentId,
      organizationId,
    });
  };
  const handleNavigateToDepartmentDetails = (id) => () => {
    navigate(departmentDetailsPath(id));
  };
  if (isLoading) {
    return (
      <Skeleton isLoaded={!isLoading} w="100%" h="325px" borderRadius="24px" />
    );
  }

  return departments?.length ? (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box display="flex" flexWrap="wrap" maxWidth={"100%"} mt={"15px"}>
        {departments.map((department) => {
          const { _id: id, name, description } = department;
          const departmentProjects = projects?.filter(
            (project) =>
              project.departmentId === id && project.state !== "completed"
          );
          const userIds = flatten(
            departmentProjects?.map((project) =>
              project.roles.map((role) => role.assigneeUserId)
            )
          );
          const members = availableUsers?.filter((user) =>
            userIds.includes(user.id)
          );
          return (
            <DepartmentCard
              key={id}
              image={logo}
              name={name}
              description={description}
              projectsCount={departmentProjects?.length}
              members={members}
              onCardClick={handleNavigateToDepartmentDetails(id)}
              navigationPath={departmentDetailsPath(id)}
              boxProps={{
                mb: "40px",
                mr: { base: "0", sm: "10px" },
              }}
            />
          );
        })}
      </Box>
    </Box>
  ) : (
    <RoundedBox
      h={"125px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text variant="h6">{emptyText}</Text>
      </Box>
    </RoundedBox>
  );
};
