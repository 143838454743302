import React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  FormLabel,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FieldArray, Form, Formik } from "formik";
import { DateTime } from "luxon";
import {
  identity,
  pick,
  pickBy,
  isNumber,
  omitBy,
  isNil,
  isEmpty,
  // keyBy,
  // concat,
  // uniq,
} from "lodash";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import { APP_PATHS } from "paths";
import { useEditProjectMutation } from "shared/mutations/project";
import { useProjectDetails } from "shared/queries/project";
import { useInvitedUsers, useUsers } from "shared/queries/users";
import {
  CreateCostSet,
  CreateRoleSet,
  FormikAutocompleteControl,
  FormikInputControl,
  FormikSelectControl,
  MultipleProfileSelection,
  PromptIfDirty,
  RoundedBox,
  RadioBlock,
  ConfirmationAlert,
  FormikTextareaControl,
  CreateOneInputSetObject,
} from "libs/ui-components/src";
import { formatDate } from "libs/utils/src";
import { ETHEREUM, SUPPORTED_CHAINS } from "services/helpers/chains";
import { useUserContextState } from "shared/contexts/user-context-provider";
import {
  // useMyOrganisationList,
  useOrganisationDetails,
} from "shared/queries/organisation";
import { isEthereumAddress } from "../../../services/helpers/validators";

export const EditProject = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: invitedUsers, isLoading: isInvitedUserLoading } =
    useInvitedUsers();
  const { projectId } = params;
  const { mutate: onEditProject } = useEditProjectMutation(projectId, {
    onSuccess: () => {
      navigate(generatePath(APP_PATHS.projectDetails, { projectId }));
    },
  });
  const handleSubmitProject = (data) => {
    data = {
      ...data,
      startsAt: DateTime.fromISO(data.startsAt),
      endsAt: DateTime.fromISO(data.endsAt),
    };
    const details = pickBy(data, identity);

    details.roles = details.roles?.map((role) =>
      omitBy(role, (item) => isNil(item) || item === "")
    );
    details.costs = details.costs?.map((cost) =>
      omitBy(cost, (item) => isNil(item) || item === "")
    );

    const project = pick(details, [
      "description",
      "documentationUrl",
      "title",
      "walletAddress",
      "stakeHolderUserIds",
      "chainId",
      "costs",
      "roles",
      "goals",
      "hazards",
      "currencyTokenContract",
      "governanceTokenContract",
      "startsAt",
      "endsAt",
      "isHidden",
    ]);
    onEditProject(project);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useProjectDetails(projectId);
  const { user } = useUserContextState();
  // const { data: organisations, isLoading: isOrganisationsLoading } =
  //   useMyOrganisationList();
  const { data: organisation, isLoading: isOrganisationLoading } =
    useOrganisationDetails(data?.organizationId);
  if (
    !data ||
    isLoading ||
    isUserLoading ||
    isOrganisationLoading ||
    isInvitedUserLoading
    // isOrganisationsLoading
  ) {
    return null;
  }
  let availableAssignee = users;

  if (!isEmpty(invitedUsers)) {
    availableAssignee = users.concat(invitedUsers);
  }

  const department = organisation?.departments?.find(
    (department) => department._id === data.departmentId
  );
  const isDisabled =
    user?.id !== data?.createdBy ||
    ["completionRequested", "completed"].includes(data?.state);
  const mappedNetworks = SUPPORTED_CHAINS.map((chain) => ({
    label: chain.name,
    value: chain.network,
  }));
  // const organisationsMap = keyBy(organisations, "id");
  const mappedOrganisations = organisation
    ? [
        {
          label: organisation.name,
          value: organisation.id,
        },
      ]
    : [
        {
          label: "No organisation assigned",
          value: null,
        },
      ];
  const mapDepartments = department
    ? [
        {
          label: department.name,
          value: department._id,
        },
      ]
    : [
        {
          label: "No Team assigned",
          value: null,
        },
      ];
  const projectData = {
    ...data,
    chainId: data.chainId || ETHEREUM.network,
    roles: data.roles?.map((role, index) => ({
      ...role,
      sid: index,
    })),
    startsAt: formatDate(data.startsAt),
    endsAt: formatDate(data.endsAt),
  };

  const initialRolesData = data.roles;

  const initialRolesDataMap = {};

  for (let item of initialRolesData) {
    let key = item._id;
    initialRolesDataMap[key] = item;
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Update {projectData.title} project details
        </Text>
        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.projectDetails, { projectId })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Back to project
        </Button>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt="13px">
        <Box>
          <Text variant="h2" color="white" mb="25px">
            Enter project details
          </Text>
          <Formik
            enableReinitialize
            initialValues={projectData}
            onSubmit={handleSubmitProject}
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Name is required"),
              description: Yup.string().required("Description is required"),
              documentationUrl: Yup.string().url("Invalid URL"),
              walletAddress: Yup.string().test(
                "is-valid",
                "Put valid ERC20 address in format '0x...'",
                isEthereumAddress
              ),
              organizationId: Yup.string().nullable(),
              departmentId: Yup.string().nullable(),
              stakeHolderUserIds: Yup.array().of(Yup.string()),
              chainId: Yup.string().oneOf(
                SUPPORTED_CHAINS.map((chain) => chain.network),
                "Please select valid network"
              ),
              currencyTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              governanceTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              roles: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string().required("Role name is required"),
                  tasksDescription: Yup.string(),
                  currencyTokenCompensation: Yup.number().nullable(),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              goals: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Goal is required"),
                })
              ),
              hazards: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Hazard is required"),
                })
              ),
              costs: Yup.array().of(
                Yup.object().shape({
                  description: Yup.string().required("Description is required"),
                  walletAddress: Yup.string().test(
                    "is-valid",
                    "Put valid ERC20 address in format '0x...'",
                    isEthereumAddress
                  ),
                  currencyTokenCompensation: Yup.number().test(
                    "is-valid",
                    "Token Amount is required",
                    isNumber
                  ),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              startsAt: Yup.date().required("Start date is required"),
              endsAt: Yup.date()
                .required("End date is required")
                .when("startsAt", (startsAt, schema) => {
                  return startsAt
                    ? schema.min(
                        startsAt,
                        "End date must be greater than Start date"
                      )
                    : schema;
                }),
            })}
          >
            {({ values, setFieldValue, dirty, submitCount }) => (
              <Form id="detailsForm" noValidate>
                <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                <RoundedBox mb="25px">
                  <FormikInputControl
                    label="Name your project"
                    name="title"
                    placeholder="Title"
                    styleProps={{ mb: "21px" }}
                    disabled={isDisabled}
                  />
                  <FormikTextareaControl
                    label="Describe your project"
                    name="description"
                    size="md"
                    placeholder="Description"
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikInputControl
                    label="Add an external document with project details"
                    name="documentationUrl"
                    placeholder="Document URL"
                    styleProps={{ mb: "21px" }}
                    disabled={isDisabled}
                  />
                  <FormikInputControl
                    label="Project wallet address"
                    name="walletAddress"
                    placeholder="0x..."
                    styleProps={{ mb: "21px", mr: "15px" }}
                    disabled={isDisabled}
                  />
                  <FormikAutocompleteControl
                    label="Organisation"
                    name="organizationId"
                    options={mappedOrganisations}
                    disabled={true}
                    inputProps={{
                      placeholder: "Select organisation",
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikAutocompleteControl
                    label="Team"
                    name="departmentId"
                    disabled={true}
                    options={mapDepartments}
                    inputProps={{
                      placeholder: "Select team",
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <MultipleProfileSelection
                    label="Stakeholders"
                    possibleProfiles={availableAssignee ?? []}
                    selectedUserIds={values.stakeHolderUserIds}
                    onChange={(value) =>
                      setFieldValue(
                        "stakeHolderUserIds",
                        value?.map((item) => item.value)
                      )
                    }
                    disabled={isDisabled}
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikSelectControl
                    label="Blockchain"
                    name="chainId"
                    options={mappedNetworks}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                    disabled={isDisabled}
                  />
                  <FormikInputControl
                    label="Primary Payment Token (ERC-20)"
                    name="currencyTokenContract"
                    placeholder="0x..."
                    styleProps={{ mb: "21px", mr: "15px" }}
                    disabled={isDisabled}
                  />
                  <FormikInputControl
                    label="Secondary Token (ERC-20)"
                    name="governanceTokenContract"
                    placeholder="0x..."
                    styleProps={{ mb: "21px" }}
                    disabled={isDisabled}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="roles"
                    render={(arrayHelpers) => (
                      <CreateRoleSet
                        isReadOnly={isDisabled}
                        roles={values.roles}
                        arrayHelpers={arrayHelpers}
                        organizationId={values.organizationId}
                        // organizationUserIds={uniq(
                        //   concat(
                        //     organisationsMap[values.organizationId]?.userIds,
                        //     organisationsMap[values.organizationId]?.createdBy
                        //   )
                        // )}
                        departmentId={values.departmentId}
                        setFieldValue={setFieldValue}
                        formValues={values}
                        initialRolesDataMap={initialRolesDataMap}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="goals"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="goals"
                        title="Goal"
                        fieldValues={values.goals}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="hazards"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="hazards"
                        title="Hazard"
                        fieldValues={values.hazards}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="costs"
                    render={(arrayHelpers) => (
                      <CreateCostSet
                        isReadOnly={isDisabled}
                        costs={values.costs}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikInputControl
                    label="Start Date"
                    type="date"
                    name="startsAt"
                    styleProps={{ mb: "21px", mr: "15px" }}
                    disabled={isDisabled}
                  />
                  <FormikInputControl
                    label="End date"
                    type="date"
                    name="endsAt"
                    styleProps={{ mb: "21px" }}
                    disabled={isDisabled}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FormLabel>Project permissions</FormLabel>
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    gridColumnGap="55px"
                    gridRowGap="25px"
                  >
                    <RoundedBox bg="gray.24" w="270px">
                      <RadioBlock
                        radioProps={{
                          isChecked: !values.isHidden,
                          onChange: () => setFieldValue("isHidden", false),
                          isDisabled: values.isHidden,
                        }}
                        label="Public"
                        description="Everyone can access the team"
                      />
                    </RoundedBox>
                    <RoundedBox bg="gray.24" w="270px">
                      <RadioBlock
                        radioProps={{
                          isChecked: values.isHidden,
                          onChange: () => {
                            if (!values.isHidden) {
                              onOpen();
                            }
                            setFieldValue("isHidden", true);
                          },
                          isDisabled: values.isHidden,
                        }}
                        label="Private"
                        description="Only the team lead and specific accounts can access the
                          team"
                      />
                    </RoundedBox>
                    <ConfirmationAlert
                      isOpen={isOpen}
                      onClose={() => {
                        onClose();
                        setFieldValue("isHidden", false);
                      }}
                      onConfirm={onClose}
                      description="This operation is not reversible cause of security reasons. 
                      Change back to the public will be blocked. Do you want to proceed?"
                      isAlert={false}
                    />
                  </Flex>
                </RoundedBox>
                <Button type="submit">Update Project</Button>
              </Form>
            )}
          </Formik>
        </Box>
      </AppContainer.Content>
    </>
  );
};
