import React, {
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useDisclosure } from "@chakra-ui/react";
import { omitBy } from "lodash";

import {
  ChooseNewPassword,
  EmailVerificationExpired,
  ForgotPassword,
  LoginModal,
  NotificationPopup,
  RegisterModal,
} from "libs/ui-components/src";
import { connectMetamask } from "services/metamask/index";
import { signMessage } from "services/metamask/provider";
import {
  useLoginMutation,
  useWalletLoginMutation,
  useRegistrationMutation,
  useResendVerifyLinkMutation,
  useResetPasswordMutation,
  useChangeResetPasswordMutation,
} from "shared/mutations/user";
import { useUser } from "shared/queries/users";
import { WalletConnectService } from "services/walletConnect";
import { APP_PATHS } from "paths";

const UserStateContext = React.createContext({
  user: {},
});

const UserDispatchContext = React.createContext({
  updateUserContext: () => void 0,
  onLogout: () => new Promise(() => void 0),
  onOpenLoginModal: () => void 0,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "logout":
      return action.payload;
    default: {
      return state;
    }
  }
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { user: {} });
  const [resetToken, setResetToken] = useState(null);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {
    isOpen: isLoginModalOpen,
    onOpen: onOpenLogin,
    onClose: onCloseLogin,
  } = useDisclosure();
  const {
    isOpen: isOpenRegisterOpen,
    onOpen: onOpenRegister,
    onClose: onCloseRegister,
  } = useDisclosure({
    defaultIsOpen: query.get("openRegistrationForm") === "true",
  });
  const {
    isOpen: isPleaseVerifyEmailOpen,
    onOpen: onOpenPleaseVerifyEmail,
    onClose: onClosePleaseVerifyEmail,
  } = useDisclosure();
  const {
    isOpen: isResendLinkOpen,
    onOpen: onResendLinkOpen,
    onClose: onResendLinkClose,
  } = useDisclosure();
  const {
    isOpen: isForgotPasswordOpen,
    onOpen: onOpenForgotPassword,
    onClose: onCloseForgotPassword,
  } = useDisclosure();
  const {
    isOpen: isPleaseResetEmailOpen,
    onOpen: onOpenPleaseResetEmail,
    onClose: onCloseResetCheckEmail,
  } = useDisclosure();
  const {
    isOpen: isChoosePasswordOpen,
    onOpen: onOpenChoosePassword,
    onClose: onCloseChoosePassword,
  } = useDisclosure();
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const { mutate: onLogin } = useLoginMutation();
  const { mutate: onWalletLogin } = useWalletLoginMutation();
  const { mutate: onRegistration } = useRegistrationMutation();
  const { mutate: resendVerificationLink } = useResendVerifyLinkMutation();
  const { mutate: onResetPassword } = useResetPasswordMutation();
  const { mutate: onChangePassword } = useChangeResetPasswordMutation();
  const { data: user, isFetched, refetch: refetchUser } = useUser();
  const handleDispatch = useCallback((action) => dispatch(action), []);
  const handleLogout = useCallback(async () => {
    navigate("./");
    localStorage.removeItem("AUTH_TOKEN");
    await refetchUser();
    await queryCache.invalidateQueries();
    handleDispatch({ type: "logout", payload: { user: {} } });
  }, [handleDispatch]);
  const handleOpenLoginModal = useCallback(() => {
    onOpenLogin();
  }, [onOpenLogin]);
  const handleOpenForgotPassword = useCallback(() => {
    onCloseLogin();
    onOpenForgotPassword();
  }, [onCloseLogin, onOpenForgotPassword]);
  const handleLogIn = async (values) => {
    onLogin(values, {
      onSuccess: async ({ token }) => {
        localStorage.setItem("AUTH_TOKEN", token);
        await refetchUser();
        await queryCache.refetchQueries();
        onCloseLogin();
      },
      onError: (response) => {
        const { error } = response;
        if (error?.code === "DSP-11") {
          onResendLinkOpen();
        }
      },
    });
  };
  const handleWalletLogIn = async () => {
    let signedMessage;
    const walletConnect = new WalletConnectService({});
    await walletConnect.disconnect();
    const message = "Log in to DeepSkills (P)";
    const expiresAt = Date.now() + 1 * 60 * 1000;
    const dataSignObject = { expiresAt, payload: message };
    const dataSign = JSON.stringify(dataSignObject);
    try {
      if (window.ethereum) {
        await connectMetamask(window.ethereum);
        signedMessage = await signMessage(window.ethereum, dataSign);
      } else {
        await walletConnect.connect();
        const { valid, result } = await walletConnect.signMessage(dataSign);
        if (valid) {
          signedMessage = {
            signature: result,
            digest: dataSign,
          };
        }
      }
    } catch (e) {
      console.log(e);
    }
    if (signedMessage)
      await onWalletLogin(signedMessage, {
        onSuccess: async ({ token }) => {
          localStorage.setItem("AUTH_TOKEN", token);
          await refetchUser();
          await queryCache.refetchQueries();
          onCloseLogin();
        },
      });
  };
  useEffect(() => {
    if (user) {
      handleDispatch({
        type: "update",
        payload: {
          user,
        },
      });
    }
  }, [user]);
  const handleRegisterClick = () => {
    onCloseLogin();
    onOpenRegister();
  };
  const handleSignUp = async (values) => {
    const filteredValues = omitBy(values, (value) => !value);
    const token = query.get("verificationToken");
    if (token) {
      filteredValues.token = token;
    }
    onRegistration(filteredValues, {
      onSuccess: (data) => {
        onCloseRegister();
        if (!data.invitedBy) {
          onOpenPleaseVerifyEmail();
        }
      },
    });
  };
  const handleResendVerificationLink = (values) => {
    const { email } = values;
    if (email) {
      resendVerificationLink(email);
      onResendLinkClose();
    }
  };
  const handleOpenChoosePassword = useCallback(
    (resetToken) => {
      setResetToken(resetToken);
      onOpenChoosePassword();
    },
    [onOpenChoosePassword]
  );
  const handleGoToLogin = useCallback(() => {
    onCloseForgotPassword();
    onOpenLogin();
  }, [onCloseChoosePassword, onOpenLogin]);
  const handleCloseChoosePassword = useCallback(() => {
    onCloseChoosePassword();
    navigate(APP_PATHS.home);
  }, [onCloseChoosePassword, onOpenLogin]);
  const handleForgotPassword = async (email) => {
    onResetPassword(
      { email },
      {
        onSettled: () => {
          onCloseForgotPassword();
        },
        onSuccess: () => {
          onOpenPleaseResetEmail();
        },
      }
    );
  };
  const handleChoosePassword = async (values) => {
    const { newPassword } = values;
    if (resetToken && newPassword) {
      onChangePassword(
        { newPassword, token: resetToken },
        {
          onSuccess: () => {
            onCloseChoosePassword();
            navigate(APP_PATHS.home);
          },
        }
      );
    }
  };
  useEffect(() => {
    if ((!user && isFetched) || !localStorage.getItem("AUTH_TOKEN")) {
      handleLogout();
      if (!isLoginModalOpen && !isOpenRegisterOpen) onOpenLogin();
    }
  }, [isLoginModalOpen, isOpenRegisterOpen, user, isFetched]);

  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    if (token) {
      refetchUser();
    }
  }, []);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider
        value={{
          updateUserContext: handleDispatch,
          onLogout: handleLogout,
          onOpenLoginModal: handleOpenLoginModal,
          onOpenChoosePasswordModal: handleOpenChoosePassword,
        }}
      >
        {children}
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={onCloseLogin}
          onForgotPassword={handleOpenForgotPassword}
          onLogin={handleLogIn}
          onWalletLogin={handleWalletLogIn}
          onRegister={handleRegisterClick}
        />
        <RegisterModal
          isOpen={isOpenRegisterOpen}
          onClose={onCloseRegister}
          onSignUpClicked={handleSignUp}
          invitedEmail={query.get("invitedEmail") || ""}
        />
        <NotificationPopup
          title="Please verify your email"
          description="We’ve sent you an email to verify your account. Please confirm it in order to start using Deep Teams. PLEASE CHECK SPAM FOLDER"
          isOpen={isPleaseVerifyEmailOpen}
          onClose={onClosePleaseVerifyEmail}
        />
        <EmailVerificationExpired
          isOpen={isResendLinkOpen}
          onClose={onResendLinkClose}
          onResendLink={handleResendVerificationLink}
        />
        <ForgotPassword
          isOpen={isForgotPasswordOpen}
          onClose={onCloseForgotPassword}
          onGoBack={handleGoToLogin}
          onSubmitEmail={handleForgotPassword}
        />
        <NotificationPopup
          title="Please check your email"
          description="Your password was successfully reset and link was sent to your email. PLEASE CHECK SPAM FOLDER"
          isOpen={isPleaseResetEmailOpen}
          onClose={onCloseResetCheckEmail}
        />
        <ChooseNewPassword
          isOpen={isChoosePasswordOpen}
          isForgot={true}
          onSubmit={handleChoosePassword}
          onClose={handleCloseChoosePassword}
        />
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};
export const useUserContextState = () => {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserContextProvider");
  }
  return context;
};
export const useUserContextStateDispatch = () => {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useUserDispatch must be used within a UserContextProvider"
    );
  }
  return context;
};
