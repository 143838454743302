import React, { useEffect, useState } from "react";
import { Avatar, Box, Text } from "@chakra-ui/react";
export const SkillBox = ({
  label,
  icon,
  data,
  color = "pink",
  isExpert,
  onClick,
  boxProps = {},
}) => {
  const [textColor, setTextColor] = useState("white");
  const [percentage, setPercentage] = useState(0);
  const [bg, setBg] = useState({
    bg: "gray.24",
  });
  const truncatedLabel =
    (label?.split(" ") ?? []).length > 2
      ? label?.split(" ").slice(0, 2).join(" ") + "..."
      : label;
  useEffect(() => {
    if (data && !isExpert) {
      const { done, total } = data ?? {};
      const isAllDone = done === total;
      const isNoneDone = done === 0;
      if (isAllDone) {
        setTextColor(color);
      }
      if (isNoneDone) {
        setTextColor("gray.50");
      }
      setPercentage((100 * done) / total);
    }
    if (isExpert) {
      setPercentage(100);
    }
  }, [color, data, isExpert]);
  useEffect(() => {
    setBg(
      !label
        ? { bg: "gray.24" }
        : {
            bgGradient: `linear(to-t, ${color} ${percentage}%, gray.50 ${percentage}%)`,
          }
    );
  }, [color, label, percentage]);
  return (
    <Box
      w="105px"
      h="105px"
      borderRadius="12px"
      p="7px"
      {...bg}
      onClick={onClick}
      cursor={onClick ? "pointer" : "normal"}
      {...boxProps}
    >
      <Box
        bg="gray.22"
        display="flex"
        h="100%"
        borderRadius="6px"
        p="8px"
        flexDirection="column"
        justifyContent="space-between"
      >
        {!!label && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Avatar src={icon} w="21px" h="21px" />
              {!!data && !isExpert && (
                <Text color={textColor} variant="x.small">
                  {data.done}/{data.total}
                </Text>
              )}
            </Box>
            <Text color={textColor} variant="x.small">
              {truncatedLabel}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};
