import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Button,
  Box,
} from "@chakra-ui/react";

import { FormikTextareaControl } from "../../form/formik-wrappers";
import { useCreateAppFeedbackMutation } from "shared/mutations/feedback";

export const AppFeedback = ({
  onCloseAppFeedbackWindow,
  isOpenAppFeedbackWindow,
}) => {
  const toast = useToast();
  const { mutate: onSendAppFeedback } = useCreateAppFeedbackMutation();

  const handlerSendAppFeedback = (values) => {
    const data = {
      description: values.description,
    };
    onSendAppFeedback(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Feedback send.",
        });
        onCloseAppFeedbackWindow();
      },
    });
  };

  return (
    <Modal
      onClose={onCloseAppFeedbackWindow}
      isOpen={isOpenAppFeedbackWindow}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent minHeight="20%" maxHeight="70%" mx={5}>
        <ModalHeader
          fontSize="1.75rem"
          pl={6}
          pt={6}
          pb={0}
          pr={14}
          color="gray.79"
        >
          Please leave a feedback about the application
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>
          <Formik
            enableReinitialize
            initialValues={{
              description: "",
            }}
            validateOnChange={false}
            validateOnMount={false}
            onSubmit={handlerSendAppFeedback}
            validationSchema={Yup.object({
              description: Yup.string().required("Field cannot be empty"),
            })}
          >
            {() => (
              <>
                <Form>
                  <FormikTextareaControl
                    name="description"
                    size="md"
                    placeholder="Enter your feedback..."
                    styleProps={{ mb: "20px" }}
                  />
                  <Box display="flex" justifyContent="space-between" mx="16px">
                    <Button
                      onClick={onCloseAppFeedbackWindow}
                      variant="warning"
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Send feedback</Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
