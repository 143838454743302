import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import { Box, Divider, Text } from "@chakra-ui/react";
import { formatDateRange } from "libs/utils/src";
import { ProjectCard, RoundedBox } from "libs/ui-components/src";
import {
  EMPTY_ACTIVE_PROJECTS_TAB_TEXT,
  EMPTY_COMPLETED_PROJECTS_TAB_TEXT,
  EMPTY_PROJECTS_TEXT,
  NO_PROJECTS_FOUND_TEXT,
} from "shared/constants/empty-text";
import { APP_PATHS } from "paths";

const projectCardList = (
  projects,
  availableAssignee,
  smartContracts,
  handleNavigateToProjectDetails,
  projectDetailsPath,
  emptyText
) => (
  <Box
    display="flex"
    flexWrap="wrap"
    justifyContent={{ base: "center", md: "space-between" }}
  >
    {!isEmpty(projects) ? (
      projects?.map((item) => {
        const userIds = item.roles.map((role) => role.assigneeUserId);
        const users = availableAssignee?.filter((user) =>
          userIds.includes(user.id)
        );
        const smartContract = smartContracts?.find(
          (contract) =>
            contract.address.toLowerCase() ===
            item.currencyTokenContract?.toLowerCase()
        );
        const currency = smartContract?.symbol || "Primary";

        return (
          <ProjectCard
            key={item.id}
            projectName={item.title}
            roles={item.roles}
            costs={item.costs}
            users={users}
            onCardClick={handleNavigateToProjectDetails(item.id)}
            navigationPath={projectDetailsPath(item.id)}
            dateRange={formatDateRange(item.startsAt, item.endsAt)}
            status={startCase(item.state)}
            currency={currency}
            boxProps={{
              mb: "40px",
              mr: { base: 0, sm: "10px" },
            }}
          />
        );
      })
    ) : (
      <RoundedBox w="100%" p={{ base: "22px 20px", md: "22px 20px" }}>
        <Text variant="h6">{emptyText}</Text>
      </RoundedBox>
    )}
  </Box>
);

export const ProjectList = ({
  filtersApplied,
  projects = [],
  availableAssignee,
  smartContracts,
  splitCompleted = false,
  isDepartmentList = false,
  name,
}) => {
  const navigate = useNavigate();

  const projectDetailsPath = (id) => {
    const additionalProps = isDepartmentList ? "backToPage=department" : "";
    return `${generatePath(APP_PATHS.projectDetails, {
      projectId: id,
    })}?${additionalProps}`;
  };

  const handleNavigateToProjectDetails = (id) => () => {
    navigate(projectDetailsPath(id));
  };
  return splitCompleted ? (
    <Box>
      {projectCardList(
        projects.filter((project) => project.state !== "completed"),
        availableAssignee,
        smartContracts,
        handleNavigateToProjectDetails,
        projectDetailsPath,
        EMPTY_ACTIVE_PROJECTS_TAB_TEXT.replace("{name}", name ?? "")
      )}
      <Divider borderColor="gray.28" mt="15px" mb="15px" />
      <Box mb="32px" mt="32px">
        <Text textTransform="uppercase">COMPLETED PROJECTS</Text>
      </Box>
      {projectCardList(
        projects.filter((project) => project.state === "completed"),
        availableAssignee,
        smartContracts,
        handleNavigateToProjectDetails,
        projectDetailsPath,
        EMPTY_COMPLETED_PROJECTS_TAB_TEXT.replace("{name}", name ?? "")
      )}
    </Box>
  ) : (
    projectCardList(
      projects,
      availableAssignee,
      smartContracts,
      handleNavigateToProjectDetails,
      projectDetailsPath,
      filtersApplied ? NO_PROJECTS_FOUND_TEXT : EMPTY_PROJECTS_TEXT
    )
  );
};
