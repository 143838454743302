import { DateTime } from "luxon";

export const formatDateRange = (startDate, endDate) => {
  const startDateString = DateTime.fromISO(startDate).toFormat("LLL dd");
  const endDateString = DateTime.fromISO(endDate).toFormat("LLL dd, yyyy");
  return `${startDateString} - ${endDateString}`;
};

export const formatDate = (date) =>
  date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : "";

export const formatDateL = (date) =>
  date ? DateTime.fromISO(date).toFormat("LLL dd, yyyy") : "";
