import React from "react";
import { Divider } from "@chakra-ui/react";

import { CostsList } from "./costs-list";
import { AdminCreateButton } from "../admin-create-button/admin-create-button";

export const CreateCostSet = ({
  isReadOnly,
  costs = [],
  arrayHelpers,
  setFieldValue,
}) => {
  const initialValues = {
    walletAddress: "",
    description: "",
    currencyTokenCompensation: 0,
    governanceTokenCompensation: null,
  };
  return (
    <>
      {costs.length > 0 && (
        <CostsList
          isReadOnly={isReadOnly}
          onDeleteCost={(id) => arrayHelpers.remove(id)}
          costs={costs}
          setFieldValue={setFieldValue}
        />
      )}
      {!isReadOnly && (
        <>
          {costs.length > 0 && <Divider mt="20px" mb="20px" />}
          <AdminCreateButton
            bg="gray.22"
            p="11.5px 15px"
            onClick={() => arrayHelpers.insert(costs.length, initialValues)}
          >
            Additional Compensation or Token Issuance
          </AdminCreateButton>
        </>
      )}
    </>
  );
};
