import React from "react";
import { Select } from "chakra-react-select";

export const Autocomplete = ({ components, chakraStyles, ...selectProps }) => {
  const selectStyles = {
    input: (provided) => ({
      ...provided,
      pt: "6px",
      pb: "6px",
    }),
    ...chakraStyles,
  };
  return (
    <Select
      components={{
        DropdownIndicator: null,
        ...components,
      }}
      chakraStyles={selectStyles}
      {...selectProps}
    />
  );
};
