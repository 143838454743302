import React from "react";

import { FormikTextareaControl } from "../../form/formik-wrappers";

export const OneInputFormArray = ({ index, disabled, fieldName, title }) => {
  const parentNode = `${fieldName}.${index}`;

  return (
    <>
      <FormikTextareaControl
        label="Description"
        name={`${parentNode}`}
        size="sm"
        disabled={disabled}
        required={true}
        placeholder={`${title} name`}
        styleProps={{ mb: "8px" }}
      />
    </>
  );
};
