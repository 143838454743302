import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { ProfileBox } from "../profile-box/profile-box";
export const CollaboratorApplication = ({
  user,
  jobTitle,
  onConfirm,
  canApprove,
  boxProps,
  children,
  onUserProfileClick,
}) => {
  return (
    <Box {...boxProps}>
      <Box
        px="20px"
        py="16px"
        bg="gray.22"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="12px 12px 0 0"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center">
          <ProfileBox
            variant="compact"
            name={`${user.firstName} ${user.lastName}`}
            jobTitle={jobTitle}
            avatarUrl={user.avatarUrl}
            userId={user.id}
            onClick={onUserProfileClick}
          />
        </Box>
        <Button
          onClick={onConfirm}
          disabled={!canApprove}
          my={{ base: "10px" }}
        >
          Confirm
          <Box as="span" ml={1} display={{ base: "none", md: "inline-flex" }}>
            {user.firstName}
          </Box>
        </Button>
      </Box>
      <Box
        px="20px"
        py="16px"
        bg="gray.24"
        borderRadius="0 0 12px 12px"
        color="gray.79"
      >
        {children}
      </Box>
    </Box>
  );
};
