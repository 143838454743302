import React from "react";
import { Link, ListItem, Text } from "@chakra-ui/react";

import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

import "highlight.js/styles/github.css";

function convertTextToId(text) {
  if (!text || !text.toLowerCase) {
    return "";
  }
  const id = text
    .toLowerCase()
    .replace(/[^a-zа-яёі#%0-9\s]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .trim();

  return id;
}

function convertLinkToId(link) {
  if (!link || !link.toLowerCase) {
    return "";
  }
  const id = link
    .toLowerCase()
    .replace(/[^a-zа-яёі#%0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .trim();

  return id;
}

const Markdown = ({ text, ...extraProps }) => {
  const rehypePlugins = [rehypeHighlight];
  const remarkPlugins = [remarkGfm];

  const Heading = (props) => {
    const { children, node } = props;
    const textToId = children[0];
    return (
      <Text
        as={node.tagName}
        variant={node.tagName}
        id={convertTextToId(textToId)}
      >
        {children}
      </Text>
    );
  };

  const customTheme = {
    h1: (props) => Heading(props),
    h2: (props) => Heading(props),
    h3: (props) => Heading(props),
    h4: (props) => Heading(props),
    h5: (props) => Heading(props),
    h6: (props) => Heading(props),

    p: (props) => {
      const { children } = props;
      return (
        <Text as="p" variant="paragraph">
          {children}
        </Text>
      );
    },

    a: (props) => {
      const { children, href } = props;
      return (
        <Link
          color="primary"
          href={href[0] === "#" ? convertLinkToId(href) : href}
          {...(href[0] !== "#" && { isExternal: true })}
        >
          {children}
        </Link>
      );
    },

    li: (props) => {
      const { children, checked } = props;

      return (
        <ListItem listStyleType={checked !== null ? "none" : "inherit"}>
          {children}
        </ListItem>
      );
    },

    blockquote: (props) => {
      const { children } = props;
      return (
        <Text as="blockquote" variant="blockquote">
          {children}
        </Text>
      );
    },
  };

  return (
    <ReactMarkdown
      components={ChakraUIRenderer(customTheme)}
      rehypePlugins={rehypePlugins}
      remarkPlugins={remarkPlugins}
      {...extraProps}
      skipHtml
    >
      {text}
    </ReactMarkdown>
  );
};

export default Markdown;
