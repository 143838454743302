import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRemoveOrganisationMembersMutation } from "shared/mutations/organisation";
import { AppContainer } from "components/app-container";
import { useUsers } from "shared/queries/users";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { useOrganisationDetails } from "shared/queries/organisation";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  ModalCloseButton,
  useToast,
  Checkbox,
  Avatar,
  UnorderedList,
  ListItem,
  useDisclosure,
  CircularProgress,
  Button,
  Heading,
} from "@chakra-ui/react";
import { useState } from "react";
import { RoundedBox } from "libs/ui-components/src";
import { DontHavePermissions } from "pages/not-found/DontHavePermissions";
import { CircleArrowRight } from "libs/ui-components/src";

export default function DeleteOrgMembers() {
  const { user } = useUserContextState();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { organizationId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: organisation, isLoading } =
    useOrganisationDetails(organizationId);
  const { data: users, isLoading: isUsersLoading } = useUsers();
  const removeMemberMutation =
    useRemoveOrganisationMembersMutation(organizationId);

  if (isUsersLoading || isLoading) {
    return (
      <>
        <AppContainer.Header>
          <Heading as="h1">Loading...</Heading>
        </AppContainer.Header>
        <AppContainer.Content>
          <CircularProgress isIndeterminate color="green.300" />;
        </AppContainer.Content>
      </>
    );
  }

  const { userIds, createdBy } = { ...organisation };

  const admin = users?.find((user) => user.id === createdBy);
  if (user?.id !== admin?.id) {
    return <DontHavePermissions />;
  }
  const nonAdminUserIds = userIds?.filter((userId) => userId !== admin.id);
  const usersToDelete = users?.filter((user) =>
    nonAdminUserIds.includes(user.id)
  );
  const handleDeleteMember = () => {
    removeMemberMutation.mutate(selectedUsers, {
      onSuccess: () => {
        navigate(-1);
        toast({
          title: `Users successfully deleted from organization.`,
          status: "success",
          position: "top-right",
        });
      },
      onError: (error) => {
        toast({
          title: error.message ? error.message : "Error removing members!",
          status: "error",
          position: "top-right",
        });
      },
    });
  };

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelectedUserIds) =>
      prevSelectedUserIds.includes(userId)
        ? prevSelectedUserIds.filter((id) => id !== userId)
        : [...prevSelectedUserIds, userId]
    );
  };
  const styles = {
    fontSize: "1.5rem",
    maxWidth: "500px",
    borderRadius: "5px",
    transition: "all 0.3s ease-in-out",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
  };
  return (
    <AppContainer>
      <AppContainer.Header>
        <Button
          onClick={() => navigate(-1)}
          variant="link"
          leftIcon={<CircleArrowRight transform={"rotate(180deg)"} />}
        >
          {organisation?.name}
        </Button>
        <Text variant="h1" mt="13px" color="white" mb="10px">
          Delete Organization Members
        </Text>
      </AppContainer.Header>
      <AppContainer.Content paddingTop="0px">
        <UnorderedList styleType="none" display="flex-col" mt="20px">
          {usersToDelete.length > 0 &&
            usersToDelete?.map((user, index) => (
              <RoundedBox
                p="0px"
                maxWidth="500px"
                key={user.id}
                mb={index === usersToDelete.length - 1 ? "0px" : "20px"}
              >
                <ListItem sx={styles}>
                  <Avatar
                    size="md"
                    borderRadius="full"
                    m="20px"
                    src={user.avatarUrl}
                  />
                  <Text flex="1" fontSize="1.5rem">
                    {user.firstName} {user.lastName}
                  </Text>
                  <Checkbox
                    onChange={() => handleCheckboxChange(user.id)}
                    paddingRight="20px"
                  />
                </ListItem>
              </RoundedBox>
            ))}
          <Button
            mt="20px"
            variant="warning"
            onClick={onOpen}
            _hover={
              selectedUsers.length > 0 && {
                bg: "red",
                transition: "all 0.4s ease-in-out",
              }
            }
            disabled={selectedUsers.length > 0 ? false : true}
          >
            Delete users
          </Button>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Delete</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  Are you sure you want to delete these users from this
                  organization?
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="warning"
                  transitionDuration="0.4s"
                  _hover={{ bg: "red", transition: "all 0.4s ease-in-out" }}
                  mr={3}
                  onClick={() => handleDeleteMember(selectedUsers)}
                >
                  Delete
                </Button>
                <Button
                  variant="ghost"
                  _hover={{ bg: "#3D3B40", transition: "all 0.4s ease-in-out" }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </UnorderedList>
      </AppContainer.Content>
    </AppContainer>
  );
}
