import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInputControl } from "../../form/formik-wrappers";
import { CircleArrowRight } from "../../../icons";

export const ForgotPassword = ({
  isOpen,
  onSubmitEmail,
  onGoBack,
  onClose,
}) => {
  const handleSubmit = (values) => {
    onSubmitEmail(values.email);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box display="flex" alignItems="center">
            <CircleArrowRight
              onClick={onGoBack}
              transform={"rotate(180deg)"}
              cursor={"pointer"}
              mr="24px"
            />
            <Text variant="h2" color="white">
              Forgot your password?
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="32px">
          <Formik
            enableReinitialize
            noValidate
            initialValues={{ email: "" }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              email: Yup.string().email().required("Email is required"),
            })}
          >
            {() => (
              <Form noValidate>
                <FormikInputControl
                  type="email"
                  label="Email"
                  placeholder="john@example.com"
                  name="email"
                  styleProps={{ mb: "28px" }}
                />
                <Button type="submit">Send reset email</Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
