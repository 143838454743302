export var APP_PATHS;
((APP_PATHS) => {
  APP_PATHS["home"] = "/";
  APP_PATHS["userProfile"] = "/profile/:userId";
  APP_PATHS["confirmEmail"] = "/confirm-email/:token";
  APP_PATHS["resetPassword"] = "/reset-password/:token";
  APP_PATHS["roleUpdateRequest"] = "/roleUpdateRequest/:orgRoleUpdateRequestId";
  APP_PATHS["inviteUser"] = "/inviteUser";

  APP_PATHS["projects"] = "/projects";
  APP_PATHS["newProject"] = `${APP_PATHS.projects}/new`;
  APP_PATHS["projectDetails"] = `${APP_PATHS.projects}/:projectId`;
  APP_PATHS["projectInvoice"] = `${APP_PATHS.projectDetails}/invoice`;
  APP_PATHS["editProject"] = `${APP_PATHS.projectDetails}/edit`;
  APP_PATHS[
    "editProjectFunctions"
  ] = `${APP_PATHS.projectDetails}/requestFunctionUpdate`;
  APP_PATHS[
    "reviewProjectFunctions"
  ] = `${APP_PATHS.projectDetails}/reviewFunctionUpdateRequests`;
  APP_PATHS["duplicateProject"] = `${APP_PATHS.projectDetails}/duplicate`;
  APP_PATHS["applyToProject"] = `${APP_PATHS.projectDetails}/:roleId/apply`;
  APP_PATHS[
    "projectApplicationSent"
  ] = `${APP_PATHS.projectDetails}/:roleId/applicationSent`;
  APP_PATHS["payments"] = `${APP_PATHS.projectDetails}/payments`;
  APP_PATHS["incomes"] = `${APP_PATHS.projectDetails}/incomes`;
  APP_PATHS["feedback"] = `${APP_PATHS.projectDetails}/feedback`;
  APP_PATHS[
    "userSkillOutdated"
  ] = `${APP_PATHS.projectDetails}/userSkillId/:userSkillId`;

  APP_PATHS["organisations"] = "/organisations";
  APP_PATHS["newOrganisation"] = `${APP_PATHS.organisations}/new`;
  APP_PATHS[
    "organisationDetails"
  ] = `${APP_PATHS.organisations}/:organizationId`;
  APP_PATHS["editOrganisation"] = `${APP_PATHS.organisationDetails}/edit`;
  APP_PATHS[
    "deleteOrgMembers"
  ] = `${APP_PATHS.organisationDetails}/deleteMembers`;
  APP_PATHS[
    "newDepartment"
  ] = `${APP_PATHS.organisationDetails}/department/new`;
  APP_PATHS[
    "departmentDetails"
  ] = `${APP_PATHS.organisationDetails}/department/:departmentId`;
  APP_PATHS["editDepartment"] = `${APP_PATHS.departmentDetails}/edit`;
  APP_PATHS[
    "organizationRoleDescription"
  ] = `${APP_PATHS.departmentDetails}/organizationRole/:organizationRoleId`;
  APP_PATHS[
    "editDepartmentFunctions"
  ] = `${APP_PATHS.departmentDetails}/editFunctions`;
})(APP_PATHS || (APP_PATHS = {}));
