import { ArrowBackIcon } from "@chakra-ui/icons";
import React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Text } from "@chakra-ui/react";
import { FieldArray, Form, Formik } from "formik";
import {
  identity,
  isEmpty,
  isNil,
  isNumber,
  omit,
  pickBy,
  omitBy,
  keyBy,
  concat,
  uniq,
} from "lodash";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import { APP_PATHS } from "paths";
import { useCreateProjectMutation } from "shared/mutations/project";
import { useProjectDetails } from "shared/queries/project";
import { useInvitedUsers, useUsers } from "shared/queries/users";
import {
  CreateCostSet,
  CreateRoleSet,
  FormikAutocompleteControl,
  FormikInputControl,
  FormikSelectControl,
  MultipleProfileSelection,
  PromptIfDirty,
  RoundedBox,
  FormikTextareaControl,
  CreateOneInputSetObject,
} from "libs/ui-components/src";
import { formatDate } from "libs/utils/src";
import { useMyOrganisationList } from "shared/queries/organisation";
import { ETHEREUM, SUPPORTED_CHAINS } from "services/helpers/chains";
import { isEthereumAddress } from "services/helpers/validators";

export const DuplicateProject = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: invitedUsers, isLoading: isInvitedUserLoading } =
    useInvitedUsers();
  const { data: organisations, isLoading: isOrganisationsLoading } =
    useMyOrganisationList();
  const { projectId } = params;
  const { mutate: onCreateProject } = useCreateProjectMutation({
    onSuccess: (response) => {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: response?.id })
      );
    },
  });
  const handleSubmitProject = (data) => {
    let details = pickBy(data, identity);
    details = omit(details, "completionResponses");
    let roles = details.roles?.map((role) =>
      omitBy(role, (item) => isNil(item) || item === "")
    );
    const costs = details.costs?.map((cost) =>
      omitBy(cost, (item) => isNil(item) || item === "")
    );
    if (isEmpty(details.stakeHolderUserIds)) {
      details = omit(details, "stakeHolderUserIds");
    }
    details = omit(details, "tagIds");
    details = omit(details, "createdAt");
    details = omit(details, "updatedAt");
    details = omit(details, "createdBy");
    details = omit(details, "organizationId");
    details = omit(details, "departmentId");
    const project = {
      ...details,
      ...(roles && {
        roles,
      }),
      ...(costs && {
        costs,
      }),
      ...(data.organizationId && {
        organizationId: data.organizationId,
      }),
      ...(data.departmentId && {
        departmentId: data.departmentId,
      }),
      startsAt: DateTime.fromISO(details.startsAt),
      endsAt: DateTime.fromISO(details.endsAt),
    };
    onCreateProject(project);
  };
  const { data, isLoading } = useProjectDetails(projectId);
  if (
    isLoading ||
    isUserLoading ||
    isOrganisationsLoading ||
    isInvitedUserLoading
  ) {
    return null;
  }
  let availableAssignee = users;

  if (!isEmpty(invitedUsers)) {
    availableAssignee = users.concat(invitedUsers);
  }
  const mappedNetworks = SUPPORTED_CHAINS.map((chain) => ({
    label: chain.name,
    value: chain.network,
  }));
  const organisationsMap = keyBy(organisations, "id");
  const organizationUserIds = uniq(
    concat(
      organisationsMap[data.organizationId]?.userIds,
      organisationsMap[data.organizationId]?.createdBy
    )
  );
  const mappedOrganisations =
    organisations?.map((organisation) => ({
      label: organisation.name,
      value: organisation.id,
    })) || [];
  const mapDepartments = (id) => {
    const organisation =
      organisations?.find((organisation) => organisation.id === id) || {};
    return (
      organisation?.departments?.map((department) => ({
        label: department.name,
        value: department._id,
      })) || []
    );
  };
  const organisation = organisations?.find(
    (organisation) => organisation.id === data?.organizationId
  );
  const projectData = {
    ...data,
    organizationId: organisation ? data.organizationId : null,
    departmentId: organisation ? data.departmentId : null,
    chainId: data?.chainId || ETHEREUM.network,
    roles: data?.roles?.map((role, index) => ({
      ...role,
      currencyTokenCompensation: role.currencyTokenCompensation || 0,
      sid: index,
      assigneeUserId: organizationUserIds.includes(role.assigneeUserId)
        ? role.assigneeUserId
        : "",
    })),
    startsAt: formatDate(data?.startsAt),
    endsAt: formatDate(data?.endsAt),
  };

  const initialRolesData = data.roles;

  const initialRolesDataMap = {};

  for (let item of initialRolesData) {
    let key = item._id;
    initialRolesDataMap[key] = item;
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Duplicate {projectData.title} project
        </Text>
        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.projectDetails, { projectId })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Back to project
        </Button>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt="13px">
        <Box>
          <Text variant="h2" color="white" mb="25px">
            Enter project details
          </Text>
          <Formik
            enableReinitialize
            initialValues={projectData}
            onSubmit={handleSubmitProject}
            validationSchema={Yup.object().shape({
              title: Yup.string().required("Name is required"),
              description: Yup.string().required("Description is required"),
              documentationUrl: Yup.string().url("Invalid URL"),
              walletAddress: Yup.string().test(
                "is-valid",
                "Put valid ERC20 address in format '0x...'",
                isEthereumAddress
              ),
              organizationId: Yup.string().nullable(),
              departmentId: Yup.string().nullable(),
              chainId: Yup.string()
                .oneOf(
                  SUPPORTED_CHAINS.map((chain) => chain.network),
                  "Please select valid network"
                )
                .required(),
              currencyTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              governanceTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              stakeHolderUserIds: Yup.array().of(Yup.string()),
              roles: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string().required("Role name is required"),
                  tasksDescription: Yup.string(),
                  currencyTokenCompensation: Yup.number().nullable(),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              goals: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Goal is required"),
                })
              ),
              hazards: Yup.array().of(
                Yup.object({
                  description: Yup.string().required("Hazard is required"),
                })
              ),
              costs: Yup.array().of(
                Yup.object().shape({
                  description: Yup.string().required("Description is required"),
                  walletAddress: Yup.string().test(
                    "is-valid",
                    "Put valid ERC20 address in format '0x...'",
                    isEthereumAddress
                  ),
                  currencyTokenCompensation: Yup.number().test(
                    "is-valid",
                    "Token Amount is required",
                    isNumber
                  ),
                  governanceTokenCompensation: Yup.number().nullable(),
                })
              ),
              startsAt: Yup.date().required("Start date is required"),
              endsAt: Yup.date().required("End date is required"),
            })}
          >
            {({ values, setFieldValue, errors, dirty, submitCount }) => (
              <Form id="detailsForm" noValidate>
                <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                <RoundedBox mb="25px">
                  <FormikInputControl
                    label="Name your project"
                    name="title"
                    placeholder="Title"
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikTextareaControl
                    label="Describe your project"
                    name="description"
                    size="md"
                    placeholder="Description"
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikInputControl
                    label="Add an external document with project details"
                    name="documentationUrl"
                    placeholder="Document URL"
                    styleProps={{ mb: "21px" }}
                  />
                  <FormikInputControl
                    label="Project wallet address"
                    name="walletAddress"
                    placeholder="0x..."
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikAutocompleteControl
                    label="Organisation"
                    name="organizationId"
                    options={mappedOrganisations}
                    inputProps={{
                      placeholder: "Select organisation",
                    }}
                    onClick={(value) => {
                      setFieldValue("organizationId", value);
                      setFieldValue("departmentId", null);
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikAutocompleteControl
                    label="Team"
                    name="departmentId"
                    disabled={isEmpty(mapDepartments(values.organizationId))}
                    options={mapDepartments(values.organizationId)}
                    inputProps={{
                      placeholder: "Select team",
                    }}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <MultipleProfileSelection
                    label="Assign a stakeholders"
                    possibleProfiles={availableAssignee ?? []}
                    selectedUserIds={values.stakeHolderUserIds}
                    error={errors.stakeHolderUserIds}
                    showError={!!errors.stakeHolderUserIds}
                    onChange={(value) =>
                      setFieldValue(
                        "stakeHolderUserIds",
                        value?.map((item) => item.value)
                      )
                    }
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikSelectControl
                    label="Blockchain"
                    name="chainId"
                    options={mappedNetworks}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikInputControl
                    label="Primary Payment Token (ERC-20)"
                    name="currencyTokenContract"
                    placeholder="0x..."
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikInputControl
                    label="Secondary Token (ERC-20)"
                    name="governanceTokenContract"
                    placeholder="0x..."
                    styleProps={{ mb: "21px" }}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="roles"
                    render={(arrayHelpers) => (
                      <CreateRoleSet
                        roles={values.roles}
                        arrayHelpers={arrayHelpers}
                        organizationId={values.organizationId}
                        organizationUserIds={uniq(
                          concat(
                            organisationsMap[values.organizationId]?.userIds,
                            organisationsMap[values.organizationId]?.createdBy
                          )
                        )}
                        departmentId={values.departmentId}
                        setFieldValue={setFieldValue}
                        formValues={values}
                        initialRolesDataMap={initialRolesDataMap}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="goals"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="goals"
                        title="Goal"
                        fieldValues={values.goals}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="hazards"
                    render={(arrayHelpers) => (
                      <CreateOneInputSetObject
                        fieldName="hazards"
                        title="Hazard"
                        fieldValues={values.hazards}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                        bg="gray.24"
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox mb="25px">
                  <FieldArray
                    name="costs"
                    render={(arrayHelpers) => (
                      <CreateCostSet
                        isReadOnly={false}
                        costs={values.costs}
                        arrayHelpers={arrayHelpers}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                </RoundedBox>
                <RoundedBox
                  mb="25px"
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormikInputControl
                    label="Start Date"
                    type="date"
                    name="startsAt"
                    placeholder="20-07-2022"
                    styleProps={{ mb: "21px", mr: "15px" }}
                  />
                  <FormikInputControl
                    label="End date"
                    type="date"
                    name="endsAt"
                    placeholder="26-07-2022"
                    styleProps={{ mb: "21px" }}
                  />
                </RoundedBox>
                <Button type="submit">Create Project</Button>
              </Form>
            )}
          </Formik>
        </Box>
      </AppContainer.Content>
    </>
  );
};
