import { useQuery } from "react-query";
import { generatePath, useNavigate } from "react-router-dom";

import { useToast } from "@chakra-ui/react";

import axios from "../api/setup";
import { QueriesKeysEnum } from "./queries-keys-enum";
import { APP_PATHS } from "paths";

export const usePaymentsList = (projectId) => {
  const toast = useToast();
  const navigate = useNavigate();
  return useQuery(
    [QueriesKeysEnum.payments, projectId],
    () =>
      axios.get("/indexPayments", {
        params: { projectId },
      }),
    {
      enabled: !!projectId,
      onError: (error) => {
        navigate(generatePath(APP_PATHS.projectDetails, { projectId }));
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useNftsList = (projectId) => {
  const toast = useToast();
  const navigate = useNavigate();
  return useQuery(
    [QueriesKeysEnum.nfts, projectId],
    () =>
      axios.get("/indexNfts", {
        params: { projectId },
      }),
    {
      enabled: !!projectId,
      onError: (error) => {
        navigate(generatePath(APP_PATHS.projectDetails, { projectId }));
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useUserPaymentsList = (userId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userPayments, userId],
    () =>
      axios.get("/indexPayments", {
        params: { userId },
      }),
    {
      enabled: !!userId,
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useOrganizationPaymentsList = (organizationId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userPayments, organizationId],
    () =>
      axios.get("/indexPayments", {
        params: { organizationId, state: "completed" },
      }),
    {
      enabled: !!organizationId,
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useSmartContractList = (addresses) => {
  addresses = addresses?.filter((item) => item !== "0" && item !== undefined);
  return useQuery(
    [QueriesKeysEnum.smartContracts, addresses],
    () =>
      addresses.length !== 0
        ? axios.get("/indexSmartContracts", {
            params: { addresses: addresses.toString() },
          })
        : [],
    {
      retry: false,
      enabled: !!addresses,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const useAllSmartContractList = (loggedIn) => {
  return useQuery(
    [QueriesKeysEnum.smartContracts, {}],
    () => axios.get("/indexSmartContracts", {}),
    {
      retry: false,
      enabled: loggedIn,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const usePaymentsIncomeList = (projectId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.incomes, projectId],
    () =>
      axios.get("/indexPayments", {
        params: { projectId, isIncome: "true" },
      }),
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};
