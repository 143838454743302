import React from "react";
import { Divider } from "@chakra-ui/react";

import { InvoiceDataList } from "./invoice-data-list";
import { AdminCreateButton } from "../admin-create-button/admin-create-button";

export const CreateInvoiceDataSet = ({
  isReadOnly,
  items = [],
  arrayHelpers,
}) => {
  return (
    <>
      {items.length > 0 && (
        <InvoiceDataList
          isReadOnly={isReadOnly}
          onDeleteRole={(id) => arrayHelpers.remove(id)}
          items={items}
        />
      )}
      {!isReadOnly && (
        <>
          {items.length > 0 && <Divider mt="20px" mb="20px" />}
          <AdminCreateButton
            bg="gray.22"
            p="11.5px 15px"
            onClick={() =>
              arrayHelpers.insert(items.length, {
                description: "",
                quantity: 1,
                item: 0,
              })
            }
          >
            Add a new item
          </AdminCreateButton>
        </>
      )}
    </>
  );
};
