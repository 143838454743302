import React, { useEffect, useState } from "react";
import {
  generatePath,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import { isEqual, isEmpty, size, uniq, identity } from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import { AppContainer } from "components/app-container";
import { DataBox, ProfileBox, RoundedBox } from "libs/ui-components/src";
import {
  useNftsList,
  usePaymentsList,
  useSmartContractList,
} from "shared/queries/payments";
import { useMintPaymentMutation } from "shared/mutations/payments";
import { useUsers } from "shared/queries/users";
import { useProjectDetails } from "shared/queries/project";
import axios from "shared/api/setup";
import { NearService } from "services/near/nearService";
import { APP_PATHS } from "paths";
import { PaymentTable } from "../../components/payments";

export const Payments = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const nearService = new NearService();

  const [processPayments, setProcessPayments] = useState(false);
  const navigate = useNavigate();
  const { projectId } = params;
  const {
    data = [],
    isLoading: isPaymentsLoading,
    refetch: paymentRefetch,
  } = usePaymentsList(projectId);
  const userPayments = data.filter((payment) => payment.type === "role");
  const costPayments = data.filter((payment) => payment.type === "costs");
  const {
    data: nfts,
    isLoading: isNftsLoading,
    refetch: nftsRefetch,
  } = useNftsList(projectId);

  const { mutate: onMint } = useMintPaymentMutation({
    onSettled: () => {
      if (searchParams.has("transactionHashes")) {
        searchParams.delete("transactionHashes");
        searchParams.delete("transactionComplete");
        searchParams.delete("userIds");
        setSearchParams(searchParams);
      }
      setProcessPayments(false);
      nftsRefetch();
    },
  });

  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: project, isLoading: isProjectLoading } =
    useProjectDetails(projectId);

  const [selectedNearAccounts, setNearAccountSelected] = useState([]);
  const [unsafeMod, setUnsafeMode] = useState(false);
  const addresses = isProjectLoading
    ? []
    : [project?.currencyTokenContract, project?.governanceTokenContract];
  const { data: smartContracts, isLoading: isContractsLoading } =
    useSmartContractList(addresses);

  const usersWithNfts = uniq(nfts?.map((item) => item.userId) || []);
  useEffect(() => {
    const keyMap = {};
    document.addEventListener("keydown", (e) => {
      keyMap[e.code] = true;
      if (keyMap["KeyA"] && keyMap["KeyP"] && !unsafeMod) {
        setUnsafeMode(true);
      }
    });
    document.addEventListener("keyup", (e) => {
      keyMap[e.code] = false;
    });
  });
  useEffect(() => {
    const transactionHash = searchParams.get("transactionHashes");
    const processTransaction = searchParams.get("transactionComplete");
    const userIds = searchParams.get("userIds");
    if (transactionHash && processTransaction && userIds) {
      setProcessPayments(true);
      onMint({
        projectId,
        userIds: userIds.split(",").filter(identity),
        chainId: nearService.chainId,
        transactionHash,
        imageUrl:
          "https://deep-skills-p-images.s3.eu-central-1.amazonaws.com/avatars/6335a2a5fb340cf6f0fa7acc-media1672172407063.png",
      });
    }
  }, [searchParams]);
  useEffect(async () => {
    const accountId = searchParams.get("account_id");
    const allKeys = searchParams.get("all_keys");
    const publicKey = searchParams.get("public_key");
    if (accountId && (allKeys || publicKey)) {
      await nearService.connect();
      await nearService.completeSignIn();
    }
  }, [searchParams]);

  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };

  const selectUserForMinting = (user) => {
    if (selectedNearAccounts.includes(user.id)) {
      setNearAccountSelected(
        selectedNearAccounts.filter((item) => item !== user.id)
      );
    } else {
      setNearAccountSelected([...selectedNearAccounts, user.id]);
    }
  };

  const mintNFTs = async () => {
    await nearService.connect();
    if (nearService.isWalletSignedIn) {
      const { minterAccountId, amount } = await axios.get("/getMintInfo", {
        params: {
          chainId: nearService.chainId,
          nftAmount: size(selectedNearAccounts),
        },
      });
      await nearService.sendNearTokens(
        minterAccountId,
        `${amount}`,
        `${window.location.origin}${
          window.location.pathname
        }?transactionComplete=1&userIds=${selectedNearAccounts.join(",")}`
      );
    } else {
      nearService.walletSignIn();
    }
  };

  const creator = users?.find((user) => user.id === project?.createdBy);
  const isLoading =
    isPaymentsLoading ||
    isUserLoading ||
    isProjectLoading ||
    isContractsLoading ||
    isNftsLoading ||
    !project;
  if (isLoading) {
    return null;
  }

  const usersForMinting = users
    ?.filter(
      (user) =>
        user.isNearAccountSet &&
        !!project?.roles?.find((role) => role.assigneeUserId === user.id)
    )
    .map((user) => {
      const role = project?.roles?.find(
        (role) => role.assigneeUserId === user.id
      );
      return {
        ...user,
        roleId: role._id,
      };
    });
  const isAllUserSelected = isEqual(
    size(selectedNearAccounts) + size(usersWithNfts),
    size(usersForMinting)
  );
  return (
    <>
      <AppContainer.Content isDetails pt={50}>
        <Text variant="h1" color="white" mb={25}>
          Complete project: {project?.title}
        </Text>
        <Box
          display="flex"
          mb={{ base: "15px", md: "25px" }}
          width={{ base: "100%", md: "auto" }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Button
            as="a"
            href={generatePath(APP_PATHS.projectDetails, { projectId })}
            variant="link"
          >
            Back to project
          </Button>
        </Box>
        <RoundedBox p={{ base: "22px 20px" }} mb={10}>
          <Text color="white">
            <InfoOutlineIcon w={6} h={6} mr={2} color="primary" /> Any wallet
            can be connected to make payments.
          </Text>
        </RoundedBox>
        <PaymentTable
          payments={userPayments}
          project={project}
          smartContracts={smartContracts}
          users={users}
          creator={creator}
          unsafeMod={unsafeMod}
          handleOpenUserProfile={handleOpenUserProfile}
          paymentRefetch={paymentRefetch}
          setProcessPayments={setProcessPayments}
        />
        <PaymentTable
          payments={costPayments}
          project={project}
          smartContracts={smartContracts}
          users={users}
          creator={creator}
          unsafeMod={unsafeMod}
          handleOpenUserProfile={handleOpenUserProfile}
          paymentRefetch={paymentRefetch}
          setProcessPayments={setProcessPayments}
        />
        {size(usersForMinting) > 0 && (
          <Box mt={{ base: "25px", md: "10px" }}>
            <Box
              display="flex"
              mb={{ base: "15px", md: "25px" }}
              width={{ base: "100%", md: "auto" }}
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Text variant="h1" color="white">
                  Mint project NFTs
                </Text>
                <Text color="gray.50">
                  Select the relevant requests from the following to continue
                  with minting NFTs for each address
                </Text>
                <Box
                  display="flex"
                  gridGap="10px"
                  alignItems="center"
                  mb={{ base: "15px", md: "25px" }}
                  mt={{ base: "15px", md: "25px" }}
                >
                  <DataBox loading={isLoading} gap="6px" label="Project owner">
                    {creator && (
                      <ProfileBox
                        size="xs"
                        userId={creator.id}
                        onClick={handleOpenUserProfile}
                        name={`${creator.firstName} ${creator.lastName}`}
                        avatarUrl={creator.avatarUrl}
                      />
                    )}
                  </DataBox>
                  <DataBox loading={isLoading} gap="6px" label="Blockchain">
                    <Text textTransform="capitalize">NEAR Test network</Text>
                  </DataBox>
                </Box>
              </Box>
            </Box>
            <Box bg="gray.22" borderRadius="12px">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          isChecked={isAllUserSelected}
                          isDisabled={
                            isEqual(
                              size(usersForMinting),
                              size(usersWithNfts)
                            ) && !isEmpty(usersWithNfts)
                          }
                          onChange={() =>
                            setNearAccountSelected(
                              isAllUserSelected
                                ? []
                                : usersForMinting
                                    .filter(
                                      (user) => !usersWithNfts.includes(user.id)
                                    )
                                    .map((user) => user.id)
                            )
                          }
                        />
                      </Th>
                      <Th>Name</Th>
                      <Th></Th>
                      <Th>Role</Th>
                      <Th>Date</Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {usersForMinting?.map((user) => {
                      const isMinted = usersWithNfts?.includes(user.id);
                      return (
                        <Tr key={user.id}>
                          <Td>
                            <Checkbox
                              isChecked={
                                selectedNearAccounts.includes(user.id) ||
                                isMinted
                              }
                              isDisabled={isMinted}
                              onChange={() => selectUserForMinting(user)}
                            />
                          </Td>
                          <Td>{`${user?.firstName} ${user?.lastName}`}</Td>
                          <Td> </Td>
                          <Td>{user.roleId}</Td>
                          <Td> </Td>
                          <Td>Requested</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              display="flex"
              mt={{ base: "5px", md: "25px" }}
              mb={{ base: "5px", md: "25px" }}
              width={{ base: "100%", md: "auto" }}
              flexWrap="wrap"
              alignItems="center"
            >
              <Button
                mt={{ base: "32px", md: 0 }}
                disabled={isEmpty(selectedNearAccounts)}
                onClick={() => mintNFTs()}
              >
                Mint NFTs
              </Button>
              <Button
                ml={{ base: "5px", md: "25px" }}
                mt={{ base: "32px", md: 0 }}
                variant="outline"
                disabled={true}
                onClick={() => {}}
              >
                Mark as Minted
              </Button>
            </Box>
          </Box>
        )}
        <AlertDialog
          motionPreset="slideInBottom"
          isOpen={processPayments}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader alignSelf="center">
              Processing payment.
            </AlertDialogHeader>
            <AlertDialogBody alignSelf="center">
              <Spinner
                thickness="4px"
                speed="0.4s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </AppContainer.Content>
    </>
  );
};
