import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { ProfileBox } from "../../layout/profile-box/profile-box";
import { Autocomplete } from "../autocomplete/autocomplete";

const MultiValueLabel = (props) => {
  const { data } = props;
  const { label, avatarUrl } = data;
  return <ProfileBox size="sm" avatarUrl={avatarUrl} name={label} />;
};

export const MultipleProfileSelection = ({
  label,
  tooltipLabel,
  selectedUserIds = [],
  error,
  showError,
  placeholder,
  disabled,
  required,
  onChange,
  possibleProfiles,
  styleProps,
}) => {
  const mappedProfiles = possibleProfiles.map((profile) => ({
    label: profile.firstName
      ? `${profile.firstName} ${profile.lastName}`
      : `${profile.email} (Invited)`,
    value: profile.id,
    avatarUrl: profile.avatarUrl,
  }));
  return (
    <FormControl
      isInvalid={showError}
      isDisabled={disabled}
      isRequired={required}
      {...styleProps}
    >
      <Box display="flex" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {!!tooltipLabel && (
          <Box mb={4} ml={2}>
            <Tooltip label={tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Autocomplete
        data-testid="profile-selection"
        options={mappedProfiles}
        value={mappedProfiles
          .filter((v) => selectedUserIds.includes(v.value))
          .sort(
            (a, b) =>
              selectedUserIds.indexOf(a.value) -
              selectedUserIds.indexOf(b.value)
          )}
        onChange={onChange}
        isMulti
        placeholder={placeholder ?? "Select user"}
        isDisabled={disabled}
        components={{
          DropdownIndicator: null,
          MultiValueLabel,
        }}
        chakraStyles={{
          multiValue: (base) => ({
            ...base,
            backgroundColor: "gray.22",
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "primary",
          }),
          menu: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      {showError && <FormErrorMessage>{error ?? " "}</FormErrorMessage>}
    </FormControl>
  );
};
