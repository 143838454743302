import React from "react";
import { Text } from "@chakra-ui/react";

export const Budget = ({
  budget = 0,
  symbol = "$",
  currency = "USD",
  disabled = false,
}) => {
  const { format } = new Intl.NumberFormat("en-US");
  const value = isNaN(budget)
    ? 0
    : typeof budget === "string" || typeof budget === "number"
    ? `${symbol}${format(Number(budget).toFixed(2))}`
    : `${symbol}${format(Math.floor(+budget[0]))}-${symbol}${format(
        Math.floor(+budget[1])
      )}`;
  return (
    <Text
      as="span"
      color={disabled ? "gray.50" : "white"}
      fontWeight="400"
      fontSize="14px"
      lineHeight="17.71px"
    >
      {value}{" "}
      <Text
        as="span"
        color={disabled ? "gray.36" : "gray.58"}
        fontWeight="400"
        fontSize="14px"
        lineHeight="17.71px"
      >
        {currency}
      </Text>
    </Text>
  );
};
