import { Icon } from "@chakra-ui/react";
import React from "react";

export const OrganisationsIcon = (props) => (
  <Icon viewBox="0 0 72 72" {...props}>
    <circle cx="20.7008" cy="26.9996" r="5.4" fill="currentColor" />
    <circle cx="35.9996" cy="26.9996" r="5.4" fill="currentColor" />
    <circle cx="51.3004" cy="26.9996" r="5.4" fill="currentColor" />
    <circle cx="51.3004" cy="44.9996" r="5.4" fill="currentColor" />
    <circle cx="35.9996" cy="44.9996" r="5.4" fill="currentColor" />
    <circle cx="20.7008" cy="44.9996" r="5.4" fill="currentColor" />
  </Icon>
);
