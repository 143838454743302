import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InfoIcon } from "@chakra-ui/icons";
import { Select } from "chakra-react-select";

export const FormikSelectControl = ({ styleProps, inputProps, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { options } = props;
  const chakraStyles = {
    input: (provided) => ({
      ...provided,
      pt: "6px",
      pb: "6px",
    }),
  };
  return (
    <FormControl
      isInvalid={!!meta.error && meta.touched}
      isDisabled={props.disabled}
      isRequired={props.required}
      {...styleProps}
    >
      <Box display="flex" alignItems="center">
        <FormLabel mr="25px">{props.label}</FormLabel>
        {!!props.tooltipLabel && (
          <Box mb={4} ml={2}>
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Select
        components={{
          DropdownIndicator: null,
        }}
        options={options}
        disabled={props.disabled}
        chakraStyles={chakraStyles}
        {...inputProps}
        {...field}
        value={options.find((v) => v.value === meta.value)}
        onChange={(option) => helpers.setValue(option.value)}
      />
      {meta.touched && meta.error && (
        <FormErrorMessage>{meta.error ?? " "}</FormErrorMessage>
      )}
    </FormControl>
  );
};
