import React from "react";
import { size } from "lodash";
import {
  Avatar,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { pluralise } from "libs/utils/src";
import { EllipsisIcon, ProfileBox } from "libs/ui-components/src";

// https://gist.github.com/ForbesLindesay/5467742
export const convertNumberToWordsEN = (value) => {
  value = Math.floor(value);
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const numString = value.toString();
  if (value < 0) throw new Error("Negative numbers are not supported.");
  if (value === 0) return "zero";
  // the case of 1 - 20
  if (value < 20) {
    return ones[value];
  }
  if (numString.length === 2) {
    return tens[Number(numString[0])] + " " + ones[Number(numString[1])];
  }
  // 100 and more
  if (numString.length === 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[Number(numString[0])] + " hundred";
    else
      return (
        ones[Number(numString[0])] +
        " hundred and " +
        convertNumberToWordsEN(+(numString[1] + numString[2]))
      );
  }
  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[Number(numString[0])] + " thousand";
    if (end < 100)
      return (
        ones[Number(numString[0])] +
        " thousand and " +
        convertNumberToWordsEN(end)
      );
    return (
      ones[Number(numString[0])] + " thousand " + convertNumberToWordsEN(end)
    );
  }
  return "";
};

export const getRestText = (rest) =>
  `+ ${convertNumberToWordsEN(rest)} ${pluralise("other", size(rest))}`;

export const getAllText = (members) =>
  `${size(members)} ${pluralise("member", size(members))}`;

export const AvatarList = ({
  users = [],
  size = "md",
  max = 3,
  disabled = false,
  popoverAvailable = false,
  showAllMembers = false,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const avatars = users.map((user) => user.avatarUrl);
  const restOfUsers = avatars.length - max;
  const wrapperStyles = disabled ? { filter: "grayscale(100%)" } : {};
  return (
    <Popover
      placement="bottom"
      isOpen={isOpen && popoverAvailable}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box
          display="flex"
          alignItems="center"
          onClick={onToggle}
          cursor={popoverAvailable ? "pointer" : "auto"}
        >
          <Box display="flex">
            {avatars.slice(0, max).map((user, index) => (
              <Box
                key={index}
                ml={index !== 0 ? "-10px" : 0}
                zIndex={100 - index}
                style={wrapperStyles}
              >
                <Avatar
                  data-testid={user}
                  borderRadius="full"
                  size={size}
                  src={user}
                />
              </Box>
            ))}
            {showAllMembers && restOfUsers > 0 && (
              <Box ml="-10px" zIndex={100 - max} style={wrapperStyles}>
                <Avatar
                  borderRadius="full"
                  bgColor="gray.36"
                  size={size}
                  icon={<EllipsisIcon color="gray.79" w="100%" h="100%" />}
                />
              </Box>
            )}
          </Box>
          {showAllMembers ? (
            <Box ml="9px">
              <Text color={disabled ? "gray.50" : "gray.79"} fontSize="md">
                {getAllText(avatars)}
              </Text>
            </Box>
          ) : (
            restOfUsers > 0 && (
              <Box ml="9px">
                <Text color={disabled ? "gray.50" : "gray.79"} fontSize="md">
                  {getRestText(restOfUsers)}
                </Text>
              </Box>
            )
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Stakeholders</PopoverHeader>
        <PopoverBody>
          <Box
            display="flex"
            alignItems="left"
            flexDirection="column"
            gridGap="10px"
          >
            {users.map((user) => (
              <ProfileBox
                key={user.id}
                size="xs"
                userId={user.id}
                name={`${user.firstName} ${user.lastName}`}
                avatarUrl={user.avatarUrl}
              />
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
