import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { useToast } from "@chakra-ui/react";
import axios from "shared/api/setup";
import { QueriesKeysEnum } from "./queries-keys-enum";

export const useOrganisationList = () => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizations, {}],
    () => axios.get(`/indexOrganizations`, {}),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useUserOrganisationList = (userId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizations, { userId }],
    () =>
      axios.get(`/indexOrganizations`, {
        params: { userId },
      }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useMyOrganisationList = () => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.myOrganizations, {}],
    () => axios.get(`/indexMyOrganizations`, {}),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useOrganisationDetails = (organizationId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizationDetails, organizationId],
    () =>
      organizationId
        ? axios.get(`/readOrganization`, {
            params: { id: organizationId },
          })
        : {},
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useOrganisationFunctions = (organizationIds) => {
  organizationIds = organizationIds?.filter(
    (item) => item !== "0" && item !== undefined && item !== null
  );
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizationFunctions, organizationIds],
    () =>
      !isEmpty(organizationIds)
        ? axios.get(`/indexOrganizationRoles`, {
            params: { organizationIds: organizationIds.toString() },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useOrganisationTeamFunctions = (organizationIds, teamId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizationTeamFunctions, organizationIds, teamId],
    () =>
      !isEmpty(organizationIds) && !isEmpty(teamId)
        ? axios.get(`/indexOrganizationRoles`, {
            params: {
              organizationIds: organizationIds.toString(),
              organizationRoleDepartmentId: teamId,
            },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useIndexJoinOrganizationRequests = (
  organizationId,
  isAdmin = false,
  queryParameters = null
) => {
  const toast = useToast();
  const query = { organizationId: organizationId };

  if (queryParameters) {
    for (const fieldName in queryParameters) {
      const fieldValue = queryParameters[fieldName];

      query[fieldName] = fieldValue;
    }
  }

  return useQuery(
    [QueriesKeysEnum.organizationsJoins, { organizationId, isAdmin }],
    () =>
      !!organizationId && isAdmin
        ? axios.get(`/indexJoinOrganizationRequests`, {
            params: query,
          })
        : [],
    {
      retry: false,
      onError: (error) => {
        if (error && error.code === "DSP-67") {
          return;
        }
        toast({
          position: "top-right",
          status: "error",
          title: error.message,
        });
      },
    }
  );
};

export const useOrganizationRole = (organizationRoleId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.organizationFunctions, organizationRoleId],
    () =>
      !isEmpty(organizationRoleId)
        ? axios.get(`/readOrganizationRole`, {
            params: { id: organizationRoleId },
          })
        : [],
    {
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};
