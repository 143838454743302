import React from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import { RoundedBox, PaymentsTable } from "libs/ui-components/src";

export const PaymentsTab = ({
  isLoading,
  payments,
  projects,
  smartContracts,
  users,
}) => {
  if (isLoading) {
    return (
      <Skeleton isLoaded={!isLoading} w="100%" h="325px" borderRadius="24px" />
    );
  }

  return payments?.length ? (
    <Box display="flex" flexWrap="wrap" maxWidth={"100%"}>
      <Box bg="gray.22" borderRadius="12px" width="100%">
        <PaymentsTable
          payments={payments}
          projects={projects}
          smartContracts={smartContracts}
          users={users}
        />
      </Box>
    </Box>
  ) : (
    <RoundedBox
      h={"325px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text variant="h6">
          No active payments. Try to find open projects and apply a role
        </Text>
      </Box>
    </RoundedBox>
  );
};
