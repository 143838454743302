import React from "react";
import { Route, Routes } from "react-router-dom";
import { APP_PATHS } from "paths";
import { Layout } from "components/layout";
import { ProfilePage } from "pages/user-profile";
import { NewProject, ProjectDetails, ProjectsList } from "pages/projects";
import { NotFound } from "pages/not-found";
import { ConfirmEmail } from "pages/email-confirmation";
import { NewDepartment } from "pages/departments";
import { DepartmentDetails } from "./pages/departments/department-details/DepartmentDetails";
import { RoleDescription } from "pages/departments/role-description/RoleDescription";
import {
  EditDepartment,
  EditDepartmentFunctions,
} from "./pages/departments/edit-department";
import {
  EditOrganisation,
  NewOrganisation,
  OrganisationsList,
  OrganisationDetails,
} from "./pages/organisations";
import { ProjectApplication } from "./pages/projects/project-application";
import { ProjectApplicationSent } from "./pages/projects/project-application-sent";
import { EditProject } from "./pages/projects/edit-project";
import { Incomes, Payments } from "./pages/payments";
import { DuplicateProject } from "./pages/projects/duplicate-project";
import {
  ReviewFunctionUpdateRequest,
  ProjectFunctionRequest,
  ProjectFunctionRequestReview,
} from "./pages/projects/project-functions";
import { ResetPassword } from "./pages/reset-password";
import { ProjectFeedback } from "pages/projects/project-feedback/ProjectFeedback";
import { OutdatedSkillDescription } from "pages/projects/outdated-skill-description/OutdatedSkillDescription";
import { InviteUser } from "pages/invite/InviteUser";
import DeleteOrgMembers from "pages/organisations/organisation-details/DeleteMembers";

const App = () => (
  <Routes>
    <Route path={APP_PATHS.home} element={<Layout />}>
      <Route index element={<OrganisationsList />} />
      <Route path={APP_PATHS.organisations}>
        <Route index element={<OrganisationsList />} />
        <Route path={APP_PATHS.newOrganisation} element={<NewOrganisation />} />
        <Route
          path={APP_PATHS.organisationDetails}
          element={<OrganisationDetails />}
        />
        <Route
          path={APP_PATHS.editOrganisation}
          element={<EditOrganisation />}
        />
        <Route path={APP_PATHS.newDepartment} element={<NewDepartment />} />
        <Route path={APP_PATHS.editDepartment} element={<EditDepartment />} />
        <Route
          path={APP_PATHS.deleteOrgMembers}
          element={<DeleteOrgMembers />}
        />
        <Route
          path={APP_PATHS.editDepartmentFunctions}
          element={<EditDepartmentFunctions />}
        />
        <Route
          path={APP_PATHS.departmentDetails}
          element={<DepartmentDetails />}
        />
        <Route
          path={APP_PATHS.organizationRoleDescription}
          element={<RoleDescription />}
        />
      </Route>
      <Route path={APP_PATHS.projects}>
        <Route index element={<ProjectsList />} />
        <Route path={APP_PATHS.newProject} element={<NewProject />} />
        <Route path={APP_PATHS.projectDetails} element={<ProjectDetails />} />
        <Route path={APP_PATHS.editProject} element={<EditProject />} />
        <Route
          path={APP_PATHS.editProjectFunctions}
          element={<ProjectFunctionRequest />}
        />
        <Route
          path={APP_PATHS.reviewProjectFunctions}
          element={<ProjectFunctionRequestReview />}
        />
        <Route
          path={APP_PATHS.duplicateProject}
          element={<DuplicateProject />}
        />
        <Route
          path={APP_PATHS.applyToProject}
          element={<ProjectApplication />}
        />
        <Route
          path={APP_PATHS.projectApplicationSent}
          element={<ProjectApplicationSent />}
        />
        <Route path={APP_PATHS.payments} element={<Payments />} />
        <Route path={APP_PATHS.incomes} element={<Incomes />} />
        <Route path={APP_PATHS.feedback} element={<ProjectFeedback />} />
        <Route
          path={APP_PATHS.userSkillOutdated}
          element={<OutdatedSkillDescription />}
        />
      </Route>
      <Route path={APP_PATHS.userProfile} element={<ProfilePage />} />
      <Route path={APP_PATHS.confirmEmail} element={<ConfirmEmail />} />
      <Route path={APP_PATHS.resetPassword} element={<ResetPassword />} />
      <Route
        path={APP_PATHS.roleUpdateRequest}
        element={<ReviewFunctionUpdateRequest />}
      />
      <Route path={APP_PATHS.inviteUser} element={<InviteUser />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default App;
