import React from "react";
import { Box } from "@chakra-ui/react";
import { ProfileBox } from "../profile-box/profile-box";

export const OrganisationCard = ({
  onCardClick,
  navigationPath,
  image,
  name,
  projects,
  boxProps,
}) => {
  // Its allow to use open in new tab handler
  const onclick = (e) => {
    e.preventDefault();
    onCardClick();
  };

  return (
    <a href={navigationPath} rel="noreferrer">
      <Box
        bg="gray.22"
        borderRadius="12px"
        h="312px"
        w={{ base: "100%", sm: "48.2%", md: "290px" }}
        onClick={onclick}
        cursor="pointer"
        display="flex"
        flexDirection="column"
        {...boxProps}
      >
        <Box
          h="100%"
          borderBottom="1px solid"
          borderColor="gray.18"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p="0 15px"
        >
          <ProfileBox
            color="gray.96"
            direction="column"
            variant="compact"
            name={name}
            avatarUrl={image ?? ""}
            avatarProps={{
              name,
              bgColor: "gray.28",
              color: "primary",
            }}
            textMaxW="250px"
            onClick={onCardClick}
            size="xl"
          />
        </Box>
        <Box
          flex="1 0 auto"
          p="14px 25px 18px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            h="51px"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            {projects?.length} active projects
          </Box>
        </Box>
      </Box>
    </a>
  );
};
