import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InfoIcon } from "@chakra-ui/icons";
export const FormikTextareaControl = ({
  styleProps,
  size,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  return (
    <FormControl
      isInvalid={!!meta.error && meta.touched}
      isDisabled={props.disabled}
      isRequired={props.required}
      {...styleProps}
    >
      <Box display="flex" alignItems="flex-start">
        <FormLabel>{props.label}</FormLabel>
        {!!props.tooltipLabel && (
          <Box mb={4} ml={2} lineHeight={0}>
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
        <Box ml="8px">
          {helperText ? (
            typeof helperText === "string" ? (
              <Text color="gray.79">{helperText}</Text>
            ) : (
              helperText.map((text, index) => (
                <Text color="gray.79" key={index}>
                  {text}
                </Text>
              ))
            )
          ) : null}
        </Box>
      </Box>
      <Textarea
        required
        placeholder={props.placeholder}
        {...field}
        size={size}
      />
      {meta.touched && meta.error && (
        <FormErrorMessage whiteSpace="pre-wrap">
          {meta.error ?? " "}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
