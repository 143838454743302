import React from "react";
import { identity, isEmpty, omit, pickBy } from "lodash";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikInputControl,
  FormikTextareaControl,
  FormikSelectControl,
  UploadIcon,
} from "libs/ui-components/src";
import { ETHEREUM, SUPPORTED_CHAINS } from "services/helpers/chains";
import { isEthereumAddress } from "services/helpers/validators";

export const ImportModal = ({ isOpen, onClose, onSave }) => {
  const mappedNetworks = SUPPORTED_CHAINS.map((chain) => ({
    label: chain.name,
    value: chain.network,
  }));
  const handleImportProject = (values, onError, onSettled) => {
    let payload = pickBy(values, identity);
    payload = omit(payload, ["file"]);
    onSave(payload, {
      onError: onError,
      onSettled: onSettled,
      onSuccess: onClose(),
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Deep Skill Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              walletAddress: "",
              chainId: ETHEREUM.network,
              currencyTokenContract: "",
              governanceTokenContract: "",
              data: "",
            }}
            onSubmit={(values, actions) => {
              handleImportProject(
                values,
                (response) => {
                  const { message, error } = response;
                  if (
                    error?.code === "DSP-32" &&
                    !isEmpty(error?.context?.errors)
                  ) {
                    const errorMessage = `Users or organization does not exists (Please create them first):
${error.context.errors
  .map((error) => `${error.type}: ${error.parameterValue}`)
  .join("\n")}`;
                    actions.setFieldError("data", errorMessage);
                  } else {
                    actions.setFieldError("data", message);
                  }
                },
                () => {
                  actions.setSubmitting(false);
                }
              );
            }}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              walletAddress: Yup.string().test(
                "is-valid",
                "Put valid ERC20 address in format '0x...'",
                isEthereumAddress
              ),
              chainId: Yup.string()
                .oneOf(
                  SUPPORTED_CHAINS.map((chain) => chain.network),
                  "Please select valid network"
                )
                .required(),
              currencyTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              governanceTokenContract: Yup.string().test(
                "is-valid",
                "Put valid ERC20 token in format '0x...'",
                isEthereumAddress
              ),
              data: Yup.string(),
            })}
          >
            {({ setFieldValue, setFieldError }) => (
              <Form id="detailsForm" noValidate>
                <Flex>
                  <FormikSelectControl
                    label="Blockchain"
                    name="chainId"
                    options={mappedNetworks}
                    styleProps={{
                      mb: "21px",
                      mr: "15px",
                      flexBasis: "content",
                    }}
                  />
                  <FormikInputControl
                    label="Project wallet address"
                    name="walletAddress"
                    placeholder="0x..."
                    styleProps={{ mb: "21px" }}
                  />
                </Flex>
                <FormikInputControl
                  label="Primary Payment Token (ERC-20)"
                  name="currencyTokenContract"
                  placeholder="0x..."
                  styleProps={{ mb: "21px", mr: "15px" }}
                />
                <FormikInputControl
                  label="Secondary Token (ERC-20)"
                  name="governanceTokenContract"
                  placeholder="0x..."
                  styleProps={{ mb: "21px" }}
                />
                <FormikTextareaControl
                  label="Payload"
                  name="data"
                  required={true}
                  placeholder="..."
                  styleProps={{ mb: "21px" }}
                />
                <Field name="dataFile">
                  {({ field, form: { touched }, meta }) => (
                    <FormControl
                      id={field.name}
                      data-testid={`${field.name}-input`}
                      isInvalid={!!meta.error && touched}
                      mb="21px"
                    >
                      <FormLabel alignItems="center">
                        <Input
                          type="file"
                          display="none"
                          name="dataFile"
                          accept="application/json"
                          onChange={(e) => {
                            if (!e.target.files || isEmpty(e.target.files)) {
                              return null;
                            }
                            const reader = new FileReader();
                            reader.readAsText(e.target.files[0], "UTF-8");
                            reader.onload = function (event) {
                              setFieldValue("data", event.target.result);
                            };
                            reader.onerror = function () {
                              setFieldError(
                                "data",
                                "Error json reading file. Try to select valid json file."
                              );
                            };
                          }}
                        />
                        <Button
                          as="text"
                          variant="outline"
                          leftIcon={<UploadIcon />}
                        >
                          Upload project config
                        </Button>
                      </FormLabel>
                      {touched && meta.error && (
                        <FormErrorMessage>{meta.error ?? " "}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Button type="submit">Import Project</Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
