import React, { useState, useEffect } from "react";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isEmpty, pick } from "lodash";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import {
  ConfirmationAlert,
  CreateFunctionSet,
  PromptIfDirty,
} from "libs/ui-components/src";
import { useUsers, useUserSkills } from "shared/queries/users";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { useOrganisationFunctions } from "shared/queries/organisation";
import { useProjectDetails } from "shared/queries/project";
import { useCreateOrganizationRoleUpdateRequestMutation } from "shared/mutations/project";
import { APP_PATHS } from "paths";

export const ProjectFunctionRequest = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { projectId } = params;
  const { user } = useUserContextState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openWarning, setOpenWarning] = useState(false);
  const [functionsToSave, setFunctionsToSave] = useState({});
  const [userFunctions, setUserFunctions] = useState([]);
  const { data: project, isLoading: isProjectLoading } =
    useProjectDetails(projectId);
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkills([user?.id]);
  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationFunctions(
      project?.organizationId ? [project.organizationId] : []
    );
  const { mutateAsync: onRoleUpdateRequest } =
    useCreateOrganizationRoleUpdateRequestMutation();
  useEffect(() => {
    if (project && !isEmpty(functions) && !isEmpty(userSkills)) {
      const userRoles = project.roles
        ?.filter(
          (role) => role.assigneeUserId === user.id && role.organizationRoleId
        )
        .map((role) => role.organizationRoleId);
      setUserFunctions(
        functions
          .filter((item) => userRoles.includes(item.id))
          .map((item) => ({
            ...item,
            owners: userSkills
              .filter((skill) => skill.organizationRoleId === item.id)
              .map((skill) => skill.userId),
          }))
      );
    }
  }, [project, functions, userSkills]);

  useEffect(() => {
    if (project && project.state !== "completed") {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: project.id })
      );
    }
  }, [project, userFunctions]);

  const handleSubmit = (values) => {
    if (openWarning) {
      setFunctionsToSave(values);
      onOpen();
    } else {
      confirmSubmit(values);
    }
  };
  const confirmSubmit = async (values) => {
    const mutations = [];
    values.functions.forEach((item) => {
      const data = {
        ...pick(item, ["changesSummary", "description"]),
        organizationRoleId: item.id,
        projectId,
      };
      mutations.push(onRoleUpdateRequest(data));
    });

    await Promise.all(mutations).then(() => {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: project.id })
      );
    });
  };
  const confirmLeave = () => {
    confirmSubmit(functionsToSave);
  };
  if (
    isProjectLoading ||
    isUserLoading ||
    isFunctionsLoading ||
    isUserSkillsLoading
  ) {
    return null;
  }
  return (
    <AppContainer.Content>
      <Box>
        <ConfirmationAlert
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={confirmLeave}
          description="All unsaved changes will be lost, are you sure you want to leave?"
          isAlert={false}
          cancelLabel="Cancel"
          confirmLabel="Discard and Leave"
        />
        <Text variant="h2" color="white" mb="25px">
          Send request to update a function
        </Text>
        <Formik
          enableReinitialize
          initialValues={{ functions: userFunctions }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            functions: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required("Title is required"),
                description: Yup.string().required("Description is required"),
                governanceTokenMultiplier: Yup.number(),
                changesSummary: Yup.string(),
                owners: Yup.array().of(Yup.string()),
              })
            ),
          })}
        >
          {({ setFieldValue, values, dirty, submitCount }) => {
            return (
              <Form id="pplAndSkillsForm" noValidate>
                <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                <Box mb="25px">
                  <CreateFunctionSet
                    isEdit={true}
                    isUpdateOnly={true}
                    functions={values.functions}
                    existingFunctionIds={userFunctions.map((item) => item.id)}
                    onChange={(e) => setFieldValue("functions", e)}
                    users={users}
                    setOpenWarning={setOpenWarning}
                  />
                </Box>
                <Box
                  display="flex"
                  mt="32px"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Button
                    disabled={!dirty}
                    type="submit"
                    data-testid="submit-btn"
                  >
                    Submit request
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppContainer.Content>
  );
};
