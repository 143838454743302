import { useQuery } from "react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "shared/api/setup";
import { QueriesKeysEnum } from "./queries-keys-enum";

export const useProjectsList = (enabled, params = {}) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.projects, params],
    () => axios.get(`/indexProjects`, { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useProjectDetails = (projectId) => {
  const toast = useToast();
  const navigate = useNavigate();
  return useQuery(
    [QueriesKeysEnum.projectDetails, projectId],
    () =>
      axios.get("/readProject", {
        params: { id: projectId },
      }),
    {
      retry: false,
      onError: (error) => {
        navigate("/projects");
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useProjectAssignee = (projectId) => {
  const toast = useToast();
  const navigate = useNavigate();
  return useQuery(
    [QueriesKeysEnum.projectAssignee, projectId],
    () =>
      axios.get("/indexProjectAssigneeRequests", {
        params: {
          projectId,
          state: "pending",
        },
      }),
    {
      retry: false,
      onError: (error) => {
        navigate("/projects");
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useRoleUpdateRequests = (
  organizationId,
  organizationDepartmentId
) => {
  const toast = useToast();
  return useQuery(
    [
      QueriesKeysEnum.organizationRoleUpdateRequests,
      [organizationId, organizationDepartmentId],
    ],
    () =>
      axios.get("/indexOrganizationRoleUpdateRequests", {
        params: {
          organizationId,
          organizationDepartmentId,
          state: "pending",
        },
      }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useUserRoleUpdateRequests = (createdBy) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.userRoleUpdateRequests, createdBy],
    () =>
      axios.get("/indexOrganizationRoleUpdateRequests", {
        params: {
          createdBy,
          state: "pending",
        },
      }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useOrganizationRoleUpdateRequest = (orgRoleUpdateRequestId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.orgRoleUpdateRequestId, [orgRoleUpdateRequestId]],
    () =>
      axios.get("/readOrganizationRoleUpdateRequest", {
        params: { id: orgRoleUpdateRequestId },
      }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};

export const useIndexProjectFeedbackRequests = (projectId) => {
  const toast = useToast();
  return useQuery(
    [QueriesKeysEnum.projectFeedbackRequests, projectId],
    () =>
      axios.get("/indexMyFeadbacks", {
        params: {
          projectId,
        },
      }),
    {
      retry: false,
      onError: (error) => {
        toast({ position: "top-right", status: "error", title: error.message });
      },
    }
  );
};
