import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionVersionsTable } from "../collapsable-table";
import { FunctionForm } from "./function-form";

export const FunctionListItem = ({
  isEdit,
  isUpdateOnly,
  item,
  onDelete,
  boxProps,
  onSubmit,
  users,
  existingFunctionIds,
  setOpenWarning,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleDelete = () => {
    onDelete(item.id);
  };
  const handleOpen = (flag) => {
    setOpen(flag);
    setOpenWarning(flag);
  };
  const handleSubmit = (values) => {
    handleOpen(false);
    setOpenWarning(true);
    onSubmit(values);
  };
  const {
    isOpen: isChangeLogOpen,
    onToggle: toggleChangeLog,
    onClose: closeChangeLog,
  } = useDisclosure();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="gray.24"
      borderRadius="12px"
      px="25px"
      py="15px"
      minHeight="57px"
      {...boxProps}
    >
      <Box w="100%">
        {!isOpen && (
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ base: "flex-start", md: "center" }}
            flex="1 0 auto"
          >
            <Text
              isTruncated
              variant="h4"
              color="white"
              maxWidth={{ base: "80%" }}
              mb={{ base: "16px", md: 0 }}
            >
              {item.title} {item.isArchived ? "(archived)" : ""}
            </Text>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={{ base: "flex-start", md: "center" }}
              width={{ base: "100%", md: "auto" }}
              flexDirection={{ base: "column", md: "row" }}
              gridColumnGap={{ base: "0", md: "35px" }}
              gridRowGap={{ base: "12px", md: "0" }}
            >
              {!item.isArchived && !isUpdateOnly && (
                <Button variant="link" color="warning" onClick={handleDelete}>
                  Delete role
                </Button>
              )}
              {isEdit && (
                <Popover
                  placement="bottom"
                  isOpen={isChangeLogOpen}
                  onClose={closeChangeLog}
                >
                  <PopoverTrigger>
                    <Button variant="link" onClick={toggleChangeLog}>
                      View changelog
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent w="md">
                    <PopoverCloseButton />
                    <PopoverBody p={0}>
                      <Box
                        display="flex"
                        alignItems="left"
                        flexDirection="column"
                        gridGap="10px"
                      >
                        <FunctionVersionsTable
                          versions={item.versions}
                          createdBy={item.createdBy}
                          users={users}
                        />
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              {!item.isArchived && (
                <Button variant="link" onClick={() => handleOpen(true)}>
                  {`${isEdit ? "Update" : "Edit"} role`}
                </Button>
              )}
            </Box>
          </Box>
        )}

        <Collapse in={isOpen} animateOpacity>
          <Text variant="h3" mb="32px">
            {`${isEdit ? "Update" : "Edit"} ${item.title}`}
          </Text>
          <FunctionForm
            item={item}
            isEdit={isEdit}
            isUpdateOnly={isUpdateOnly}
            existingFunctionIds={existingFunctionIds}
            onSubmit={handleSubmit}
            onCancel={() => handleOpen(false)}
            users={users}
          />
        </Collapse>
      </Box>
    </Box>
  );
};
