export var QueriesKeysEnum;
((QueriesKeysEnum) => {
  QueriesKeysEnum["projects"] = "projects";
  QueriesKeysEnum["projectDetails"] = "projectDetails";
  QueriesKeysEnum["projectAssignee"] = "projectAssignee";
  QueriesKeysEnum["projectFeedbackRequests"] = "projectFeedbackRequests";
  QueriesKeysEnum["payments"] = "payments";
  QueriesKeysEnum["incomes"] = "incomes";
  QueriesKeysEnum["nfts"] = "nfts";
  QueriesKeysEnum["organizations"] = "organizations";
  QueriesKeysEnum["myOrganizations"] = "myOrganizations";
  QueriesKeysEnum["organizationDetails"] = "organizationDetails";
  QueriesKeysEnum["organizationFunctions"] = "organizationFunctions";
  QueriesKeysEnum["organizationTeamFunctions"] = "organizationTeamFunctions";
  QueriesKeysEnum["organizationRoleUpdateRequests"] =
    "organizationRoleUpdateRequests";
  QueriesKeysEnum["userRoleUpdateRequests"] = "userRoleUpdateRequests";
  QueriesKeysEnum["orgRoleUpdateRequestId"] = "orgRoleUpdateRequestId";
  QueriesKeysEnum["organizationsJoins"] = "organizationsJoins";
  QueriesKeysEnum["departments"] = "departments";
  QueriesKeysEnum["departmentDetails"] = "departmentDetails";
  QueriesKeysEnum["userPayments"] = "userPayments";
  QueriesKeysEnum["smartContracts"] = "smartContracts";
  QueriesKeysEnum["users"] = "users";
  QueriesKeysEnum["customUsers"] = "customUsers";
  QueriesKeysEnum["user"] = "user";
  QueriesKeysEnum["userSkills"] = "userSkills";
})(QueriesKeysEnum || (QueriesKeysEnum = {}));
