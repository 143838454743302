import React, { useState } from "react";
import { uniq, keyBy } from "lodash";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Markdown from "components/Markdown";
import { RoleCard, RoundedBox } from "libs/ui-components/src";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { ANONYMOUS_USER_ID } from "shared/constants/user";
import { useOrganisationFunctions } from "shared/queries/organisation";
import { PopupMdDescription } from "libs/ui-components/src/components/modals/md-description/md-description";
import { EMPTY_SKILLS_TAB_TEXT_TEAM } from "shared/constants/empty-text";
import { useUserSkillsByUsersAndOrganization } from "shared/queries/users";

export const ProjectRolesList = ({
  assignee,
  users,
  roles,
  baseCurrency,
  governanceCurrency,
  onApplyClick,
  onUserProfileClick,
  project,
  organisation,
  projectState,
}) => {
  const {
    isOpen: isOpenShowTasks,
    onClose: onCloseShowTasks,
    onOpen: onOpenShowTasks,
  } = useDisclosure();

  const {
    isOpen: isDescriptionOpen,
    onToggle: toggleDescription,
    onClose: closeDescription,
  } = useDisclosure();
  const [popupRoleDetails, setPopupRoleDetails] = useState({});

  const [roleDetail, setRoleDetail] = useState();
  const { user } = useUserContextState();

  const handleOpenShowTasks = (role) => () => {
    setRoleDetail(role);
    onOpenShowTasks();
  };

  const usersMap = roles
    .map((role) => role.assigneeUserId)
    .filter((userId) => userId !== undefined);

  const uniqueUserIds = uniq(usersMap);

  const organizationId = project.organizationId;
  const departmentId = project.departmentId;

  const isAdmin = organisation?.createdBy === user.id;

  const stakeHolderDepartmentUserIds = organisation?.departments?.find(
    (department) => department._id === departmentId
  )?.stakeHolderUserIds;
  const isTeamLeader = stakeHolderDepartmentUserIds?.includes(user?.id);

  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationFunctions([organizationId]);

  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkillsByUsersAndOrganization(uniqueUserIds, organizationId);

  if (isFunctionsLoading || isUserSkillsLoading) {
    return null;
  }

  const functionsMap = keyBy(functions, "id");

  const userSkillsMap = {};

  for (const userSkill of userSkills) {
    const { userId, organizationRoleId } = userSkill;
    userSkillsMap[organizationRoleId] = userSkillsMap[organizationRoleId] || {};
    userSkillsMap[organizationRoleId][userId] = userSkill;
  }

  let { title, description, organizationRoleId } = popupRoleDetails;

  const handleOpenShowDescription = (role) => () => {
    const currentFunction = functions.find(
      (functionItem) => functionItem.id === role.organizationRoleId
    );

    const firstMatchingVersion = currentFunction.versions
      .slice()
      .reverse()
      .find((item) => new Date(item.date) <= new Date(project.createdAt));

    const { title, description } =
      firstMatchingVersion || currentFunction.versions[0];

    if (currentFunction) {
      setPopupRoleDetails({
        title: title || currentFunction.title,
        description: description || currentFunction.description,
        organizationRoleId: currentFunction.id,
      });

      toggleDescription();
    }
  };

  const isOrgRoleDescription = (role) => {
    const currentFunction = functions.find(
      (functionItem) => functionItem.id === role.organizationRoleId
    );
    return currentFunction?.description;
  };

  const getUserApplicationByRole = (role) => {
    const applications = assignee?.filter((item) => item.roleId === role?.id);
    return applications.find(
      (application) => application.createdBy === user.id
    );
  };

  const getContributor = (role) => {
    return users.find((user) => user.id === role.assigneeUserId);
  };

  return (
    <>
      <Box>
        <Text mb="26px" color={"white"} variant="h2">
          Roles
        </Text>
        {roles.length === 0 ? (
          <RoundedBox w="100%" p={{ base: "22px 20px", md: "22px 20px" }}>
            <Text variant="h6">{EMPTY_SKILLS_TAB_TEXT_TEAM}</Text>
          </RoundedBox>
        ) : (
          <>
            {roles.map((role) => (
              <Box mb="26px" key={role._id}>
                <RoleCard
                  role={role}
                  userSkillList={userSkillsMap[role.organizationRoleId] || {}}
                  functionData={functionsMap[role.organizationRoleId]}
                  isAnonymous={role.assigneeUserId === ANONYMOUS_USER_ID}
                  contributor={getContributor(role)}
                  user={user}
                  baseCurrency={baseCurrency}
                  governanceCurrency={governanceCurrency}
                  application={getUserApplicationByRole(role)}
                  onShowTasksClick={handleOpenShowTasks(role)}
                  onShowDescription={handleOpenShowDescription(role)}
                  isDescriptionActiveBTN={isOrgRoleDescription(role)}
                  onApplyClick={() => onApplyClick(role)}
                  onUserProfileClick={onUserProfileClick}
                  projectId={project.id}
                  projectCreatedBy={project.createdBy}
                  projectCreatedAt={project.createdAt}
                  projectState={projectState}
                />
              </Box>
            ))}
          </>
        )}
      </Box>

      {roleDetail && (
        <Modal
          size="sm"
          isOpen={isOpenShowTasks}
          onClose={onCloseShowTasks}
          returnFocusOnClose={false}
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{roleDetail.title}</ModalHeader>
            <ModalCloseButton data-testid="close-modal" />
            <ModalBody>
              <Text color="white" variant="h6" margin="0 0 0 15px">
                <Markdown
                  linkTarget="_blank"
                  text={roleDetail.tasksDescription}
                />
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <PopupMdDescription
        title={title}
        description={description}
        organizationId={organizationId}
        departmentId={departmentId}
        organizationRoleId={organizationRoleId}
        canEditRole={isAdmin || isTeamLeader}
        closeDescription={closeDescription}
        isDescriptionOpen={isDescriptionOpen}
      />
    </>
  );
};
