import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { environment } from "../../../../../../environments";
import { isEthereumAddress } from "../../../../../../services/helpers/validators";

import { FormikInputControl } from "../../form/formik-wrappers";
import { FileUpload } from "../../form/file-upload/file-upload";

export const EditProfileModal = ({ isOpen, profile, onClose, onSave }) => {
  const toast = useToast();
  const initialValues = {
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    bio: profile?.bio || "",
    discordId: profile?.discordId || "",
    workLink: profile?.workLink || "",
    nearAccountId: profile?.nearAccountId || "",
    email: profile?.email || "",
    walletAddress: profile?.walletAddress || "",
    avatarUrl: null,
    isEmailPublic: profile?.isEmailPublic,
    isNotificationsOn: profile?.isNotificationsOn,
  };
  const network = environment.NEAR_IS_MAIN_NETWORK ? "near" : "testnet";
  const handleSubmitForm = (values) => {
    onSave(values);
  };

  const onChangePublicEmailFiled = (setFieldValue) => (event) => {
    setFieldValue("isEmailPublic", event.target.checked);
    if (!event.target.checked) {
      toast({
        position: "top-right",
        status: "error",
        title:
          "If you switch email visibility off, others won't be able reach out to you for work",
      });
    }
  };

  const onChangeEmailNotificationsFiled = (setFieldValue) => (event) => {
    setFieldValue("isNotificationsOn", event.target.checked);
    if (!event.target.checked) {
      toast({
        position: "top-right",
        status: "error",
        title:
          "If you switch email notifications off, you will receive only service email",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit your account details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First name is required"),
              lastName: Yup.string().required("Last name is required"),
              discordId: Yup.string().nullable(),
              bio: Yup.string()
                .max(500, "Bio must be under 500 characters")
                .nullable(),
              workLink: Yup.string().url("Must be a valid URL").nullable(),
              email: Yup.string().email("Invalid email"),
              nearAccountId: Yup.string()
                .min(2)
                .max(64)
                .matches(
                  /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/,
                  "Account ID: consists of Account ID parts separated by . and part consists of lowercase" +
                    " alphanumeric symbols separated by either _ or -."
                ),
              walletAddress: Yup.string()
                .test(
                  "is-valid",
                  "Put valid ERC20 address in format '0x...'",
                  isEthereumAddress
                )
                .required("Wallet is required"),
            })}
            onSubmit={handleSubmitForm}
          >
            {(
              { values, setFieldValue } // eslint-disable-line
            ) => (
              <Form noValidate>
                <FormikInputControl
                  label="First Name"
                  name="firstName"
                  placeholder="John"
                  styleProps={{ mb: "21px" }}
                  disabled={false}
                />
                <FormikInputControl
                  label="Last Name"
                  name="lastName"
                  placeholder="Doe"
                  styleProps={{ mb: "21px" }}
                  disabled={false}
                />
                <FormikInputControl
                  label="Bio"
                  name="bio"
                  type="text"
                  isTextArea={true}
                  placeholder="A short bio"
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Discord ID"
                  name="discordId"
                  placeholder="Your Discord ID"
                  styleProps={{ mb: "21px" }}
                />

                <FormikInputControl
                  label="Work Link"
                  name="workLink"
                  type="text"
                  placeholder="http://yourwork.link"
                  styleProps={{ mb: "21px" }}
                />
                <FormikInputControl
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="johndoe@example.com"
                  styleProps={{ mb: "21px" }}
                />
                <FormControl display="flex" alignItems="center">
                  <Switch
                    id="email-isEmailPublic"
                    name="isEmailPublic"
                    onChange={onChangePublicEmailFiled(setFieldValue)}
                    colorScheme="green"
                    styleProps={{ mb: "21px" }}
                    isChecked={values?.isEmailPublic}
                  />
                  <FormLabel htmlFor="email-isEmailPublic" ml="10px" mb="0">
                    Make email public
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" alignItems="center" mt="10px">
                  <Switch
                    id="email-notifications"
                    name="isNotificationsOn"
                    onChange={onChangeEmailNotificationsFiled(setFieldValue)}
                    colorScheme="green"
                    styleProps={{ mb: "21px" }}
                    isChecked={values?.isNotificationsOn}
                  />
                  <FormLabel htmlFor="email-notifications" ml="10px" mb="0">
                    Notifications on email
                  </FormLabel>
                </FormControl>
                <FormikInputControl
                  label="Ethereum Address"
                  placeholder="0x..."
                  name="walletAddress"
                  styleProps={{ mb: "21px", mt: "21px" }}
                />
                {environment.NEAR_AVAILABLE && (
                  <FormikInputControl
                    label="Near Named Account Id"
                    placeholder={`name.${network}`}
                    name="nearAccountId"
                    styleProps={{ mb: "21px" }}
                  />
                )}
                <FormControl id="photo" mb="21px">
                  <FormLabel>Photo</FormLabel>
                  <FileUpload
                    name="avatar"
                    preview={profile?.avatarUrl}
                    onChange={setFieldValue}
                  />
                </FormControl>
                <Button type="submit">Save</Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
