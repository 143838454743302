import { ExternalLinkIcon } from "@chakra-ui/icons";
import Linkify from "linkify-react";
import React, { useState } from "react";
import { Box, Button, Collapse, Text } from "@chakra-ui/react";
import { formatDateL } from "libs/utils/src";
import { DataBox } from "../../data-display/data-box/data-box";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";
import { FunctionForm } from "./function-form";

const renderLink = ({ attributes, content }) => {
  const { href } = attributes;
  return (
    <Button
      as="a"
      href={href}
      target="_blank"
      variant="link"
      whiteSpace="pre-wrap"
      display="block"
      rightIcon={<ExternalLinkIcon />}
    >
      {content}
    </Button>
  );
};

export const FunctionListItem = ({
  item,
  boxProps,
  onSubmit,
  users,
  setOpenWarning,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleDelete = () => {
    onSubmit({
      ...item,
      isRejected: true,
      isApproved: false,
    });
  };
  const handleOpen = (flag) => {
    setOpen(flag);
    setOpenWarning(flag);
  };
  const handleSubmit = (values) => {
    handleOpen(false);
    onSubmit({
      ...values,
      isRejected: false,
      isApproved: true,
    });
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="gray.24"
      borderRadius="12px"
      px="25px"
      py="15px"
      minHeight="57px"
      {...boxProps}
    >
      <Box w="100%">
        {!isOpen && (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
              gridColumnGap="40px"
            >
              <DataBox
                label="Role"
                boxProps={{
                  mb: "18px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gridRowGap: "10px",
                }}
              >
                {item.title}
              </DataBox>
              <DataBox
                label="Date"
                boxProps={{
                  mb: "18px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gridRowGap: "10px",
                }}
              >
                {formatDateL(item.createdAt)}
              </DataBox>
              <DataBox
                label="Request from"
                boxProps={{
                  mb: "18px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gridRowGap: "10px",
                }}
              >
                {item.createdBy}
              </DataBox>
            </Box>
            <Box display="flex" flexDirection="column" w="100%">
              <Text fontSize="14px">
                Suggested changes{" "}
                {item.isRejected ? (
                  <Text as="span" color="warning">
                    (rejected)
                  </Text>
                ) : item.isApproved ? (
                  <Text as="span" color="primary">
                    (approved)
                  </Text>
                ) : (
                  ""
                )}
              </Text>
              <RoundedBox
                bg="gray.18"
                display="flex"
                flexDirection="column"
                p={{ base: "5px 20px", md: "5px 20px" }}
                borderRadius="4px"
              >
                <Box whiteSpace="pre-wrap">
                  <Linkify options={{ render: renderLink }}>
                    {item.description}
                  </Linkify>
                </Box>
              </RoundedBox>
              <Box
                display="flex"
                mt="4"
                justifyContent="space-between"
                alignItems={{ base: "flex-start", md: "center" }}
                width={{ base: "100%", md: "auto" }}
                flexDirection={{ base: "column", md: "row" }}
                gridColumnGap={{ base: "0", md: "35px" }}
                gridRowGap={{ base: "12px", md: "0" }}
              >
                <Button variant="link" onClick={() => handleOpen(true)}>
                  Review request
                </Button>
                {!item.isRejected && (
                  <Button variant="link" color="warning" onClick={handleDelete}>
                    Reject request
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Collapse in={isOpen} animateOpacity>
          <FunctionForm
            item={item}
            isEdit={true}
            onSubmit={handleSubmit}
            onCancel={() => handleOpen(false)}
            users={users}
          />
        </Collapse>
      </Box>
    </Box>
  );
};
