import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Button,
  Box,
} from "@chakra-ui/react";

import { useCreateJoinOrganizationMutation } from "shared/mutations/organisation";
import { FormikTextareaControl } from "../../form/formik-wrappers";

export const PopupJoinRequestNote = ({
  organizationId,
  onCloseJoinRequestWindow,
  isOpenJoinRequestWindow,
}) => {
  const toast = useToast();
  const { mutate: onSubmitRequest } = useCreateJoinOrganizationMutation();

  const joinOrganizationRequest = (values) => {
    const data = {
      organizationId: organizationId,
      note: values.note,
    };
    onSubmitRequest(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Join request send.",
        });
        onCloseJoinRequestWindow();
      },
    });
  };

  return (
    <Modal
      onClose={onCloseJoinRequestWindow}
      isOpen={isOpenJoinRequestWindow}
      isCentered
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent minHeight="20%" maxHeight="70%" mx={5}>
        <ModalHeader
          fontSize="1.75rem"
          pl={6}
          pt={6}
          pb={0}
          pr={14}
          color="gray.79"
        >
          Join organization request
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>
          <Formik
            enableReinitialize
            initialValues={{
              note: "",
            }}
            validateOnChange={false}
            validateOnMount={false}
            onSubmit={joinOrganizationRequest}
            validationSchema={Yup.object({
              note: Yup.string().required("Field cannot be empty"),
            })}
          >
            {() => (
              <>
                <Form>
                  <FormikTextareaControl
                    label="Why do you want to join this organization?"
                    name="note"
                    size="sm"
                    required={true}
                    placeholder="Enter your text..."
                    styleProps={{ mb: "20px" }}
                  />
                  <Box display="flex" justifyContent="space-between" mx="16px">
                    <Button
                      onClick={onCloseJoinRequestWindow}
                      variant="warning"
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Send request</Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
