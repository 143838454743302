import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useBreakpoint,
  useDisclosure,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { CreatableAutocomplete } from "../creatable-autocomplete/creatable-autocomplete";
import Markdown from "components/Markdown";

export const FunctionSelection = ({
  label,
  tooltipLabel,
  error,
  showError,
  placeholder,
  disabled,
  required,
  selectedFunctionId,
  onChange,
  functions = [],
}) => {
  const {
    isOpen: isDescriptionOpen,
    onToggle: toggleDescription,
    onClose: closeDescription,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const breakpoint = useBreakpoint();
  const selectedFunction = functions?.find(
    (item) => item.id === selectedFunctionId
  );
  const [selected, setSelected] = useState(selectedFunction);
  const mappedFunctions = functions?.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const handleSelect = (data) => {
    const selectedFunction = functions?.find((item) => item.id === data.value);
    if (selectedFunction) {
      setSelected(selectedFunction);
      onChange(selectedFunction);
    } else if (data.isNew) {
      const newOption = {
        id: data.value,
        title: data.label,
        isNew: true,
      };
      setSelected(newOption);
      onChange(newOption);
    }
  };

  const handleChange = () => {
    setSelected(null);
    onChange();
  };

  const onNewFunction = (label) => {
    setIsLoading(true);
    const selectedOptions = {
      label,
      value: label,
      isNew: true,
    };
    setTimeout(() => {
      setIsLoading(false);
      handleSelect(selectedOptions);
    }, 1000);
  };

  useEffect(() => {
    if (selectedFunction?.id && !selected) {
      setSelected(selectedFunction);
    }
  }, [selectedFunction, selected, functions]);

  useEffect(() => {
    if (selectedFunction === undefined) {
      setSelected(null);
    }
  }, [selectedFunction]);

  const handleDetailsClick = (e) => {
    e.preventDefault();
    toggleDescription();
  };
  return (
    <FormControl
      isInvalid={showError}
      isDisabled={disabled}
      isRequired={required}
    >
      <Box display="flex" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {!!tooltipLabel && (
          <Box mb={4} ml={2}>
            <Tooltip label={tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      {selected ? (
        <Box
          display="flex"
          height="45px"
          borderColor="primary"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="8px"
          p="0.125rem 1rem"
        >
          <Box
            flexGrow="1"
            onClick={handleChange}
            display="flex"
            alignItems="center"
            color="primary"
          >
            {selected.title}
          </Box>
          <Popover
            placement={breakpoint === "base" ? "bottom-end" : "bottom"}
            isOpen={isDescriptionOpen}
            onClose={closeDescription}
          >
            {!selected.isNew && (
              <PopoverTrigger>
                <Button variant="link" onClick={handleDetailsClick}>
                  Details
                </Button>
              </PopoverTrigger>
            )}
            <PopoverContent w={{ base: "sm", md: "md" }}>
              <PopoverHeader borderColor="transparent">
                {selected.title}
              </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <Box
                  whiteSpace="pre-wrap"
                  style={{ maxHeight: "280px", overflowY: "auto" }}
                >
                  <Markdown
                    linkTarget="_blank"
                    text={
                      selected.isNew
                        ? "Custom project role"
                        : selected.description
                    }
                  />
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ) : (
        <CreatableAutocomplete
          data-testid="function-selection"
          options={mappedFunctions}
          onChange={handleSelect}
          placeholder={placeholder}
          isDisabled={disabled || isLoading}
          isLoading={isLoading}
          onCreateOption={onNewFunction}
          formatCreateLabel={(inputValue) => {
            return `New role "${inputValue}"`;
          }}
        />
      )}
      {showError && <FormErrorMessage>{error ?? " "}</FormErrorMessage>}
    </FormControl>
  );
};
