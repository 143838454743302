import React, { useCallback, useReducer } from "react";
export const DepartmentContext = React.createContext({
  details: {},
  functions: {},
  dirty: false,
});
export const DepartmentDispatchContext = React.createContext({
  onUpdateDetails: () => undefined,
  onUpdateFunctions: () => undefined,
  onUpdateDirty: () => undefined,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "update-details":
      return { ...state, details: { ...state.details, ...action.payload } };
    case "update-functions":
      return {
        ...state,
        functions: { ...state.functions, ...action.payload },
      };
    case "update-dirty":
      return { ...state, dirty: action.payload };
    default: {
      return state;
    }
  }
};
export const DepartmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    details: {
      name: "",
      description: "",
      stakeHolderUserIds: [],
      isHidden: false,
    },
    functions: {
      functions: [],
    },
    dirty: false,
  });
  const handleDispatch = useCallback((action) => dispatch(action), []);
  const handleUpdateDetails = useCallback(
    (details) => {
      handleDispatch({ type: "update-details", payload: details });
    },
    [handleDispatch]
  );
  const handleUpdateFunctions = useCallback(
    (functions) => {
      handleDispatch({
        type: "update-functions",
        payload: functions,
      });
    },
    [handleDispatch]
  );
  const handleUpdateDirty = useCallback(
    (dirty) => {
      handleDispatch({
        type: "update-dirty",
        payload: dirty,
      });
    },
    [handleDispatch]
  );
  return (
    <DepartmentContext.Provider value={state}>
      <DepartmentDispatchContext.Provider
        value={{
          onUpdateDetails: handleUpdateDetails,
          onUpdateFunctions: handleUpdateFunctions,
          onUpdateDirty: handleUpdateDirty,
        }}
      >
        {children}
      </DepartmentDispatchContext.Provider>
    </DepartmentContext.Provider>
  );
};
export const useDepartmentState = () => {
  const context = React.useContext(DepartmentContext);
  if (context === undefined) {
    throw new Error(
      "useDepartmentState must be used within a DepartmentContext"
    );
  }
  return context;
};
export const useDepartmentDispatch = () => {
  const context = React.useContext(DepartmentDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useDepartmentDispatch must be used within a DepartmentDispatchContext"
    );
  }
  return context;
};
