import React from "react";
import { Box, Spacer, Text } from "@chakra-ui/react";
import { AvatarList } from "../../data-display/avatar-list/avatar-list";
import { DataBox } from "../../data-display/data-box/data-box";
import { ProfileBox } from "../profile-box/profile-box";

export const DepartmentCard = ({
  onCardClick,
  navigationPath,
  image,
  name,
  description,
  members,
  projectsCount,
  boxProps,
}) => {
  // Its allow to use open in new tab handler
  const onclick = (e) => {
    e.preventDefault();
    onCardClick();
  };

  return (
    <Box
      bg="gray.22"
      borderRadius="12px"
      minH="265px"
      w={{ base: "100%", sm: "48.2%", md: "290px" }}
      onClick={onclick}
      cursor={"pointer"}
      display="flex"
      flexDirection="column"
      {...boxProps}
    >
      <a href={navigationPath} rel="noreferrer">
        <Box p="13px 25px 11px" borderBottom="1px solid" borderColor="gray.18">
          <ProfileBox
            color="gray.96"
            name={name}
            avatarUrl={image ?? ""}
            size="sm"
          />
        </Box>
        <Box
          flex="1 0 auto"
          p="14px 25px 18px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box flex="1 0 auto">
            <Text color="white" variant="paragraph">
              {description}
            </Text>
          </Box>
          <DataBox label="Projects" boxProps={{ mr: "45px" }}>
            <Text>{projectsCount || 0} Active</Text>
          </DataBox>
          <Spacer />
          <Box
            display="flex"
            flexDirection={{ base: "row", md: "column" }}
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "space-between", md: "center" }}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <Box
              h="51px"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              {!!members?.length && <AvatarList size="sm" users={members} />}
            </Box>
          </Box>
        </Box>
      </a>
    </Box>
  );
};
