export const Tooltip = {
  baseStyle: {
    px: "24px",
    py: "24px",
    borderRadius: "12px",
    bg: "gray.24",
    lineHeight: "24px",
  },
  variants: {
    compact: {
      px: "10px",
      py: "10px",
      borderRadius: "6px",
    },
  },
};
