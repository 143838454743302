import React, { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContainer } from "components/app-container";
import {
  EmailVerificationExpired,
  EmailWasVerified,
} from "libs/ui-components/src";
import {
  useResendVerifyLinkMutation,
  useVerifyEmailMutation,
} from "shared/mutations/user";
import { APP_PATHS } from "paths";

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { mutate: verifyEmail } = useVerifyEmailMutation();
  const { mutate: resendVerificationLink } = useResendVerifyLinkMutation();
  const {
    isOpen: isVerifiedOpen,
    onOpen: onVerifiedOpen,
    onClose: onVerifiedClose,
  } = useDisclosure();
  const {
    isOpen: isResendLinkOpen,
    onOpen: onResendLinkOpen,
    onClose: onResendLinkClose,
  } = useDisclosure();

  useEffect(() => {
    if (params?.token) {
      verifyEmail(params?.token, {
        onSuccess: () => {
          onVerifiedOpen();
        },
        onError: () => {
          onResendLinkOpen();
        },
      });
    }
  }, [onVerifiedOpen]);

  const onEmailVerifiedClose = () => {
    onVerifiedClose();
    navigate(APP_PATHS.home);
  };

  const onResendVerificationLinkClose = () => {
    onResendLinkClose();
    navigate(APP_PATHS.home);
  };

  const handleResendVerificationLink = (values) => {
    const { email } = values;
    if (email) {
      resendVerificationLink(email, {
        onSuccess: () => {
          onResendVerificationLinkClose();
        },
      });
    }
  };

  return (
    <>
      <AppContainer>
        <AppContainer.Header />
      </AppContainer>
      <EmailWasVerified
        isOpen={isVerifiedOpen}
        onClose={onEmailVerifiedClose}
      />
      <EmailVerificationExpired
        isOpen={isResendLinkOpen}
        onClose={onResendVerificationLinkClose}
        onResendLink={handleResendVerificationLink}
      />
    </>
  );
};
