import React from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Markdown from "components/Markdown";

import { AppContainer } from "components/app-container";
import { generatePath, useParams } from "react-router-dom";
import { RoundedBox } from "libs/ui-components/src";
import { NotFound } from "pages/not-found";
import { APP_PATHS } from "paths";
import { useReadeMyUserSkills } from "shared/queries/users";
import { useOrganizationRole } from "shared/queries/organisation";
import { useUpdateMyUserSkillMutation } from "shared/mutations/user";
import { useProjectDetails } from "shared/queries/project";

export const OutdatedSkillDescription = () => {
  const params = useParams();
  const toast = useToast();
  const { projectId, userSkillId } = params;

  const { data: project = {}, isLoading: isProjectLoading } =
    useProjectDetails(projectId);

  const {
    data: myUserSkill = [],
    isLoading: isMyUserSkillLoading,
    refetch: refetchMyUserSkill,
  } = useReadeMyUserSkills(userSkillId);

  const { data: organizationRole = [], isLoading: isOrganizationRoleLoading } =
    useOrganizationRole(myUserSkill.organizationRoleId);

  const { mutate: updateMyUserSkill } = useUpdateMyUserSkillMutation();

  if (isProjectLoading || isMyUserSkillLoading || isOrganizationRoleLoading) {
    return null;
  }
  const { organizationRoleVersion, organizationRoleReviledVersion } =
    myUserSkill;

  const { isArchived, versions } = organizationRole;

  const firstMatchingVersion = versions
    .slice()
    .reverse()
    .find((item) => new Date(item.date) <= new Date(project.createdAt));

  const { title, description, versionNumber } =
    firstMatchingVersion || versions[0];

  const handlerUpdateMyRoleVersion = () => {
    const data = { id: userSkillId, projectId };
    updateMyUserSkill(data, {
      onSuccess: () => {
        toast({
          position: "top-right",
          status: "success",
          title: "Role version checked as read.",
        });
        refetchMyUserSkill();
      },
    });
  };

  const isOutdated = organizationRoleVersion < versionNumber;
  const isLastProjectVersionRead =
    versionNumber === organizationRoleReviledVersion;

  return (
    <AppContainer>
      <AppContainer.Content>
        <Button
          // mt={4}
          mb={4}
          as="a"
          href={generatePath(APP_PATHS.projectDetails, {
            projectId,
          })}
          variant="link"
          justifyContent="flex-start"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Go to project
        </Button>
        {organizationRole ? (
          <RoundedBox minHeight={`calc(80vh - 100px)`}>
            {isArchived && (
              <Badge colorScheme="red" mx={10} mb={5}>
                Archived
              </Badge>
            )}
            <Flex mx={10} mb={5}>
              <Text as="h1" variant="h1">
                {title}
              </Text>
            </Flex>
            <VStack mx={10} mb={5} alignItems="start">
              <Text as="h3" variant="h3">
                Actual role version in project:
                <Text as="span" variant="h3" color="primary" ml="4px">
                  {versionNumber}
                </Text>
              </Text>
              <Text as="h3" variant="h3">
                Your role version:
                <Text as="span" variant="h3" color="warning" ml="4px">
                  {organizationRoleVersion}
                </Text>
              </Text>
            </VStack>

            <Text variant="h1" mx={10} mb="12px">
              Description
            </Text>
            <RoundedBox
              bg="gray.18"
              display="flex"
              flexDirection="row"
              p={{ base: "8px 16px", md: "8px 16px" }}
              borderRadius="4px"
              mx={10}
              mb="12px"
            >
              <Text whiteSpace="pre-wrap" fontWeight="700">
                <Markdown linkTarget="_blank" text={description} />
              </Text>
            </RoundedBox>

            {isOutdated && !isArchived && (
              <>
                <Flex mx={10} mt="20px" alignItems="center">
                  <Button
                    onClick={handlerUpdateMyRoleVersion}
                    isDisabled={isLastProjectVersionRead}
                    variant="warning"
                  >
                    Familiarized
                  </Button>
                  {isLastProjectVersionRead && (
                    <Text variant="h4" ml="16px" color="primary">
                      You are already familiar with the new role version
                    </Text>
                  )}
                </Flex>
              </>
            )}
          </RoundedBox>
        ) : (
          <NotFound />
        )}
      </AppContainer.Content>
    </AppContainer>
  );
};
