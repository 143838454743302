import { isEmpty } from "lodash";

export const onApiError = (error, toast) => {
  let message;
  const {
    error: { code, fields },
  } = error;
  if (code === "COM-9" && !isEmpty(fields)) {
    message = fields.map((field) => field.message).join("\n");
  } else {
    message = error.message;
  }
  toast({ position: "top-right", status: "error", title: message });
};
