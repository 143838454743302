import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
  ConfirmationAlert,
  FormikInputControl,
  FormikTextareaControl,
  MultipleProfileSelection,
} from "libs/ui-components/src";

const INITIAL_TASK_VALUES = {
  id: "",
  title: "",
  description: "",
};

export const FunctionForm = ({ item, onSubmit, onCancel, users }) => {
  const initialValues = item ? { ...item } : INITIAL_TASK_VALUES;
  const popupDescription =
    "This will send all owners of this role a message and mark their role as out-of-date until they" +
    " re-verified it on a project.\n\nWould you like to continue?";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = (values) => {
    const item = {
      ...values,
      id: values.id === "" ? uuidv4() : values.id,
    };
    onSubmit(item);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        governanceTokenMultiplier: Yup.number(),
        changesSummary: Yup.string(),
        owners: Yup.array().of(Yup.string()),
      })}
    >
      {({ submitForm, values, errors }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            mb="21px"
            gridColumnGap="21px"
          >
            <FormikInputControl
              label="Title"
              name="title"
              type="text"
              required={true}
              placeholder="Prepare Miro Board..."
              disabled={true}
            />
          </Box>
          <MultipleProfileSelection
            label="Owner(s)"
            possibleProfiles={users ?? []}
            selectedUserIds={values.owners}
            error={errors.owners}
            showError={!!errors.owners}
            disabled={true}
            styleProps={{ mb: "21px" }}
          />
          <FormikTextareaControl
            label="Description"
            name="description"
            size="md"
            disabled={true}
            placeholder="Enter task description or task document URL"
            styleProps={{ mb: "21px" }}
          />
          <FormikTextareaControl
            label="Update summary and message to owners"
            name="changesSummary"
            size="sm"
            placeholder="Creative Director"
            disabled={true}
            styleProps={{ mb: "21px" }}
          />
          <Box
            display="flex"
            mt="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={onOpen} variant="link" size="sm">
              Save & broadcast update
            </Button>
            <Button variant="link" size="sm" color="warning" onClick={onCancel}>
              Cancel
            </Button>
            <ConfirmationAlert
              isOpen={isOpen}
              onClose={onClose}
              onConfirm={() => {
                onClose();
                submitForm();
              }}
              description={popupDescription}
              isAlert={false}
              cancelLabel="Cancel"
              confirmLabel="Yes, send update"
            />
          </Box>
        </>
      )}
    </Formik>
  );
};
