import { isEmpty, size } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";

export const Filter = ({
  options,
  onSelect,
  allowEmpty = true,
  showAllMessage = "Show All",
  emptyMessage = "Non selected",
}) => {
  const [selected, setSelected] = useState([]);
  const handleSelectMenuItem = (value) => () => {
    if (selected.includes(value.value)) {
      const newItems = selected.filter((item) => item !== value.value);
      if (isEmpty(newItems) && !allowEmpty) {
        return;
      }
      setSelected(newItems);
      onSelect(newItems);
    } else {
      const newItems = [...selected, value.value];
      setSelected(newItems);
      onSelect(newItems);
    }
  };
  useEffect(() => {
    if (options && !isEmpty(options)) {
      setSelected(options.map((option) => option.value));
      onSelect(options.map((option) => option.value));
    }
  }, []);
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} variant="link" rightIcon={<TriangleDownIcon />}>
        {isEmpty(selected)
          ? emptyMessage
          : size(selected) === size(options)
          ? showAllMessage
          : `${size(selected)} Selected`}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="checkbox" value={selected}>
          {options.map((item, index) => (
            <MenuItemOption
              key={index}
              value={item.value}
              onClick={handleSelectMenuItem(item)}
            >
              {item.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
