import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const EmailWasVerified = ({ isOpen, onClose }) => {
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>You email has been verified!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="25px" color="white" variant="h6">
            Thank you so much for signing up. We hope you enjoy using this tool.
          </Text>

          <Text mb="25px" color="white" variant="h6">
            It’s been created with love and would not have been possible without
            the creative collaboration of dedicated individuals, who live across
            different time zones and represent various demographic backgrounds.
          </Text>

          <Text color="white" variant="h6">
            We hope that this software will help you collaborate, learn and
            create beyond the limits of your imagination.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
