import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";

export const Sorting = ({ options = [], onSelect, selected }) => {
  const selectedOption = options.find((item) => item.value === selected);
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} variant="link" rightIcon={<TriangleDownIcon />}>
        {selectedOption.label}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="checkbox" value={selectedOption.value}>
          {options.map((item, index) => (
            <MenuItemOption
              key={index}
              value={item.value}
              onClick={() => onSelect(item.value)}
            >
              {item.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
