export const Popover = {
  baseStyle: {
    content: {
      bg: "gray.22",
      borderRadius: "6px",
      _focus: {
        boxShadow: "none",
        outline: 0,
      },
    },
    closeButton: {
      top: "8px",
    },
    body: {
      p: "12px",
    },
  },
};
