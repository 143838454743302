import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text } from "@chakra-ui/react";
import { isEmpty, startCase, uniq } from "lodash";

import { AppContainer } from "components/app-container";
import { CardListSkeleton } from "components/loading-skeletons";
import {
  DateFilter,
  Filter,
  ProjectList,
  Sorting,
} from "libs/ui-components/src";
import { STATUSES } from "shared/constants/statuses";
import { useOrganisationList } from "shared/queries/organisation";
import { useProjectsList } from "shared/queries/project";
import { useAllSmartContractList } from "shared/queries/payments";
import { useUsers } from "shared/queries/users";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { APP_PATHS } from "paths";

export const ProjectsList = () => {
  const navigate = useNavigate();
  const { user } = useUserContextState();
  const loggedIn = !isEmpty(user) || !!localStorage.getItem("AUTH_TOKEN");
  const { data: projects = [], isLoading } = useProjectsList(loggedIn);
  const { data: organisations = [] } = useOrganisationList();
  const { data: smartContracts, isLoading: isContractsLoading } =
    useAllSmartContractList();
  const { data: availableAssignee, isLoading: isUsersLoading } = useUsers();

  const handleCreateNewProject = () => {
    navigate(APP_PATHS.newProject);
  };
  const projectOrganizationIds = uniq(
    projects.map((project) => project.organizationId)
  );
  const availableStatuses = STATUSES.map((value) => ({
    value,
    label: startCase(value),
  }));
  const sortingOptions = [
    {
      value: "createdAt",
      label: "Creation date",
    },
    {
      value: "startsAt",
      label: "Start date",
    },
    {
      value: "endsAt",
      label: "End date",
    },
  ];
  const availableOrgs = [
    {
      value: undefined,
      label: "Not in any organization",
    },
    ...organisations
      .filter((org) => projectOrganizationIds.includes(org.id))
      .map((org) => ({
        value: org.id,
        label: org.name,
      })),
  ];
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [selectedSorting, setSelectedSorting] = useState("createdAt");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startDate, setStartDate] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);
  useEffect(() => {
    const filteredProjects = projects
      .filter((project) => {
        const projectStartsDate = DateTime.fromISO(project.startsAt);
        const filteredStartDate = DateTime.fromJSDate(startDate);
        return (
          (!startDate || projectStartsDate >= filteredStartDate) &&
          selectedOrgs.includes(project.organizationId) &&
          selectedStatuses.includes(project.state)
        );
      })
      .sort(
        (project1, project2) =>
          DateTime.fromISO(project2[selectedSorting]) -
          DateTime.fromISO(project1[selectedSorting])
      );
    setFilteredProjects(filteredProjects);
  }, [projects, selectedSorting, startDate, selectedOrgs, selectedStatuses]);
  if (isLoading || isContractsLoading || isUsersLoading || !loggedIn) {
    return <CardListSkeleton isLoading={isLoading} />;
  }
  return (
    <>
      <AppContainer.Header>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          mt="18px"
        >
          <Box display="flex" flex="1 0 auto">
            <Text variant="h1" color="white">
              Explore all projects
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent={{ base: "space-between", md: "center" }}
            width={{ base: "100%", md: "auto" }}
            flexWrap="wrap"
            alignItems="center"
          >
            <Button
              variant="link"
              ml={{ base: 0, md: "32px" }}
              mt={{ base: "32px", md: 0 }}
              onClick={handleCreateNewProject}
            >
              Create a new project
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="flex-start"
          alignItems={{ base: "flex-start", md: "center" }}
          mt="18px"
          gridGap="30px"
        >
          <Box display="flex" gridGap="10px">
            <Text color="white">State:</Text>
            <Filter
              options={availableStatuses}
              onSelect={setSelectedStatuses}
              allowEmpty={false}
            />
          </Box>
          <Box display="flex" gridGap="10px">
            <Text color="white">Start:</Text>
            <DateFilter value={startDate} onChange={setStartDate} />
          </Box>
          <Box display="flex" gridGap="10px">
            <Text color="white">Organizations:</Text>
            <Filter
              options={availableOrgs}
              onSelect={setSelectedOrgs}
              allowEmpty={false}
            />
          </Box>
          <Box display="flex" gridGap="10px">
            <Text color="white">Sort By:</Text>
            <Sorting
              options={sortingOptions}
              onSelect={setSelectedSorting}
              selected={selectedSorting}
            />
          </Box>
        </Box>
      </AppContainer.Header>
      <AppContainer.Content pt="13px">
        <ProjectList
          filtersApplied={
            !!startDate || !isEmpty(selectedOrgs) || !isEmpty(selectedStatuses)
          }
          projects={filteredProjects}
          availableAssignee={availableAssignee}
          smartContracts={smartContracts}
        />
      </AppContainer.Content>
    </>
  );
};
