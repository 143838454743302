import { InfoOutlineIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { RoundedBox, FormikInputControl } from "libs/ui-components/src";
const INITIAL_VALUES = {
  email: "",
  password: "",
};
export const LoginModal = ({
  isOpen,
  onClose,
  onForgotPassword,
  onLogin,
  onWalletLogin,
  onRegister,
}) => {
  const breakpoint = useBreakpoint();
  const handleHandleLogIn = (values) => {
    onLogin(values);
  };
  const [unsafeMod, setUnsafeMode] = useState(false);

  useEffect(() => {
    const keyMap = {};
    document.addEventListener("keydown", (e) => {
      keyMap[e.code] = true;
      if (keyMap["KeyA"] && keyMap["KeyP"] && !unsafeMod) {
        setUnsafeMode(true);
      }
    });
    document.addEventListener("keyup", (e) => {
      keyMap[e.code] = false;
    });
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      autoFocus={false}
      size={unsafeMod ? "4xl" : "lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={1} />
        <ModalBody>
          <Stack direction="column">
            <Stack
              direction={{ md: "row", base: "column" }}
              p={4}
              justifyContent="space-between"
            >
              <Box flex={1}>
                <Text variant="h2" color="white" mb="12px">
                  Log in using email
                </Text>
                <Formik
                  enableReinitialize
                  noValidate
                  initialValues={INITIAL_VALUES}
                  onSubmit={handleHandleLogIn}
                  validationSchema={Yup.object({
                    email: Yup.string().email().required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                >
                  {() => (
                    <Form noValidate>
                      <FormikInputControl
                        type="email"
                        label="Email"
                        name="email"
                        placeholder="john@example.com"
                        styleProps={{ mb: "21px" }}
                      />
                      <FormikInputControl
                        type="password"
                        label="Password"
                        name="password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        styleProps={{ mb: "28px" }}
                      />
                      <Box display="flex" mb="28px">
                        <Button mr="28px" type="submit">
                          Log in
                        </Button>
                        <Button variant="link" onClick={onForgotPassword}>
                          Forgot Password?
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>

              {unsafeMod && (
                <>
                  <Stack
                    direction={{ base: "row", md: "column" }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Divider
                      borderColor="gray.28"
                      orientation={
                        breakpoint === "sm" ? "horizontal" : "vertical"
                      }
                    />
                    <Text color="gray.28" padding="2">
                      OR
                    </Text>
                    <Divider
                      borderColor="gray.28"
                      orientation={
                        breakpoint === "sm" ? "horizontal" : "vertical"
                      }
                    />
                  </Stack>
                  <Stack orientation="vertical" flex={1}>
                    <Text variant="h2" color="white" mb="12px">
                      Log in with wallet
                    </Text>
                    <RoundedBox
                      p={{ base: "22px 20px" }}
                      bgColor="gray.24"
                      mb={3}
                      display="flex"
                      direction="row"
                      alignItems="center"
                    >
                      <InfoOutlineIcon w={6} h={6} mr={4} color="white" />
                      <Text color="white">
                        Use the wallet address that is linked to your Deep Teams
                        account
                      </Text>
                    </RoundedBox>
                    <Box display="flex" mb="28px">
                      <Button
                        data-testid="new-account-btn"
                        onClick={onWalletLogin}
                      >
                        Connect wallet
                      </Button>
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider borderColor="gray.28" />
            <Stack
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              pt={4}
            >
              <Text color="white" variant="h3">
                Don’t have an account?
              </Text>
              <Button
                variant="link"
                data-testid="new-account-btn"
                onClick={onRegister}
              >
                <Text variant="h3" color="primary">
                  Create a new account →
                </Text>
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
