export const Text = {
  baseStyle: {
    color: "gray.79",
  },
  variants: {
    h1: {
      fontSize: { base: "26px", md: "28px" },
      fontWeight: "700",
      lineHeight: "35.42px",
    },
    h2: {
      fontSize: "22px",
      fontWeight: "700",
      lineHeight: "27.83px",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "22.77px",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "20.24px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "20.24px",
    },
    h6: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20.24px",
    },
    h8: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17.71px",
    },
    helper: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "17.71px",
      letterSpacing: "0.5px",
    },
    button: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "17.71px",
    },
    "nav.link": {
      fontSize: "16px",
      fontWeight: "450",
      lineHeight: "20.24px",
      letterSpacing: "0.4px",
    },
    paragraph: {
      fontSize: "16px",
      fontWeight: "450",
      lineHeight: "26.5px",
    },
    "paragraph.l": {
      fontSize: "16px",
      fontWeight: "450",
      lineHeight: "24px",
    },
    bold: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "26.5px",
    },
    small: {
      fontSize: "14px",
      fontWeight: "450",
      lineHeight: "18px",
    },
    "x.small": {
      fontSize: "12px",
      fontWeight: "450",
      lineHeight: "18px",
    },
    "xx.small": {
      fontSize: "11px",
      fontWeight: "450",
      lineHeight: "13.91px",
    },
    blockquote: {
      fontSize: "16px",
      fontWeight: "450",
      lineHeight: "26.5px",
      pl: "16px",
      borderLeft: "4px solid",
      borderColor: "gray.58",
    },
  },
  defaultProps: {
    variant: "paragraph",
  },
};
