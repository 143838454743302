import React, { useState, useEffect } from "react";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isEmpty, uniq } from "lodash";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import {
  ConfirmationAlert,
  PromptIfDirty,
  ReviewFunctionSet,
} from "libs/ui-components/src";
import { useUsers, useUserSkills } from "shared/queries/users";
import { useOrganisationFunctions } from "shared/queries/organisation";
import {
  useProjectDetails,
  useRoleUpdateRequests,
} from "shared/queries/project";
import {
  useConfirmOrganizationRoleUpdateRequestMutation,
  useDeclineOrganizationRoleUpdateRequestMutation,
} from "shared/mutations/project";
import { APP_PATHS } from "paths";

export const ProjectFunctionRequestReview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { projectId } = params;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openWarning, setOpenWarning] = useState(false);
  const [functionsToSave, setFunctionsToSave] = useState({});
  const [requestedFunctions, setRequestedFunctions] = useState([]);

  const { data: project, isLoading: isProjectLoading } =
    useProjectDetails(projectId);
  const { data: requests = [], isLoading: isRequestsLoading } =
    useRoleUpdateRequests(project?.organizationId, project?.departmentId);
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkills(uniq(requests?.map((item) => item.createdBy)));
  const { data: users, isLoading: isUserLoading } = useUsers();
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationFunctions(
      project?.organizationId ? [project.organizationId] : []
    );
  const { mutateAsync: onConfirmRequest } =
    useConfirmOrganizationRoleUpdateRequestMutation();
  const { mutateAsync: onDeclineRequest } =
    useDeclineOrganizationRoleUpdateRequestMutation();

  useEffect(() => {
    if (project && !isEmpty(functions) && !isEmpty(requests)) {
      const requestedFunctionIds = requests.map(
        (item) => item.organizationRoleId
      );
      const requestedRoles = project.roles
        ?.filter((role) =>
          requestedFunctionIds.includes(role.organizationRoleId)
        )
        .map((role) => role.organizationRoleId);
      setRequestedFunctions(
        functions
          .filter(
            (item) =>
              requestedRoles.includes(item.id) &&
              requests.find(
                (request) =>
                  request.organizationRoleVersion === item.actualVersion
              )
          )
          .map((item) => {
            const request = requests.find(
              (request) =>
                request.organizationRoleId === item.id &&
                request.organizationRoleVersion === item.actualVersion
            );
            return {
              ...item,
              changesSummary: request.changesSummary,
              createdAt: request.createdAt,
              createdBy: request.createdBy,
              requestId: request.id,
              description: request.description,
              owners: userSkills
                .filter((skill) => skill.organizationRoleId === item.id)
                .map((skill) => skill.userId),
            };
          })
      );
    }
  }, [project, functions, requests, userSkills]);

  const handleSubmit = (values) => {
    if (openWarning) {
      setFunctionsToSave(values.functions);
      onOpen();
    } else {
      confirmSubmit(values.functions);
    }
  };
  const confirmSubmit = async (values) => {
    const toReject = values.filter((item) => item.isRejected);
    const toApprove = values.filter((item) => item.isApproved);
    const mutations = [];
    toReject.forEach((item) =>
      mutations.push(onDeclineRequest(item.requestId))
    );
    toApprove.forEach((item) =>
      mutations.push(onConfirmRequest(item.requestId))
    );
    await Promise.all(mutations).then(() => {
      navigate(
        generatePath(APP_PATHS.projectDetails, { projectId: project.id })
      );
    });
  };
  const confirmLeave = () => {
    confirmSubmit(functionsToSave);
  };
  if (
    isProjectLoading ||
    isUserLoading ||
    isFunctionsLoading ||
    isRequestsLoading ||
    isUserSkillsLoading
  ) {
    return null;
  }
  return (
    <AppContainer.Content>
      <Box>
        <ConfirmationAlert
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={confirmLeave}
          description="All unsaved changes will be lost, are you sure you want to leave?"
          isAlert={false}
          cancelLabel="Cancel"
          confirmLabel="Discard and Leave"
        />
        <Text variant="h2" color="white" mb="25px">
          Pending role update requests
        </Text>
        <Formik
          enableReinitialize
          initialValues={{ functions: requestedFunctions }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            functions: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required("Title is required"),
                description: Yup.string().required("Description is required"),
                governanceTokenMultiplier: Yup.number(),
                changesSummary: Yup.string(),
                owners: Yup.array().of(Yup.string()),
              })
            ),
          })}
        >
          {({ setFieldValue, values, dirty, submitCount }) => {
            return (
              <Form id="pplAndSkillsForm" noValidate>
                <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                <Box mb="25px">
                  <ReviewFunctionSet
                    functions={values.functions}
                    onChange={(e) => setFieldValue("functions", e)}
                    users={users}
                    setOpenWarning={setOpenWarning}
                  />
                </Box>
                <Box
                  display="flex"
                  mt="32px"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Button
                    disabled={!dirty}
                    type="submit"
                    data-testid="submit-btn"
                  >
                    Submit request
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppContainer.Content>
  );
};
