import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { isEqual, keyBy } from "lodash";
import { Box, Text, Switch, VStack } from "@chakra-ui/react";

import {
  FormikInputControl,
  FormikTextareaControl,
} from "../../form/formik-wrappers";
import { FunctionSelection } from "../../form/function-selection/function-selection";
import { ProfileSelection } from "../../form/profile-selection/profile-selection";

export const RoleForm = ({
  index,
  role,
  disabled,
  setFieldValue,
  functions,
  userSkills,
  availableAssignee,
  showShouldReieveSkill = false,
  simpleRole = false,
  symbols,
  initialRoleData,
  currentFunctionInfo,
}) => {
  const prevFunctionsRef = useRef(functions);
  const [functionList, setFunctionList] = useState(functions);
  const [profileDescriptions, setDescriptions] = useState([]);

  const [unitCurrencyTokenPriceDB, setUnitCurrencyTokenPriceDB] = useState();
  const [unitGovernanceTokenPriceDB, setUnitGovernanceTokenPriceDB] =
    useState();

  const parentNode = `roles.${index}`;

  const idField = useField(`${parentNode}._id`);
  const idMeta = idField?.[1];
  const [_, parentMeta] = useField(parentNode); // eslint-disable-line
  const touched = parentMeta?.touched || idMeta?.touched;
  useEffect(() => {
    if (
      !role.organizationRoleId &&
      !!role.id &&
      !functionList.find((item) => item.id === role.id)
    ) {
      setFunctionList([
        ...functionList,
        {
          id: role.title,
          title: role.title,
          isNew: true,
        },
      ]);
    }
    if (!isEqual(prevFunctionsRef.current, functions)) {
      setFunctionList([
        ...functions,
        ...functionList.filter((item) => item.isNew),
      ]);
      prevFunctionsRef.current = functions;
    }
  }, [functions, role]);
  useEffect(() => {
    const descriptions = keyBy(
      userSkills
        .filter((skill) => skill.organizationRoleId === role.organizationRoleId)
        .map((skill) => ({
          id: skill.userId,
          color: skill.isOutdated ? "warning" : "primary",
          text: skill.isOutdated ? "Role out of date" : "Role owner",
        })),
      "id"
    );
    setDescriptions(descriptions);
  }, [userSkills, role.organizationRoleId]);

  const unitOfPrice = currentFunctionInfo?.unitOfPrice;
  const unitCurrencyTokenPrice = currentFunctionInfo?.unitCurrencyTokenPrice;
  const unitGovernanceTokenPrice =
    currentFunctionInfo?.unitGovernanceTokenPrice;

  const isRoleInitial =
    initialRoleData?._id === role?._id &&
    (initialRoleData?._id !== undefined || role?._id !== undefined);

  const initialPriceAmount = initialRoleData?.priceUnitsAmount;
  const initialCurrency = initialRoleData?.currencyTokenCompensation;
  const initialGovernance = initialRoleData?.governanceTokenCompensation;

  // Initial data from saved role in DB
  useEffect(() => {
    if (isRoleInitial && initialPriceAmount && initialGovernance) {
      setUnitGovernanceTokenPriceDB(initialGovernance / initialPriceAmount);
    }
    if (isRoleInitial && initialPriceAmount && initialCurrency) {
      setUnitCurrencyTokenPriceDB(initialCurrency / initialPriceAmount);
    }

    if (isRoleInitial && !initialPriceAmount) {
      setUnitCurrencyTokenPriceDB(unitCurrencyTokenPrice);
      setUnitGovernanceTokenPriceDB(unitGovernanceTokenPrice);
    }
  }, []);

  useEffect(() => {
    if (
      (!isRoleInitial && role.organizationRoleId) ||
      (isRoleInitial &&
        !role.priceUnitsAmount &&
        !role.currencyTokenCompensation &&
        !role.governanceTokenCompensation)
    ) {
      setUnitCurrencyTokenPriceDB(unitCurrencyTokenPrice);
      setUnitGovernanceTokenPriceDB(unitGovernanceTokenPrice);
    }
    // Resetting fields when changing roles
    if (!role.organizationRoleId && !role.title) {
      setFieldValue(`${parentNode}.priceUnitsAmount`, "");
      setFieldValue(`${parentNode}.currencyTokenCompensation`, "");
      setFieldValue(`${parentNode}.governanceTokenCompensation`, "");
    }
  }, [role]);

  // If changed priceUnitsAmount or price from role => recalculate total amount
  useEffect(() => {
    if (
      role?.priceUnitsAmount &&
      role.organizationRoleId &&
      unitCurrencyTokenPriceDB
    ) {
      let amountCurrencyToken =
        role.priceUnitsAmount * unitCurrencyTokenPriceDB;
      amountCurrencyToken = Number(amountCurrencyToken.toFixed(2));
      setFieldValue(
        `${parentNode}.currencyTokenCompensation`,
        amountCurrencyToken
      );
    }

    if (
      role?.priceUnitsAmount &&
      role.organizationRoleId &&
      unitGovernanceTokenPriceDB
    ) {
      let amountGovernanceToken =
        role?.priceUnitsAmount * unitGovernanceTokenPriceDB;
      amountGovernanceToken = Number(amountGovernanceToken.toFixed(2));
      setFieldValue(
        `${parentNode}.governanceTokenCompensation`,
        amountGovernanceToken
      );
    }

    if (
      (!role.priceUnitsAmount &&
        role.organizationRoleId &&
        initialCurrency !== role.currencyTokenCompensation) ||
      (!role.priceUnitsAmount && initialPriceAmount !== role.priceUnitsAmount)
    ) {
      setFieldValue(`${parentNode}.currencyTokenCompensation`, "");
    }

    if (
      (!role.priceUnitsAmount &&
        role.organizationRoleId &&
        initialGovernance !== role.governanceTokenCompensation) ||
      (!role.priceUnitsAmount && initialPriceAmount !== role.priceUnitsAmount)
    ) {
      setFieldValue(`${parentNode}.governanceTokenCompensation`, "");
    }
  }, [
    role.priceUnitsAmount,
    role.organizationRoleId,
    unitCurrencyTokenPriceDB,
    unitGovernanceTokenPriceDB,
  ]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        mb="32px"
        gridGap="15px"
      >
        <FunctionSelection
          label="Title"
          functions={functionList}
          selectedFunctionId={role.organizationRoleId || role.title}
          error={idMeta?.error}
          showError={touched || idMeta?.error}
          required={true}
          onChange={(value) => {
            if (value?.isNew) {
              setFieldValue(`${parentNode}.id`, value?.id);
              setFieldValue(`${parentNode}.title`, value?.title);
            } else {
              setFieldValue(`${parentNode}.title`, value?.title);
              setFieldValue(`${parentNode}.organizationRoleId`, value?.id);
              setFieldValue(`${parentNode}.unitOfPrice`, value?.unitOfPrice);
            }
          }}
        />
        {!simpleRole && (
          <ProfileSelection
            label="Assignee"
            possibleProfiles={availableAssignee ?? []}
            selectedUserId={role.assigneeUserId}
            disabled={disabled}
            onChange={(value) =>
              setFieldValue(`${parentNode}.assigneeUserId`, value?.id)
            }
            profileDescriptions={profileDescriptions}
          />
        )}
      </Box>
      <FormikTextareaControl
        label="Extra details"
        type="text"
        parentName={parentNode}
        name={`${parentNode}.tasksDescription`}
        placeholder="Enter tasks"
        styleProps={{ mb: "15px" }}
      />
      {!simpleRole && (
        <>
          <Box mt="8px" display="flex" alignItems="center">
            <Text variant="h6" color="white" mr="8px">
              Token Amount
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
          >
            {unitOfPrice && (
              <FormikInputControl
                parentName={parentNode}
                name={`${parentNode}.priceUnitsAmount`}
                type="number"
                required={false}
                placeholder="0"
                inputProps={{
                  min: 0,
                }}
                styleProps={{ mr: "15px" }}
                disabled={disabled}
                InputRightElement={
                  <VStack
                    ml="8px"
                    mr="30px"
                    alignItems="flex-start"
                    spacing="4px"
                  >
                    <Text variant="h6" color="white" w="75px">
                      Amount of
                    </Text>
                    <Text variant="h6" color="white">
                      {unitOfPrice}s
                    </Text>
                  </VStack>
                }
              />
            )}
            <FormikInputControl
              parentName={parentNode}
              name={`${parentNode}.currencyTokenCompensation`}
              type="number"
              required={false}
              placeholder="0"
              inputProps={{
                min: 0,
              }}
              styleProps={{ mr: "15px" }}
              disabled={disabled}
              InputRightElement={
                <Text variant="h6" color="white" ml="8px" mr="30px">
                  {symbols.currencyTokenContract}
                </Text>
              }
            />
            <FormikInputControl
              parentName={parentNode}
              name={`${parentNode}.governanceTokenCompensation`}
              type="number"
              placeholder="0"
              inputProps={{
                min: 0,
              }}
              InputRightElement={
                <Text variant="h6" color="white" ml="8px" mr="30px">
                  {symbols.governanceTokenContract}
                </Text>
              }
            />
          </Box>
          {!functionList.find((item) => item.id === role._id) && (
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={{ base: "column", md: "row" }}
              mt="32px"
              gridGap="15px"
            >
              {showShouldReieveSkill && (
                <Switch
                  name={`${parentNode}.shouldRecieveSkill`}
                  onChange={(event) =>
                    setFieldValue(
                      `${parentNode}.shouldRecieveSkill`,
                      event.target.checked
                    )
                  }
                  colorScheme="green"
                  defaultIsChecked={role.shouldRecieveSkill}
                >
                  <Text variant="h6" color="white" mt="10px">
                    Issue/update role attestation{" "}
                  </Text>
                </Switch>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};
