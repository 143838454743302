import React from "react";
import {
  createSearchParams,
  generatePath,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { AppContainer } from "components/app-container";
import { useProjectsList } from "shared/queries/project";
import {
  useOrganisationDetails,
  useOrganisationTeamFunctions,
} from "shared/queries/organisation";
import { useAllSmartContractList } from "shared/queries/payments";
import { useUsers, useUserSkillsByOrganization } from "shared/queries/users";
import { CardListSkeleton } from "components/loading-skeletons";
import { useUserContextState } from "shared/contexts/user-context-provider";
import {
  OverviewSidebar,
  ProfileBox,
  ProjectList,
} from "libs/ui-components/src";
import { makeFriendly, pluralise } from "libs/utils/src";
import { APP_PATHS } from "paths";
import { OrganisationRolesTab } from "components/organisation-roles-tab";
import {
  EMPTY_SKILLS_TAB_TEXT,
  EMPTY_SKILLS_TAB_TEXT_OWNER,
} from "shared/constants/empty-text";

export const DepartmentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { organizationId, departmentId } = params;
  const { user } = useUserContextState();
  const loggedIn = !isEmpty(user) || !!localStorage.getItem("AUTH_TOKEN");
  const { data: projects, isLoading: isProjectsLoading } = useProjectsList(
    loggedIn,
    {
      organizationIds: organizationId,
      departmentIds: departmentId,
    }
  );
  const { data: smartContracts, isLoading: isContractsLoading } =
    useAllSmartContractList();
  const { data: users, isLoading: isUsersLoading } = useUsers();
  const { data: functions = [], isLoading: isFunctionsLoading } =
    useOrganisationTeamFunctions([organizationId], departmentId);
  const { data: userSkills = [], isLoading: isUserSkillsLoading } =
    useUserSkillsByOrganization(organizationId);
  const { data: organisation, isLoading } =
    useOrganisationDetails(organizationId);
  const handleEditDepartment = () => {
    navigate(
      generatePath(APP_PATHS.editDepartment, { organizationId, departmentId })
    );
  };
  const handleEditFunctions = () => {
    navigate(
      generatePath(APP_PATHS.editDepartmentFunctions, {
        organizationId,
        departmentId,
      })
    );
  };
  const handleCreateNewProject = () => {
    navigate({
      pathname: APP_PATHS.newProject,
      search: `?${createSearchParams({ organizationId, departmentId })}`,
    });
  };
  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };
  if (
    isLoading ||
    isProjectsLoading ||
    isContractsLoading ||
    isUsersLoading ||
    !loggedIn
  ) {
    return <CardListSkeleton isLoading={isLoading} />;
  }
  const { createdBy, departments = [], userIds = [] } = { ...organisation };
  const department = departments.find(
    (department) => department._id === departmentId
  );
  const {
    name,
    description,
    documentationUrl,
    stakeHolderUserIds,
    projectManagersUserIds,
    goals = [],
    hazards = [],
  } = {
    ...department,
  };
  const teamLeaders = users?.filter((user) =>
    stakeHolderUserIds?.includes(user.id)
  );
  const isAdmin = createdBy === user?.id;
  const isMember = userIds?.includes(user?.id) || isAdmin;
  const projectManagersUsers = users?.filter((user) => {
    return projectManagersUserIds?.includes(user.id);
  });
  const isTeamLeader = stakeHolderUserIds?.includes(user?.id);
  const canCreateProject = userIds.includes(user.id) || createdBy === user.id;
  const filteredFunctions = functions.filter((f) => {
    return f.isArchived === false;
  });
  return (
    <AppContainer.Content>
      <Box display="flex" flexDirection={{ base: "column", md: "row" }}>
        <OverviewSidebar
          loading={isLoading || isUsersLoading}
          title={name || ""}
          description={description}
          goals={goals}
          hazards={hazards}
          showAvatar={false}
          showBackButton={true}
          backButtonProps={{
            path: generatePath(APP_PATHS.organisationDetails, {
              organizationId,
            }),
            title: "All teams",
          }}
          departmentId={departmentId}
        >
          {canCreateProject && (
            <Box mb="35px">
              <Button onClick={handleCreateNewProject}>
                Create a new project
              </Button>
            </Box>
          )}
          {teamLeaders?.length > 0 && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Text vacolor="white" mb="5px" mt="5px">
                {pluralise("Team Lead", teamLeaders.length)}
              </Text>
            </>
          )}
          {teamLeaders?.map((teamLeader) => (
            <Flex mt="15px" mb="15px" flexWrap="wrap" key={teamLeader.id}>
              <ProfileBox
                size="xs"
                userId={teamLeader.id}
                onClick={handleOpenUserProfile}
                name={`${teamLeader.firstName} ${teamLeader.lastName}`}
                avatarUrl={teamLeader.avatarUrl}
              />
            </Flex>
          ))}
          {projectManagersUsers?.length > 0 && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Text vacolor="white" mb="5px" mt="5px">
                {pluralise("Project Manager", projectManagersUsers.length)}
              </Text>
            </>
          )}
          {projectManagersUsers?.map((teamManager) => (
            <Flex mt="15px" mb="15px" flexWrap="wrap" key={teamManager.id}>
              <ProfileBox
                size="xs"
                userId={teamManager.id}
                onClick={handleOpenUserProfile}
                name={`${teamManager.firstName} ${teamManager.lastName}`}
                avatarUrl={teamManager.avatarUrl}
              />
            </Flex>
          ))}
          {documentationUrl && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Button
                  as="a"
                  href={documentationUrl}
                  target="_blank"
                  variant="link"
                  rightIcon={<ExternalLinkIcon />}
                  mb="30px"
                >
                  Working files
                </Button>
              </Box>
            </>
          )}
          {(isTeamLeader || isAdmin) && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Button variant="link" onClick={handleEditDepartment}>
                Edit team
              </Button>
            </>
          )}
          {(isTeamLeader || isAdmin) && (
            <>
              <Divider borderColor="gray.28" mt="15px" mb="15px" />
              <Button variant="link" onClick={handleEditFunctions}>
                Edit roles
              </Button>
            </>
          )}
        </OverviewSidebar>
        <Box
          width="100%"
          ml={{ base: "0", md: "30px" }}
          mt={{ base: "30px", md: "0" }}
        >
          <Tabs>
            <TabList>
              <Tab>Projects {makeFriendly(projects?.length)}</Tab>
              <Tab>Roles {makeFriendly(filteredFunctions?.length)}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <ProjectList
                  isDepartmentList
                  name={department?.name}
                  projects={projects}
                  availableAssignee={users}
                  smartContracts={smartContracts}
                  splitCompleted
                />
              </TabPanel>
              <TabPanel>
                <OrganisationRolesTab
                  isLoading={
                    isFunctionsLoading ||
                    isUserSkillsLoading ||
                    isProjectsLoading ||
                    isContractsLoading ||
                    isUsersLoading
                  }
                  emptyText={
                    isMember
                      ? EMPTY_SKILLS_TAB_TEXT_OWNER
                      : EMPTY_SKILLS_TAB_TEXT.replace("{owner}", name ?? "")
                  }
                  functions={functions}
                  userSkills={userSkills}
                  users={users}
                  organizationName={name}
                  canEditRole={isTeamLeader || isAdmin}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </AppContainer.Content>
  );
};
