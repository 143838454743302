import { ethers } from "ethers";
import abi from "erc-20-abi";
import { convertStringToHex } from "../helpers/bignumber";
import { ETHEREUM } from "../helpers/chains";

export const getProvider = async (provider, chainId = ETHEREUM.chain_id) => {
  if (provider.networkVersion !== `${chainId}`) {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${convertStringToHex(chainId)}` }],
    });
  }
  return new ethers.providers.Web3Provider(provider, chainId);
};

export const requestAccounts = async (provider) => {
  return await provider.send("eth_requestAccounts", []);
};

export const sendTransaction = async (
  baseProvider,
  chainId,
  contract,
  to,
  value,
  decimals
) => {
  const provider = await getProvider(baseProvider, chainId);
  const usdcWhale = await provider.getSigner();
  const USDC = new ethers.Contract(contract, abi, provider);
  return await USDC.connect(usdcWhale).transfer(
    to,
    ethers.utils.parseUnits(value, decimals)
  );
};

export const signMessage = async (baseProvider, message) => {
  const provider = await getProvider(baseProvider);
  const signer = provider.getSigner();
  const signature = await signer.signMessage(message);
  return {
    signature,
    digest: message,
  };
};
