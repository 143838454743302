import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { identity, omitBy, isEmpty, pickBy } from "lodash";

import { AppContainer } from "components/app-container";
import { ConfirmationAlert, PromptIfDirty } from "libs/ui-components/src";
import { useOrganisationDetails } from "shared/queries/organisation";
import {
  useDeleteDepartmentMutation,
  useUpdateDepartmentMutation,
} from "shared/mutations/department";
import { useUserContextState } from "shared/contexts/user-context-provider";
import { APP_PATHS } from "paths";
import {
  DepartmentProvider,
  useDepartmentDispatch,
  useDepartmentState,
} from "../DepartmentContext";
import { DetailsStep } from "../steps/DetailsStep";

const EditDepartmentComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUserContextState();
  const { organizationId, departmentId } = params;
  const { data: organisation, isLoading: isOrganizationLoading } =
    useOrganisationDetails(organizationId);
  const { dirty } = useDepartmentState();
  const { onUpdateDetails, onUpdateDirty } = useDepartmentDispatch();
  const { createdBy, departments = [] } = { ...organisation };
  const department = departments?.find(
    (department) => department._id === departmentId
  );
  const { name, stakeHolderUserIds } = { ...department };
  const isAdmin = createdBy === user?.id;
  const isTeamLeader = stakeHolderUserIds?.includes(user?.id);
  useEffect(() => {
    if (department) {
      const departmentDefaultData = { ...department };
      onUpdateDetails(departmentDefaultData);
    }
  }, [department]);
  const { mutate: onUpdateDepartment } = useUpdateDepartmentMutation(
    departmentId,
    organizationId
  );
  const { mutate: onDeleteDepartment } = useDeleteDepartmentMutation(
    departmentId,
    organizationId,
    {
      onSuccess: () => {
        navigate(
          generatePath(APP_PATHS.organisationDetails, {
            organizationId,
          })
        );
      },
    }
  );
  const handleSubmitDepartment = async (details) => {
    // TODO: Check why we required this
    details = omitBy(details, isEmpty);
    const data = {
      ...pickBy(details, identity),
    };
    data.isHidden = details.isHidden;
    onUpdateDirty(false);
    const mutations = [];
    if (isAdmin || isTeamLeader) {
      mutations.push(onUpdateDepartment(data));
    }

    await Promise.all(mutations).then(() => {
      navigate(
        generatePath(APP_PATHS.departmentDetails, {
          departmentId,
          organizationId,
        })
      );
    });
  };
  const handleDeleteDepartment = () => {
    onDeleteDepartment();
  };
  const {
    isOpen: isOpenDeleteWarning,
    onClose: onCloseDeleteWarning,
    onOpen: onOpenDeleteWarning,
  } = useDisclosure();

  if (isOrganizationLoading) {
    return null;
  }
  const steps = [];

  if (isAdmin) {
    steps.push({ page: 1, name: "details", label: "Details" });
    // steps.push({ page: 2, name: "functions", label: "Roles" });
  } else if (isTeamLeader) {
    steps.push({ page: 1, name: "details", label: "Details" });
    // steps.push({ page: 1, name: "functions", label: "Roles" });
  }
  if (isEmpty(steps)) {
    return (
      <>
        <AppContainer.Header>
          <Button
            mt={4}
            as="a"
            href={generatePath(APP_PATHS.departmentDetails, {
              organizationId,
              departmentId,
            })}
            variant="link"
            leftIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bg="primary"
              >
                <ArrowBackIcon color="#2D3A4A" />
              </Box>
            }
          >
            Back to team
          </Button>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
            alignItems={{ base: "flex-start", md: "center" }}
            mt="18px"
          >
            <Box display="flex" flex="1 0 auto">
              <Text variant="h1" color="white">
                Edit “{name}”
              </Text>
            </Box>
          </Box>
        </AppContainer.Header>
        <AppContainer.Content isDetails pt="13px">
          <Text>You do not have permissions to edit team</Text>
        </AppContainer.Content>
      </>
    );
  }

  return (
    <>
      <AppContainer.Header>
        <Button
          mt={4}
          as="a"
          href={generatePath(APP_PATHS.departmentDetails, {
            organizationId,
            departmentId,
          })}
          variant="link"
          leftIcon={
            <Box
              height="16px"
              width="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bg="primary"
            >
              <ArrowBackIcon color="#2D3A4A" />
            </Box>
          }
        >
          Back to team
        </Button>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          mt="18px"
        >
          <Box display="flex" flex="1 0 auto">
            <Text variant="h1" color="white">
              Edit “{name}”
            </Text>
          </Box>
        </Box>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt="13px">
        <PromptIfDirty showPrompt={dirty} />
        <DetailsStep
          organisation={organisation}
          onNext={handleSubmitDepartment}
          onOpenDeleteWarning={onOpenDeleteWarning}
          isEdit={true}
        />
        <ConfirmationAlert
          isOpen={isOpenDeleteWarning}
          onClose={onCloseDeleteWarning}
          onConfirm={handleDeleteDepartment}
          title={`Are you sure you want to delete ${department?.name}?`}
        />
      </AppContainer.Content>
    </>
  );
};

export const EditDepartment = () => (
  <DepartmentProvider>
    <EditDepartmentComponent />
  </DepartmentProvider>
);
