export const EMPTY_ACTIVE_PROJECTS_TAB_TEXT =
  "{name} does not seem to have any active projects yet.";
export const EMPTY_COMPLETED_PROJECTS_TAB_TEXT =
  "{name} does not seem to have any completed projects yet.";
export const EMPTY_PROJECTS_TEXT = "There are no projects created yet.";
export const NO_PROJECTS_FOUND_TEXT = "No projects matching filters found.";
export const EMPTY_TEAMS_TAB_TEXT =
  "{name} does not seem to have any teams yet.";
export const EMPTY_SKILLS_TAB_TEXT =
  "{owner} does not seem to have any roles yet.";
export const EMPTY_SKILLS_TAB_TEXT_OWNER =
  "You do not seem to have any roles yet.";
export const EMPTY_SKILLS_TAB_TEXT_TEAM =
  "This team does not seem to have any roles yet.";
export const EMPTY_SKILLS_TAB_TEXT_ORGANIZATION =
  "This organization does not seem to have any roles yet.";
export const EMPTY_COWORKERS_TAB_TEXT =
  "{owner} does not seem to have any coworkers yet.";
export const EMPTY_COWORKERS_TAB_TEXT_OWNER =
  "You do not seem to have any coworkers yet.";
