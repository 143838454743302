import React from "react";
import { Box, CloseButton, Text } from "@chakra-ui/react";

import { RoundedBox } from "libs/ui-components/src";
import { OneInputFormObject } from "./one-input-form-object";

export const OneInputListObject = ({
  isReadOnly,
  onDeleteCost,
  fieldName,
  title,
  fieldValues,
  setFieldValue,
  ...props
}) => {
  return fieldValues.map((fieldValue, index) => {
    return (
      <RoundedBox key={index} mb="10px" {...props}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="15px"
        >
          <Text color="white" variant="h4">
            {title} #{(index || 0) + 1}
          </Text>

          <CloseButton
            isDisabled={isReadOnly}
            onClick={() => onDeleteCost(index)}
          />
        </Box>
        <OneInputFormObject
          index={index}
          fieldName={fieldName}
          title={title}
          disabled={isReadOnly}
          fieldValue={fieldValue}
          setFieldValue={setFieldValue}
        />
      </RoundedBox>
    );
  });
};
