import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { formatDateL } from "libs/utils/src";
import { RoundedBox, ProfileBox } from "libs/ui-components/src";

import { APP_PATHS } from "paths";
import { generatePath, useNavigate } from "react-router-dom";
import { PopupMdDescription } from "../../modals/md-description/md-description";

export const FunctionsTable = ({
  functions,
  departments,
  users,
  canEditRole,
}) => {
  const shouldShowCostPerRole = functions.some(
    (item) => item.unitOfPrice && item.unitCurrencyTokenPrice
  );
  return (
    <TableContainer bgColor="gray.22" borderRadius="12px" p="8px 12px">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th
              color="gray.58"
              textTransform="none"
              fontSize="md"
              fontWeight="450"
              letterSpacing="0"
              borderColor="gray.28"
            >
              Title
            </Th>
            {!isEmpty(departments) && (
              <Th
                color="gray.58"
                textTransform="none"
                fontSize="md"
                fontWeight="450"
                letterSpacing="0"
                borderColor="gray.28"
              >
                Team
              </Th>
            )}
            <Th
              color="gray.58"
              textTransform="none"
              fontSize="md"
              fontWeight="450"
              letterSpacing="0"
              borderColor="gray.28"
            >
              Owners
            </Th>
            <Th borderColor="gray.28" />
            {shouldShowCostPerRole && (
              <Th
                color="gray.58"
                textTransform="none"
                fontSize="md"
                fontWeight="450"
                letterSpacing="0"
                borderColor="gray.28"
              >
                Cost per Role
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {functions?.map((item) => (
            <CollapsableFunctionsTableRow
              key={item.id}
              title={item.title}
              organizationId={item.organizationId}
              departmentId={item.organizationDepartmentId}
              organizationRoleId={item.id}
              date={
                item.versions.find(
                  (version) => version.versionNumber === item.actualVersion
                )?.date
              }
              description={item.description}
              departments={departments}
              owners={item.owners}
              users={users}
              canEditRole={canEditRole}
              goals={item?.goals}
              hazards={item?.hazards}
              unitOfPrice={item?.unitOfPrice}
              unitCurrencyTokenPrice={item?.unitCurrencyTokenPrice}
              shouldShowCostPerRole={shouldShowCostPerRole}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const CollapsableFunctionsTableRow = ({
  title,
  date,
  description,
  departments,
  organizationId,
  departmentId,
  organizationRoleId,
  users = [],
  owners = [],
  canEditRole,
  goals,
  hazards,
  unitCurrencyTokenPrice,
  unitOfPrice,
  shouldShowCostPerRole,
}) => {
  const ownerUserIds = owners.map((owner) => {
    return owner.userId;
  });
  const ownerUsers = users.filter((user) => {
    return ownerUserIds.includes(user.id);
  });
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isDescriptionOpen,
    onToggle: toggleDescription,
    onClose: closeDescription,
  } = useDisclosure();
  const {
    isOpen: isOwnersOpen,
    onToggle: toggleOwners,
    onClose: closeOwners,
  } = useDisclosure();

  const department = departments?.find(
    (department) => department._id === departmentId
  );

  const navigate = useNavigate();

  const handleOpenUserProfile = (userId, inNewTab = false) => {
    const userProfileUrl = generatePath(APP_PATHS.userProfile, { userId });
    if (inNewTab) {
      window.open(userProfileUrl, "_blank", "noreferrer");
    } else {
      navigate(userProfileUrl);
    }
  };

  return (
    <>
      <Tr className="borderLess">
        <Td>{title}</Td>
        {!isEmpty(departments) && <Td>{department?.name}</Td>}
        <Td>{owners.length}</Td>
        <Td>
          <Button
            onClick={onToggle}
            variant="link"
            rightIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {isOpen ? (
                  <ChevronUpIcon color="primary" />
                ) : (
                  <ChevronDownIcon color="primary" />
                )}
              </Box>
            }
          >
            Details
          </Button>
        </Td>
        {shouldShowCostPerRole && (
          <Td>
            {unitOfPrice && unitCurrencyTokenPrice
              ? `${unitCurrencyTokenPrice}$ per ${unitOfPrice}`
              : "No cost per role assigned"}
          </Td>
        )}
      </Tr>
      <Tr>
        <Td
          colSpan={4}
          className={classNames({ noPadding: !isOpen })}
          p="5px 15px 15px"
        >
          <Collapse in={isOpen} animateOpacity>
            <RoundedBox
              bg="gray.18"
              display="flex"
              flexDirection="row"
              p={{ base: "5px 20px", md: "5px 20px" }}
              borderRadius="4px"
            >
              <Text>Last Updated: {formatDateL(date)}</Text>
              <Spacer />
              <Popover
                placement="bottom"
                isOpen={isOwnersOpen}
                onClose={closeOwners}
              >
                <PopoverTrigger>
                  <Button
                    variant="link"
                    onClick={toggleOwners}
                    isDisabled={ownerUsers.length === 0 && "true"}
                  >
                    Show owners
                  </Button>
                </PopoverTrigger>
                <PopoverContent w="md">
                  <PopoverHeader borderColor="transparent">
                    Owners
                  </PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <>
                      {ownerUsers.map(function (owner, i) {
                        return (
                          <Box whiteSpace="pre-wrap" key={i}>
                            <ProfileBox
                              size="xs"
                              userId={owner.id}
                              onClick={handleOpenUserProfile}
                              name={`${owner.firstName} ${owner.lastName}`}
                              avatarUrl={owner.avatarUrl}
                            />
                            <Divider borderColor="gray.18" mt="8px" mb="8px" />
                          </Box>
                        );
                      })}
                    </>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <Spacer />
              <Button variant="link" onClick={toggleDescription}>
                Description
              </Button>
              <PopupMdDescription
                title={title}
                description={description}
                goals={goals}
                hazards={hazards}
                organizationId={organizationId}
                departmentId={departmentId}
                organizationRoleId={organizationRoleId}
                canEditRole={canEditRole}
                closeDescription={closeDescription}
                isDescriptionOpen={isDescriptionOpen}
              />
            </RoundedBox>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};
