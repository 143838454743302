import React from "react";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  VStack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useLocation, useMatch } from "react-router-dom";
import { OrganisationsIcon } from "libs/ui-components/src";
import { APP_PATHS } from "paths";

const SidebarHeader = ({ onClick, isOpen }) => (
  <VStack>
    <Box display="flex" alignItems="center" onClick={onClick} cursor="pointer">
      <Avatar
        size="md"
        outline={isOpen ? "4px solid" : "0px"}
        outlineColor="primary"
        bgColor="gray.28"
        borderRadius="full"
        icon={<OrganisationsIcon color="gray.58" w="100%" h="100%" />}
      />
    </Box>
  </VStack>
);

const SidebarContent = ({
  organisations,
  selectedOrganisation,
  onItemClick,
  onAddClick,
}) => (
  <VStack>
    {organisations.map((organisation) => {
      const iconProps = {
        ...(!!organisation.imageUrl && {
          bgColor: "transparent",
        }),
      };
      const isOpen =
        organisation.id.toString() ===
        selectedOrganisation?.params?.organizationId;
      return (
        <Box
          key={organisation.id}
          display="flex"
          alignItems="center"
          onClick={() => onItemClick(organisation.id)}
          cursor="pointer"
        >
          <Avatar
            size="md"
            bgColor="gray.28"
            color="primary"
            outline={isOpen ? "4px solid" : "0px"}
            outlineColor="primary"
            name={organisation.name}
            src={organisation.imageUrl}
            borderRadius="full"
            {...iconProps}
          />
        </Box>
      );
    })}
    <Avatar
      size="md"
      bgColor="gray.28"
      borderRadius="full"
      onClick={onAddClick}
      cursor="pointer"
      icon={<AddIcon color="gray.58" />}
    />
  </VStack>
);

const OrganisationsSidebar = ({
  isOpen,
  variant,
  onClose,
  organisations,
  onOrganisationClick,
  onLogoClick,
  onAddClick,
}) => {
  const location = useLocation();
  const selectedOrganisation = useMatch(APP_PATHS.organisationDetails);
  const isOrganisationsOpen = location.pathname === APP_PATHS.organisations;
  const handleDrawerLogoClick = () => {
    onLogoClick();
    onClose();
  };
  const handleDrawerOrganisationClick = (id) => {
    onOrganisationClick(id);
    onClose();
  };
  const handleDrawerAddClick = () => {
    onAddClick();
    onClose();
  };
  return variant === "sidebar" ? (
    <Collapse in={isOpen} initialScale={0.9} animateOpacity>
      <Box
        position="fixed"
        left={0}
        p={3}
        w="80px"
        top={0}
        h="100%"
        bgColor="gray.12"
        borderRight="1px"
        borderColor="gray.22"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#3A3B41",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#2C2D30",
            borderRadius: "24px",
          },
        }}
      >
        <SidebarHeader onClick={onLogoClick} isOpen={isOrganisationsOpen} />
        <Divider mt="20px" mb="20px" borderColor="gray.36" />
        <SidebarContent
          organisations={organisations}
          selectedOrganisation={selectedOrganisation}
          onItemClick={onOrganisationClick}
          onAddClick={onAddClick}
        />
      </Box>
    </Collapse>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay zIndex={11000}>
        <DrawerContent bgColor="gray.12" w="120px" maxW="120px">
          <DrawerCloseButton />
          <DrawerHeader pl={0} borderBottomWidth="1px">
            <SidebarHeader
              onClick={handleDrawerLogoClick}
              isOpen={isOrganisationsOpen}
            />
          </DrawerHeader>
          <DrawerBody pl={0}>
            <SidebarContent
              organisations={organisations}
              selectedOrganisation={selectedOrganisation}
              onItemClick={handleDrawerOrganisationClick}
              onAddClick={handleDrawerAddClick}
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default OrganisationsSidebar;
