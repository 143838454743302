import React from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronRightIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Logo } from "../../../icons/logo";

export const Header = ({
  links,
  profileLinks,
  user,
  onHomeClick,
  onLoginClick,
  onAvatarClick,
  onNavigationLinkClick,
  onShowSidebar,
  showSidebarButton = false,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const menuItemProps = {
    color: "white",
    variant: "ghost",
    bg: "gray.18",
    borderRadius: 0,
    w: "100%",
    mb: "1px",
    py: "21px",
    height: "unset",
    justifyContent: "left",
    px: "20px",
    _hover: { bg: "primary" },
    _active: { bg: "primary" },
    _focus: { bg: "primary" },
  };
  const handleNavigationLinkClick = (route) => () => {
    onClose();
    if (route) onNavigationLinkClick(route);
  };
  const handleLoginClick = () => {
    onClose();
    if (onLoginClick) onLoginClick();
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
    >
      {showSidebarButton && (
        <Box>
          <IconButton
            icon={<ChevronRightIcon w={8} h={8} />}
            colorScheme="blackAlpha"
            variant="outline"
            onClick={onShowSidebar}
          />
        </Box>
      )}
      <Box>
        <Logo onClick={onHomeClick} cursor="pointer" />
      </Box>
      <Box display={{ base: "none", md: "flex" }} alignItems="center">
        <Box as="nav">
          {links.map((item, index) => (
            <Link
              key={index}
              variant="nav.link"
              color="primary"
              cursor="pointer"
              onClick={
                !item.onClick
                  ? handleNavigationLinkClick(item.route)
                  : item.onClick
              }
              mx="20px"
              _first={{ ml: "0" }}
              _last={{ mr: "0" }}
            >
              {item.label}
            </Link>
          ))}
        </Box>
        <Box ml="40px">
          {user ? (
            <Menu>
              <MenuButton
                as={Button}
                p="0"
                bg="transparent"
                outline="none"
                _hover={{ bg: "transparent", outline: "none" }}
                _focus={{ bg: "transparent", outline: "none" }}
                _expanded={{ bg: "transparent", outline: "none" }}
                data-testid="header-avatar-button"
              >
                <Avatar
                  onClick={onAvatarClick}
                  src={user.avatarUrl}
                  size="xs"
                  borderWidth="2px"
                  borderColor="primary"
                  cursor="pointer"
                />
              </MenuButton>
              <MenuList>
                {profileLinks.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={
                      !item.onClick
                        ? handleNavigationLinkClick(item.route)
                        : item.onClick
                    }
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            onLoginClick && (
              <div>
                <Button
                  id="login-sign-up-button"
                  variant="primary"
                  onClick={handleLoginClick}
                >
                  Log in / Sign up
                </Button>
              </div>
            )
          )}
        </Box>
      </Box>
      <Box display={{ base: "flex", md: "none" }} alignItems="center">
        <IconButton
          variant="ghost"
          aria-label="menu-button"
          onClick={onToggle}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        />
        {isOpen && (
          <Box
            position="absolute"
            left="0"
            right="0"
            top="100%"
            zIndex={10000}
            bg="gray.18"
          >
            {links.map((item, index) => (
              <Button
                key={index}
                _last={{ mr: "0" }}
                onClick={
                  !item.onClick
                    ? handleNavigationLinkClick(item.route)
                    : () => {
                        onClose();
                        item.onClick();
                      }
                }
                {...menuItemProps}
              >
                {item.label}
              </Button>
            ))}
            {user ? (
              <>
                <Button {...menuItemProps} cursor="initial">
                  <Avatar
                    onClick={onAvatarClick}
                    src={user.avatarUrl}
                    size="xs"
                    borderWidth="2px"
                    borderColor="primary"
                    cursor="pointer"
                  />
                  <Text as="span" ml={4} variant="h4" color="white">
                    {user.username}
                  </Text>
                </Button>
                {profileLinks.map((item, index) => (
                  <Button
                    key={index}
                    onClick={
                      !item.onClick
                        ? handleNavigationLinkClick(item.route)
                        : item.onClick
                    }
                    {...menuItemProps}
                  >
                    {item.label}
                  </Button>
                ))}
              </>
            ) : (
              onLoginClick && (
                <Button onClick={handleLoginClick} {...menuItemProps}>
                  Log in / Sign up
                </Button>
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
