import React from "react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Skeleton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { startCase, isEmpty } from "lodash";

import { pluralise } from "libs/utils/src";
import { RoundedBox } from "../../data-display/rounded-box/rounded-box";
import { PopupMdDescription } from "libs/ui-components/src/components/modals/md-description/md-description";

export const OverviewSidebar = ({
  title,
  badge,
  avatar,
  description,
  goals,
  hazards,
  loading,
  showAvatar = true,
  showBackButton = false,
  backButtonProps,
  avatarProps,
  children,
  extraChildren,
  projectId,
  departmentId,
}) => {
  const {
    isOpen: isOpenDescription,
    onClose: onCloseDescription,
    onOpen: onOpenDescription,
  } = useDisclosure();

  return loading ? (
    <Skeleton width="290px" borderRadius="24px" isLoaded={!loading} />
  ) : (
    <Box mt={showAvatar ? "-65px" : 0}>
      {showBackButton && backButtonProps?.path && (
        <RoundedBox mb="15px" p="19px 20px" display="flex">
          <Button
            mt={0}
            as="a"
            href={backButtonProps?.path}
            variant="link"
            leftIcon={
              <Box
                height="16px"
                width="16px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bg="primary"
              >
                <ArrowBackIcon color="#2D3A4A" />
              </Box>
            }
          >
            {backButtonProps?.title || "Back"}
          </Button>
        </RoundedBox>
      )}
      {showAvatar && (
        <Box display="flex" justifyContent="center" mb="-65px">
          <Avatar bgColor="gray.18" src={avatar} size="xl" {...avatarProps} />
        </Box>
      )}
      <RoundedBox
        pt={{
          base: showAvatar ? "80px" : "22px",
          md: showAvatar ? "80px" : "25px",
        }}
        width={{ base: "100%", md: "290px" }}
      >
        <VStack spacing="18px" alignItems="flex-start" mb="18px">
          {title && (
            <Text variant="h1" color="gray.96">
              {title}
            </Text>
          )}
          {badge && (
            <Badge bg="gray.18" color="#F4F3F9">
              {startCase(badge)}
            </Badge>
          )}
          {description && !projectId && !departmentId && (
            <Text>{description}</Text>
          )}
          {description && !projectId && departmentId && (
            // <Text>{description}</Text>
            <>
              <Button
                variant="link"
                fontWeight="700"
                onClick={onOpenDescription}
              >
                {!isEmpty(goals) && `${pluralise("Goal", goals.length)}`}
                {!isEmpty(hazards) &&
                  `, ${pluralise("Hazard", hazards.length)}, `}
                Description
              </Button>

              <PopupMdDescription
                title={title}
                description={description}
                closeDescription={onCloseDescription}
                isDescriptionOpen={isOpenDescription}
                goals={goals}
                hazards={hazards}
                isArray={true}
                modalWindowSize={"4xl"}
              />
            </>
          )}
          {description && projectId && (
            <>
              <Button
                variant="link"
                fontWeight="700"
                onClick={onOpenDescription}
              >
                {!isEmpty(goals) && `${pluralise("Goal", goals.length)}`}
                {!isEmpty(hazards) &&
                  `, ${pluralise("Hazard", hazards.length)}, `}
                Description
              </Button>

              <PopupMdDescription
                title={title}
                description={description}
                closeDescription={onCloseDescription}
                isDescriptionOpen={isOpenDescription}
                goals={goals}
                hazards={hazards}
                isArray={false}
              />
            </>
          )}
        </VStack>
        {children}
      </RoundedBox>
      {extraChildren}
    </Box>
  );
};
