import React from "react";
import { Avatar, Box, Text, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

const TEXT_SIZES = {
  xs: "h6",
  sm: "h5",
  md: "h4",
  lg: "h3",
  xl: "h3",
};

export const ProfileBox = ({
  userId,
  name,
  avatarUrl,
  tooltipText,
  jobTitle,
  variant = "wide",
  direction = "row",
  color = "primary",
  size = "sm",
  textMaxW = "180px",
  avatarProps,
  onClick,
  boxProps,
}) => {
  const handleClick = (e) => {
    if (onClick && userId) {
      const { button } = e;
      if (button === 0) {
        onClick(userId);
      } else if (button === 1) {
        onClick(userId, true);
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
  const iconProps = {
    ...avatarProps,
    ...(!!avatarUrl && {
      bgColor: "transparent",
    }),
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      onMouseDown={handleClick}
      flexDirection={direction === "column" ? "column" : "row"}
      cursor={onClick ? "pointer" : "default"}
      {...boxProps}
    >
      <Avatar
        size={size}
        borderRadius="full"
        src={avatarUrl}
        mr={direction === "column" ? 0 : "8px"}
        mb={direction === "column" ? "16px" : 0}
        {...iconProps}
      />
      <Box
        display="flex"
        flexDirection={variant === "compact" ? "column" : "row"}
        alignItems={variant === "compact" ? "flex-start" : "center"}
      >
        <Box display="flex">
          <Text
            as="span"
            color={color}
            variant={TEXT_SIZES[size] || "h6"}
            aria-label={name}
            isTruncated
            maxW={textMaxW}
          >
            {name}
          </Text>
          {!!tooltipText && (
            <Tooltip label={tooltipText} fontSize="sm">
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
        {!!jobTitle && (
          <Text
            as="span"
            variant="h4"
            color="gray.79"
            fontWeight={400}
            ml={variant === "compact" ? 0 : "8px"}
            mt={variant === "compact" ? "7px" : 0}
            aria-label={jobTitle}
            textTransform="capitalize"
            isTruncated
            maxW={{ base: "90%", md: "200px" }}
          >
            {jobTitle}
          </Text>
        )}
      </Box>
    </Box>
  );
};
