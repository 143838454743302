import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InfoIcon } from "@chakra-ui/icons";
import { Autocomplete } from "libs/ui-components/src";
export const FormikAutocompleteControl = ({
  onClick,
  styleProps,
  inputProps,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  const { options } = props;
  const handleClick = (value) => {
    if (onClick) {
      onClick(value);
    } else {
      helpers.setValue(value);
    }
  };
  return (
    <FormControl
      isInvalid={!!meta.error && meta.touched}
      isDisabled={props.disabled}
      isRequired={props.required}
      {...styleProps}
    >
      <Box mb="8px" display="flex" alignItems="center">
        <FormLabel>{props.label}</FormLabel>
        {!!props.tooltipLabel && (
          <Box mb={4} ml={2}>
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Autocomplete
        isClearable
        options={props.options}
        disabled={props.disabled}
        chips={meta.value}
        {...inputProps}
        {...field}
        value={options.find((v) => v.value === meta.value) || null}
        onChange={(option) => handleClick(option?.value)}
      />
      {meta.touched && meta.error && (
        <FormErrorMessage>{meta.error ?? " "}</FormErrorMessage>
      )}
    </FormControl>
  );
};
