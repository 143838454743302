import React from "react";
import { Box, CloseButton, Text } from "@chakra-ui/react";

import { RoundedBox } from "libs/ui-components/src";
import { InvoiceDataForm } from "./invoice-data-form";

export const InvoiceDataList = ({ isReadOnly, onDeleteRole, items }) =>
  items.map((item, index) => {
    return (
      <RoundedBox key={index} bg="gray.24" mb="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="15px"
        >
          <Text color="white" variant="h4">
            #{(index || 0) + 1}
          </Text>
          <CloseButton
            isDisabled={isReadOnly}
            onClick={() => onDeleteRole(index)}
          />
        </Box>
        <InvoiceDataForm index={index} disabled={isReadOnly} />
      </RoundedBox>
    );
  });
