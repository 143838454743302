import React from "react";
import { Button, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

export const AdminCreateButton = ({ ...props }) => (
  <Button
    w="100%"
    borderRadius="12px"
    border="1px"
    bg="gray.24"
    p="21.5px 25px"
    variant="link"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    rightIcon={<AddIcon w="18px" h="18px" />}
    {...props}
  >
    <Text
      variant={"h3"}
      color={props.color ?? "primary"}
      display="flex"
      flex="1 0 auto"
    >
      {props.children}
    </Text>
  </Button>
);
