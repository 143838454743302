import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";

export const PopupChildren = ({
  title,
  closeDescription,
  isDescriptionOpen,
  children,
  ...props
}) => {
  return (
    <Modal
      onClose={closeDescription}
      isOpen={isDescriptionOpen}
      isCentered
      scrollBehavior="inside"
      {...props}
    >
      <ModalOverlay />
      <ModalContent minHeight="50%" mx={5}>
        <ModalHeader
          fontSize="1.75rem"
          pl={16}
          pt={6}
          pb={3.5}
          pr={14}
          color="gray.79"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={3}>
          <Text whiteSpace="pre-wrap" mx={4} fontWeight="700">
            {children}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
