import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { identity, pickBy } from "lodash";
import { Box, Button, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { AppContainer } from "components/app-container";
import {
  FileUpload,
  FormikInputControl,
  FormikSelectControl,
  MultipleProfileSelection,
  PromptIfDirty,
  RoundedBox,
} from "libs/ui-components/src";
// import { useUserContextState } from "shared/contexts/user-context-provider";
import { useCreateOrganisationMutation } from "shared/mutations/organisation";
import { useUsers } from "shared/queries/users";
import axios from "shared/api/setup";
import { APP_PATHS } from "paths";

import { isEthereumAddress } from "services/helpers/validators";
import { ETHEREUM, SUPPORTED_CHAINS } from "services/helpers/chains";

export const NewOrganisation = () => {
  const detailsValues = {
    name: "",
    description: "",
    invoiceName: "",
    invoiceAddress: "",
    communityUrl: "",
    knowledgeBaseUrl: "",
    websiteUrl: "",
    userIds: [],
    termsAndConditionsUrl: "",
    chainId: ETHEREUM.network,
    currencyTokenContract: "",
    governanceTokenContract: "",
  };
  const navigate = useNavigate();
  const { mutate: onCreateNewOrganisation } = useCreateOrganisationMutation({
    onSuccess: (organisation) => {
      navigate(
        generatePath(APP_PATHS.organisationDetails, {
          organizationId: organisation.id,
        })
      );
    },
  });
  const handleSubmitOrganisation = async (details) => {
    if (details.logo) {
      const formData = new FormData();
      formData.append("file", details.logo);
      const { url } = await axios.post("/media/logos", formData);
      details.logo = null;
      details.imageUrl = url;
    }
    const data = {
      ...pickBy(details, identity),
    };
    onCreateNewOrganisation(data);
  };

  const { data: users, isLoading: isUserLoading } = useUsers();
  // const { data: invitedUsers, isLoading: isInvitedUserLoading } =
  //   useInvitedUsers(); //For next sprint

  if (isUserLoading) {
    return null;
  }

  let availableAssignee = users;

  // if (!isEmpty(invitedUsers)) { //For next sprint
  //   availableAssignee = users.concat(invitedUsers);
  // }

  const mappedNetworks = SUPPORTED_CHAINS.map((chain) => ({
    label: chain.name,
    value: chain.network,
  }));

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white" data-testid="page-title">
          Create a new organisation
        </Text>
      </AppContainer.Header>
      <AppContainer.Content isDetails>
        <Box>
          <Text variant="h2" color="white" mb="25px">
            Enter organisation details
          </Text>
          <RoundedBox mb="25px">
            <Formik
              enableReinitialize
              initialValues={detailsValues}
              onSubmit={handleSubmitOrganisation}
              validationSchema={Yup.object({
                name: Yup.string().required("Name is required"),
                description: Yup.string().required("Description is required"),
                invoiceName: Yup.string(),
                invoiceAddress: Yup.string(),
                websiteUrl: Yup.string().url(),
                communityUrl: Yup.string().url(),
                knowledgeBaseUrl: Yup.string().url(),
                termsAndConditionsUrl: Yup.string().url(),
                userIds: Yup.array()
                  .of(Yup.string())
                  .min(1, "Users are required"),
                chainId: Yup.string().oneOf(
                  SUPPORTED_CHAINS.map((chain) => chain.network),
                  "Please select valid network"
                ),
                currencyTokenContract: Yup.string().test(
                  "is-valid",
                  "Put valid ERC20 token in format '0x...'",
                  isEthereumAddress
                ),
                governanceTokenContract: Yup.string().test(
                  "is-valid",
                  "Put valid ERC20 token in format '0x...'",
                  isEthereumAddress
                ),
              })}
            >
              {({ setFieldValue, values, errors, dirty, submitCount }) => (
                <Form id="detailsForm" noValidate>
                  <PromptIfDirty showPrompt={dirty && submitCount === 0} />
                  <FormikInputControl
                    label="Organisation name"
                    name="name"
                    required={true}
                    placeholder="Deep Work Studio"
                    styleProps={{
                      mb: "32px",
                    }}
                  />
                  <FormikInputControl
                    label="Description"
                    type="text"
                    name="description"
                    required={true}
                    placeholder="A collective of designers with shared profits creating meaningful projects in web3."
                    styleProps={{
                      mb: "32px",
                    }}
                  />
                  <FormikInputControl
                    label="Name on invoice"
                    type="text"
                    name="invoiceName"
                    required={false}
                    placeholder="Deep Work Ltd."
                    styleProps={{
                      mb: "32px",
                    }}
                  />
                  <FormikInputControl
                    label="Address on invoice"
                    type="text"
                    name="invoiceAddress"
                    required={false}
                    placeholder="Intershore Chambers, Road Town, Tortola, British Virgin Islands"
                    styleProps={{
                      mb: "32px",
                    }}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mb="32px"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <FormikInputControl
                      label="Website URL"
                      name="websiteUrl"
                      placeholder="https://deepwork.studio"
                      styleProps={{
                        mr: { base: "0", md: "30px" },
                        mb: { base: "32px", md: 0 },
                      }}
                    />
                    <FormikInputControl
                      label="Community URL"
                      name="communityUrl"
                      placeholder="https://discord.gg/hamFDG5f"
                      styleProps={{
                        mr: { base: "0", md: "30px" },
                        mb: { base: "32px", md: 0 },
                      }}
                    />
                    <FormikInputControl
                      label="Knowledge base URL"
                      name="knowledgeBaseUrl"
                      placeholder="https://docs.deepwork.studio"
                    />
                  </Box>
                  <FormikInputControl
                    label="Link to the terms &amp; conditions document"
                    name="termsAndConditionsUrl"
                    placeholder="Document URL..."
                    required={false}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt="32px"
                    mb="32px"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <MultipleProfileSelection
                      label="Members"
                      possibleProfiles={availableAssignee ?? []}
                      selectedUserIds={values.userIds}
                      required={true}
                      error={errors.userIds}
                      showError={!!errors.userIds}
                      onChange={(value) =>
                        setFieldValue(
                          "userIds",
                          value?.map((item) => item.value)
                        )
                      }
                    />
                  </Box>
                  <Box
                    p={0}
                    mt="32px"
                    mb="32px"
                    display="flex"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <FormikSelectControl
                      label="Blockchain"
                      name="chainId"
                      options={mappedNetworks}
                      styleProps={{
                        mb: "21px",
                        mr: "15px",
                        flexBasis: "content",
                      }}
                    />
                    <FormikInputControl
                      label="Primary Payment Token (ERC-20)"
                      required={false}
                      name="currencyTokenContract"
                      placeholder="0x..."
                      tooltipLabel="Stable only accepted"
                      styleProps={{ mb: "21px", mr: "15px" }}
                    />
                    <FormikInputControl
                      label="Secondary Token (ERC-20)"
                      required={false}
                      name="governanceTokenContract"
                      placeholder="0x..."
                      tooltipLabel="Stable only accepted"
                      styleProps={{ mb: "21px" }}
                    />
                  </Box>
                  <FormControl>
                    <FormLabel>Logo</FormLabel>
                    <FileUpload name="logo" onChange={setFieldValue} />
                  </FormControl>
                </Form>
              )}
            </Formik>
          </RoundedBox>
          <Button type="submit" form="detailsForm" data-testid="next-btn">
            Create
          </Button>
        </Box>
      </AppContainer.Content>
    </>
  );
};
