import React from "react";
import { RoundedBox } from "libs/ui-components/src";
import { Text } from "@chakra-ui/react";
import { pluralise } from "../../../../../utils/src";
import { FunctionListItem } from "./function-list-item";

export const FunctionsList = ({
  handleChangeFunction,
  onDeleteFunction,
  functions,
  users,
  setOpenWarning,
}) => {
  const handleSubmitFunction = (values) => {
    handleChangeFunction({ ...values });
    setOpenWarning(false);
  };
  const handleDeleteFunction = (id) => {
    onDeleteFunction(id);
  };
  return (
    <>
      {functions.length > 0 && (
        <RoundedBox m="17px 0 25px" bgColor="gray.22">
          {functions.map((item, index) => (
            <FunctionListItem
              key={index}
              item={item}
              onDelete={handleDeleteFunction}
              onSubmit={handleSubmitFunction}
              users={users}
              setOpenWarning={setOpenWarning}
              boxProps={{
                _notLast: {
                  mb: "13px",
                },
              }}
            />
          ))}
          <Text mt="17px" variant="small" color="gray.58">
            {functions.length} {pluralise("role", functions.length)}
          </Text>
        </RoundedBox>
      )}
    </>
  );
};
