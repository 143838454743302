import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "libs/utils/src";
import { useState } from "react";

export var BadgeType;
((BadgeType) => {
  BadgeType[(BadgeType["shadowed"] = 0)] = "shadowed";
  BadgeType[(BadgeType["completed"] = 1)] = "completed";
})(BadgeType || (BadgeType = {}));

const ToggleItem = ({ value, onChange, disabled, selected, label }) => {
  const handleToggle = () => {
    if (!disabled) {
      onChange(value);
    }
  };
  return (
    <Box
      borderRadius="24px"
      cursor="pointer"
      bg={
        selected === value ? (disabled ? "gray.36" : "primary") : "transparent"
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="26px"
      width="92px"
      onClick={handleToggle}
    >
      <Text
        p="4px"
        color={
          selected === value ? (disabled ? "gray.50" : "white") : "gray.50"
        }
        variant="button"
      >
        {capitalizeFirstLetter(label)}
      </Text>
    </Box>
  );
};
export const BadgeTypeToggle = ({ onChange, disabled, value }) => {
  const [selected, setSelected] = useState(value);
  const handleToggle = (value) => () => {
    if (!disabled) {
      setSelected(value);
      onChange && onChange(value);
    }
  };
  return (
    <Box
      w="192px"
      h="34px"
      bg="gray.28"
      p="4px"
      borderRadius="24px"
      display="flex"
    >
      <ToggleItem
        value={BadgeType.shadowed}
        onChange={handleToggle(BadgeType.shadowed)}
        disabled={!!disabled}
        selected={selected}
        label={"shadowed"}
      />
      <ToggleItem
        value={BadgeType.completed}
        onChange={handleToggle(BadgeType.completed)}
        disabled={!!disabled}
        selected={selected}
        label={"completed"}
      />
    </Box>
  );
};
