import React from "react";
import { CreatableSelect } from "chakra-react-select";

export const CreatableAutocomplete = ({ onCreateOption, ...selectProps }) => {
  const chakraStyles = {
    input: (provided) => ({
      ...provided,
      pt: "6px",
      pb: "6px",
    }),
  };
  return (
    <CreatableSelect
      components={{
        DropdownIndicator: null,
      }}
      chakraStyles={chakraStyles}
      onCreateOption={onCreateOption}
      {...selectProps}
    />
  );
};
