import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { onApiError } from "../helpers/onApiError";

import axios from "../api/setup";

export const useCompletePaymentMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.put("/completePayment", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};

export const useUnsafeCompletePaymentMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.put("/bulkUnsafeCompletePayment", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useMintPaymentMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/mintFinishedProjectNFTs", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};

export const useIncomePaymentMutation = (options) => {
  const toast = useToast();
  return useMutation(async (data) => axios.post("/createIncomePayment", data), {
    onError: (error) => {
      onApiError(error, toast);
    },
    ...options,
  });
};
