import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  column: {
    flexDirection: "column",
  },
  alert: {
    color: "#a61c00",
    fontSize: 12,
    textAlign: "left",
    marginTop: 3,
  },
  companyTitle: {
    color: "#000",
    fontSize: 14,
    textAlign: "left",
  },
  companyText: {
    color: "#000",
    fontSize: 11,
    textAlign: "left",
  },
  grayText: {
    color: "#444",
  },
  title: {
    color: "#000",
    fontSize: 25,
    textAlign: "center",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
  },
  container: {
    flexDirection: "row",
    backgroundColor: "#eee",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    flexGrow: 1,
  },
  header: {
    fontWeight: "bold",
    fontSize: 14,
  },
  description: {
    width: "60%",
    textAlign: "left",
  },
  qty: {
    width: "10%",
    textAlign: "right",
  },
  rate: {
    width: "15%",
    textAlign: "right",
  },
  amount: {
    width: "15%",
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: 12,
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: 12,
    borderTopColor: "#ddd",
    borderTopWidth: 1,
  },
  subtotalDescription: {
    width: "85%",
    textAlign: "right",
  },
  subtotal: {
    width: "15%",
    textAlign: "right",
  },
});
