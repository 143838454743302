import { useToast } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { onApiError } from "../helpers/onApiError";

import axios from "../api/setup";

export const useImportProjectMutation = (options) => {
  const toast = useToast();
  return useMutation(
    async (data) => axios.post("/importDeepSkillsProject", data),
    {
      onError: (error) => {
        onApiError(error, toast);
      },
      ...options,
    }
  );
};
