import React from "react";
import { Box } from "@chakra-ui/react";
import { AppHeader } from "./AppHeader";
import { AppContent } from "./AppContent";

export const AppContainer = ({ children }) => (
  <Box display="flex" flexDirection="column" minH={`calc(100vh - 100px)`}>
    {/* // 100px - height of header */}
    {children}
  </Box>
);

AppContainer.Header = AppHeader;
AppContainer.Content = AppContent;
